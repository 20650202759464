/*
Theme: Default CeeView Theme
Version: 1.0
Description: App Theme Version for CeeView.
* */
@import "oxygen.css";
@import "material.css";
@import "jquery.gridster.css";
@import "ceesoft-sort.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html [class^="cw_"] {
  box-sizing: border-box;
}
ul {
  list-style: none;
}
li {
  list-style-type: none;
}
.statusBackground1 {
  background: #e45f48;
}
.statusBackground2 {
  background: #f3b749;
}
.statusBackground3 {
  background: #f7e400;
}
.statusBackground4 {
  background: #7cc623;
}
.statusBackground5 {
  background: #7cc623;
}
.statusBackground6 {
  background: #c4c4c4;
}
.scrollable {
  overflow-y: scroll;
}
/* Colors */
a:not(.ant-btn),
a:link:not(.ant-btn),
a:visited:not(.ant-btn) {
  text-decoration: none;
  cursor: pointer;
}
a:hover:not(.ant-btn),
a:active:not(.ant-btn) {
  font-weight: bolder;
}
/* Helpers */
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.light {
  font-weight: 300;
}
.normal {
  font-weight: 400;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.uppercase {
  text-transform: uppercase;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.break-word {
  word-break: break-all;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline_block {
  display: inline-block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.left {
  float: left;
}
.right {
  float: right;
}
.go_left {
  float: left !important;
}
.go_right {
  float: right !important;
}
.no_float {
  float: none !important;
}
.text_left {
  text-align: left;
}
.text_right {
  text-align: right !important;
}
.text_center {
  text-align: center !important;
}
.dark_txt_shadow {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
.visible_overflow {
  overflow: visible !important;
}
.clearfix:after {
  clear: both;
  height: 0;
  display: block;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  content: ".";
}
.clearfix {
  zoom: 1;
}
.clearleft {
  clear: left;
}
.clear {
  clear: both;
}
.hidden {
  visibility: hidden;
}
.hide,
.no_icon {
  display: none !important;
}
.show {
  display: block !important;
}
.no_bg {
  background: none !important;
}
.no_border {
  border: none !important;
}
.no_padding {
  padding: 0 !important;
}
.no_margin {
  margin: 0 !important;
}
.no_margin_top {
  margin-top: 0 !important;
}
.margin10 {
  margin: 10px;
}
.margin_left4 {
  margin-left: 4px !important;
}
.indent_left20 {
  padding-left: 20px !important;
}
.indent_left10 {
  padding-left: 20px !important;
}
.indent_top3 {
  padding-top: 3px !important;
}
.marginRight10 {
  margin-right: 10px;
}
.marginRight30 {
  margin-right: 30px;
}
.marginTopMinus20 {
  margin-top: -20px;
}
.marginTop35 {
  margin-top: 35px;
}
.white {
  color: #fff;
}
.black {
  color: #000000 !important;
}
.half {
  width: 48% !important;
}
.w5 {
  width: 5%;
}
.w10 {
  width: 10%;
}
.w15 {
  width: 15%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w33 {
  width: 33%;
}
.w34 {
  width: 34%;
}
.w35 {
  width: 35%;
}
.w40 {
  width: 40%;
}
.w45 {
  width: 45%;
}
.w49_3 {
  width: 49.3%;
}
.w50 {
  width: 50% !important;
}
.w55 {
  width: 55%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w75 {
  width: 75%;
}
.w80 {
  width: 80%;
}
.w85 {
  width: 85%;
}
.w95 {
  width: 95%;
}
.w100 {
  width: 100%;
}
.w-auto {
  width: auto !important;
}
.w100-important {
  width: 100% !important;
}
.w130 {
  width: 129px !important;
}
.h100 {
  height: 100%;
}
.h100-important {
  height: 100% !important;
}
.h15 {
  height: 15px !important;
}
.h40 {
  height: 40px;
}
.no_rights {
  -moz-opacity: 0.3;
  -khtml-opacity: 0.3;
  -webkit-opacity: 0.3;
  opacity: 0.3;
}
.pointer {
  cursor: pointer !important;
}
.pointer.is_disabled {
  cursor: default !important;
}
.cursor_move {
  cursor: move !important;
}
.cursor {
  cursor: default !important;
}
.move {
  cursor: move;
}
.no_resize {
  resize: none;
}
.scroll_y {
  overflow-y: auto;
}
.scroll_x {
  overflow-x: auto;
}
.no_bullet {
  list-style: none;
}
.text_color1 {
  color: #e45f48;
}
.text_color2 {
  color: #f3b749;
}
.text_color3 {
  color: #f7e400;
}
.text_color5 {
  color: #7cc623;
}
.text_color6 {
  color: #aaaaaa;
}
.bg_color1 {
  background: #e45f48;
}
.bg_color2 {
  background: #f3b749;
}
.bg_color3 {
  background: #f7e400;
}
.bg_color5 {
  background: #7cc623;
}
.bg_color6 {
  background: #ddd;
}
.bg_color7 {
  background-color: #f1ae31;
}
.bg_light_grey {
  background-color: #e5e5e5 !important;
}
.vertical {
  display: table-cell;
  vertical-align: middle;
}
.cw_link {
  color: #709bba;
  cursor: pointer !important;
}
.gold {
  color: #ecc814 !important;
}
.split3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
.padding_left_5 {
  padding-left: 5px;
}
.opacity {
  opacity: 0.5;
}
.disabled_cell {
  pointer-events: none;
  background-color: #e1e1e1;
}
.no_pointer_events {
  pointer-events: none;
}
.required:before {
  content: "*";
  position: absolute;
  left: 155px;
  color: red;
  line-height: initial;
  z-index: 999999;
}
.required > input::placeholder {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf !important;
}
.required_input:after {
  content: "*";
  position: absolute;
  top: 0px;
  left: 4px;
  color: red;
  line-height: initial;
}
.required_form:before {
  content: "*";
  position: absolute;
  left: 142px;
  color: red;
  line-height: initial;
  z-index: 999999;
}
.required_form > input::placeholder {
  color: #bfbfbf;
}
.required_field:before {
  content: "*";
  top: 25px;
  right: 25px;
  color: red;
  line-height: initial;
}
.required_textarea:before {
  content: "*";
  position: absolute;
  top: 25px;
  left: 19px;
  color: red;
  line-height: initial;
}
.required_multiselect:before {
  content: "*";
  position: absolute;
  top: 0px;
  left: 4px;
  color: red;
  line-height: initial;
  z-index: 999909;
}
.required_vmware:before {
  content: "*";
  position: absolute;
  left: 135px;
  color: red;
  line-height: initial;
  z-index: 999999;
}
.required_text:before {
  content: "*";
  position: absolute;
  left: 150px;
  color: red;
  line-height: initial;
  z-index: 999999;
}
.required_text > input::placeholder {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf;
}
.cw_reasons_dialog {
  padding-left: 10px;
}
.k-grid .glyphicons {
  font-size: 18px;
}
.red {
  color: #e14b32;
}
.green {
  color: green;
}
.capitalize_title {
  text-transform: capitalize;
}
.required_name:before {
  content: "*";
  position: absolute;
  top: 20px;
  left: 142px;
  color: red;
  line-height: initial;
  z-index: 999999;
}
.required_name > input::placeholder {
  font-size: 12px;
  color: #bfbfbf;
}
.required_select:before {
  content: "*";
  position: absolute;
  top: 2px;
  color: red;
  line-height: initial;
  z-index: 999909;
}
.required_textbox:before {
  content: "*";
  position: absolute;
  color: red;
  left: 165px;
  line-height: initial;
  z-index: 999999;
}
.required_password:before {
  content: "*";
  position: absolute;
  color: red;
  margin-top: -2px;
  margin-left: 135px;
  z-index: 999999;
}
.font_size_20 {
  font-size: 20px;
}
.default-grid-norecords {
  height: 45px;
}
.required_sla:after {
  content: "*";
  position: absolute;
  top: 0px;
  left: 0px;
  color: red;
  line-height: initial;
}
.required_sla > span .k-input {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf;
}
.required_logtypes:before {
  content: "*";
  position: absolute;
  top: 0px;
  left: 2px;
  color: red;
  line-height: initial;
}
.required_logtypes.cw_eventlog_logtypes {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf;
}
/* Typography */
body {
  overflow: hidden;
  font: 12px/1.5 'Oxygen', Arial, sans-serif;
  color: #54595E;
  text-align: left;
}
/* General Layout */
html,
body {
  height: 100%;
}
.row {
  position: absolute;
  right: 0;
  left: 0;
}
.main_content {
  top: 60px;
  bottom: 0;
  padding: 10px;
}
@media (max-width: 1400px) {
  .main_content {
    top: 120px;
  }
}
.main_content.is_minimized_nav .aside {
  width: 60px;
}
.main_content.is_minimized_nav #content_area {
  left: 60px;
}
.main_content.is_minimized_nav .cw_toggle_nav {
  left: 44px;
}
.main_content.is_minimized_nav .cw_logo_negative {
  display: none !important;
}
.main_content.is_minimized_nav .cw_main_nav .label {
  display: none !important;
}
.main_content.is_minimized_nav .cw_context_action {
  display: none !important;
}
.main_content.is_minimized_nav .cw_multi_caret {
  width: 40px;
  border-radius: 4px;
}
.main_content.is_minimized_nav .cw_multi_options {
  left: 65px;
}
.main_content.is_minimized_nav .cw_nav_secondary {
  left: 60px;
  width: 200px;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.main_content #content_area {
  left: 200px;
}
#content_area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: #dee8ec;
  display: flex;
}
#content_area.mask:before,
#content_area.busy:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #07d;
  border-bottom-color: #07d;
  animation: spinner 0.8s ease infinite;
  z-index: 100000;
}
#content_area.mask:after,
#content_area.busy:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  overflow: hidden;
  z-index: 99999;
  background-color: #dee8ec;
}
#content_area.busy:after {
  opacity: 0.5;
}
#content_area.mask.mask_hide:after {
  opacity: 0;
  transition: opacity 0.3s;
}
#main_loading_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #dee8ec;
}
#modal {
  float: left;
  width: 100%;
  overflow: visible !important;
  background: none;
  padding-bottom: 56px;
}
#authentication_modal {
  float: left;
  width: 100%;
  overflow: auto !important;
  background: none;
}
#authentication_modal .cw_actions {
  width: 100%;
}
#modal .actions {
  position: absolute;
}
/* Generic Elements */
/* Main Content Titlebar */
.cw_content_section {
  width: auto !important;
  margin: 0 !important;
  border-radius: 0;
}
.cw_content_preview,
.cw_content_main {
  position: absolute;
  left: 15px;
  right: 15px;
}
.cw_content_preview {
  top: 15px;
  height: 228px;
}
.cw_content_main {
  top: 268px;
  bottom: 15px;
  overflow-y: auto;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
/* Header */
/**********/
.site-header {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  height: 60px;
  flex-wrap: wrap;
  position: relative;
}
@media (max-width: 1400px) {
  .site-header {
    height: 120px;
  }
}
.site-header:before {
  content: "";
  position: absolute;
  top: 59px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4999;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}
.site-header__navigation {
  display: flex;
  align-items: center;
}
@media (max-width: 1400px) {
  .site-header__navigation {
    order: 20;
    min-width: 400px;
  }
}
.site-header__navigation > * {
  margin-left: 15px;
}
.site-header__navigation > *:first-child {
  margin-left: 0;
}
.logo {
  width: 200px;
  height: 60px;
  border-right: 1px solid #eee;
}
.logo.is_hidden {
  display: none;
}
.logo a {
  width: 180px;
  height: 40px;
  margin: 12px 0 0 10px;
  object-fit: scale-down;
  display: block;
}
.cw_logo_custom {
  float: left;
  width: 200px;
  height: 60px;
  border-right: 1px solid #eee;
  text-align: center;
  margin-left: 0 !important;
}
.cw_logo_custom.is_hidden {
  display: none;
}
.cw_logo_custom img {
  width: 200px;
  height: 60px;
}
.cw_logo_negative {
  position: absolute;
  z-index: 0;
  bottom: 0;
  width: 100px;
  height: 30px;
  margin: 10px 50px;
  background: url("~styles/shared/images/app_theme//negative-logo.png") no-repeat center center;
  background-size: contain;
}
/* Breadcrumbs */
.cw_breadcrumb {
  float: left;
  margin: 15px 10px 0 0;
  height: 26px;
  text-indent: 0;
  font-size: 14px;
}
.cw_breadcrumb .current {
  color: #555;
  cursor: default !important;
}
.cw_breadcrumb .inactive {
  color: #555;
  cursor: not-allowed;
}
.cw_breadcrumb .cw_divider {
  float: left !important;
  height: 26px;
  line-height: 26px;
  font-size: 26px;
  font-weight: 300;
  font-size: 11px;
  color: #999;
  text-align: center !important;
  padding: 0 8px !important;
}
.cw_breadcrumb_item {
  float: left;
  height: 26px;
  line-height: 26px;
  color: #257cba;
  cursor: pointer !important;
}
.no_child_accounts .caret,
.no_rights .caret {
  display: none !important;
}
/* Current Account */
.cw_account_context {
  background: #fff;
  height: 24px;
  line-height: 24px;
  font-size: 15px;
  font-weight: 700;
  color: #257cba;
  cursor: pointer !important;
}
.cw_account_context.no_child_accounts,
.cw_account_context.no_rights {
  background: none;
  border: none;
  color: #555;
  padding: 0 5px;
  cursor: default !important;
}
.cw_account_context .caret {
  float: right;
  margin: 7px -15px 0 0;
}
/* Select Account*/
.cw_accounts_container {
  float: left;
  width: 100%;
}
.cw_accounts_tree {
  width: 100%;
  min-height: 300px;
  max-height: calc(100vh - 180px);
  padding: 10px;
  overflow: auto;
  box-shadow: 0 1px 0 #fff;
  margin-bottom: 60px;
}
.cw_accounts_tree .k-treeview-lines {
  overflow-y: auto;
}
.cw_accounts_selector {
  float: left;
  width: 100%;
  background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}
.cw_accounts_selector .k-button {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  line-height: 30px;
  text-align: center !important;
}
.cw_accounts_selector .glyphicons {
  font-size: 16px;
}
.cw_accounts_selector .k-button:first-child {
  margin-left: 0;
}
.cw_accounts_selector .cw_search_box {
  width: 235px;
  margin-left: 5px;
  text-indent: 10px;
}
.cw_account_drop {
  display: none !important;
  position: absolute;
  top: 50px;
  left: 200px;
  width: 360px;
  height: 500px;
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.cw_account_drop .cw_accounts_container {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}
.cw_account_drop.is_open {
  display: block !important;
  display: flex !important;
  flex-flow: column nowrap;
}
.cw_account_drop .cw_actions {
  padding: 5px 10px;
  height: 57px;
}
/* Mini Status */
.cw_statuses {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cw_statuses .cw_status_mini {
  margin-right: 3px;
  height: 20px;
}
.cw_statuses .cw_status_mini .cw_icon {
  float: left;
  height: 100%;
  text-align: right;
  background-color: #fff;
}
.cw_statuses .cw_status_mini .glyphicons {
  margin: 0 3px 0 10px;
  font-size: 24px;
  height: 20px;
  line-height: 20px;
  color: #aaa;
}
.cw_statuses .cw_status_mini .cw_value {
  float: left;
  height: 100%;
  padding: 2px 8px;
  margin: 0 0 0 1px;
  font-size: 11px;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.cw_statuses .cw_status_mini .cw_value:hover {
  cursor: pointer !important;
}
.cw_statuses .cw_status_mini .cw_value.is_major {
  background-color: #f1ae31;
  color: #fff;
}
.cw_statuses .cw_status_mini .cw_value.is_critical {
  background-color: #e14b32;
  color: #fff;
}
.cw_statuses .cw_status_mini .cw_value.is_minor {
  background-color: #f7e400;
}
.cw_statuses .cw_status_mini .cw_value.is_idle {
  background-color: #c4c4c4;
  color: #fff;
}
/* Logged User Menu*/
.cw_userinfo {
  position: relative;
  border-left: 1px solid #d8d8d8;
}
.cw_userinfo > .cw_item {
  float: right;
  padding: 15px 10px;
  height: 60px;
  line-height: 60px;
  list-style: none;
  text-shadow: 0 1px 0 #fff;
  cursor: pointer !important;
}
.cw_userinfo > .cw_item:hover {
  background-color: #f5f5f5;
}
.cw_userinfo > .is_selected {
  position: relative;
  padding: 15px 9px;
  border: 1px solid #90c5e8;
  border-top: none;
  border-bottom: none;
  color: #54595E;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #cfe6f5;
}
.cw_userinfo .cw_logged_user {
  float: left;
  height: 30px;
  line-height: 30px;
}
.cw_userinfo .cw_current_user .caret {
  float: left;
}
.cw_userinfo .glyphicons {
  float: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  color: #aaa;
}
.cw_userinfo .cw_current_user img {
  float: left;
  margin-left: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-top: -10px;
  border: 1px solid #d8d8d8;
}
.cw_userinfo .glyphicons#cw_user_icon {
  margin-left: 10px;
  font-size: 45px;
}
.cw_userinfo .cw_value {
  float: left;
  height: 20px;
  line-height: 16px;
  padding: 2px 8px;
  margin: 3px 0 0 10px;
  font-size: 11px;
  border-radius: 3px;
  background-color: #e14b32;
  color: #fff;
  text-shadow: none;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.cw_systemerror_notifications .cw_value,
.cw_event_summary_notifications .cw_value,
.cw_notifications .cw_value,
.cw_last_changes_notifications .cw_value {
  height: 22px;
  background-color: #fff;
  color: #333;
  border: 1px solid #aaa;
  box-shadow: none;
}
.cw_open_panels {
  width: 260px;
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 99999;
}
.cw_user_actions.is_open {
  display: block;
}
.cw_user_actions {
  display: none;
}
.cw_user_actions,
.cw_user_actions ul {
  border: 1px solid #bbb;
  background: #bfbfbf;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.cw_user_actions .cw_item {
  border-width: 0 0 1px;
  border-bottom: 1px solid #d8d8d8;
  background-color: #fff;
}
.cw_user_actions .cw_item:last-child {
  border: none;
}
.cw_user_actions a {
  padding: 8px 14px;
  color: #777;
  text-align: left;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  background-color: #FFFFFF;
}
.cw_user_actions .cw_item a:hover,
.cw_user_actions .cw_item ul li:hover {
  background-color: #cfe6f5;
  color: #3B3E42;
}
.caret {
  float: right;
  display: inline-block;
  width: 20px;
  height: 10px;
  margin: 10px 0 0;
  background: url("~styles/shared/images/app_theme//caret.png") no-repeat center right;
}
.cw_user_actions ul {
  width: 150px;
}
.cw_user_actions ul .k-context-menu.k-menu-vertical > .k-item > .k-link,
.k-menu .k-menu-group .k-item > .k-link {
  padding: 0.5em 1.1em 0.4em;
}
/* Notifications */
.cw_user_notifications.is_open {
  display: block;
}
.cw_user_notifications {
  display: none;
  position: absolute;
  top: 0px;
  right: 0;
  width: 260px;
  overflow: hidden;
  border: 1px solid #ccc;
  background: #bfbfbf;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.cw_user_notifications .cw_item {
  float: left;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #d8d8d8;
  background-color: #fff;
}
.cw_user_notifications .cw_item:last-child {
  border-bottom: none;
  cursor: pointer !important;
}
.cw_user_notifications .cw_notifications_header,
.cw_message_center {
  color: #3B3E42;
  font-size: 13px;
  font-weight: 700;
  background-color: #fbfbfb;
}
.cw_user_notifications h2 {
  margin-bottom: 5px;
  font-size: 13px;
  color: #3B3E42;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_user_notifications p {
  margin: 0;
  line-height: 1.4;
  color: #6c7279;
}
.cw_user_notifications .cw_notification_date {
  float: left;
  font-weight: 700;
  padding: 4px 0 0;
  color: #54595E;
}
.cw_user_notifications .cw_notification:hover {
  background-color: #cfe6f5;
}
.cw_mark_as_read {
  float: right;
  font: 700 15px/1.5 'Oxygen', Arial, sans-serif;
}
.cw_mark_as_read .glyphicons {
  float: left;
  font-size: 16px;
  font-weight: 700;
  margin: 3px 6px;
}
.cw_mark_as_read .glyphicons:hover {
  color: #555;
  font-size: 22px;
}
/* Include Subaccounts */
.cw_include_subaccounts {
  margin-left: 15px;
  text-indent: 0;
}
.cw_include_subaccounts .cw_item {
  padding: 5px 10px;
}
.cw_include_subaccounts .cw_icon {
  float: left;
  display: block;
  width: 14px;
  height: 14px;
}
.cw_include_subaccounts .cw_item:first-child .cw_icon {
  background: url("~styles/shared/images/app_theme//cw_include_account.png") no-repeat;
}
.cw_include_subaccounts .cw_item:last-child .cw_icon {
  background: url("~styles/shared/images/app_theme//cw_include_subaccount.png") no-repeat;
}
/* User Tags */
.cw_usertags_section.k-content {
  padding: 20px;
}
.cw_usertags_section.k-content .cw_show_untagged {
  text-indent: 0;
}
.cw_usertags_section.k-content .actions {
  position: absolute;
  left: 0;
}
.cw_usertags_section.k-content .k-multiselect-wrap {
  max-height: 200px;
  overflow-y: auto;
}
.cw_usertags_section.k-content fieldset {
  position: relative;
}
.cw_usertags_section.k-content .cw_white_mask {
  background-color: #fff !important;
  opacity: 1;
  margin-top: 15px;
}
@-moz-document url-prefix() {
  .cw_usertags_section.k-content .cw_white_mask {
    margin-top: -6px;
  }
}
.cw_show_untagged,
.cw_ignore_tags {
  margin: -5px 0 10px;
}
.cw_tags_window .k-resize-handle.k-resize-se {
  background: transparent;
  box-shadow: none;
}
.cw_tags_window .k-resize-handle.k-resize-sw {
  opacity: 1;
  z-index: 1000000;
  left: 1px;
  bottom: 1px;
  width: 12px;
  height: 12px;
  background: transparent;
  box-shadow: inset 1px -1px 0 #ddd, inset 2px -2px 0 #eee, inset 3px -3px 0 #bbb;
}
.cw_usertag legend span,
.cw_teamtags legend span {
  padding: 0 10px;
  font-weight: bold;
}
.cw_usertag .active,
.cw_teamtags .active {
  color: #7cc623;
}
.cw_usertag .inactive,
.cw_teamtags .inactive {
  color: #e14b32;
}
.edit_mode {
  float: right;
  margin-top: 18px;
}
/* Aside */
/*********/
.aside {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  background-color: #343f49;
}
/* CW_Multi_Content */
.cw_multi_content {
  float: left;
  position: relative;
  margin: 15px 10px;
}
.cw_multi_content .cw_context_action {
  float: left;
  width: 155px;
  border: none;
  padding: 0;
  text-align: center !important;
  font-size: 1.1em;
  color: #fff;
  height: 35px;
  line-height: 32px;
  border-radius: 3px 0 0 3px;
  background-color: #00aeef;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
}
.cw_multi_content .cw_context_action.no_rights {
  cursor: default !important;
}
.cw_multi_content .cw_multi_caret {
  float: left;
  width: 25px;
  text-align: center !important;
  height: 35px;
  line-height: 32px;
  background-color: #0089bc;
  cursor: pointer !important;
}
.cw_multi_content .cw_multi_caret .glyphicons {
  color: #fff;
  text-shadow: none;
}
.cw_multi_caret {
  border: none;
  cursor: pointer !important;
  border-radius: 0 3px 3px 0;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
}
.cw_multi_caret .glyphicons {
  height: 35px;
  line-height: 32px;
}
.cw_multi_options.is_open {
  display: block !important;
}
.cw_multi_options {
  display: none !important;
  position: absolute;
  top: 0;
  left: 205px;
  width: 420px;
  padding: 10px;
  background-color: #0089bc;
  border-radius: 3px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}
.cw_multi_current {
  display: none !important;
  float: left;
  width: 180px;
  padding: 10px;
  margin: -10px 0 -10px -210px;
  background-color: #006489;
}
.cw_multi_current .cw_item {
  text-indent: 10px;
}
.cw_multi_half {
  float: left;
  width: 50%;
}
.cw_multi_content .cw_item {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 38px;
  color: #ddd;
  margin: 5px 0 0;
  cursor: pointer !important;
}
.cw_multi_content .cw_item:first-child {
  margin: 0;
}
.cw_multi_content .cw_item:hover {
  color: #fff;
  background-color: #00aeef;
}
.cw_multi_content .cw_item.is_disabled {
  color: #bbb;
}
.cw_multi_content .cw_item.is_disabled:hover {
  background-color: #fff;
  cursor: not-allowed;
  box-shadow: none;
}
.cw_multi_content .cw_item.is_disabled .glyphicons {
  opacity: 0.4;
}
.cw_multi_content .cw_item .glyphicons {
  width: 40px;
  font-size: 20px;
  line-height: 38px;
  text-align: center !important;
}
/* Main Navigation */
.cw_main_nav {
  z-index: 5;
  position: absolute;
  top: 65px;
  bottom: 0;
  left: 0;
  width: 100%;
}
.cw_main_nav .cw_item {
  float: left;
  width: 100%;
  padding: 5px 30px 5px 10px;
  cursor: pointer !important;
  background-color: #343f49;
}
.cw_main_nav .cw_item:hover {
  background-color: #2a323a;
  color: #fff;
}
.cw_main_nav > .cw_item:last-child {
  border-top: 1px solid #555666;
}
.cw_main_nav .glyphicons,
.cw_main_nav .label {
  float: left;
  display: block;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  color: #969fa8;
  text-decoration: none;
}
.cw_main_nav .glyphicons {
  width: 40px;
  text-align: center !important;
  font-size: 24px;
  text-shadow: none;
}
.cw_main_nav .label {
  width: 120px;
  text-indent: 10px;
}
.cw_main_nav .current {
  background-color: #2a323a;
}
.cw_main_nav .current .glyphicons,
.cw_main_nav .current .label {
  color: #fff;
}
.cw_nav_tier2 {
  float: left;
  width: 100%;
  list-style: none;
}
.cw_nav_tier2 .cw_item {
  width: auto;
  margin-left: 20px;
  padding: 0 0 0 20px;
}
.cw_nav_tier2 .label {
  height: 28px;
  line-height: 28px;
}
.cw_shortcuts:hover .cw_item {
  background-color: transparent;
}
/* Secondary Navigation */
.cw_nav_secondary {
  display: none !important;
  width: 100%;
  position: absolute;
  top: 65px;
  bottom: 0;
  background-color: #343f49;
}
.cw_nav_secondary.expanded {
  display: block !important;
}
.cw_nav_secondary.up-to-20 .cw_item {
  padding: 2px 30px 2px 10px;
}
.cw_nav_secondary.up-to-30 .cw_item {
  padding: 0 30px 0 10px;
}
.cw_nav_secondary.up-to-30 .label,
.cw_nav_secondary.up-to-30 .glyphicons {
  height: 30px;
  line-height: 30px;
}
.cw_nav_secondary.up-to-30 .glyphicons {
  font-size: 20px;
}
.cw_nav_secondary .current {
  background-color: #2a323a;
}
.cw_nav_secondary .cw_item {
  float: left;
  width: 100%;
  padding: 5px 30px 5px 10px;
  color: #969fa8;
  cursor: pointer !important;
}
.cw_nav_secondary .cw_item:hover {
  background-color: #2a323a;
  color: #fff;
}
.cw_nav_secondary .label {
  float: left;
  width: 120px;
  text-indent: 10px;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  color: #969fa8;
}
.cw_nav_secondary .glyphicons {
  float: left;
  color: #969fa8;
  text-shadow: 0 1px 0 #000;
  width: 40px;
  height: 36px;
  line-height: 36px;
  text-indent: 0;
  text-align: center;
  font-size: 24px;
}
.cw_nav_secondary .nav_header {
  float: left;
  width: 200px;
  padding: 10px;
  cursor: pointer !important;
  color: #fff;
  text-shadow: 0 1px 0 #000;
  text-transform: uppercase;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.1);
}
.cw_nav_secondary .nav_header .glyphicons {
  float: left;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  text-indent: 0;
  margin-top: 10px;
}
.cw_nav_secondary .nav_header .main {
  float: right;
}
.cw_nav_secondary .nav_header .label {
  float: left;
  width: 140px;
  height: 20px;
  text-indent: 0;
  overflow: visible;
  text-overflow: initial;
}
.cw_nav_secondary .current {
  background-color: #2a323a;
}
.cw_nav_secondary.expanded > .cw_item:last-child {
  border-top: 1px solid #555666;
}
.cw_nav_secondary #management_server ul .label {
  width: auto;
  color: #969fa8;
}
.cw_nav_secondary #management_server ul .glyphicons {
  color: #969fa8;
}
.cw_nav_secondary #management_server ul .label:hover {
  color: #fff !important;
}
#management_server .label {
  float: none;
  position: relative;
  left: 10px;
}
/* Favorites */
.cw_favorites_modules {
  position: absolute;
  left: 215px;
  z-index: 10003;
  min-width: 160px;
  /*width: 100%;*/
  max-width: 680px;
  padding: 0 20px;
  overflow-y: auto;
  background-color: #343f49;
  color: #fff;
  border-radius: 4px;
}
.cw_favorites_modules .cw_title {
  float: left;
  width: 100%;
  padding: 10px 20px;
  margin: -20px -20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
}
.cw_favorites_modules .cw_list {
  float: left;
  position: relative;
  /*width: 100%;*/
  padding: 15px 0 0 0px;
  overflow: auto;
  -webkit-column-width: 150px;
  -moz-column-width: 150px;
  column-width: 150px;
}
.cw_favorites_modules .cw_item {
  width: 150px;
  display: inline-block;
  position: relative;
  padding-top: 8px;
  padding-right: 20px;
  padding-bottom: 6px;
  padding-left: 5px;
  cursor: pointer !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_favorites_modules .cw_no_fav {
  margin-top: 15px;
  float: left;
}
.cw_favorites_modules .bin {
  display: none;
}
.cw_favorites_modules .cw_item:hover .bin {
  display: block;
  float: right !important;
  margin-right: 5px;
  font-size: 12px !important;
  line-height: 16px;
}
.cw_favorites_modules .cw_item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.cw_favorites_modules .glyphicons {
  position: absolute;
  font-size: 18px;
  top: 7px;
  right: 0;
}
.cw_favorites_modules .star {
  color: #f7e400;
}
.cw_favorites_modules span.cw_fav_item.label {
  width: 90%;
  float: left;
}
.cw_favorites {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
  margin: 6px 15px 0 15px;
}
/* Nav Toggle */
.cw_toggle_nav {
  z-index: 5;
  position: absolute;
  bottom: 5px;
  left: 184px;
  width: 16px;
  height: 16px;
  color: #999;
  cursor: pointer !important;
}
.cw_toggle_nav:hover {
  color: #fff;
}
/*Kendo doesn't cover password inputs*/
input[type="password"].k-input {
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  font-size: 100%;
}
.k-input {
  text-align: left;
}
.k-button[disabled] {
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
}
.k-input[readonly] {
  background-color: #fafafa;
}
/* Inputs */
.k-textbox > input,
.k-autocomplete .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  padding: 0.35em 0;
}
.k-textbox.k-space-right {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.k-textbox.k-space-right .k-input {
  margin: -1px 0 -1px -1px;
}
.k-dropdown .k-input {
  color: #54595E;
}
.k-checkbox-label:before {
  border-color: #c5c5c5;
  color: #333 !important;
  border-color: #d8d8d8 !important;
}
.k-dropdown-wrap .k-icon.k-i-close {
  display: none !important;
}
/* k-multiselect */
.k-multiselect.k-widget {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.k-multiselect.k-state-disabled .k-multiselect-wrap {
  background-color: #fafafa;
}
.k-multiselect,
.k-multiselect-wrap {
  border-radius: 0 0 3px 3px;
  overflow: auto;
}
.k-multiselect .k-button {
  position: relative;
  padding: 2px 20px 2px 3px;
  box-shadow: none;
  margin: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}
.k-multiselect .k-button.k-state-hover {
  border-color: #fafafa;
  background-color: #fafafa;
}
.k-animation-container .k-item.k-state-selected {
  border-color: #e6f7ff;
  color: rgba(0, 0, 0, 0.65);
  background-color: #e6f7ff;
}
.k-multiselect .k-button:active {
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: inset 0 0 10px 3px #fafafa;
  box-shadow: inset 0 0 10px 3px #fafafa;
}
.k-multiselect .k-button > span:first-child {
  float: left;
  position: relative;
  max-width: 135px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.k-multiselect .cw_item_label {
  float: left;
  max-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.k-multiselect .cw_target_icon {
  position: absolute;
  right: 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.k-popup .k-list .k-state-focused {
  color: #333333;
  background-color: #ebebeb;
}
.k-filter-menu .k-multiselect .k-button {
  width: 98%;
  height: 24px;
  background-color: #eee;
  color: #333;
  box-sizing: border-box;
}
.k-filter-menu .k-multiselect .k-button .glyphicons {
  margin: 0 3px;
}
/* k-slider */
.k-slider {
  width: 100%;
}
.k-slider .k-slider-selection {
  background-color: #00aeef;
}
.k-slider .k-slider-track {
  border: 1px solid #d8d8d8;
}
/* k-numerictextbox */
.k-numerictextbox .k-numeric-wrap {
  height: 28px;
}
.k-numerictextbox .k-input {
  height: 28px;
  line-height: 28px;
  padding: 0;
}
.k-numerictextbox input.k-input {
  text-align: left;
}
/* Buttons */
.k-button {
  padding: 0.4em 1em;
  border-radius: 3px;
  border-width: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}
.k-button:focus:not(.k-state-disabled):not([disabled]) {
  box-shadow: none;
}
.k-button.k-primary {
  background-color: #257cba;
  border-color: #1d608f;
  border-width: 0 0 1px;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
.k-button.k-primary:hover {
  border-color: #144365;
  background-color: #1d608f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-button.k-button-icon {
  line-height: 1;
  box-shadow: none;
}
.k-button.k-button-icontext .glyphicons {
  margin: 3px 10px 0 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
/* Grid */
.k-alt {
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
}
tr.k-state-selected {
  background-color: #e4f1f9;
  color: inherit;
}
.k-header,
.k-grid-header {
  background-color: #fff;
}
.k-grid-header th.k-header {
  font-weight: 700;
  background-color: #fbfbfb;
  box-sizing: border-box;
}
.k-grid-content td {
  text-align: left;
  width: 100%;
}
.k-grid tr td {
  border-bottom-width: 1px;
  padding: 0.3em 0.6em;
  border-color: #d8d8d8;
}
.k-grid .k-detail-cell {
  padding: 0 0.6em;
}
tr[role=row]:hover td {
  background-color: #e4f1f9;
}
.k-grid tr:hover {
  background-image: none;
  background-color: transparent;
}
.cw_section_full.no_title .k-grid-header {
  background-color: #fbfbfb;
  border-top: 1px solid #fff;
}
.cw_section_full.no_title .k-grid-header th {
  height: 38px;
}
.main-section-grid .k-grid-header th {
  height: 38px;
}
.k-animation-container .k-columns-item,
.k-animation-container .k-separator:last-child {
  display: none !important;
}
.k-pager-sizes {
  float: right;
}
.k-pager-wrap {
  padding: 8px 10px;
}
.k-pager-wrap .k-link {
  border-color: #d8d8d8;
}
.k-filter-menu .k-dropdown,
.k-filter-menu .k-multiselect {
  float: none;
}
.k-filter-menu .k-button {
  overflow: hidden;
}
.k-filter-menu .k-multiselect .k-button {
  background-color: #fff;
  border: 1px solid #d8d8d8;
  color: #333;
}
.k-treelist-group td {
  background-color: #eee;
}
/* Filtering */
.k-menu .k-state-selected > .k-link {
  background-color: #257cba;
}
/* Animation Container for Dropdowns */
.k-animation-container .k-list .k-item {
  clear: left;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
/* K-loading-indicator */
.k-loading-color {
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1 !important;
}
.k-loading-mask {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.k-loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 26px;
  width: 26px;
  border: 2px solid #00aeef;
  border-right-color: transparent;
  margin: -15px 0 0 -15px;
  border-radius: 50%;
  background: none;
  animation: rotate 0.6s infinite linear;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Scheduler */
.k-nonwork-hour {
  background-color: rgba(0, 0, 0, 0.015);
}
.k-scheduler-table .k-today {
  background-color: rgba(207, 230, 245, 0.2);
  border-color: rgba(207, 230, 245, 0.8);
}
.k-event-actions {
  top: 0;
  right: 5px;
}
.k-event .k-event-delete {
  display: inline-block;
}
/* Window */
.k-widget.k-window {
  border-radius: 3px;
  padding-top: 30px !important;
}
.k-window-titlebar.k-header {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  margin-top: -40px;
  padding: 5px 0;
  text-align: left;
  border-top: 1px solid #fff;
  border-radius: 3px 3px 0 0;
  background-color: #fbfbfb;
  border-bottom: 1px solid #eee;
  height: 30px;
  margin-top: -30px;
  padding: 0 0 5px;
  box-sizing: border-box !important;
}
.k-window-title {
  float: left;
  font-size: 13px;
  font-weight: 700;
  color: #3B3E42;
  line-height: 30px;
  text-indent: 15px;
  text-shadow: 0 1px 0 #fff;
  width: 80%;
}
.k-window-title .status_icon,
.k-window-title .cw_status_indicator {
  float: left !important;
  width: 20px;
  height: 20px;
  margin: 4px 0 0 5px;
  padding: 0;
  text-align: center !important;
  text-indent: 0;
  font-size: 20px;
}
.k-window-title .circle-arrow-down {
  color: #e14b32;
}
.k-window-title .circle-arrow-top {
  color: #7cc623;
}
.k-window-title .circle-arrow-right {
  color: #f1ae31;
}
.k-window-title .k-button.cw_actions_trigger {
  margin: 2px 0 0 10px;
}
div.k-window-content {
  padding: 0;
  width: 100%;
}
.k-resize-handle.k-resize-se {
  opacity: 1;
  z-index: 1000000;
  right: 1px;
  bottom: 1px;
  width: 12px;
  height: 12px;
  background: transparent;
  box-shadow: inset -1px -1px 0 #aaa, inset -2px -2px 0 #eee, inset -3px -3px 0 #aaa;
}
/* Close Icon */
.k-window-titlebar .k-window-action {
  opacity: 1;
  margin: 0 6px 0 0;
}
/* Tabs */
.k-tabstrip:focus {
  box-shadow: none;
}
/* Upload Control */
.k-widget.k-upload.k-header {
  border: none;
}
.k-button.k-upload-button[disabled] {
  opacity: 0.5;
}
.k-button.k-upload-button {
  background-color: #257cba;
  border-color: #257cba;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  padding: 2px 15px;
}
.k-button.k-upload-button:hover {
  background-position: left -7px;
}
.k-upload-status.k-upload-status-total {
  display: none !important;
}
/* Notification */
.content-icon .glyphicons {
  float: left;
  margin-left: -35px;
  margin-right: 10px;
  font-size: 22px;
  color: #fff;
}
.content-icon .info-sign {
  color: #00bdf2;
  text-shadow: 0 1px 0 #fff;
}
.content-icon .ok,
.content-icon .ok-circle {
  color: #7cc623;
  text-shadow: 0 1px 0 #fff;
}
/* k-panelbar */
.k-panelbar .k-link.k-header.k-selected.k-state-selected {
  color: #54595E;
}
/* K-tooltip */
.k-widget.k-tooltip {
  background-color: #fff;
  color: #333;
  border: 1px solid #d8d8d8 !important;
  text-align: left;
}
/* K-Scheduler */
.k-event-time {
  display: block !important;
}
/* TreeList */
.k-treelist,
.k-treelist .k-grid-content td {
  background-color: #fff;
}
/* Multiselect in Filter Dropdowns */
.k-filter-menu .k-multiselect-wrap .cw_status_indicator {
  float: left;
  margin: 0 5px 0 0;
}
.k-filter-menu .k-textbox,
.k-filter-menu .k-dropdown {
  width: 100%;
}
.k-filter-menu .k-multiselect-wrap.k-floatwrap {
  padding: 0;
}
.k-filter-menu .k-multiselect-wrap.k-floatwrap > .k-icon.k-i-close {
  display: none !important;
}
/* Tooltip */
.k-tooltip-content {
  line-height: 1.8;
}
/* No data in cw_administration_module*/
.cw_administration_module .k-nodata {
  height: 100px;
}
.cw_administration_module .k-nodata div {
  float: left;
  width: 100%;
  height: 20px;
  background-color: #f4f7f9;
  color: #267fb8;
  padding: 15px 0;
}
/* Checkboxes */
.k-checkbox-label,
.k-radio-label {
  padding-left: 23px;
}
.k-tabstrip > .k-content[role=tabpanel] {
  overflow: hidden;
}
.k-grid-norecords {
  background-color: #f4f7f9;
  color: #267fb8;
  padding: 15px 0;
}
/* Form Div Class */
.form {
  float: left;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.form p:last-child {
  margin-bottom: 0;
}
.form .cw_inline,
.form .cw_inline_full,
.form .cw_dropdown_container {
  box-sizing: border-box;
}
.form_content,
.form_card_container {
  float: left;
  width: 100%;
}
.form_content_minimal {
  display: none;
  float: left;
  width: 100%;
  text-shadow: 0 1px 0 #fff;
  padding: 5px 20px;
  margin: 20px 0 20px -20px;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  background: url("~styles/shared/images/app_theme//button_overlay.png") repeat-x scroll left -7px #e9e9e9;
  box-shadow: inset 0 1px 1px #ffffff;
}
p,
.p,
.cw_field,
.cw_field_with_btn {
  margin: 0 0 16px;
}
.cw_field,
.cw_field_with_btn {
  float: left;
  width: 100%;
}
input {
  color: #54595E;
  font-family: inherit;
}
textarea {
  padding: 0.5em;
  font: 13px/1.5 'Oxygen', Arial, sans-serif;
  border: 1px solid #d8d8d8;
}
input[readonly],
textarea[readonly],
input[disabled] {
  background-color: #fafafa;
  color: #999 !important;
}
.cw_input {
  float: left;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-indent: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.is_invalid,
.is_invalid .k-numeric-wrap,
.is_invalid .k-dropdown-wrap,
.is_invalid .k-dropdown-wrap {
  border-color: #e14b32;
}
/* CW Inline */
.cw_inline {
  border: 1px solid #ccc;
  line-height: normal;
  text-align: left;
}
input.cw_inline {
  float: left;
  width: 100%;
  height: 30px;
  line-height: 16px;
  padding-left: 130px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
input.cw_inline:focus {
  box-shadow: 0 0 7px 0 #76abd9;
  -webkit-box-shadow: 0 0 7px 0 #76abd9;
  outline: none !important;
}
label.cw_inline {
  float: left;
  position: relative;
  z-index: 1;
  width: 120px;
  height: 30px;
  padding: 0.5em 0;
  line-height: 16px;
  margin: 0 -120px 0 0;
  border-right-color: #ddd;
  text-indent: 6px;
  background-color: #fbfbfb;
  border-radius: 4px 0 0 4px;
}
textarea.cw_inline {
  float: left;
  clear: left;
  width: 100%;
  border-radius: 0 0 4px 4px;
  resize: both;
  height: 47px;
  min-height: 47px;
  padding: 0.5em;
  min-width: 100%;
  position: relative;
  z-index: 2;
}
textarea.cw_inline:focus {
  box-shadow: 0 0 7px 0 #76abd9;
  -webkit-box-shadow: 0 0 7px 0 #76abd9;
  outline: none !important;
}
.cw_inline_full {
  float: left;
  position: relative;
  z-index: 4;
  width: 100%;
  text-indent: 10px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: left;
  border: 1px solid #ccc;
  border-bottom: 0;
  background: #fbfbfb;
  text-shadow: 0 1px 0 #fff;
  border-radius: 4px 4px 0 0;
}
.cw_inline + .cw_multi_toggle {
  margin-left: 130px;
}
input#cw_cvid.cw_inline {
  padding-left: 124px;
  font-size: 11.5px;
}
/* Full Width Dropdown */
.cw_dropdown_container {
  float: left;
  width: 100%;
  padding-left: 130px;
}
.cw_dropdown_container .k-dropdown,
.cw_dropdown_container .k-combobox,
.cw_dropdown_container .k-numerictextbox {
  padding: 0;
  width: 100%;
  margin-left: 0;
}
.cw_dropdown_container .cw_inline {
  padding-left: 0;
  text-indent: 4px;
}
/* CW field with button */
.cw_field_with_btn {
  position: relative;
}
.cw_field_with_btn .cw_dropdown_container {
  padding-right: 30px!important;
}
.cw_field_with_btn .glyphicons {
  position: absolute;
  top: 6px;
  right: 3px;
  font-size: 18px;
}
/* Search Input */
.cw_search_box,
.cw_text_input {
  border: 1px solid #ccc;
  line-height: normal;
  text-align: left;
  text-indent: 6px;
  border-radius: 4px;
  height: 30px;
  line-height: 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.cw_search_box:focus,
.cw_text_input:focus {
  box-shadow: 0 0 7px 0 #76abd9;
  -webkit-box-shadow: 0 0 7px 0 #76abd9;
  outline: none !important;
}
/* Upload Control */
.upload_section {
  float: left;
  position: relative;
  width: 100%;
  margin: 10px 0 10px -1px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  height: 69px;
  line-height: 69px;
  background: #fff;
}
.upload_section .k-widget.k-upload {
  float: right;
  width: auto;
}
.upload_section .k-button.k-upload-button {
  width: auto;
  line-height: 14px;
  padding: 4px 12px;
}
.upload_section .k-dropzone {
  padding: 0;
  margin: 5px 10px 5px 0;
}
.upload_section .k-dropzone em {
  display: none;
}
.upload_section .upload_avatar {
  position: relative;
}
#cw_node_icon_preview .glyphicons {
  color: #333;
}
.label-info-title {
  float: right;
  margin: -15px 3px 0 0;
}
ul#auth_privacyProtocol li,
ul#auth_authProtocol li {
  padding: 0.5em;
}
.create_authentication #auth_privacyProtocol,
.create_authentication #auth_authProtocol {
  margin-left: -130px;
}
ul#auth_list_listbox li:hover {
  background-color: #cfe6f5;
}
/* Fieldset*/
fieldset {
  border: 1px solid #d8d8d8;
  padding: 0px 10px 10px;
  margin-bottom: 10px;
}
fieldset .cw_field:last-child,
fieldset .cw_field_with_btn:last-child {
  margin-bottom: 0;
}
/* Legend */
legend {
  padding: 0 5px 5px;
}
/* Set information */
.cw_set_information {
  width: 94%;
  height: 70%!important;
  margin: 15px 0 0 3%;
}
/* Section (Head + Body) */
.cw_section {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.cw_section.cw_section_full {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  width: auto;
  height: auto;
}
.cw_section.cw_section_full .cw_section_content {
  overflow: visible;
}
.cw_section.no_title {
  overflow: hidden;
  padding-top: 0;
}
.cw_section.no_title .cw_section_content {
  top: 0;
}
.cw_section_expanded .cw_section_content {
  bottom: 47px;
}
/* Has Footer */
.cw_section_content .cw_section_footer,
.cw_section .cw_section_footer {
  float: left;
  width: 100%;
  position: absolute;
  height: 40px;
  line-height: 40px;
  bottom: 0;
  border-radius: 0 0 3px 3px;
  text-align: center !important;
  background-color: #f5f5f5;
  font-size: 24px;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
}
.cw_section_footer.active {
  cursor: pointer !important;
}
.cw_section_footer.active:hover {
  color: #131213;
}
.cw_section_footer .red {
  color: #e14b32;
}
.cw_section_footer .green {
  color: #7cc623;
}
.cw_section_titlebar {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  margin-top: -40px;
  padding: 5px 0;
  text-align: left;
  border-top: 1px solid #fff;
  border-radius: 3px 3px 0 0;
  background-color: #fbfbfb;
  border-bottom: 1px solid #eee;
}
.cw_section_title {
  float: left;
  font-size: 13px;
  font-weight: 700;
  color: #3B3E42;
  line-height: 30px;
  text-indent: 15px;
  text-shadow: 0 1px 0 #fff;
}
.cw_section_title .status_icon,
.cw_section_title .cw_status_indicator {
  float: left !important;
  width: 20px;
  height: 20px;
  margin: 4px 0 0 5px;
  padding: 0;
  text-align: center !important;
  text-indent: 0;
  font-size: 20px;
}
.cw_section_title .circle-arrow-down {
  color: #e14b32;
}
.cw_section_title .circle-arrow-top {
  color: #7cc623;
}
.cw_section_title .circle-arrow-right {
  color: #f1ae31;
}
.cw_section_title .k-button.cw_actions_trigger {
  margin: 2px 0 0 10px;
}
.cw_section_content {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  float: left;
  width: 100%;
  background: #fff;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}
.cw_section_content .status {
  text-align: left;
}
.cw_section_content .k-grid.k-widget {
  border: none;
}
.cw_section_content .k-grid-header-wrap th a {
  color: #666;
  text-shadow: 0 1px 0 #fff;
}
/* Section Placeholder */
.cw_section_placeholder {
  float: left;
  width: 100%;
  text-align: center !important;
  margin-top: 100px;
}
.cw_section_placeholder .glyphicons {
  padding: 10px 20px;
  margin: 0 auto;
  color: #e14b32;
}
.cw_section_placeholder .cw_placeholder_message {
  float: left;
  clear: left;
  width: 100%;
  text-align: center !important;
  font-size: 13px;
}
.section_height_full {
  height: 100%;
}
.cw_events_toolbar {
  float: right;
  padding: 2px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px 2px 2px 20px;
  background-color: #fff;
  border: 1px solid #ccc;
}
.cw_events_toolbar:hover {
  border-color: #aaa;
}
.cw_events_toolbar:hover .cw_events_text {
  border-color: #888;
}
.cw_events_toolbar .cw_events_play {
  float: left;
  width: 20px;
  padding: 0;
  margin: 4px 0 0 5px;
  border: none;
  background: none;
}
.cw_events_toolbar .cw_events_text,
.cw_events_toolbar .cw_search_box {
  display: inline-block;
  text-align: left;
  width: 50px;
  height: 24px;
  margin: 0 0 0 -1px;
  line-height: 24px;
  padding: 0;
  text-align: center;
  text-indent: 0;
  border: 1px solid #d8d8d8;
}
.cw_events_toolbar span.glyphicons {
  font-size: 18px;
}
.cw_events_toolbar .cw_events_reset {
  width: auto;
  height: auto;
  background-color: transparent;
  padding: 4px;
  border: none;
}
/* CW_Checkbox */
.cw_checkbox {
  float: left;
  width: 46px;
  height: 18px;
  display: block;
  margin-right: 10px;
  border: 1px solid #bbb;
  line-height: 18px;
  background: url("control/~styles/shared/images/app_theme//cw_checkmark_off.png") no-repeat 28px center #eee;
  text-indent: 5px;
  text-shadow: 0 1px 0 #fff;
  cursor: pointer !important;
  border-radius: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset, 0 1px 0 rgba(255, 255, 255, 0.3);
}
.cw_checkbox:hover {
  box-shadow: 0 0 4px #cfe6f5;
}
.cw_checkbox.is_disabled {
  opacity: 0.3;
}
.cw_checkbox_input {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.cw_checkbox_button {
  float: left;
  width: 20px;
  height: 18px;
  margin: -1px;
  border: 1px solid #999;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), transparent);
  background-color: #f5f5f5;
  border-radius: 2px;
  box-shadow: inset 0 -2px 0 #ddd;
}
.cw_checked,
.is_checked {
  border-color: #6eb01f;
  background: url("control/~styles/shared/images/app_theme//cw_checkmark_on.png") no-repeat 5px center #7cc623;
}
.cw_checked .cw_checkbox_button,
.is_checked .cw_checkbox_button {
  float: right;
}
.cw_part_checked {
  color: #65ade0;
}
.cw_half_checkbox {
  float: left;
  width: 48%;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #ccc;
}
.cw_half_checkbox .cw_checkbox {
  margin: 0.4em 0 0 130px;
}
/* Mini Version */
.cw_switch_mini .cw_checkbox {
  width: 36px;
  margin-right: 0;
  background-image: none;
}
.cw_switch_mini .cw_checkbox,
.cw_switch_mini .cw_checkbox_button {
  height: 14px;
  border-radius: 20px;
}
.cw_switch_mini .cw_checkbox_button {
  width: 18px;
}
.cw_checkoxes_with_labels {
  float: left;
  padding: 5px 10px 15px 1px;
  width: 50%;
}
/* Sliding Box */
.cw_filter_wrapper {
  float: right;
  padding-right: 10px;
}
.cw_filter_wrapper .star.default {
  color: #918600;
  text-shadow: 0 0 2px #222;
}
.cw_filter_wrapper .k-button-icon {
  float: left;
  margin-left: 5px;
  height: 30px;
  width: 30px;
  text-align: center !important;
}
.cw_filter_wrapper .cw_view_button {
  float: right;
  margin: 0 !important;
}
.cw_filter_new {
  float: left;
  position: absolute;
  top: 43px;
  right: 0;
  display: none;
  z-index: 5;
  width: 100%;
  height: 45px;
  padding: 5px 20px;
  background-color: #eee;
  border-bottom: 1px solid #d8d8d8;
}
.cw_filter_new .k-input {
  border-radius: 3px;
  height: 24px;
  text-indent: 10px;
  width: 200px;
  margin-left: 5px;
}
.cw_filter_new .k-button {
  margin: -3px 0 0 5px;
}
.cw_filters_header {
  float: right;
  line-height: 1;
}
.cw_filters_header h3 {
  line-height: 30px;
}
.cw_filters_header .cw_search_box {
  float: left;
  width: 210px;
  margin-right: 5px;
  text-indent: 10px;
}
.cw_filters_header .cw_filter_list {
  float: left;
  width: 150px;
}
.cw_filters_header .k-button-icon {
  margin: 0 0 0 10px !important;
}
.cw_filter_default {
  position: absolute;
  top: 50%;
  right: 5px;
  margin: -6px 0 0;
  background-color: inherit;
}
.cw_sliding_box.is_open {
  display: flex;
}
.cw_sliding_box.k-window-content.k-content {
  display: flex!important;
}
.cw_sliding_box .w50 {
  flex: 1;
}
.cw_sliding_box .w50.left {
  border-right: 1px solid #eee;
}
.cw_sliding_box .cw_header {
  padding: 5px 10px 5px 20px;
  height: 42px;
  background-color: #eee;
  border-bottom: 1px solid #d8d8d8;
}
.cw_sliding_box .cw_header h3 {
  padding-top: 7px;
}
.cw_sliding_box .cw_header .k-button.k-button-icon {
  height: 29px;
  width: 28px;
}
.cw_sliding_box .cw_visible_columns {
  float: left;
  width: 100%;
  /*height:250px;*/
  overflow-y: auto;
  font-weight: 400;
  padding: 15px 15px 0 15px;
}
.cw_sliding_box .cw_visible_columns label {
  cursor: pointer !important;
}
.cw_sliding_box .cw_visible_columns .glyphicons {
  float: left;
  margin: 3px 6px 0 0;
  font-size: 18px;
}
.cw_sliding_box .cw_visible_columns ul {
  float: left;
  width: 100%;
  /*column-count: 2; column-gap: 0; -moz-column-count: 2; -moz-column-gap: 0; -webkit-column-count: 2; -webkit-column-gap: 0; */
  -webkit-column-width: 135px;
  -moz-column-width: 135px;
  column-width: 135px;
}
.cw_sliding_box .cw_visible_columns li {
  display: inline-block;
  width: 100%;
  height: 28px;
  line-height: 28px;
}
.cw_sliding_box .circle-arrow-top {
  color: #7cc623;
}
.cw_sliding_box .circle-arrow-right {
  color: #f1ae31;
}
.cw_sliding_box .circle-arrow-down {
  color: #e14b32;
}
.cw_filter_op_columns {
  padding: 15px;
  font-weight: 400;
}
.cw_filter_op_columns .status {
  width: 303px;
}
.cw_filter_panel_content {
  float: left;
  position: relative;
  width: 100%;
  height: 210px;
  overflow-y: auto;
}
.cw_filter_panel_content li {
  width: 100%;
  display: inline-block;
  padding-top: 8px;
  padding-bottom: 6px;
}
.cw_filter_panel_content .glyphicons {
  float: left;
  /*height:28px; line-height: 28px;*/
  margin-right: 10px;
}
.cw_filter_panel_content {
  -webkit-column-width: 150px;
  -moz-column-width: 150px;
  column-width: 150px;
}
.cw_filter_panel_content > li > span:first-child {
  margin-right: 5px;
}
.cw_preferences_window {
  border: 1px solid #d8d8d8 !important;
}
.cw_preferences_window .k-resize-handle.k-resize-se {
  /*box-shadow: none !important*/
}
.cw_preferences_window .k-resize-handle.k-resize-sw {
  display: block;
  left: 1px;
  bottom: 1px;
  opacity: 1;
  z-index: 100000000;
  width: 12px;
  height: 12px;
  box-shadow: inset 1px -1px 0 #aaa, inset 2px -2px 0 #eee, inset 3px -3px 0 #aaa;
}
.cw_fs_settings_filter .cw_status_indicator,
.cw_fs_settings_filter .inline .glyphicons {
  display: inline-block;
  margin: -2px 2px;
  float: none;
}
.cw_filter_remove .glyphicons,
.cw_filter_update .glyphicons,
.cw_filter_create .glyphicons,
.cw_filter_default .glyphicons,
.cw_filter_reset .glyphicons,
.cw_filter_clear .glyphicons,
.cw_view_button .glyphicons {
  font-size: 16px;
}
/* Tab Control */
.cw_tabs_absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  background-color: #dee8ec;
  border: none;
}
.cw_tabs_absolute_eventlog {
  width: 100%;
  left: unset;
}
.cw_tabs_absolute .k-tabstrip-items {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.cw_tabs_absolute .k-content.k-state-active {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 1px 0 0;
  margin: 0;
  border-color: #d8d8d8;
}
.cw_tabs_absolute .k-item {
  margin-right: 4px;
  position: relative;
  z-index: 1;
}
.cw_tabs_absolute .k-loading {
  border: none;
}
.cw_tabs_absolute .cw_section_full {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cw_pillbox {
  position: relative;
  border: 1px solid #d8d8d8;
  width: 60px;
  height: 20px;
  line-height: 20px;
  margin: 2px auto 0;
  border-radius: 18px;
  background: #f5f5f5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8);
}
.cw_pillbox .cw_status {
  float: left;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
  background: #fff;
}
.is_critical .cw_status {
  background-color: #e14b32;
}
.is_idle .cw_status {
  background-color: #c4c4c4;
}
.is_major .cw_status {
  background-color: #f1ae31;
}
.is_minor .cw_status {
  background-color: #f7e400;
}
.is_ok .cw_status {
  background-color: #7cc623;
}
.cw_pillbox .cw_indicator {
  margin: 3px auto;
  font-size: 10px;
  color: #fff;
}
.cw_pillbox .cw_indicator.remove {
  margin: 0px !important;
}
.cw_pillbox .exclamation-mark {
  margin: 0;
}
.cw_pillbox .cw_value {
  float: left;
  width: 30px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  font-weight: 700;
}
.cw_pillbox .glyphicons {
  font-size: 16px;
}
.cw_badge {
  display: inline-block;
  padding: 3px 5px 4px;
  margin: 2px;
  line-height: 1;
  font-size: 11px;
  font-weight: 700;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  background-color: #777;
  border-radius: 3px;
}
.cw_badge.is_critical {
  background-color: #e14b32;
}
.cw_badge.is_major {
  background-color: #f1ae31;
}
.cw_badge.is_minor {
  background-color: #f7e400;
}
.cw_badge.is_ok {
  background-color: #7cc623;
}
.cw_badge.is_idle {
  background-color: #c4c4c4;
  color: #333;
}
.cw_badge.is_inverted {
  background-color: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
}
.cw_badge.is_inverted.is_critical {
  color: #e14b32;
}
.cw_badge.is_inverted.is_major {
  color: #f1ae31;
}
.cw_badge.is_inverted.is_minor {
  color: #f7e400;
}
.cw_badge.is_inverted.is_ok {
  color: #7cc623;
}
.cw_badge.is_inverted.is_idle {
  color: #c4c4c4;
}
/* Upload Media Library Control*/
.cw_media_library_content {
  float: left;
  clear: left;
  width: 100%;
  height: 99999px;
  padding: 10px 20px;
  position: relative;
}
.cw_media_library_content a.cw_current {
  position: relative;
  z-index: 5;
  background: #fff;
  padding: 5px 10px 6px;
  margin-bottom: -1px;
}
.cw_media_library_content em {
  display: none;
}
.cw_media_library_content .cw_current {
  background-color: #ddd;
}
.cw_media_library_content .k-dropzone {
  padding: 0;
  margin: 0;
  width: auto;
}
.b-upload-image .k-dropzone {
  padding: 0 !important;
}
.cw_resolution_tip {
  position: absolute;
  right: 20px;
  bottom: -80px;
  left: 20px;
  height: 80px;
  background-color: #dee8ec;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
}
.cw_resolution_tip .cw_preview {
  width: 140px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #d8d8d8;
  margin: 10px auto;
  text-align: center;
  background-color: #fff;
}
.cw_media_library {
  clear: right;
  float: right;
  margin: 0 10px 5px 0;
}
.w-image-uploader {
  display: table;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}
.w-image-uploader #cw_upload_image {
  width: 150px;
}
.w-image-uploader .b-actions {
  margin-top: 10px;
}
.w-image-uploader .b-extra-panel {
  float: right;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
}
.w-image-uploader .b-extra-panel .b-search {
  float: right;
  margin-left: 7px;
}
.w-image-uploader .b-extra-panel .b-search input {
  height: 28px;
  text-align: left;
  border-radius: 4px;
  text-indent: 10px;
  line-height: 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
  width: 105px;
}
.w-image-uploader .b-extra-panel .b-upload-image {
  width: 108px;
}
.w-image-uploader .b-progress-bar {
  display: none;
  height: 5px;
}
.w-image-uploader .b-progress-bar .filler {
  background-color: #7cc623;
  height: 100%;
  width: 0;
}
.w-image-uploader .b-tabs {
  overflow: hidden;
  float: left;
}
.w-image-uploader .b-tabs .b-tab {
  float: left;
  cursor: pointer !important;
  padding: 7px 10px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  margin: 0 4px 0 0;
  text-decoration: none;
  color: #555 !important;
  text-shadow: 0 1px 0 #fff;
}
.w-image-uploader .b-tabs .b-tab.active {
  background-color: #dddddd;
}
.w-image-uploader .b-tabs-content {
  height: 100%;
  display: table-row;
  clear: both;
}
.w-image-uploader .b-tabs-content .b-tab-content {
  height: 100%;
  position: relative;
  border: 1px solid #ccc;
  display: table;
  width: 100%;
  box-sizing: border-box;
}
.w-image-uploader .b-tabs-content .b-tab-content.icons .b-recently-used {
  border-bottom: 1px solid #d8d8d8;
  height: 45px;
}
.w-image-uploader .b-tabs-content .b-tab-content > .wrapper-row {
  display: table-row;
  width: 100%;
  height: 100%;
}
.w-image-uploader .b-tabs-content .b-tab-content > .wrapper-row > .wrapper-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list.fullscreen {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: absolute;
}
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list .empty-list {
  box-sizing: border-box;
  padding: 20px 0 0 20px;
}
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list .item {
  cursor: pointer;
}
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list .item.glyphicons {
  text-align: center !important;
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 3px;
  margin: 2px;
  font: 24px 'Glyphicons Regular';
  color: #333 !important;
}
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list .item.material-icons {
  font-size: 32px;
  margin-right: 2px;
}
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list .item.material-icons:hover,
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list .item.glyphicons:hover {
  background: #eee;
  border-radius: 3px;
}
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list hr {
  margin: 10px 5px;
}
.w-image-uploader .b-tabs-content .b-tab-content .b-images-list h4 {
  margin: 10px 0;
  text-indent: 10px;
  font-size: 1.2em;
}
.w-image-uploader .b-tabs-content .b-tab-content.images .b-images-list .item {
  width: 64px;
  height: 64px;
  margin: 5px;
  display: inline-block;
  position: relative;
}
.w-image-uploader .b-tabs-content .b-tab-content.images .b-images-list .item img {
  width: 64px;
  height: 64px;
}
.w-image-uploader .b-tabs-content .b-tab-content.images .b-images-list .item.selected {
  outline: 3px solid #dddddd;
}
.w-image-uploader .b-tabs-content .b-tab-content.images .b-images-list .item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 0.2s ease-in-out;
  opacity: 0;
}
.w-image-uploader .b-tabs-content .b-tab-content.images .b-images-list .item .overlay .dimmer {
  background-color: white;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.w-image-uploader .b-tabs-content .b-tab-content.images .b-images-list .item .overlay .close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
}
.w-image-uploader .b-tabs-content .b-tab-content.images .b-images-list .item .overlay:hover {
  opacity: 1;
}
.w-image-uploader .b-tabs-content .b-tab-content.icons .b-images-list .item {
  padding: 3px;
}
.w-image-uploader .b-tabs-content .b-tab-content.icons .b-images-list .item.selected {
  background-color: #dddddd;
}
/* Content Placeholder */
.new_card {
  text-align: center;
  margin-top: 10px;
}
.new_card .k-button {
  padding: 10px 20px;
  margin-top: 20px;
}
/* Expandable Textarea */
.cw_onethird .expandable_area textarea {
  float: left;
  clear: left;
  width: 100%;
  border-radius: 0 0 4px 4px;
  resize: both;
  overflow: auto;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 40;
  min-width: 100%;
  min-height: 93px;
  height: 93px;
}
.expandable_area {
  position: relative;
  margin-bottom: 65px;
}
.expandable_area textarea {
  float: left;
  clear: left;
  width: 100%;
  border-radius: 0 0 4px 4px;
  resize: both;
  overflow: auto;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 40;
  min-width: 100%;
  min-height: 47px;
  height: 47px;
}
.expandable_area textarea.expanded,
.expandable_area textarea.is_expanded {
  position: absolute;
  top: 24px;
  left: 0;
  width: 800px;
  height: 600px;
  z-index: 51;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-color: #aaa;
}
.expandable_area .glyphicons {
  float: right;
  cursor: pointer !important;
  position: relative;
  text-indent: 0;
  z-index: 20;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  text-align: center !important;
  border: 1px solid transparent;
}
textarea.expanded,
textarea.is_expanded {
  z-index: 51;
  border-color: #aaa;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
@-moz-document url-prefix() {
  .expandable_area textarea {
    z-index: 100000000;
  }
}
/* Time Range */
.cw_timerange_box.is_open {
  display: block !important;
}
.cw_timerange_box {
  display: none;
  background-color: #fff;
  padding: 10px 5px 10px 10px;
  border: 1px solid #d8d8d8;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.cw_timerange_box .k-widget,
.cw_timerange_box .k-button {
  margin-right: 5px;
}
.cw_timerange_box .k-widget {
  width: 180px;
}
/* Toggle Button */
.cw_button_toggle {
  float: right;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-radius: 3px;
}
.cw_button_toggle .label {
  text-transform: uppercase;
}
.cw_button_toggle .glyphicons {
  font-size: 18px;
}
.cw_button_toggle .pause {
  color: #e14b32;
}
.cw_mask,
.cw_widget_mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cw_mask {
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 0.6;
}
.cw_widget_mask {
  background-color: rgba(255, 255, 255, 0.15);
}
.cw_content_mask {
  position: absolute;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #dee8ec;
}
.cw_hint {
  cursor: pointer !important;
  color: #257cba;
  font-size: 16px;
  margin: 3px 0 0;
}
/* Table */
.circle-arrow-top,
.ok-sign {
  color: #7cc623;
}
.circle-arrow-right,
.exclamation-sign {
  color: #f1ae31;
}
.circle-arrow-down,
.remove-sign {
  color: #e14b32;
}
.remove-sign.orange {
  color: #f1ae31;
}
/* Curious case */
.status_icon {
  font-size: 16px;
}
.cw_message_expanded {
  overflow: visible;
  white-space: normal;
  -o-text-overflow: clip;
  -webkit-text-overflow: clip;
  text-overflow: clip;
  word-wrap: break-word;
}
/* Table Link*/
.cw_grid_link {
  cursor: pointer !important;
  display: block;
  color: #709bba;
  text-decoration: none;
}
.cw_grid_link:hover {
  font-weight: 700;
}
td.cw_grid_link {
  display: table-cell;
}
.cw_grid_check {
  margin-top: 5px;
}
.cw_grid_results {
  float: left;
  height: 30px;
  padding: 0 10px;
  margin-right: 10px;
  line-height: 30px;
  color: #777;
}
.cw_grid_input {
  height: 26px;
  line-height: 26px;
  padding: 0 5px;
  border: 1px solid #d8d8d8;
  border-radius: 3px ;
}
.cw_grid_input:focus {
  box-shadow: 0 0 7px 0 #76abd9;
  -webkit-box-shadow: 0 0 7px 0 #76abd9;
  outline: none !important;
}
.cw_row_disabled {
  color: #aaa;
}
/* CW Excerpt */
.cw_excerpt {
  position: absolute;
  top: 10px;
  right: 10px;
  left: 10px;
  border: 1px solid #afd9ee;
  border-bottom-color: #9acfea;
  color: #247fad;
  border-radius: 2px;
  background-color: #D9EDF7;
}
.cw_excerpt.relative {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
}
.cw_excerpt div.info {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  float: left;
  width: 100%;
  padding: 15px;
  margin: 0;
  text-shadow: 0 1px 0 #fff;
}
.cw_excerpt p {
  width: auto;
  padding: 15px;
  margin: 0;
  text-shadow: 0 1px 0 #fff;
}
.k-detail-cell .cw_excerpt {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* Table Column Filter Indicator */
table .cw_filtered_column {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#cfe6f5), to(#e1eff9)) !important;
  background-image: -webkit-linear-gradient(top, #cfe6f5, #e1eff9) !important;
  background-image: -moz-linear-gradient(top, #cfe6f5, #e1eff9) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
/*Grid Searchbox*/
.cw_grid_searchbox {
  width: 130px;
  margin: -3px 3px 0 0;
}
/* Grid Filters */
.cw_filter_item {
  float: left;
  width: 100%;
}
.cw_filter_item .cw_status_indicator {
  float: left;
}
.cw_filter_item .cw_status_indicator.no_status,
.cw_filter_tag .cw_status_indicator.no_status {
  box-shadow: none;
}
.cw_filter_item .no_status .cw_indicator,
.cw_filter_tag .no_status .cw_indicator {
  color: #333;
  background: #fff;
  margin: 0;
  font-size: 16px;
  padding: 0;
}
.cw_filter_item .cw_filter_label {
  float: left;
  margin: 3px 0;
}
.cw_filter_item .has_wrench.cw_status_indicator,
.cw_filter_tag .has_wrench.cw_status_indicator {
  background-color: #333 !important;
  text-align: center;
}
.cw_filter_item .has_wrench .cw_indicator,
.cw_filter_tag .has_wrench .cw_indicator {
  color: #fff;
  font-size: 10px;
  margin: 2px auto 0;
}
/* Full Table */
.cw_table_full {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  text-align: left;
}
/* Pod/Widget Window */
.window {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.window_titlebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 70;
  height: 50px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
  color: #3B3E42;
  text-align: left;
  text-shadow: 0 1px 0 #fff;
  border-radius: 3px 3px 0 0;
}
.window_titlebar strong {
  float: left;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.window_titlebar .cw_sm_name {
  float: left;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.window_titlebar .cw_card_options {
  float: right;
  border: none;
}
.window_titlebar .sm_wt_name {
  float: left;
  max-width: 65%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.window_titlebar .titlebar_icon {
  margin: 6px 5px 0 0;
  padding: 6px 10px;
}
.window_titlebar .cw_actions_menu {
  left: 15px;
}
.window_title {
  float: left;
  width: 40%;
  text-align: left;
  margin-top: 15px;
  height: 35px;
}
.titlebar_title {
  float: left;
  width: 55%;
  text-align: left;
}
.cw_window_actions {
  float: left;
  width: 60%;
  padding-left: 10px;
  padding-right: 15px;
  margin-top: 15px;
  height: 35px;
}
.cw_window_actions .k-button-icon {
  padding: 0.5em 0.6em;
}
.cw_window_actions .k-button {
  margin: 0 0 0 10px;
}
.cw_window_actions .glyphicons {
  font-size: 17px;
}
.window_area {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-color: #dee8ec;
}
.window_area.inner15 {
  top: 52px;
  right: 15px;
  bottom: 15px;
  left: 15px;
}
.cw_page_title {
  float: left;
  height: 35px;
  line-height: 34px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  text-indent: 15px;
}
/* Grid Action Menu */
.cw_global_actions {
  float: left;
  text-indent: 0;
}
.cw_global_actions .cw_actions_trigger {
  float: left;
  margin: 5px 0 0 15px;
  padding: 0.3em 0.5em;
}
#cw_available_metrics_window .cw_actions_trigger {
  padding: 0.3em 0.5em;
}
.cw_global_actions .cw_actions_trigger .glyphicons,
#cw_available_metrics_window .cw_actions_trigger .glyphicons {
  font-size: 19px;
}
.cw_actions_wrapper {
  float: left;
  width: 100%;
  position: relative;
  z-index: 10100;
}
.cw_secton_title .cw_actions_menu {
  left: 10px;
}
.cw_actions_menu.is_open {
  display: block !important;
}
.cw_actions_menu {
  display: none !important;
  float: left;
  position: absolute;
  top: -2px;
  z-index: 10;
  left: 10px;
  min-width: 220px;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.cw_actions_menu .cw_item {
  float: left;
  width: 100%;
  margin: 0;
  padding: 5px;
  line-height: 30px;
  border-bottom: 1px solid #ddd;
  list-style: none;
  background-color: #fff;
  border-radius: 0;
  font-weight: 700;
  cursor: pointer !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cw_actions_menu .cw_item:first-child {
  border-radius: 0 3px 0 0;
}
.cw_actions_menu .cw_item:last-child {
  border-bottom: none;
  border-radius: 0 0 3px 0;
}
.cw_actions_menu .cw_item:hover {
  background: #cfe6f5;
}
.cw_actions_menu .cw_item:hover.is_disabled {
  background-color: #f1f8fc;
}
.cw_actions_menu .cw_item.divider {
  border-color: #bfbfbf;
}
.cw_actions_menu .cw_item.double_divider {
  border-color: #bfbfbf;
  border-width: 2px;
}
.cw_actions_menu .cw_item.is_disabled,
.cw_actions_menu .cw_item.is_disabled .glyphicons {
  color: #aaa;
}
.cw_actions_menu .glyphicons {
  float: left;
  font-size: 1.8em;
  width: 30px;
  padding: 0;
  margin: 6px 5px 0 0;
  text-align: center !important;
}
.cw_actions_menu .material-icons {
  float: left;
  font-size: 1.3em;
  width: 30px;
  padding: 0;
  margin: 6px 5px 0 0;
  text-align: center !important;
}
/* Cw_Tip */
.cw_tip {
  height: 85px;
}
.cw_tip p {
  font-weight: 700;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  line-height: 1.5;
  box-sizing: border-box;
}
.cw_tip_image {
  border-radius: 4px;
  border: 1px solid #d8d8d8;
}
/* Custom Kendo Multiselect*/
.cw_custom_multiselect {
  float: left;
  width: 100%;
}
/* CW_SingleSelect + CW_MultiSelect */
.cw_select {
  float: left;
  width: 100%;
  height: 215px;
  overflow: auto;
  padding: 2px;
  border: 1px solid #bbb;
  background: #fff;
  color: #666;
  list-style: none;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.15);
}
.cw_select li {
  padding: 3px 0 4px;
  font-size: 13px;
  list-style: none;
  cursor: pointer !important;
}
.cw_select li span {
  display: block;
  text-indent: 4px;
}
/* Cw_Multi_Toggle */
.cw_multi_toggle {
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #54595E;
}
.cw_multi_toggle.small li {
  padding: 0.15em 0.5em;
  text-indent: 0;
  line-height: 1;
  height: auto;
}
.cw_multi_toggle.is_disabled {
  border-color: #eee;
}
.cw_multi_toggle.is_disabled li {
  cursor: default !important;
}
.cw_multi_toggle li {
  float: left;
  line-height: 1;
  padding: 0.7em;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  border-right: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cw_multi_toggle li:last-child {
  border-right: none;
}
.cw_multi_toggle .glyphicons {
  color: #888;
}
.cw_multi_toggle .first_btn {
  border-radius: 2px 0 0 3px;
}
.cw_multi_toggle .last_btn {
  border-radius: 0 2px 2px 0;
}
.cw_multi_toggle input {
  position: absolute;
  left: -9999999999px;
}
.cw_multi_toggle .is_selected {
  background: #cfe6f5;
  color: #000;
}
.cw_multi_toggle .is_selected .glyphicons {
  color: #54595E;
}
.cw_multi_toggle.cw_last_changes_timerange {
  position: relative;
}
.cw_multi_toggle.severity .item {
  padding: 5px 10px;
  background-color: #eee;
  color: #fff;
}
.cw_multi_toggle.severity .is_selected.critical {
  background-color: #e14b32;
}
.cw_multi_toggle.severity .is_selected.major {
  background-color: #f1ae31;
}
.cw_multi_toggle.severity .is_selected.minor {
  background-color: #f7e400;
}
.cw_multi_toggle.severity .is_selected.ok {
  background-color: #7cc623;
}
.cw_section_titlebar .cw_multi_toggle {
  margin-right: 10px;
}
.cw_section_titlebar .cw_multi_toggle li {
  line-height: 20px;
}
/* CW Grid2Card */
.cw_grid_card {
  float: right;
  margin-left: 10px;
}
.cw_grid_card li {
  height: 28px;
  line-height: 28px;
  padding: 0 1em;
}
.cw_grid_card .glyphicons {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}
/* CW List as Table */
.cw_list {
  float: left;
  width: 100%;
  list-style: none;
  margin: 0 0 16px;
}
.cw_list_row {
  float: left;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.cw_list_row {
  border-right: 1px solid #d8d8d8;
}
.cw_list_head,
.cw_list_item {
  float: left;
  padding: 5px 0 0;
  text-align: center;
  border: 1px solid #d8d8d8;
  border-left: none;
  border-bottom: none;
}
.cw_list_head,
.cw_list_item:nth-child(3) {
  border-right: none;
}
.cw_list_head {
  background: #fbfbfb;
}
.cw_list_item {
  min-height: 25px;
  border-top: none;
  text-align: center;
}
.cw_list_head:first-child {
  border-radius: 3px 0 0 0;
  border-left: 1px solid #d8d8d8;
}
.cw_list_head:last-child {
  border-radius: 0 3px 0 0;
}
.cw_list_item:first-child {
  border-left: 1px solid #d8d8d8;
}
.highlight {
  background-color: #eee;
}
/* CW Progress - Timeline  */
.cw_progress {
  float: left;
  width: 100%;
  position: relative;
  height: 24px;
  padding: 2px;
  margin: 1px 0;
  border: 1px solid #d8d8d8;
  background: #fff;
  border-radius: 20px;
}
.cw_progress .cw_health_0 {
  position: static;
}
.cw_progress .cw_completion_tooltip {
  display: none;
}
.cw_progress:hover .cw_completion_tooltip {
  position: absolute;
  top: -30px;
  right: -20px;
  display: block;
  padding: 3px 5px;
  border: 1px solid #d8d8d8;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.cw_progress.small {
  height: 18px;
  padding: 1px;
}
.cw_progress_bar {
  float: left;
  height: 18px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), transparent);
  cursor: pointer !important;
}
.cw_progress_bar.ok,
.cw_progress_bar.up,
.cw_progress_bar.compliant,
.cw_progress_bar.is_ok {
  background-color: #7cc623;
}
.cw_progress_bar.minor,
.cw_progress_bar.is_minor {
  background-color: #f7e400;
}
.cw_progress_bar.major,
.cw_progress_bar.warning,
.cw_progress_bar.is_major {
  background-color: #f1ae31;
}
.cw_progress_bar.critical,
.cw_progress_bar.down,
.cw_progress_bar.breached,
.cw_progress_bar.is_critical {
  background-color: #e14b32;
}
.cw_progress_bar.inactive,
.cw_progress_bar.is_idle {
  background-color: #dddddd;
}
.cw_progress_bar:first-child {
  border-radius: 20px 0 0 20px;
}
.cw_progress_bar:last-child {
  border-radius: 0 20px 20px 0;
}
.cw_progress_bar.single_bar {
  position: absolute;
  left: 2px;
  width: auto !important;
  right: 2px;
  border-radius: 20px !important;
}
.cw_progress_bar.single_bar.inactive {
  background-color: #dddddd;
}
.cw_progress_slice {
  float: left;
  position: absolute;
  left: 2px;
  height: 18px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), transparent);
  border-radius: 20px 0 0 20px;
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.5);
}
.cw_progress.small .cw_progress_slice {
  height: 14px;
  left: 1px;
}
.cw_compliance_goal {
  position: absolute;
  top: 21%;
  right: 4%;
}
.cw_health_100 {
  background-color: #7cc623;
}
.cw_health_60 {
  background-color: #f7e400;
}
.cw_health_40 {
  background-color: #f1ae31;
}
.cw_health_20 {
  background-color: #e14b32;
}
.cw_health_0 {
  position: absolute;
  background-color: #c4c4c4;
  border: none;
  left: 2px;
  right: 2px;
  border-radius: 20px;
}
.cw_health_warning {
  background-color: #f1ae31;
}
/* Progress - alternative for split Service Details */
.progress {
  float: left;
  width: 100%;
  height: 10px;
  background-color: #eee;
  border-radius: 2px;
  overflow: hidden;
}
.progress .part {
  float: left;
  margin: 0;
  padding: 0;
  height: 100%;
  box-shadow: inset -1px 0 0 rgba(255, 255, 255, 0.5);
}
.progress .part.critical,
.progress .part.breached {
  background-color: #e14b32;
}
.progress .part.major,
.progress .part.warning {
  background-color: #df9f27;
}
.progress .part.minor {
  background-color: #f7e400;
}
.progress .part.ok {
  background-color: #7cc623;
}
.progress .part.inactive {
  background-color: #dddddd;
}
/* CW Bars */
.cw_bars {
  float: left;
  width: 100%;
  height: 110px;
}
.cw_bars .cw_bar {
  float: left;
  position: relative;
  height: 80px;
  margin: 0 5px;
  width: 120px;
  border-width: 1px 1px 3px;
  border-style: solid;
  line-height: 80px;
  border-radius: 5px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.3) 20%, transparent);
}
.cw_bars .cw_bar:hover {
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}
.cw_bars .cw_bar.no_bg:hover {
  box-shadow: none;
}
.cw_bars .cw_bar.no_bg .value {
  text-shadow: none;
  color: #555;
}
.cw_bars .cw_bar.is_ok {
  border-color: #6eb01f;
  background-color: #7cc623;
}
.cw_bars .cw_bar.is_minor {
  border-color: #c4b500;
  background-color: #f7e400;
}
.cw_bars .cw_bar.is_major {
  border-color: #efa519;
  background-color: #f1ae31;
}
.cw_bars .cw_bar.is_critical {
  border-color: #da3a20;
  border-bottom-color: #c3341d;
  background-color: #e14b32;
}
.cw_bars .cw_bar.is_idle {
  border-color: #c4c4c4;
  background-color: #dddddd;
}
.cw_bars .cw_bar span {
  float: left;
  width: 100%;
  display: block;
}
.cw_bars .value {
  height: 80px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  color: #fff;
}
.cw_bars .legend {
  height: 20px;
  line-height: 20px;
  margin-top: 5px;
  color: #888;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center !important;
}
.cw_bars .cw_qualifiers_down {
  position: absolute;
  top: -2px;
  right: -2px;
  min-width: 20px;
  height: 20px;
  border: 1px solid #fff;
}
.cw_bars .cw_services_in_maintenance {
  position: absolute;
  top: -2px;
  left: -2px;
  min-width: 20px;
  height: 20px;
  border: 1px solid #fff;
}
.cw_account_health_bars .cw_bar {
  cursor: pointer !important;
}
.cw_account_health_bars .cw_bar.no_bg {
  cursor: default !important;
}
.cw_account_application_bars .cw_bar {
  cursor: pointer !important;
}
.cw_account_application_bars .cw_bar.no_bg {
  cursor: default !important;
}
/* CW Separator */
.cw_separator {
  float: left;
  width: 100%;
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin: 15px 0 0;
}
.cw_top_separator {
  float: left;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #d8d8d8;
}
.cw_separator .cw_separator_title,
.cw_top_separator .cw_separator_title {
  float: left;
  background: #fff;
  padding: 5px 10px;
  margin: -25px 0 0 10px;
  color: #444;
  font-weight: 700;
}
.cw_top_separator .cw_separator_title:first-letter {
  text-transform: capitalize;
}
.cw_separator p,
.cw_separator p:first-child {
  margin: 0 0 15px;
}
.cw_separator p:last-child {
  margin-bottom: 0;
}
.cw_separator .btn_icon {
  margin: -20px 0 0;
}
.cw_separator .cw_half_checkbox .cw_checkbox {
  margin: 3px 0 0 130px;
}
.cw_separator .cw_inline + .cw_checkbox {
  float: left;
  margin: 3px 0 0 130px;
}
.cw-separator-frame {
  float: left;
  width: 100%;
  position: relative;
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}
.cw-separator-frame__title {
  position: absolute;
  top: -8px;
  left: 10px;
  background-color: white;
  padding: 0 3px;
  font-weight: bold;
}
.cw-separator-frame.selection {
  margin-bottom: 10px;
}
/* Splitter */
#splitter {
  float: left;
  width: 100%;
  height: 100%;
  border: 0;
}
#splitter .k-splitbar-horizontal {
  border-left-color: #3b3e42;
}
#uc_splitter_inner.k-splitter {
  position: absolute;
  top: 37px;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto !important;
}
#uc_splitter_inner .status.global {
  left: 220px;
}
/* CW Label */
.cw_label {
  float: left;
  margin-top: 2px;
  text-shadow: 0 1px 0 #fff;
  font-size: 11px;
  font-weight: 700;
  background: #eee;
  padding: 0 10px;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 4px;
}
.cw_label_error {
  background-color: #e14b32;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
/* CW Dropdown Animation */
.cw_dropdown_reset .k-list-container.k-popup,
.cw_dropdown_reset .k-list-container.k-popup .k-list {
  height: auto !important;
}
.cw_dropdown_reset .k-list li {
  float: left;
  width: 100%;
  clear: left;
}
.cw_dropdown_reset .k-list .k-item > span {
  float: left !important;
}
.cw_indicator_dropdown .k-list .k-item {
  clear: left;
  float: left;
  width: 100%;
}
/* STATE Widget */
.cw_state_widget {
  float: left;
  width: 33.3333%;
  padding: 0 10px;
  text-align: center;
}
.cw_state_widget .content {
  position: relative;
  float: left;
  width: 100%;
  display: table;
  height: 80px;
  padding-bottom: 30px;
  margin: 10px auto 0;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}
.cw_state_widget .value {
  display: inline-block;
  font-size: 40px;
  height: 50px;
  color: inherit;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}
.cw_state_widget .value .total {
  font-size: 25px;
}
.cw_state_widget .vertical {
  text-align: center;
}
.cw_state_widget .title {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
  border-radius: 0 0 3px 3px;
  line-height: 30px;
  text-shadow: none;
  font-weight: 700;
}
.cw_state_widget .subtitle {
  float: left;
  clear: left;
  width: 100%;
  font-weight: 700;
  text-align: center;
  color: inherit;
}
.cw_state_widget .glyphicons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 28px;
  border-radius: 3px;
  transition: background 0.25s;
}
.cw_state_widget .glyphicons:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.cw_state_widget2 {
  float: left;
  padding: 0 10px;
  text-align: center;
  box-sizing: border-box;
}
.cw_state_widget2 .content {
  position: relative;
  float: left;
  width: 100%;
  display: table;
  margin: 10px auto;
  height: 137px;
  padding-bottom: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  background-color: #fbfbfb;
}
.cw_state_widget2 .value,
.cw_state_widget2 .days {
  display: inline-block;
  font-size: 32px;
  height: 50px;
  color: #333;
}
.cw_state_widget2 .value .total,
.cw_state_widget2 .days .total {
  font-size: 25px;
}
.cw_state_widget2 .vertical {
  width: 100%;
  text-align: center;
}
.cw_state_widget2 .title {
  position: absolute;
  left: 1px;
  bottom: 1px;
  right: 1px;
  padding: 0 15px;
  height: 30px;
  background-color: #fff;
  color: #54585E;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
  line-height: 30px;
  text-shadow: none;
  font-weight: 700;
}
.cw_state_widget2 .circle-arrow-top {
  color: #7cc623;
}
.cw_state_widget2 .circle-arrow-down {
  color: #e14b32;
}
.cw_state_widget2 .exclamation-sign {
  color: #f1ae31;
}
.cw_state_widget2 .subtitle,
.cw_state_widget2 .extra {
  color: #999;
}
/* Tags */
.cw_tag_indicator {
  float: right;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
  margin: 7px 15px 0 0;
}
.cw_new_tag_indicator {
  display: inline-block;
  margin-left: 30px;
}
.cw_new_treelist_tag_indicator {
  display: inline-block;
}
.cw_home_tag_indicator {
  position: absolute;
  display: inline;
}
/* Cw Status Widget */
.cw_status_widget {
  border: 1px solid #d8d8d8;
  width: 60px;
  height: 20px;
  line-height: 20px;
  margin: 2px auto 0;
  border-radius: 18px;
  background: #f5f5f5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8);
}
.cw_agent_status {
  width: 80px;
}
.cw_status_widget.cw_agent_status {
  line-height: 18px;
}
.cw_agent_status .glyphicons {
  float: left;
  margin: -1px 2px 0;
  height: 18px;
  line-height: 18px;
}
.cw_status_widget:hover {
  background: #eee;
  border-color: #bfbfbf;
}
.cw_status_widget_color {
  float: left;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
  background: #fff;
}
.cw_status_widget_color:hover {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.cw_status_widget_color.cw_color1 {
  background: #e45f48;
}
.cw_status_widget_color.cw_color2 {
  background: #f3b749;
}
.cw_status_widget_color.cw_color3 {
  background: #f7e400;
}
.cw_status_widget_color.cw_color4 {
  background: #7cc623;
}
.cw_status_widget_color.cw_color5 {
  background: #7cc623;
}
.cw_status_widget_color.cw_color6,
.cw_status_widget_color.is_idle {
  background: #c4c4c4;
  cursor: default !important;
}
.cw_status_widget_color .cw_indicator {
  background: none !important;
  font-size: 10px !important;
  margin: 3px auto !important;
  color: #fff;
}
.cw_status_widget_color.cw_color6:hover,
.cw_status_widget_color.cw_color0:hover {
  box-shadow: none;
}
.cw_status_widget_color.cw_color7 {
  background: #f1ae31;
  cursor: default !important;
}
.cw_status_widget_value {
  float: left;
  width: 30px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  font-weight: 700;
}
.cw_status_widget_icon {
  float: left;
  font-size: 12px;
  width: 17px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  text-shadow: none;
  background: url("~styles/shared/images/app_theme//cw_exclamation.png") no-repeat center center;
  padding: 0 !important;
}
.cw_status_indicator {
  /*float:none; display: inline-block; */
  float: none;
  display: block;
  margin: 0 auto;
}
.cw_status_indicator.empty {
  display: inline-block;
  background: none;
  margin: 0 0 -2px;
  border: 2px solid #54595E;
  width: 12px;
  height: 12px;
  border-radius: 14px;
}
.cw_agent_status .cw_status_indicator {
  float: left !important;
  margin: 1px 1px 1px 2px;
  cursor: pointer !important;
}
.cw_inline_status_indicator {
  margin-left: 5px;
}
.cw_status_widget_color .cw_monitor_agent_down {
  text-shadow: none;
  margin-top: -1px;
}
.cw_status_widget_color .cw_color1 {
  color: #e45f48;
}
.cw_status_widget_color .cw_color2 {
  color: #f3b749;
}
.cw_status_widget_color .cw_color3 {
  color: #f7e400;
}
.cw_status_widget_color .cw_color4 {
  color: #7cc623;
}
.cw_status_widget_color .cw_color5 {
  color: #7cc623;
}
.cw_status_widget_color .cw_color6 {
  color: #c4c4c4;
  cursor: default !important;
}
.cw_status_widget_color .status_icon {
  height: 16px;
  line-height: 16px;
  font-size: 16px !important;
}
/*NEW*/
.cw_status_indicator.is_round {
  width: 16px;
  height: 16px;
  margin: 7px -5px 7px 7px;
  line-height: 20px;
  border-radius: 50%;
  text-shadow: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
}
.cw_status_indicator.is_round.share.glyphicons {
  line-height: 1;
  font-size: 14px;
  color: #fff;
  padding: 1px 2px;
}
.cw_status_indicator.no_shadow {
  box-shadow: none;
  font-size: 16px;
}
.cw_status_indicator .cw_indicator {
  color: #fff;
  font-size: 10px;
  margin: 3px auto;
}
.cw_status_indicator.has_multiple_monitors {
  border: 2px solid rgba(0, 0, 0, 0.75);
}
.cw_status_indicator.is_idle {
  background-color: #c4c4c4;
}
.cw_status_indicator.is_critical {
  background-color: #e14b32;
}
.cw_status_indicator.is_major {
  background-color: #f1ae31;
}
.cw_status_indicator.is_minor {
  background-color: #f7e400;
}
.cw_status_indicator.is_ok {
  background-color: #7cc623;
}
.cw_status_indicator .cw_indicator.exclamation-mark {
  margin: 0;
}
.cw_status_indicator .glyphicons {
  background-color: #333;
  color: #fff;
  border-radius: 20px;
  font-size: 14px;
  width: 16px;
  height: 16px;
  padding: 2px;
}
.cw_status_indicator .glyphicons.exclamation-sign {
  color: #333;
  font-size: 18px;
  background: #fff;
  padding: 0;
}
.exclamation-mark {
  margin: 0!important;
  width: 16px;
  height: 16px;
  background-image: url("~styles/shared/images/app_theme//cw_exclamation_white.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.cw_service_indicator.exclamation-mark-circle {
  margin: 0!important;
  width: 16px;
  height: 16px;
  background: url("~styles/shared/images/app_theme//cw_exclamation_white_circle.png") no-repeat center center;
  background-size: cover;
}
.exclamation-mark-circle {
  margin: 0!important;
  width: 16px;
  height: 16px;
  background: url("~styles/shared/images/app_theme//cw_exclamation_white_circle.png") no-repeat center center;
  background-size: cover;
}
/* Footer Action Bar for Modal Windows */
.k-window .actions,
.k-window .cw_actions {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
}
.actions,
.cw_actions {
  float: left;
  position: relative;
  z-index: 5;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
  border-top: 1px solid #eee;
  background-color: #f5f5f5;
  border-radius: 0 0 3px 3px;
}
.actions .k-button,
.cw_actions .k-button {
  margin: 0 0 0 10px;
  min-width: 70px;
}
.actions .k-button.left,
.cw_actions .k-button.left {
  margin-left: 0;
}
.window .actions .white,
.window .cw_actions .white {
  margin-right: 0;
}
/* Modal */
.k-window .cw_expand_area,
#modal .cw_expand_area {
  display: none;
}
/* Preloader */
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cw_preloader {
  font-size: 16px;
  color: #777;
  display: none;
}
.cw_preloader .spinner {
  position: absolute;
  top: 50px;
  left: 50%;
  margin-right: -12px;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #00aeef #00aeef #fff;
  border-radius: 15px;
  -webkit-animation: spin 0.5s infinite;
  -moz-animation: spin 0.5s infinite;
  -o-animation: spin 0.5s infinite;
  animation: spin 0.5s infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
/*Loader*/
.cw_loader {
  margin: 30px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.4em solid rgba(0, 0, 0, 0.2);
  border-right: 0.4em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.4em solid rgba(0, 0, 0, 0.2);
  border-left: 0.4em solid #ffffff;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.cw_loader,
.cw_loader:after {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*Custom time selector*/
.cw_last_changes_period {
  width: 400px;
  position: absolute;
  top: 28px;
  right: -1px;
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #ccc;
  border-radius: 3px 0 3px 3px;
  z-index: 10;
}
.cw_last_changes_period .cw_select_periods {
  margin-top: -2px;
}
.cw_last_changes_period input {
  position: initial;
}
/*Date picker*/
.k-calendar-container td,
.k-calendar-container th {
  text-align: center;
}
.k-calendar .k-footer a {
  padding: 5px!important;
}
.cw_window_actions .k-button.cw_button_help {
  height: 30px;
  width: 30px;
  text-align: center !important;
  padding-top: 0.6em;
}
.cw_window_actions a:last-child {
  margin-top: 2px;
}
.cw_btn_group {
  margin: 10px 0;
  float: left;
}
.cw_btn_group button {
  margin-right: 2px;
}
.cw_btn_group button.is_selected {
  background: #cfe6f5;
  color: #000;
}
#cw_rrule_text {
  padding-left: 10px;
}
#cw_interval_text {
  display: inline;
  padding-left: 15px;
}
#cw_monthly_frequency .k-dropdown {
  margin-left: 5px;
}
#cw_monthly_frequency label {
  padding-left: 5px;
}
#service_qualifiers_list #cw_service_qualifiers_list_taglist li,
#service_elements_list #cw_service_elements_list_taglist li,
#service_state_list #cw_service_state_elements_list_taglist li {
  width: 100%;
}
#service_qualifiers_list #cw_service_qualifiers_list_taglist .k-button > span:first-child,
#service_elements_list #cw_service_elements_list_taglist .k-button > span:first-child,
#service_state_list #cw_service_state_elements_list_taglist .k-button > span:first-child {
  max-width: 100%;
}
label[for="cw_state_up"] {
  padding-right: 10px;
}
.cw_states_container {
  line-height: 30px;
  margin-left: 130px;
}
.cw_multigraph_button {
  display: flex;
  justify-content: flex-end;
}
.cw_widget_form .cw_field.cw_multigraph_button {
  margin-bottom: unset;
}
#cw_add_service_element_button {
  margin: -10px 0 10px 0;
}
#cw_run_details,
#cw_datasource_test_content,
#cw_action_test_content,
#cw_test_result {
  padding: 5px;
}
.cw_script_content .cw_section.cw_box_full {
  margin-top: -40px;
}
.cw_action_content,
.cw_datasource_content,
.cw_postscript_content {
  margin: 10px;
  height: calc(33% - 20px);
  width: calc(100% - 20px);
}
.cw_script_content .minus,
.cw_script_content .fullscreen {
  float: right;
  padding: 6px 0;
  text-indent: 5px;
  margin-right: 5px;
  cursor: pointer !important;
}
.cw_script_content .cw_section_content {
  height: 100%;
  overflow: auto;
}
.cw_script.is_active .cw_section_titlebar {
  background-color: #cfe6f5;
}
.cw_script .cw_section_content {
  padding: 10px;
  border: 1px solid #d8d8d8;
}
.cw_script .cw_section_titlebar {
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-bottom: none;
}
.cw_script .cw_section_content .ace_editor {
  height: 100%!important;
}
.cw_automation_settings {
  width: 405px;
}
.cw_automation_scripts_view {
  left: 415px;
}
#cw_automation_module .cw_script_test_result {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 100;
}
#cw_test_content {
  margin-top: -30px;
}
#cw_test_result {
  margin-top: 55px;
}
#cw_test_content .cw_preloader .spinner {
  top: 50%;
}
.cw_service_details_module_title .cw_preloader .spinner {
  top: 50%;
}
.cw_service_details_module_title .cw_mask {
  z-index: 999;
}
#cw_status_transition_to {
  margin-left: 2px;
}
#cw_status_transition_to {
  margin-left: 2px;
}
.cw_automation_settings .cw_mask {
  background-color: #fff;
  z-index: 40;
}
.share_script .cw_section_titlebar {
  margin-top: 0;
}
#cw_enable_profile {
  float: left;
  margin-top: 6px;
  margin-left: 6px;
  text-indent: 0;
}
.cw_separator_hide {
  float: none;
  border: none;
  padding: 0;
}
.cw_transitions .cw_inline_full span {
  text-indent: 10px;
  font-weight: bold;
}
#cw_add_transition.is_disabled {
  opacity: 0.5;
  cursor: initial!important;
}
.cw_automation_settings .plus-sign {
  font-size: 20px;
  margin: 5px 0 0 0;
}
.cw_status_transition_list .cw_transition_from {
  float: left;
  display: block;
  width: 138px;
  font-weight: 700;
}
.cw_status_transition_list .cw_transition_to {
  float: right;
  display: block;
  width: 138px;
  font-weight: 700;
}
.cw_status_transition_list {
  float: left;
  width: 100%;
  padding: 10px;
  min-height: 120px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  position: relative;
}
.cw_status_transition_list .cw_remove_transition {
  float: right;
}
.cw_automation_settings .cw_show_filter_options {
  margin-bottom: 5px!important;
  margin-top: -2px !important;
}
.cw_automation_settings .cw_no_items {
  float: left;
  width: 100%;
  padding: 5px 10px;
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.cw_automation_details {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#cw_automation_module .cw_automation_details.k-splitter {
  height: 100%;
}
#cw_automation_module .cw_automation_details.k-splitter .cw_automation_settings {
  height: 100%;
}
#cw_automation_module .cw_automation_details.k-splitter .k-splitter .k-ghost-splitbar-horizontal,
#cw_automation_module .cw_automation_details.k-splitter .k-splitter .k-splitbar-horizontal {
  height: 100%;
}
#cw_automation_module .cw_automation_details.k-splitter .k-tabstrip-wrapper {
  height: 100%;
}
@-moz-document url-prefix() {
  #cw_enable_profile {
    margin-right: -12px;
  }
}
.window-title-icon {
  position: absolute;
  font-size: 16px;
  top: 8px;
  right: 40px;
}
.grid_favorite_item {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.cw_no_records_container {
  height: 100%;
  background-color: #f4f7f9;
  color: #267fb8;
}
.cw_no_records_info {
  position: relative;
  top: 20px;
}
.grid-menu-tooltip {
  float: right !important;
  font-size: 16px !important;
  margin-top: 8px !important;
}
.filtered-content-details-grids {
  float: left;
  height: 32px;
}
.required-mark {
  color: red;
  margin: -20px 0 0 -3px;
}
.cw_modal textarea {
  height: 47px;
}
.cw_modal #modal .cw_create_prtg #cw_authenticate {
  float: right;
}
/* Login Page */
body.login::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 63, 73, 0);
}
body.login {
  background-size: cover;
}
body.login iframe {
  float: left;
  width: 100%;
  height: 300px;
  padding: 20px;
  border: none;
}
body.login .main_content {
  width: 100%;
  padding: 0;
}
body.login .wrapper {
  max-width: 960px;
  margin: 0 auto;
}
body.login .logo {
  float: left;
  margin: 15px 0 0;
  border: none;
}
body.login .logo a {
  margin: 0;
}
body.login .support {
  clear: right;
  font-size: 12px;
  text-transform: uppercase;
  color: #777;
  text-decoration: none;
  margin: 10px 0 0;
}
body.login .support:hover {
  color: #EA0039;
}
body.login .contact {
  clear: right;
  margin: 10px 0 0;
}
body .row:first-child {
  background-color: #fff;
}
.ie_warning {
  position: absolute;
  display: none;
  top: 20%;
  left: 50%;
  margin-left: -220px;
  background: #fff;
  padding: 20px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.ie_content {
  float: left;
  width: 100%;
  margin-left: -20px;
  padding: 20px;
  border-top: 1px solid #d8d8d8;
}
.ie_content p {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}
.ie_content a {
  float: left;
  width: 48%;
  margin: 0 1%;
  padding: 10px 0;
  text-align: center;
  background-color: #eee;
  border-radius: 5px;
}
#cw_ie_forbidden {
  height: 60px;
  background-color: #cfe6f5;
  margin-bottom: 20px;
  padding: 12px 5px 5px 5px;
  border-radius: 5px;
}
.detached #login_form {
  float: none;
  margin: 40px auto 0;
  background-color: #fff;
}
.login_wrapper a.cw_login {
  width: 340px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
}
#login_form,
.changePassword,
#login_azure_container {
  width: 340px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
}
#login_form .cw_field,
.changePassword .cw_field,
#login_azure_container .cw_field {
  position: relative;
  margin-bottom: 20px;
}
#login_form .logo a,
.changePassword .logo a,
#login_azure_container .logo a {
  width: 340px;
  margin: 20px 0 0;
}
#login_form .k-input,
.changePassword .k-input,
#login_azure_container .k-input {
  width: 280px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  box-sizing: border-box;
}
#login_form .k-button,
.changePassword .k-button,
#login_azure_container .k-button {
  width: 280px;
  padding: 8px 0;
  font-weight: 400;
  background-color: #da1f3d;
  border: none;
}
#login_form .glyphicons,
.changePassword .glyphicons,
#login_azure_container .glyphicons {
  position: absolute;
  right: 20px;
  top: 13px;
  color: #ccc;
}
#login_form .invalid,
.changePassword .invalid,
#login_azure_container .invalid {
  padding: 1em;
}
#login_form .invalid:focus,
.changePassword .invalid:focus,
#login_azure_container .invalid:focus {
  padding: 1em;
}
#login_form,
#login_azure_container {
  height: auto;
}
.changePassword .k-input,
.changePassword label {
  width: 100%;
}
.changePassword .cw_field:first-child {
  margin: 10px 0;
  font-weight: bold;
}
.cw_loginarea {
  max-width: 600px;
  min-height: 540px;
  margin: 150px auto 0;
  text-align: center;
}
.login_content {
  width: 300px;
  padding: 30px 20px;
  box-sizing: content-box;
}
#login_azure_container #login_azure_button {
  margin-top: 80px;
  background-color: #0078D7;
  font-size: 16px;
}
#login_local_user {
  margin-top: 50px;
  color: #89ADC6;
  padding-bottom: 10px;
}
.login_wrapper .logo {
  float: none !important;
  width: 600px;
  border: none !important;
  margin: 10px auto!important;
}
.updates h1 {
  font-size: 16px;
}
.clearfix:after {
  clear: both;
  height: 0;
  display: block;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  content: ".";
}
.clearfix {
  zoom: 1;
}
.cw_login_preloader .spinner {
  top: 390px !important;
  width: 30px !important;
  height: 30px !important;
}
.cw_progress_content {
  width: 340px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  height: 60px;
}
.cw_info_content {
  width: 340px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  height: auto;
}
.cw_info_content p {
  padding: 12px;
  font-weight: 700;
}
#cw_progress_value {
  margin-left: 35px;
  margin-top: 10px;
}
.login_content .cw_mask {
  width: 340px;
  margin: 150px auto;
  background-color: #fafafa;
  border-radius: 10px;
  height: 211px;
  opacity: 0.6;
}
#return_to_login {
  margin-top: 25px;
}
p.error {
  word-break: break-word;
}
/* Collapsable Menu when going into designer */
.cw_designer_module .aside {
  width: 60px;
}
.cw_designer_module .cw_account_drop {
  left: 200px;
}
.cw_designer_module .cw_multi_options {
  left: 65px;
}
.cw_designer_module .cw_context_action {
  display: none;
}
.cw_designer_module .cw_main_nav {
  width: 60px;
}
.cw_designer_module .cw_main_nav .cw_item {
  width: 100%;
}
.cw_designer_module .cw_main_nav .label {
  display: none !important;
}
.cw_designer_module .cw_nav_secondary {
  left: 60px;
}
.cw_designer_module #content_area {
  left: 60px;
}
.cw_designer_module .cw_multi_content .cw_multi_caret {
  width: 35px;
  border-radius: 4px;
}
.cw_designer_module .cw_sq_details .k-window,
.cw_designer_module .cw_sq_details .k-window.k-state-focused {
  z-index: 1000000!important;
}
.cw_designer_module .cw_sq_details .k-window[id^="windowcw_sq_preview"] {
  z-index: 19999 !important;
}
/* Service Model Designer */
.window_area.designer_area {
  top: 65px;
}
.cw_sm_template_wizard {
  padding: 15px;
}
.cw_sm_template_wizard .k-listview {
  float: left;
  width: 100%;
  max-height: 509px;
  overflow-y: auto;
  overflow-x: hidden;
  border: none;
}
#cw_se_list {
  height: 200px;
  overflow-y: auto;
  width: 100%;
}
#cw_se_list li .glyphicons {
  float: left;
  font-size: 22px;
  padding-right: 10px;
}
#cw_se_list_section {
  width: 59%;
}
#cw_se_list_section .cw_card_avatar {
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}
#cw_se_list_section .w-image-uploader {
  padding: 10px 0;
}
#cw_se_list_section .w-image-uploader .b-tabs-content {
  left: 0;
  right: 0;
  height: 90px;
  top: 89px;
}
#cw_se_list_section .w-image-uploader .b-recently-used {
  display: block!important;
  height: 50px;
}
#cw_se_list_section .w-image-uploader .b-search [name=search] {
  height: 18px;
}
#cw_se_wizard {
  width: 39%;
}
.cw_se_sqs {
  height: 200px;
  overflow: auto;
}
.cw_qualifiers_down {
  position: absolute;
  top: -10px;
  right: -10px;
  min-width: 20px;
  height: 20px;
  line-height: 16px;
  padding: 1px 3px;
  background-color: #e14b32;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 3px #000000;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.cw_agent_down {
  position: absolute;
  top: -10px;
  right: -10px;
  min-width: 20px;
  height: 20px;
  line-height: 16px;
  padding: 1px 3px;
  background-color: #e14b32;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 3px #000000;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.exclamation-mark-container {
  width: 26px;
  height: 26px;
  right: 0;
  top: 0;
  position: absolute;
}
.exclamation-mark-container .exclamation-mark-inner-circle {
  width: 26px;
  height: 26px;
  background-color: lightgray;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 26px;
  border: 1px gray solid;
}
.exclamation-mark-container .exclamation-mark-inner-circle .exclamation-mark-symbol {
  font-size: 27px;
  font-weight: 900;
  position: absolute;
  top: 0;
  left: 10px;
  line-height: 25px;
  color: white;
}
.cw_exclamation_sign.exclamation-mark-container {
  top: -9px;
  right: -9px;
}
.cw_exclamation_sign {
  position: absolute;
  top: -5px;
  right: -11px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  padding: 1px 3px;
  color: #dddddd;
  text-align: center;
}
.cw_exclamation_sign .glyphicons.exclamation-sign {
  font-size: 22px;
  margin-top: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #dddddd;
}
.cw_exclamation_sign .exclamation-sign-back {
  position: absolute;
  width: 10px;
  height: 15px;
  background-color: white;
  top: 2px;
  left: 4px;
}
.cw_agent_down.cw_agent_maintenance {
  background-color: #dddddd;
}
.cw_services_in_maintenance {
  position: absolute;
  top: -10px;
  min-width: 20px;
  height: 20px;
  line-height: 16px;
  padding: 1px 3px;
  background-color: #dddddd;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 3px #000000;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.icon-container > .glyphicons.wrench {
  font-size: 22px;
  margin: 1px 0 0 -3px;
}
.applications-details .icon-container > .glyphicons.wrench {
  font-size: 24px;
  margin: 2px 0 0 -3px;
}
/* Service Model Templates */
.cw_header_info {
  position: relative;
  float: left;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 15px;
}
.cw_header_info .k-button {
  position: absolute;
  left: 0;
  width: 48% !important;
  max-width: 300px;
}
.cw_header_info .cw_search_box {
  position: absolute;
  right: 0;
  width: 48% !important;
  max-width: 300px;
}
.cw_smt_separator {
  float: left;
}
#cw_sm_templates,
#cw_sm_custom_templates,
.cw_sm_template {
  float: left;
  width: 100%;
}
#cw_sm_templates,
#cw_sm_custom_templates {
  height: 468px;
  overflow-y: scroll;
  border: none;
}
#cw_sm_templates li:hover {
  background: #cfe6f5;
  cursor: pointer !important;
}
.cw_sm_template {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  list-style: none;
}
.cw_sm_template_preview {
  float: left;
  width: 94px;
  height: 64px;
  margin-right: -111px;
  background: #fff;
  border: 1px solid #d8d8d8;
  overflow: hidden;
  text-align: center !important;
}
.cw_sm_template_preview img {
  max-height: 60px;
  max-width: 90px;
  padding: 1px;
}
.cw_sm_template_preview span.glyphicons {
  font-size: 48px;
  margin-top: 8px;
  color: #333;
}
.cw_sm_template_item {
  float: left;
  width: 100%;
  max-height: 90px;
  padding-left: 116px;
  overflow: hidden;
}
.cw_sm_template_item h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_sm_template_item p {
  max-height: 47px;
  overflow: auto;
  word-break: break-all;
}
.cw_current_template {
  background: #cfe6f5 !important;
}
/* Service Elements Templates */
.create_se_template .k-multiselect.k-widget {
  max-height: 60px;
}
.cw_sm_template_wizard .cw_se_template_wizard {
  padding: 0;
  margin: 0;
  height: 100%;
}
.cw_se_template_wizard {
  float: left;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  padding: 15px;
  overflow: auto;
}
.cw_se_template_wizard .cw_card {
  margin-bottom: 15px;
}
.cw_se_template_wizard .cw_card_content {
  overflow-y: scroll;
}
.cw_se_template_wizard .cw_separator .k-dropdown {
  /*width: 247px !important;*/
}
.cw_se_template_wizard .k-pager-wrap {
  float: left;
  width: 100%;
}
.cw_se_template_wizard .cw_sm_template_wizard {
  max-height: 480px;
  overflow: auto;
}
.cw_sm_template_wizard .cw_se_template_wizard .cw_actions {
  bottom: 60px;
  left: 61%;
  background-color: #fff;
}
.cw_blank_template {
  float: left;
  margin-left: 0 !important;
}
.cw_se_step1 {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.cw_se_templates_list {
  float: left;
  width: 100%;
  height: 650px;
  list-style: none;
  border: none;
}
.cw_se_template {
  cursor: pointer !important;
  float: left;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.cw_se_template:last-child {
  border-bottom: none;
}
.cw_se_template.k-state-selected {
  background-color: #cfe6f5;
  border-color: #a5d0ec;
  color: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
}
.cw_se_template_content {
  float: left;
  width: 100%;
  padding: 15px 15px 15px 126px;
  max-height: 90px;
  overflow: hidden;
}
.cw_se_template_content h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_se_template_content p {
  max-height: 47px;
  overflow: auto;
  word-break: break-all;
}
.cw_se_template_preview {
  float: left;
  width: 96px;
  height: 64px;
  margin: 15px -111px 15px 15px;
  background-color: #eee;
  overflow: hidden;
  text-align: center !important;
}
.cw_se_image_preview {
  width: 32px;
  height: 32px;
  margin: 12px 0 0 0;
}
.cw_se_template_preview span.glyphicons {
  font-size: 32px;
  line-height: 63px;
}
.cw_se_template_wizard .cw_card {
  margin-bottom: 0 !important;
}
.cw_se_template_settings,
.cw_se_template_settings p {
  float: left;
  width: 100%;
}
.cw_se_template_settings .cw_field {
  margin: 0 0 10px;
}
.cw_se_template_settings .p:last-child {
  margin: 0;
}
.cw_se_template_settings input.cw_inline {
  width: 100%;
}
.cw_se_template_settings .cw_separator {
  margin-top: -1px;
}
.cw_se_preview {
  float: left;
  width: 100px;
  height: 100px;
}
.cw_se_sqs {
  float: left;
  /*width:200px;*/
  zoom: 1;
  width: 100% !important;
}
.cw_se_sqs li {
  float: left;
  width: 100%;
  list-style: none;
  padding: 10px;
  text-indent: 5px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}
.cw_se_sqs a {
  text-decoration: none;
  padding: 2px 0;
}
#cw_select_sm_template_step1 .cw_tip_image {
  width: 100%;
  height: 200px;
}
.cw_select_service_template .cw_se_step1 .cw_separator {
  height: 490px;
}
.cw_select_service_template .cw_se_step1 .cw_excerpt {
  margin-bottom: 0;
}
.cw_avatar_sm_template,
.cw_avatar_se_template,
.cw_avatar_sq_template {
  margin: 5px;
}
.cw_card .cw_avatar.cw_avatar_sm_template .glyphicons,
.cw_card .cw_avatar.cw_avatar_se_template .glyphicons,
.cw_card .cw_avatar.cw_avatar_sq_template .glyphicons {
  width: auto;
  height: auto;
}
#cw_se_template_element_name_section .cw_card {
  border: none !important;
}
/* Service Qualifier Templates */
.create_sq_template .k-multiselect.k-widget {
  max-height: 60px;
}
#service_modal {
  z-index: 10010;
}
#service_modal {
  float: left;
  width: 100%;
  overflow: visible !important;
  background: none;
  padding-bottom: 56px;
}
/* Service Element - Contextual Menu  */
.cw_se_menu {
  float: left;
  position: absolute;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}
.cw_se_menu .cw_item {
  float: left;
  width: 34px;
  height: 34px;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #fff;
  background-color: #f9f9f9;
  list-style: none;
  text-align: center;
  border-radius: 0;
  cursor: pointer !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cw_se_menu .glyphicons {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  color: #555;
}
.cw_se_menu .cw_item:hover {
  background-color: #cfe6f5;
}
.cw_se_menu .cw_item:hover .glyphicons {
  color: #333;
}
.cw_se_menu .cw_item:first-child {
  border-radius: 3px 0 0 3px;
}
.cw_se_menu .cw_item:last-child {
  border-right: none;
  border-radius: 0 3px 3px 0;
}
/* Service Elements Styles */
.node {
  z-index: 201;
  width: 48px;
  height: 47px;
  margin: 20px;
  border-width: 1px;
  border-style: solid;
  background: #fff;
  border-radius: 4px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center !important;
}
.node.round {
  border-width: 1px !important;
  border-radius: 5000px;
}
.node.yellow {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff200), to(#d8c800));
  background-image: -webkit-linear-gradient(top, #fff200, #d8c800);
  background-image: -moz-linear-gradient(top, #fff200, #d8c800);
  border-color: #ccbe00;
  border-bottom-color: #eedc00 !important;
  background-color: #fff685;
  box-shadow: 0 3px 0 #c1b400, 0 3px 3px rgba(0, 0, 0, 0.15), 1px 1px 0 rgba(255, 255, 255, 0.5) inset;
}
.node.yellow:hover {
  border-color: #f80;
  border-bottom-color: transparent;
  background: #ebe37a;
  background-image: none;
}
.node.yellow.round {
  border-bottom-color: #c1b400 !important;
}
.node.green {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#b2d235), to(#73a533));
  background-image: -webkit-linear-gradient(top, #b2d235, #73a533);
  background-image: -moz-linear-gradient(top, #b2d235, #73a533);
  border-color: #73a533;
  border-bottom-color: #9aca3c !important;
  background-color: #7cc623;
  box-shadow: 0 3px 0 #598527, 0 3px 3px rgba(0, 0, 0, 0.15), 0 -2px 5px rgba(255, 255, 255, 0.35) inset, 1px 1px 0 rgba(255, 255, 255, 0.5) inset;
}
.node.green:hover {
  border-color: #66952e;
  border-bottom-color: transparent;
  background: #90ba34;
  background-image: none;
}
.node.green.round {
  border-bottom-color: #66952e !important;
}
.node.blue {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#00bdf2), to(#008bbf));
  background-image: -webkit-linear-gradient(top, #00bdf2, #008bbf);
  background-image: -moz-linear-gradient(top, #00bdf2, #008bbf);
  border-color: #008bbf;
  border-bottom-color: #20c4f4 !important;
  background-color: #00bdf2;
  box-shadow: 0 3px 0 #0076a3, 0 3px 3px rgba(0, 0, 0, 0.15), 1px 1px 0 rgba(255, 255, 255, 0.5) inset;
}
.node.blue:hover {
  border-color: #0076a3;
  border-bottom-color: transparent;
  background: #00a2d6;
  background-image: none;
}
.node.blue.round {
  border-bottom-color: #008bbf !important;
}
.node.white {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f3f3f3), to(#ccc));
  background-image: -webkit-linear-gradient(top, #f3f3f3, #ccc);
  background-image: -moz-linear-gradient(top, #f3f3f3, #ccc);
  border-color: #aaa;
  background-color: #ddd;
  box-shadow: 0 3px 0 #888, 0 3px 3px rgba(0, 0, 0, 0.15), 1px 1px 0 rgba(255, 255, 255, 0.5) inset;
}
.node.white:hover {
  border-color: #aaa;
  border-bottom-color: transparent;
  background: #d5d5d5;
  background-image: none;
}
.node.white.round {
  border-bottom-color: #bbb !important;
}
.node.red {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ff4726), to(#d93b20));
  background-image: -webkit-linear-gradient(top, #ff4726, #d93b20);
  background-image: -moz-linear-gradient(top, #ff4726, #d93b20);
  border-color: #c03620;
  border-bottom-color: #ff4726 !important;
  background-color: #d93b20;
  box-shadow: 0 3px 0 #b7331e, 0 3px 3px rgba(0, 0, 0, 0.15), 1px 1px 0 rgba(255, 255, 255, 0.5) inset;
}
.node.red:hover {
  border-color: #c74a1b;
  border-bottom-color: transparent;
  background: #e76c28;
  background-image: none;
}
.node.red.round {
  border-bottom-color: #c03620 !important;
}
.node.orange {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f1ae31), to(#f4c161));
  background-image: -webkit-linear-gradient(top, #f1ae31, #f4c161);
  background-image: -moz-linear-gradient(top, #f1ae31, #f4c161);
  border-color: #e0970f;
  border-bottom-color: #e0970f !important;
  background-color: #f1ae31;
  box-shadow: 0 1px 0 #f80, 0 2px 0 #f39200, 0 3px 0 #f80, 0 3px 3px rgba(0, 0, 0, 0.15), 1px 1px 0 rgba(255, 255, 255, 0.5) inset;
}
.node.orange.orange:hover {
  border-color: #e0970f;
  border-bottom-color: transparent;
  background: #f1ae31;
  background-image: none;
}
.node.highlight {
  border: 2px solid #f90 !important;
}
.node.cw_highlight_node {
  box-shadow: 0 0 0 6px #fff, 0 0 0 8px rgba(0, 0, 0, 0.5);
}
.node .glyphicons {
  font-size: 24px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  margin-top: 13px;
}
.node .material-icons {
  font-size: 30px;
  line-height: 50px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.port {
  width: 4px !important;
  height: 4px !important;
  border: 1px solid #888 !important;
  background: #ffffff !important;
  border-radius: 5px;
}
/* Designer Area */
.cw_designer_actions {
  float: right;
}
#canvas_editor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
}
div.textnode {
  margin: 0;
  padding: 0;
  border-color: #d8d8d8 !important;
}
.cw_sm_preview {
  width: 32px;
  height: 32px;
  padding: 0 !important;
}
.cw_sm_preview .glyphicons {
  height: 32px;
  line-height: 32px;
}
.textnode textarea {
  margin: 0;
  border: 0;
  background: #eee;
}
.textnode .caption_text {
  top: 0 !important;
}
/* Service Model Link */
.cw_sm_link_dialog {
  display: none !important;
  position: absolute;
  top: 5px;
  right: 20px;
  width: 280px;
  padding: 9px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.cw_sm_link_dialog.is_open {
  display: block !important;
  z-index: 10000;
}
.cw_sm_link_dialog .cw_field {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.cw_sm_link_dialog .k-dropdown {
  width: 260px;
  text-align: left;
}
.cw_sm_link_dialog .k-button {
  float: right;
}
.node.smlink {
  padding: 4px 12px;
  font-weight: 700;
  font-size: 12px;
  color: #555;
  text-shadow: 0 -1px 0 #fff;
  cursor: pointer !important;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.4) inset, 0 1px 0 rgba(255, 255, 255, 0.4) inset, 0 -1px 0 rgba(255, 255, 255, 0.2) inset !important;
}
.node.smlink div:first-child {
  background: url("~styles/shared/images/app_theme//flowchart.png") no-repeat center center;
}
.node.smlink .caption {
  width: 100%;
  overflow: hidden;
  margin-left: -50%;
  margin-right: -50%;
}
.caption {
  position: absolute;
  z-index: 201;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 120px;
  max-height: 32px;
  margin-bottom: -20px;
  margin-left: -60px;
  font-size: 11px;
  color: #333 !important;
  line-height: 1.3;
  background-color: #fff;
  border-radius: 3px;
  white-space: normal;
  word-break: break-all;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer !important;
}
.caption .toppos {
  top: -4px !important;
  margin-left: -4px !important;
}
.caption input {
  padding: 0;
  font-size: 11px !important;
}
.node.sm_link_broken {
  background-image: -moz-linear-gradient(center top, #B2D235, #FF0000);
}
/* CW_Panel */
#left_panel {
  text-align: left;
  background: #2e3740;
  z-index: 10001;
}
#left_panel p {
  padding-top: 10px;
  color: #fff !important;
}
.cw_panel {
  float: left;
  width: 100%;
}
.cw_panel em {
  display: none !important;
}
.cw_panel .k-upload-button {
  border-color: #3b3e42;
}
.cw_panel .k-dropzone {
  margin: 5px 0;
  padding: 0;
}
.cw_panel .k-widget {
  text-shadow: 0 1px 0 #fff;
  box-shadow: none !important;
}
.cw_panel .k-widget > span {
  border-color: #3b3e42 !important;
}
.cw_panel input[type="text"]:disabled {
  border-color: #3b3e42 !important;
  background: #A4A9AE;
  color: #fff;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) !important;
}
.cw_panel input[type="text"],
.cw_panel .k-widget {
  width: 100%;
  border-color: #3b3e42;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
}
.cw_panel input[type="text"]:focus {
  border-color: #3b3e42 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.cw_panel input[type="radio"] {
  margin-top: 5px;
}
.cw_panel label {
  float: left;
  width: 100%;
}
.cw_panel .cw_panel_item {
  float: left;
  width: 100%;
  padding: 0 10px 10px;
  color: #fff;
  list-style: none;
  text-shadow: 0 1px 0 #3b3e42;
}
.cw_panel .cw_panel_item.cw_panel_head {
  float: left;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  font-weight: 700;
  line-height: 34px;
  color: #fff;
  text-shadow: 0 1px 0 #3B3E42;
  border-radius: 3px 0 0 0;
  margin-bottom: 5px;
}
.cw_panel .cw_panel_item.cw_panel_head.cw_sq_list {
  height: 30px;
  line-height: 28px;
}
#prop_icon_preview {
  float: right;
  position: relative;
  padding: 4px 10px;
  margin: -10px 0 0 10px;
  border: 1px solid #888;
  vertical-align: bottom;
  border-radius: 4px;
  background: #333;
}
#prop_icon_preview .glyphicons {
  font: 18px/1.5 'Glyphicons Regular';
}
#prop_icon_preview .close_minibtn {
  position: absolute;
  top: -5px;
  right: -5px;
  left: auto;
}
#sm_prop_bgImage,
#sm_prop_save_as_template {
  width: 100%;
  margin: 5px 0;
}
#prop_icon_preview {
  margin-top: 0;
}
.prop_input {
  float: right;
  width: 100px;
  height: 18px;
  margin: 4px 0;
  padding: 3px 5px;
  font-size: 11px;
}
#sq_templates_popup {
  position: relative;
  bottom: 0;
  left: 0;
}
.cw_panel .cw_rule_input {
  width: 155px;
}
#prop_success-list li,
#prop_failure-list li,
#prop_failure-list .k-item.k-state-selected.k-state-focused,
#prop_success-list .k-item.k-state-selected.k-state-focused {
  padding: 1px 0;
}
#prop_success-list li:hover,
#prop_failure-list li:hover,
#prop_failure-list .k-item.k-state-selected.k-state-focused,
#prop_success-list .k-item.k-state-selected.k-state-focused {
  border: none !important;
}
/* Model Preview */
.cw_model_preview {
  z-index: 99 !important;
}
/* Colored Dropdowns */
.se_yellow {
  background: #fff685 !important;
}
.se_green {
  background: #7cc623 !important;
}
.se_blue {
  background: #00bdf2 !important;
}
.se_white {
  background: #ddd !important;
}
.se_red {
  background: #d93b20 !important;
}
.se_orange {
  background: #ffc53f !important;
}
.seh_yellow {
  background-color: #fff685;
}
.seh_green {
  background-color: #7cc623 !important;
}
.seh_blue {
  background-color: #00bdf2 !important;
}
.seh_white {
  background-color: #ddd !important;
}
.seh_red {
  background-color: #d93b20 !important;
  color: #fff !important;
  text-shadow: 0 1px 0 #590000 !important;
}
.seh_orange {
  background-color: #ffc53f !important;
}
.se_yellow,
.se_green,
.se_blue,
.se_white,
.se_red,
.se_orange {
  float: left;
  width: 100%;
  display: block;
  padding: 0;
  color: #fff;
  text-shadow: 0 1px 6px #222;
  border-radius: 3px;
}
.k-item.se_yellow,
.k-item.se_green,
.k-item.se_blue,
.k-item.se_white,
.k-item.se_red,
.k-item.se_orange {
  color: #333;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
}
.se_yellow > .k-dropdown-wrap,
.se_green > .k-dropdown-wrap,
.se_blue > .k-dropdown-wrap,
.se_white > .k-dropdown-wrap,
.se_red > .k-dropdown-wrap,
.se_orange > .k-dropdown-wrap {
  border-color: transparent !important;
  background: transparent url("~styles/shared/images/app_theme//button_overlay.png") repeat-x left -7px !important;
  color: #333;
}
#cw_se_sq_list {
  float: left;
  width: 100%;
  margin: 10px 0 0;
}
#cw_se_sq_list li {
  float: left;
  position: relative;
  width: 100%;
  padding: 5px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer !important;
}
#cw_se_sq_list li:first-child {
  border-top: none;
}
#cw_se_sq_list li:last-child {
  border-bottom: none;
}
#cw_se_sq_list li .k-button .glyphicons {
  margin: 0;
}
#cw_se_sq_list li[data-shared=true] .cw_status_widget_color.glyphicons.share {
  color: #fff;
}
#cw_se_sq_list .glyphicons {
  float: right;
  /*margin:0 0 0 5px;*/
  padding: 0;
  text-shadow: none;
}
#cw_se_sq_list .ok-sign {
  color: #7cc623;
  text-shadow: 0 1px 0 #619b1b;
}
#cw_se_sq_list .remove-sign {
  color: #e14b32;
  text-shadow: 0 1px 0 #c3341d;
}
#cw_se_sq_list .se_sq_name {
  float: left;
  width: 60%;
  display: block;
  color: #fff;
  text-indent: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  line-height: 20px;
}
#cw_se_sq_list li span.right .cw_actions_wrapper .cw_actions_menu {
  left: 28px!important;
  top: -200px !important;
}
.cw_designer_module .window_area.designer_area {
  overflow: initial;
}
#sm_bg_preview {
  position: relative;
  float: left;
  clear: left;
  display: block;
}
#sm_bg_preview .remove_bg_preview {
  position: absolute;
  top: -6px;
  right: -6px;
  left: auto;
}
#sm_bg_preview .sm_bg_preview {
  max-width: 198px;
  border: 1px solid #fff;
}
/* Authentication */
#auth_list-list li {
  float: left;
  clear: left;
  width: 100%;
}
#cw_acc_auth {
  border: none;
}
.close_minibtn,
.remove_bg_preview {
  position: relative;
  top: -6px;
  left: 212px;
  z-index: 2;
  width: 16px;
  height: 16px;
  background: url("~styles/shared/images/app_theme//icons_sprite_2.png") -100px -120px;
  cursor: pointer !important;
  border-radius: 8px;
  box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.05);
}
.cw_sq_form.clearfix {
  padding: 10px;
  background-color: #d5d5d5 !important;
}
.cw_sq_component {
  float: left;
  clear: left;
  font-weight: 700;
  text-shadow: 0 1px 0 #ddd;
}
/* CW_Rules */
.cw_rules {
  padding: 15px;
  background: #eee;
}
.cw_rules ul {
  list-style: none;
}
.cw_rules .cw_rules_components_head {
  padding: 0;
  color: #444;
  text-shadow: none;
}
.cw_rules .cw_rules_components {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.cw_rules .cw_rules_components .cw_rules_component {
  float: left;
  width: auto;
  padding: 2px 5px 2px 0;
}
.cw_rules .editor-actions {
  text-align: right;
}
.cw_rules .editor-actions * {
  margin-left: 10px;
}
.cw_rule {
  float: left;
  width: 100%;
  min-height: 100px;
  margin: 10px 0;
  padding: 5px;
  border: 1px solid #d8d8d8;
  background: #fff;
  border-radius: 3px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2) inset;
}
.cw_rule li {
  float: left;
  height: 33px;
  margin-top: 5px;
  margin-right: 8px;
  padding: 2px 5px 5px;
  border: 1px solid #d8d8d8;
  background-color: #eee;
  list-style: none;
  cursor: move;
  border-radius: 3px;
}
.cw_rule div {
  height: 25px;
  line-height: 25px;
}
.cw_rule .selected {
  border: 1px solid #257cba;
}
.cw_rule_title {
  font-weight: 400;
  font-size: 13px;
}
#op_placeholder {
  border: 1px dashed #d8d8d8;
}
#cw_rule_editor {
  width: 100%;
  height: 100px;
}
#cw_rule_editor .serviceQualifier {
  font-weight: 700;
}
#cw_rule_input {
  float: left !important;
  width: 85%;
  color: #fff !important;
}
#cw_rule_edit,
#cw_rule_view {
  float: right !important;
  padding: 2px 5px;
  margin-top: 6px;
}
#op_hint {
  float: left;
  position: relative;
  width: 0;
  margin: 8px 6px 0 0;
  border: 1px solid #5cb4e5;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px #5cb4e5;
}
.cw_rule_sq_text {
  font-weight: 400;
}
.cw_rule_se_text {
  font-weight: 400;
}
.cw_rule_operator {
  font-weight: 700;
  font-size: 16px;
  color: #00bdf2;
}
.cw_rule_paranthesis {
  font-weight: 700;
  font-size: 16px;
  color: #00bdf2;
}
.cw_ruleset_text {
  padding: 10px;
}
/* SQ Save as Template */
.cw_sq_details .k-widget.k-window {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid #ccc;
}
.cw_sq_details .k-window-title {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_sq_details p {
  margin: 0 0 10px;
}
.cw_sq_preview_content {
  position: absolute;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 50px;
  left: 0;
  overflow-y: auto;
  padding: 10px 0 0 0;
}
.cw_sq_preview_content .cw_field {
  margin: 0 0 8px;
}
.cw_sq_preview_content.full {
  bottom: 0;
}
.cw_sq_preview_content label {
  width: 120px;
  margin-right: -120px;
  text-align: right;
}
.cw_sq_preview_content .cw_sq_component {
  float: left;
  width: 100%;
  position: absolute;
  padding-left: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  clear: none;
  text-shadow: none;
}
.cw_sq_private_form {
  float: left;
  width: 100%;
  padding-bottom: 56px;
}
.cw_sq_private_form .cw_separator {
  margin-bottom: 10px;
}
.cw_sq_category_form .k-button {
  margin: 10px 0 0 5px;
}
#cw_sq_template_category_tree {
  height: 200px;
}
/*WIP - toolbar styles*/
div.k-popup.k-group.k-reset {
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}
div.k-popup.k-group.k-reset a {
  margin: 0;
  padding: 0;
  border-radius: 0;
}
div.k-popup.k-group.k-reset a span:hover {
  background-color: #cfe6f5;
}
div.k-popup.k-group.k-reset a span.cw_item:first-child {
  /* border-radius: 3px 0 0 3px; */
}
div.k-popup.k-group.k-reset a span.cw_item {
  float: left;
  width: 34px;
  height: 34px;
  border-left: 1px solid #fff;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 0;
  cursor: pointer !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.k-popup.k-group.k-reset a span.cw_item span.glyphicons {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  color: #555555;
}
/*Shared qualifiers*/
#cw_shared_qualifiers_list.form {
  padding: 0;
  border: none;
}
#cw_se_sq_list li ul.cw_actions_menu {
  border-left: 1px solid #aaa;
}
#cw_se_sq_list li ul.cw_actions_menu li.cw_item {
  padding: 5px;
  background: #2e3740;
  color: #fff;
}
#cw_se_sq_list li ul.cw_actions_menu li.cw_item:hover {
  background: #434f5c;
}
#cw_se_sq_list li ul.cw_actions_menu li.cw_item .glyphicons {
  margin: 6px 5px 0 0;
  float: left;
}
/* Service Qualifiers Wizard */
#sq_template_tree {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  border: 1px solid #eee;
  border-radius: 3px;
}
.cw_panelbar {
  position: absolute;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-left: 1px solid #e5e5e5;
  background-color: #fafafa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.cw_panelbar .cw_item {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  overflow-y: auto;
}
.cw_panelbar .cw_item.minimized {
  overflow: hidden;
}
.cw_panelbar .cw_title {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #fafafa;
  font-size: 13px;
  font-weight: 700;
  text-indent: 15px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #fff;
}
.cw_panelbar .glyphicons,
.cw_panelbar .glyphicons:hover {
  color: #54595E;
}
.cw_panelbar .k-button-icon {
  margin: 5px 5px 0 0;
  text-indent: 0;
}
.cw_panelbar .cw_content {
  float: left;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.cw_panelbar .minimized .cw_content {
  display: none !important;
}
.cw_panelbar .minimized .cw_title {
  color: #888;
}
.cw_panelbar .cw_sqwiz_nav {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 48px;
  padding: 10px 10px 0 5px;
}
.cw_panelbar .cw_sqwiz_nav .k-button {
  margin: 0 0 0 10px;
}
.cw_panelbar .k-slider {
  float: left;
  width: 210px;
}
.cw_qualifiers_list {
  top: 0;
  bottom: 50px;
}
.cw_qualifiers_list.minimized {
  bottom: auto;
}
.cw_qualifiers_settings {
  bottom: 48px;
  top: 0px;
}
.cw_qualifiers_settings.minimized {
  top: auto;
}
.cw_qualifiers_settings #block1 .cw_inline_full,
#sq_interval_line {
  margin-top: 7px;
}
.cw_qualifiers_settings .cw_field,
.cw_qualifiers_settings .cw_field_with_btn {
  margin-bottom: 7px;
}
.cw_qualifiers_settings .cw_sq_asset_new.is_disabled {
  opacity: 0.5;
}
/* SQ Tips */
.cw_tips {
  background-color: #cfe6f5;
}
.cw_tips_nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  background-color: #343F49;
  color: #fff;
}
.cw_tips_nav .cw_main_title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.cw_tips_nav .item {
  display: block;
}
.cw_tips_nav .link {
  padding: 5px 10px;
  text-decoration: none;
}
.cw_tips_details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 200px;
  right: 0;
  padding: 20px;
}
.cw_tips_details .cw_section {
  position: relative;
  margin: 0 0 15px;
  height: auto;
}
.cw_tips_details .cw_section_content {
  padding: 20px;
}
/* Test Configuration */
.cw_configuration_status {
  z-index: 5;
  position: absolute;
  right: 80px;
  bottom: 50px;
  left: 0;
  top: 5px;
  text-align: center;
}
.cw_configuration_status .cw_symbol {
  width: 26px;
  height: 26px;
  line-height: 26px;
  margin-top: 8px;
  font-size: 16px;
  color: #fff;
  border-radius: 30px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3);
}
.cw_configuration_status .cw_symbol:hover {
  color: #fff;
}
.cw_configuration_status .cw_symbol.is-positive {
  background-color: #7cc623;
}
.cw_configuration_status .cw_symbol.is-negative {
  background-color: #e14b32;
}
.cw_configuration_status .cw_value {
  padding: 10px;
  font-weight: 600;
  text-align: center;
}
.cw_configuration_error_content {
  padding: 13px;
}
.cw_configuration_error_content ul {
  margin-left: 9px;
}
.cw_configuration_error_content ul li {
  padding-top: 2px;
}
.cw_error_status {
  float: right;
}
.cw_error_status.ok {
  color: #7cc623;
}
.cw_error_status.remove-circle {
  color: #e14b32;
}
#configuration_error_list {
  /*height:32px;*/
}
#prev_step {
  margin-left: 7px;
}
.k-link .glyphicons.question-sign {
  color: #54595E;
}
.cw_sq_asset_add_multiple {
  float: left;
  margin-top: 10px;
  color: #428bca;
}
.cw_sq_asset_plus.is_disabled,
.cw_sq_asset_add_multiple.is_disabled {
  opacity: 0.4;
}
.warning_switch_link {
  margin-top: 10px;
  color: #428bca;
}
.ruleset-textbox {
  padding-left: 10px;
  padding-right: 5px;
}
.w_sq_actions {
  position: absolute;
  display: none;
  background: white;
  width: 220px;
  z-index: 110000;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: left;
}
.w_sq_actions li {
  line-height: 30px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.w_sq_actions li .glyphicons {
  line-height: inherit;
  margin: 0 10px;
  font-size: 1.5em;
}
.w_sq_actions li:last-child {
  border-bottom: none;
}
.w_sq_actions li:hover {
  background: #fbfbfb;
}
#widget_wrapper [data-type="statusLog"] {
  overflow-y: hidden;
}
.qualifiers_title_container {
  margin: 3px 0 3px 0;
}
/* Service Model View */
.cw_viewer_module .k-overlay {
  z-index: -1;
}
.cw_viewer_module .k-window-title.ellipsis {
  width: 300px;
  text-indent: 0;
}
.cw_viewer_module .port {
  display: none !important;
}
.cw_viewer_module .k-grid {
  border: none;
}
.cw_viewer_module #screen_options,
.cw_viewer_module #cw_revert {
  margin-top: 3px;
}
.cw_viewer_module .cw_section_titlebar .cw_mask {
  background-color: rgba(251, 251, 251, 0.65);
  left: auto;
  width: 525px;
}
.cw_viewer_sq_details .k-window {
  z-index: 9999 !important;
}
.cw_viewer_sq_details .k-window.k-state-focused {
  z-index: 10000 !important;
}
.cw_viewer_sq_details .k-window-content {
  width: 100%;
}
.readonly {
  position: absolute;
  top: 28px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  overflow: hidden;
  opacity: 0;
  background-color: #fff;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
}
.caption_text {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  top: 5px !important;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 11px !important;
  background-color: #fff;
  text-align: left;
  word-break: break-all;
}
.connection_label {
  font-size: 11px !important;
  background-color: #fff;
  color: #54595E !important;
}
.cw_modal_viewer {
  position: absolute;
  float: left;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.cw_modal_viewer .window_area {
  top: 0;
}
.cw_modal_viewer #canvas_editor {
  top: 65px;
}
#cw_smp_service_qualifiers {
  top: 0;
  height: 300px;
}
#cw_smp_service_qualifiers .cw_section_content {
  padding: 0 10px 10px;
}
#cw_smp_service_qualifiers .glyphicons {
  padding: 0;
  margin: 0 10px 0 0;
  vertical-align: top;
  font-size: 18px;
}
#cw_smp_metrics {
  top: 0;
  bottom: 40px;
}
.smp_sq_list .ok-sign {
  color: #7cc623;
}
.smp_sq_list .remove-sign {
  color: #e14b32;
}
.smp_sq_list .cw_item {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  cursor: pointer !important;
  list-style: none;
  padding: 5px 55px 10px 5px;
  border-top: 1px solid #d8d8d8;
  height: auto;
}
.smp_sq_list .cw_item:first-child {
  border-top: 3px solid black;
}
.smp_sq_list .wrench {
  color: #fff !important;
  margin: 1px;
}
.smp_sq_name {
  float: left;
  text-indent: 6px;
  max-width: 70%;
  min-width: 100px;
}
.smp_sq_container {
  position: absolute;
  right: 10px;
  top: 7px;
}
.smp_sq_container .glyphicons {
  margin-right: 8px;
  font-size: 14px;
}
.cw_smp_se_container .glyphicons {
  font-size: 14px;
}
.smp_sq_container .glyphicons:first-child {
  margin-right: 0;
}
.cw_current_qualifier {
  float: left;
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
}
.cw_smp_se_item {
  float: left;
  width: 100%;
  padding: 5px;
  list-style: none;
}
.cw_smp_se_item .smp_sq_state {
  float: left;
  margin-right: 10px;
}
.cw_smp_se_list .cw_smp_se_container {
  float: left;
  width: 100%;
  padding: 5px 10px 5px 5px;
}
.cw_smp_se_list .cw_smp_se_container .cw_status_widget_color {
  margin-right: 10px;
}
.cw_smp_node_color,
.cw_smp_se_name {
  float: left;
}
.cw_smp_se_list #smp_sq_list {
  clear: left;
  float: left;
  width: 100%;
}
.cw_smp_se_container #cw_service_ruleset_description {
  padding: 0 8px 0 8px;
  background-color: #cfe6f5;
  margin-left: 15px;
  border-radius: 3px;
}
.cw_smp_node_icon {
  margin-right: 10px;
}
.cw_viewer_panelbar {
  position: absolute;
  z-index: 500;
  top: 75px;
  right: 25px;
  width: 380px;
  border-color: #ddd !important;
  border-radius: 3px;
}
.cw_status_log {
  position: absolute;
  left: 10px;
  bottom: 20px;
  right: 20px;
  max-height: 240px;
  z-index: 500;
}
.cw_status_log .k-grid-content {
  max-height: 173px;
}
.k-icon.k-i-toggle {
  background-position: -32px -80px;
}
.period_multi_toggle {
  float: right;
  margin: 0 10px;
}
.cw_element_header_item {
  margin-left: 9px;
}
.cw_element_header_item.material-icons {
  font-size: 16px;
}
.cw_window_tab {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
}
.cw_window_tab .k-content {
  position: absolute;
  top: 32px;
  bottom: 0;
  padding: 0;
}
.cw_titlebar_nav {
  padding: 4px 10px 5px;
  border: 1px solid transparent;
  cursor: pointer !important;
}
.cw_titlebar_nav.is_selected {
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-bottom: none;
}
.cw_agent_down_icon {
  position: absolute;
  margin: -1px 0 0 130px !important;
}
.cw_agent_down_name {
  margin-left: 25px;
}
.cw_custom_time_selector {
  position: absolute;
  top: 75px;
  right: 0;
  z-index: 100;
  width: 340px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
}
.cw_custom_time_selector .k-button {
  margin-top: 10px;
}
.cw_time_input_container {
  display: inline-block;
  width: 50%;
  padding: 3px;
}
.cw_time_input {
  width: 100%;
  height: 23px;
}
.qualifier_description_tooltip {
  position: absolute;
  top: 7px;
}
.cw_viewer_logs_grid_menu {
  position: relative;
  margin-top: -34px;
}
.cw_viewer_logs_grid_title {
  margin-left: 50px;
}
/* Administration - Summary */
/* Cards View */
.cw_view {
  float: left;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 15px;
  border: none !important;
  background: transparent;
  box-shadow: none;
}
.cw_view li {
  float: left;
  width: 50%;
  padding-right: 5px;
  list-style: none;
  text-align: left;
  box-sizing: border-box;
}
.cw_view li.w100 {
  padding: 0 !important;
}
.cw_view li.w100 .cw_card_avatar {
  width: 12%;
  margin: 0;
  padding: 0 !important;
}
.cw_view li.w100 h2 {
  margin: 0;
  padding: 5px 0 0;
}
.cw_view li:nth-child(4n) {
  padding: 0;
}
#modal .cw_card {
  box-shadow: none;
  border: 1px solid #d8d8d8;
  margin-bottom: 16px;
}
.cw_card {
  float: left;
  position: relative;
  width: 100%;
  height: 110px;
  background-color: #fff;
  text-align: left;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
}
.cw_card:hover {
  cursor: pointer !important;
}
.cw_card .glyphicons {
  float: right;
}
.cw_card .cw_media_library {
  position: absolute;
  left: 5px;
  bottom: 5px;
  right: 5px;
  margin: 0;
}
.cw_card h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.2;
  text-shadow: 0 1px 0 #fff;
}
.cw_card .cw_avatar {
  text-align: center !important;
}
.cw_card .cw_avatar .glyphicons {
  font-size: 48px;
}
.cw_card .cw_avatar .material-icons {
  font-size: 58px;
  margin-top: 8px;
}
.cw_card img {
  max-width: 54px;
  max-height: 54px;
  margin: 5px auto;
}
.cw_card .status_icon {
  margin: 0;
  width: 100%;
  text-align: center !important;
}
.cw_card .cw_label {
  float: none;
}
.cw_card .image_preview_text {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding-bottom: 5px;
}
.cw_card_content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 97px;
  overflow: hidden;
  padding: 15px;
}
.cw_card_content p {
  float: left;
  width: 100%;
  padding: 6px 0 0;
  text-shadow: 0 1px 0 #fff;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_card_sla_value {
  font-size: 13px;
  font-weight: 700;
}
.cw_card_avatar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100px;
  padding: 15px;
  border-right: 1px solid #d8d8d8;
  border-radius: 3px 0 0 3px;
}
.cw_card_bottom {
  position: absolute;
  float: left;
  width: 100%;
  bottom: 0;
  height: 34px;
  line-height: 34px;
  margin-left: -15px;
  border-top: 1px solid #d8d8d8;
  background-color: #f5f5f5;
  border-radius: 0 0 3px 3px;
}
.cw_card_bottom > div {
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #fff;
  text-indent: 10px;
  text-shadow: 0 1px 0 #fff;
}
.cw_card_bottom > div:last-child {
  border-right: none;
}
.cw_sla_status_icon {
  text-align: center !important;
  text-indent: 0 !important;
  margin-top: 5px;
}
.cw_sla_status_icon .glyphicons {
  font-size: 24px;
}
.cw_card_options {
  float: right !important;
  position: relative;
  border: 1px solid #d8d8d8;
  border-radius: 0 4px;
  margin: -1px -1px 0 0;
}
.cw_card_options .glyphicons {
  padding: 4px 5px;
  font-size: 14px;
}
.cw_card .cw_card_options {
  background-color: #fff;
}
.cw_close {
  float: right;
  top: -7px;
  right: -7px;
  width: 18px;
  height: 18px;
  background: url("administration/~styles/shared/images/app_theme//cw_close.png") no-repeat center center;
  border-radius: 10px;
  position: absolute;
}
.cw_card_large {
  position: absolute !important;
  z-index: 4;
  width: 400px;
  overflow: visible !important;
}
.cw_card_large .cw_card {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #d8d8d8;
  background: #fff url("administration/~styles/shared/images/app_theme//cw_card_body.png") bottom left repeat-x;
  text-align: left;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}
.cw_card_large .cw_card:hover {
  cursor: pointer !important;
}
.cw_large_details {
  float: left;
  height: 110px;
  overflow: auto;
}
.cw_card_double {
  height: 140px;
}
.cw_card_double .cw_card_content {
  left: 127px;
}
.cw_card_double .cw_card_avatar {
  width: 96px;
}
.card_breached span {
  font-weight: 700;
  color: #d93b20;
}
.card_compliance span {
  font-weight: 700;
  color: #7cc623;
}
.cw_card_double .half {
  margin-top: 10px;
}
.cw_card_user {
  float: left;
  width: 100%;
}
.cw_card_user .cw_card_avatar,
#modal .cw_card_avatar {
  padding: 12px 18px;
}
.cw_card_user .cw_card_content,
#modal .cw_card_content {
  left: 109px;
}
.upload_avatar {
  float: left;
  width: 39px;
  height: 39px;
  margin: 10px;
  padding: 4px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 0 #fff;
}
div#cw_account_card_avatar.cw_account_preview_image {
  width: 100%;
}
div#cw_account_card_avatar.cw_account_preview_image .close_minibtn {
  display: none;
}
div#cw_account_card_avatar.cw_account_preview_image .cw_avatar {
  width: 90%;
  height: 75%;
}
div#cw_account_card_avatar.cw_account_preview_image img {
  max-width: 180px;
  max-height: 40px;
  width: 180px;
  height: 40px;
  padding: 10px;
  border: 1px solid #d8d8d8;
  object-fit: scale-down;
}
.create_account {
  float: left;
  width: 100%;
}
.create_account .form {
  padding-bottom: 0;
}
.create_account li {
  float: left;
  /*width:50%;*/
  list-style: none;
}
.create_account .close_minibtn {
  position: absolute;
  top: -8px;
  left: 55px;
}
.create_account .cw_field_with_btn .k-dropdown {
  width: 100%;
}
.create_account .k-dropdown {
  width: 190px;
}
.create_account #account_responsibleTeam {
  width: 330px;
  text-overflow: ellipsis;
  padding-right: 5px;
}
.create_account button#cw_team_cancel_btn,
.create_account button#cw_save_team_btn {
  width: auto;
}
.create_account .k-multiselect-wrap {
  min-height: 140px;
  max-height: 172px;
  overflow-y: auto;
  padding: 5px;
}
#cw_create_team_btn,
#cw_team_cancel_btn {
  cursor: pointer;
}
#account_modal .create_account .cw_card {
  margin-bottom: 15px;
}
/* Avatar */
.cw_avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 0 0 0 2px;
  box-shadow: 0 1px 0 #fff;
}
.cw_avatar.no_bg {
  background: #fff !important;
}
.cw_avatar.cw_avatar_service {
  width: 96px;
  height: 96px;
  padding: 0;
  overflow: hidden;
  text-align: center !important;
}
.cw_avatar.cw_avatar_service img {
  max-width: 96px;
  max-height: 96px;
}
.cw_avatar .glyphicons {
  width: 100%;
  height: 100%;
}
.cw_avatar .close_minibtn {
  position: absolute;
  top: -8px;
  left: 55px;
}
.cw_service_document .cw_avatar .glyphicons {
  width: 100%;
  height: auto !important;
}
/* User Categories */
.cw_entry {
  height: 190px;
  padding: 20px 20px 20px 430px;
}
.cw_entry .cw_fieldgroup {
  float: left;
  width: 400px;
  margin-left: -410px;
}
.cw_entry textarea {
  height: 52px;
}
.cw_entry textarea.cw_inline.is_expandable.expanded {
  width: 100%!important;
}
.cw_privileges_details {
  position: absolute;
  top: 170px;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid #d8d8d8 !important;
}
.cw_privileges_details .k-button {
  float: right;
  padding: 1px 8px;
}
.cw_privileges_details .cw_list.is_hidden {
  display: none;
}
.list_content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow-y: auto;
}
.list_content.list_content_full {
  bottom: 0;
}
.list_paging {
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  margin-top: -40px;
  padding: 8px;
  background-color: #eef3f5;
  text-shadow: 0 1px 0 #fff;
  border: none;
}
/* Create Asset */
.create_asset .close_minibtn {
  position: absolute;
  top: -8px;
  left: 56px;
}
/* Create/Edit Asset Groups */
.cw_assets_list {
  float: left;
  width: 100%;
}
/* Create User */
.create_user .close_minibtn {
  position: absolute;
  top: -8px;
  left: 56px;
}
.create_user .k-dropdown {
  width: 240px;
}
/* Create / Edit Account */
.cw_authkey {
  margin: 2px;
}
.pic_48 {
  width: 48px;
  height: 48px;
  background-color: #fff;
}
.side_panel {
  min-height: 400px !important;
}
/* Avatars for Cards / Forms */
.cw_avatar_account {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_account.png") no-repeat center center !important;
}
.cw_avatar_agent {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_agent.png") no-repeat center center !important;
}
.cw_avatar_asset {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_asset.png") no-repeat center center !important;
}
.cw_avatar_asset_group {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_asset_group.png") no-repeat center center !important;
}
.cw_avatar_auth {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_auth.png") no-repeat center center !important;
}
.cw_avatar_user {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_user.png") no-repeat center center !important;
}
.cw_avatar_team {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_team.png") no-repeat center center !important;
}
.cw_avatar_sla {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_sla.png") no-repeat center center !important;
}
.cw_avatar_service {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_service.png") no-repeat center center !important;
}
.cw_avatar_serviceboard {
  background: #fff url("~styles/shared/images/app_theme//cw_avatar_serviceboard.png") no-repeat center center !important;
}
.cw_avatar_se_template {
  background: #fff;
}
.cw_avatar_sq_template {
  background: #fff;
}
.cw_card_content .cw_card_description_truncated {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: unset !important;
}
#cw_uc_privileges_treelist tr[role=row]:hover td {
  background: transparent;
  background: #fff;
}
/* Cards previews for Forms */
#card_details {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
#card_description,
.card_description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
#upload_bg {
  padding: 20px;
}
/* Calendar */
.radio_buttons_container {
  display: flex;
}
.radio_buttons_container li {
  padding: 15px;
  font-size: 14px;
}
.cw_event_form_date_picker {
  position: relative;
  width: 140px;
  margin-left: 130px;
}
.cw_event_form_hour_picker {
  position: relative;
  width: 85px;
  margin-left: 90px;
}
.cw_event_form_time_toggle {
  position: absolute;
  display: inline-block;
  margin-left: 12px;
}
/* User Details */
.cw_user_summary .cw_card_content {
  left: 110px;
}
.cw_user_summary .actions {
  position: absolute;
  left: 0;
  right: 0;
}
.cw_user_summary.cw_section_expanded .cw_section_content {
  top: 0;
}
/* Disable User */
.cw_disable_user {
  margin-top: 5px;
}
.cw_user_panel {
  float: left;
  padding: 10px 0;
  /*width: 100%;*/
}
.cw_user_panel.cw_user_times {
  float: left;
  padding: 10px 20px;
  margin: 0 0.5%;
}
.cw_user_panel .cw_heading {
  font-size: 18px;
  font-weight: 400;
  padding: 10px 0 20px;
  color: #999;
}
/* User Scheduler */
.cw_scheduler {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}
.cw_scheduler .k-scheduler {
  border: none;
  box-shadow: none;
}
.cw_scheduler .k-scheduler-toolbar {
  display: none !important;
}
.cw_scheduler .cw_content_mask {
  opacity: 0.4;
}
.cw_scheduler .disabled_schedule {
  position: absolute;
  top: 20%;
  left: 44%;
  z-index: 100;
  color: #bcbcbc;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
}
.cw_event_start,
.cw_event_end {
  width: 112px;
}
.cw_allday_event {
  font-size: 20px;
  margin: 3px 20px 0 10px;
  cursor: pointer !important;
}
.cw_event_form label {
  width: 150px;
  margin-right: -150px;
}
.cw_event_form .cw_dropdown_container {
  padding-left: 160px;
}
.cw_event_form .cw_multi_toggle {
  margin: 0 10px;
}
.cw_event_form .cw_multi_toggle li {
  padding: 0 1em;
}
.cw_event_form .cw_multi_toggle li,
.cw_event_form .cw_multi_toggle .glyphicons {
  height: 28px;
  line-height: 28px;
}
.cw_severity_threshold_preview {
  float: left;
  margin: 2px 0 0 10px;
  padding: 2px 15px 2px 0;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 15px;
  font-weight: 700;
}
.cw_severity_threshold_preview .label {
  padding-left: 6px;
}
.cw_severity_threshold_preview.is_disabled {
  color: #aaa;
}
.cw_severity_threshold_preview.is_disabled .cw_status_widget_color {
  background: #dddddd;
}
/* Notification Policy */
.cw_policy_settings {
  height: 160px;
}
.cw_policy_filter {
  position: absolute;
  top: 161px;
  bottom: 0;
  left: 0;
  height: auto;
}
.cw_policy_filter .cw_section_content {
  position: absolute;
  top: 42px;
}
.cw_dropdown_container .cw_notification_penalty {
  width: 200px;
}
.is_disabled .cw_notification_overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.is_disabled .k-event-actions {
  display: none !important;
}
#my_profile {
  position: absolute;
  top: 37px;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
#my_profile .k-widget,
#cpanel .k-upload,
.k-dropzone,
#cpanel .k-dropdown {
  float: left;
}
#my_profile .cw_card_user {
  padding: 20px;
}
#profile_glyph_icons {
  position: relative;
}
#update_user {
  padding: 10px 20px;
  width: 100%;
}
#update_user span {
  padding: 0 15px 0 0;
}
#cpanel .upload_section {
  height: auto;
  line-height: auto;
  padding: 10px;
}
#cpanel .k-dropzone,
#cpanel .cw_media_library {
  margin: 0;
}
#language,
#cpanel .k-dropdown .k-input {
  text-align: left;
}
#cpanel .k-dropdown {
  width: 100%;
}
#cpanel,
.cw_card_user {
  max-width: 600px;
}
#cw_profile_image_preview .close_minibtn {
  left: 57px;
  position: absolute;
}
#cw_ruleset_search {
  position: relative;
  float: right;
  margin-right: 50px;
  height: 30px;
  width: 180px;
}
.cw_breaches {
  position: absolute;
  top: -1px;
  right: -1px;
  min-width: 20px;
  height: 20px;
  line-height: 16px;
  padding: 1px 3px;
  background-color: #e14b32;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 3px #000000;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.cw_breaches.is_idle {
  left: -1px;
  right: auto;
  background-color: #c4c4c4;
}
/* Designer/Viewer Breches */
.node .cw_breaches.is_idle {
  top: -10px;
  left: -10px;
}
.cw_bar .cw_service_indicator {
  position: absolute;
  right: 3px;
  top: 3px;
  opacity: 0.9;
}
.cw_bar span.cw_service_indicator {
  width: 16px;
  font-size: 16px;
  font-weight: 700;
}
.cw_bar.is_ok span.cw_service_indicator {
  color: #7cc623;
}
.cw_bar.is_major span.cw_service_indicator {
  color: #f1ae31;
}
.cw_bar.is_critical span.cw_service_indicator {
  color: #e14b32;
}
/* Service Level Agreement */
.form .cw_day_label {
  width: 100px;
  display: inline-block;
  padding: 3px;
  text-transform: capitalize;
}
.cw_sla_button {
  min-width: auto !important;
}
.sla_form_operating_time {
  float: inherit;
  width: 100%;
}
.cw_operating_time_area {
  float: left;
  width: 100%;
  overflow-y: auto;
  height: 312px;
}
.create_sla .k-widget {
  width: 100%;
}
.create_sla .form {
  min-height: 500px;
}
.create_sla div.cw_section_content {
  position: relative;
  top: 0;
}
.sla_area .glyphicons {
  position: absolute;
  right: 15px;
  top: 5px;
  padding: 0;
  cursor: default;
}
.sla_area .glyphicons {
  font-size: 50px;
}
/* Second Step */
.cw_sla_ot_list .k-timepicker {
  width: 70px;
}
.cw_sla_ot_list .cw_multi_toggle li {
  padding: 0.35em 0.5em;
}
/*SLA Widget*/
.cw_sla_details {
  right: 10%;
  bottom: 30px;
  left: 10%;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}
.cw_sla_details__time {
  display: flex;
  white-space: initial;
  width: 100%;
  justify-content: space-between;
}
.cw_sla_details .cw_progress_slice {
  background-color: #257cba;
  width: 0;
  border-radius: 20px;
}
.cw_period_tag {
  margin-right: auto;
}
.cw_operating_time_header {
  float: left;
  width: 100%;
  line-height: 1;
  padding: 4px 0;
  background: #eee;
  margin: 0 0 5px;
}
.cw_sla_percentage {
  position: absolute;
  width: 100%;
  margin: 0;
  top: 25%;
  font-size: 50px;
  text-align: center !important;
  text-shadow: 0 1px 0 #fff;
}
.cw_sla_percentage.pointer:hover {
  color: #257cba;
}
.sla_area .cw_compliance_goal {
  right: auto;
  left: 20px;
  top: 15px;
  color: #888;
}
.sla_area .circle-arrow-top {
  font-size: 36px;
  color: #7cc623;
}
.sla_area .circle-arrow-right {
  font-size: 36px;
  color: #f90;
}
.sla_area .circle-arrow-down {
  font-size: 36px;
  color: #d93b20;
}
/* SLA Estimator */
.cw_sla_estimator {
  float: left;
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
  border-top: 1px solid #d8d8d8;
}
.cw_sla_estimator .cw_item {
  float: left;
  position: relative;
  list-style: none;
  padding: 5px 15px;
  width: 33%;
  height: 120px;
  overflow: hidden;
  border-right: 1px solid #d8d8d8;
  text-align: right !important;
}
.cw_sla_estimator .cw_item:last-child {
  width: 34%;
  border-right: none;
}
.cw_sla_estimator .glyphicons {
  position: absolute;
  left: -10px;
  padding: 0;
  bottom: -20px;
  color: #8DBED0;
  font-size: 60px;
}
.cw_sla_estimator .label {
  font-size: 14px;
  text-shadow: 0 1px 0 #fff;
}
.cw_sla_estimator .label,
.cw_sla_estimator .value {
  float: right;
  clear: right;
  width: 50% !important;
  display: block;
  padding: 5px 0;
  font-weight: 700;
}
.cw_sla_estimator .value {
  float: right;
  clear: right;
  display: block;
  padding: 5px 0;
}
/* SLA Details */
.cw_sla_module .cw_tier_top,
.cw_sla_module .cw_tier_middle,
.cw_sla_module .cw_tier_bottom {
  position: relative;
  float: left;
  width: 100%;
  height: 25%;
  min-height: 240px;
}
.cw_sla_module .cw_tier_top .cw_section,
.cw_sla_module .cw_tier_top .cw_tier_middle .cw_section,
.cw_sla_module .cw_tier_top .cw_tier_bottom .cw_section {
  width: auto;
}
.cw_sla_module .cw_tier_top .cw_section_content {
  float: left;
}
.cw_sla_module .cw_tier_bottom {
  height: 48%;
}
.cw_sla_module .cw_tier_bottom > .cw_section {
  width: 100%;
  bottom: 0;
}
.cw_sla_module .cw_tier_middle .cw_sla_percentage {
  font-size: 80px;
}
.cw_sla_module .cw_operating_time_header {
  text-align: left;
}
.cw_sla_module .cw_operating_time_header span {
  float: left;
  display: inline-block;
  width: 72px;
}
.cw_sla_module .cw_operating_time_header span:first-child {
  padding-left: 80px;
  width: 70px;
}
.cw_sla_summary_content {
  padding: 10px !important;
  overflow-y: auto;
}
.cw_sla_summary_content .cw_section {
  border: 1px solid #d8d8d8;
  box-shadow: none;
}
/*Sla Period Details*/
.cw_sla_time.cw_box22 .cw_section_content {
  position: absolute;
  top: 42px;
  overflow-y: auto;
}
.cw_sla_time .cw_sla_time_section {
  float: left;
  width: 100%;
  height: 50%;
  padding: 30px 20px;
  text-align: left;
}
.cw_sla_time .cw_sla_time_section:first-child {
  border-bottom: 1px solid #d8d8d8;
}
.cw_sla_time_section strong {
  float: left;
  width: 30%;
}
.cw_sla_time_section span {
  float: left;
  width: 70%;
  font-size: 24px;
  text-indent: 10px;
  color: #e14b32;
}
.cw_sla_time_section:first-child span {
  color: #7cc623;
}
.cw_sladetails_times .cw_section_content,
#cw_sladetails_exclude_times .cw_section_content {
  padding: 0 !important;
}
.cw_sladetails_times ul li:last-child {
  border-bottom: none;
}
.cw_sla_settings .cw_list_head,
.cw_sla_settings .cw_list_item {
  float: left;
  width: 33%;
}
.cw_sla_settings .cw_list_head:last-child,
.cw_sla_settings .cw_list_item:last-child {
  float: left;
  width: 34%;
}
.cw_time_to_breach {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50px;
  overflow: hidden;
}
/*.cw_time_to_breach_values_downtimes { float: left; border: 1px solid @border_color; width: 100%; box-sizing: border-box; }*/
.cw_time_to_breach_values {
  float: left;
  position: absolute;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  text-align: center !important;
  border-top: 1px solid #d8d8d8;
}
.cw_time_to_breach_values .value {
  font-weight: 700;
  padding: 2px 0;
}
.cw_max_allowed {
  float: left;
  border-left: 1px solid #d8d8d8;
  border-top: none;
  border-radius: 0 0 3px 3px;
}
.cw_max_allowed .value {
  padding: 0;
}
.cw_current_downtime,
.cw_remaining_downtime,
.cw_max_allowed {
  float: left;
  width: 33%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.cw_remaining_downtime {
  border-left: 1px solid #d8d8d8;
}
.cw_remaining_downtime .value {
  color: #7cc623;
}
.cw_remaining_downtime .breached {
  color: #e14b32;
}
.cw_current_downtime .vertical {
  border-right: 1px solid #d8d8d8;
}
.cw_current_downtime .value {
  color: #e14b32;
}
.cw_current_downtime.no-downtime .value {
  color: #7cc623;
}
.cw_glyph_timezone {
  font-size: 16px;
  color: #257cba;
  margin-right: 8px;
}
/* Operating Time */
.cw_section.cw_sladetails_exclude {
  height: 200px;
}
.cw_section.cw_sladetails_note_form {
  height: 200px;
}
/*  */
.cw_sladetails_time_container {
  float: left;
  margin: 5px 0 0 10px;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 3px;
}
/* Scheduler */
.cw_sladetails_times .k-event {
  background-color: #428BCA;
  border-color: #2a6496;
}
.cw_sla_time_day {
  float: left;
  width: 100px;
  padding-right: 10px;
  height: 32px;
  line-height: 32px;
  text-align: right !important;
  margin-right: -110px;
  clear: left;
}
.cw_sla_time_control {
  height: 270px;
  padding-top: 40px;
  margin-bottom: -10px;
}
.cw_sla_time_control .k-state-selected {
  color: #333;
}
.cw_sla_time_selector {
  float: left;
  width: 100%;
  min-height: 36px;
  padding-left: 120px;
  padding-right: 60px;
  border: none;
  background: none;
  box-shadow: none;
}
.cw_sla_time_actions {
  float: right;
  width: 60px;
  margin-left: -70px;
}
.cw_sla_time_actions .glyphicons {
  float: left;
  padding: 5px;
  font-size: 18px;
  cursor: pointer !important;
}
.cw_selected_time {
  float: left;
  width: 100%;
  min-height: 31px;
  padding: 5px 0 3px 110px;
  border-bottom: 1px solid #d8d8d8;
}
.cw_selected_time:last-child {
  border-bottom: none;
}
.cw_selected_time li {
  float: left;
  list-style: none;
  padding: 1px 5px 0;
  font-size: 11px;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  border: 1px solid #e5e5e5;
  border-bottom-color: #d8d8d8;
  background-color: #f5f5f5;
  margin: 0 2px 2px 0;
  border-radius: 2px;
}
.cw_sla_operating_time {
  height: 280px;
  padding-top: 30px;
  background-color: #f5f5f5;
  border-left: 1px solid #d3d3d3;
  margin-left: -1px;
  margin-bottom: -10px;
}
.cw_exclude_date_control {
  float: left;
  width: 100%;
}
.cw_sladetails_calendar {
  float: left;
  max-width: 300px;
  width: 48%;
  margin: 0 1%;
}
.cw_sladetails_summary li {
  list-style: none;
}
.cw_sladetails_exclude_list {
  float: left;
  width: 30%;
  height: 290px;
  overflow-y: auto;
}
.cw_action_buttons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 47px;
  padding: 0 20px;
  border-top: 1px solid #eee;
  background-color: #fff;
}
.cw_action_buttons .k-button {
  float: right;
  width: auto;
  margin: 7px 0 0 10px;
}
.cw_actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 47px;
  padding: 0 20px;
  border-top: 1px solid #eee;
  background-color: #fff;
}
.cw_actions .k-button {
  float: right;
  width: auto;
  margin: 7px 0 0 10px;
}
.cw_add_exclude_time {
  float: left;
  width: 100%;
  padding: 20px 20px 20px 400px;
}
.cw_excludetime_selectors {
  float: left;
  margin-left: -380px;
  width: 360px;
}
.cw_excludetime_selectors .k-widget {
  float: left;
  width: 100%;
}
.cw_exclude_date_control textarea {
  height: 47px;
}
.cw_sladetails_summary_exclude li,
#cw_sladetails_exclude_list li {
  float: left;
  width: 100%;
  text-align: left;
  padding: 5px 0;
  border-bottom: 1px solid #d8d8d8;
  list-style: none;
}
.cw_exclude_time_separator {
  float: left;
  position: absolute;
  top: 115px;
  bottom: 62px;
  left: 15px;
  right: 15px;
  overflow-y: auto;
  width: auto;
}
.cw_section_content.cw_scheduler .w100.h100 span,
.cw_section_content.cw_scheduler .h100 span {
  margin-right: 5px;
  line-height: 1.3;
}
/*State changes*/
.cw_sla_breaches td.k-detail-cell {
  padding: 0;
}
.cw_sla_breaches .k-detail-row .k-grid {
  border: 1px solid #d8d8d8;
  margin: -1px 0 5px -1px;
}
.cw_sla_breaches .k-detail-row .k-alt {
  background-color: #fff;
}
.cw_breaches_filter_container {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 10;
}
#cw_sladetails_state_changes .cw_section_titlebar .calendar {
  margin: 9px 10px 0 0;
}
/* Wizard */
.cw_setup {
  background: #DEE8EC !important;
  overflow-y: auto;
}
.cw_setup .row:first-child {
  background-color: #fff;
}
.cw_setup .logo {
  float: none !important;
  border: none !important;
  height: auto;
  margin: 20px auto;
}
.cw_setup .logo a {
  float: none !important;
  display: block !important;
  width: 600px;
  margin: 0 auto;
}
.cw_setup .main_content {
  width: 600px;
  margin: 40px auto;
}
/* Number Steps with labels  */
.cw_steps_nav {
  float: left;
  width: 100%;
  height: 46px;
}
.cw_steps_nav .cw_item {
  position: relative;
  width: 33.3333%;
  float: left;
  list-style: none;
  text-align: center !important;
  color: #fff;
}
.cw_steps_nav .label,
.cw_steps_nav .value {
  color: #54595E;
}
.cw_steps_nav .cw_item:first-child .value {
  margin: 0 auto;
}
.cw_steps_nav .cw_item:first-child .value {
  margin: 0 auto;
}
.cw_steps_nav .label {
  height: 30px;
  min-width: 180px;
  display: block;
  font-weight: 700;
  font-style: normal;
  font-size: 11px;
  line-height: 30px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 #fff;
}
.cw_steps_nav .value {
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto;
  font-weight: 700;
  font-size: 13px;
  line-height: 30px;
  background-color: #fff;
  text-align: center !important;
  text-shadow: 0 1px 0 #fff;
  border-radius: 20px;
}
.cw_steps_nav .is_valid .value {
  border: 1px solid #d8d8d8;
  background-color: #fff;
  color: #00bdf2;
}
.cw_steps_nav .is_selected .value {
  border: 1px solid #fff;
  background: #257cba;
  color: #fff;
  text-shadow: 0 1px 0 #709bba;
}
/* Wizard Content */
.cw_wizard {
  float: left;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-radius: 2px 2px 0 0;
}
.cw_wizard .cw_title {
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;
  font-size: 18px;
  line-height: 1.5;
  background-color: #f8f8f8;
  text-align: center !important;
  text-shadow: 0 1px 0 #fff;
}
.cw_wizard .cw_textinput {
  float: left;
  width: 100%;
  height: 30px;
  padding: 6px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}
.cw_wizard .cw_content {
  width: 600px;
  overflow: hidden;
}
.cw_wizard .cw_content .status {
  float: left;
  width: 612px;
  margin: 20px 10px 0px 10px;
}
.cw_wizard .cw_steps {
  float: left;
  margin-left: 0;
  list-style: none;
}
.cw_wizard .cw_steps .cw_item {
  float: left;
  width: 590px;
  padding: 20px;
}
.required-wizard-field {
  color: red;
  position: absolute;
  left: 152px;
}
#account_comment {
  resize: vertical;
  max-height: 75px;
}
/* Wizard Navigation */
.cw_wizard_nav {
  float: left;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #d8d8d8;
  border-top: none;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
}
.cw_wizard_nav .k-button {
  min-width: 80px;
}
.cw_wizard_nav .glyphicons {
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 0 5px;
  font-size: 18px;
}
.cw_wizard_nav .left .glyphicons {
  float: left;
  margin-left: -10px;
}
.cw_wizard_nav .right .glyphicons {
  float: right;
  margin-right: -10px;
}
.cw_service_details_module_title .cw_page_title {
  display: inline-block;
}
.cw_service_details_module_title .cw_additional_warning {
  display: none;
  height: 35px;
  margin-left: 50px;
}
.cw_service_details_module_title .cw_additional_warning .icon_block {
  margin-right: 5px;
  font-size: 16px;
}
.cw_service_details_module_title .cw_notification {
  background: #f2dede;
  padding: 6px;
  border-radius: 5px;
  color: #803333;
  text-decoration: none;
  text-shadow: none;
  border: 1px solid #f0d3d3;
}
.cw_service_details_module_title .cw_notification.maintenance {
  background: #c4c4c4;
  color: #ffffff;
  border: 1px solid #9b9ea2;
}
.cw_service_module .cw_tabs_absolute .cw_section_full {
  overflow: hidden;
}
/* Service */
.cw_content_wrapper {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
}
.cw_content_wrapper {
  overflow-y: auto;
}
.cw_content_wrapper .cw_section_content {
  padding: 0;
}
/* New CARD */
.cw_services_list .cw_card {
  width: 100%;
  height: 170px;
  padding-left: 130px;
  margin-bottom: 5px;
}
.cw_services_list .cw_card .cw_card_avatar {
  float: left;
  width: 131px;
  height: 130px;
  padding: 0;
  border-right: 1px solid #d8d8d8;
}
.cw_services_list .cw_card .cw_avatar {
  float: left;
  width: 130px;
  height: 130px;
}
.cw_services_list .cw_card .cw_title {
  font-size: 15px;
}
.cw_services_list .cw_card .cw_field {
  float: left;
  width: 100%;
  margin: 0 0 5px;
}
.cw_services_list .cw_card .cw_content div:first-of-type {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cw_services_list .cw_card .cw_content {
  padding: 10px;
}
.cw_services_list .cw_card .cw_content .label {
  display: inline-block;
  color: #888;
}
.cw_services_list .cw_card .cw_content .value {
  display: inline-block;
  font-weight: 700;
  text-indent: 10px;
}
.cw_services_list .cw_card .cw_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
}
.cw_services_list .cw_card .cw_footer .cw_states {
  float: left;
  width: 131px;
  height: 40px;
  line-height: 40px;
  border-right: 1px solid #ddd;
  text-align: center;
}
.cw_services_list .cw_card .cw_footer .cw_states:last-child {
  border-right: none;
}
.cw_services_list .cw_card .cw_footer .cw_card_service_state,
.cw_services_list .cw_card .cw_footer .cw_card_sla_state,
.cw_services_list .cw_card .cw_footer .cw_card_model_state {
  display: inline-block;
  margin: 6px 10px -5px;
}
.cw_services_list .cw_card .cw_footer .cw_card_sla_state .glyphicons {
  /*margin:-3px 0 3px*/
}
.cw_services_list .cw_card .cw_footer .cw_card_sla_state .cw_status_indicator {
  float: left;
}
.cw_services_list .cw_card .cw_footer .cw_operating_state {
  width: 100%;
  padding: 0;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: transparent;
}
.cw_services_list .cw_card .cw_card_options {
  position: absolute;
  top: 0;
  right: 0;
}
.cw_services_list .cw_card .cw_team_name {
  width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* Service Details Layout */
.cw_service_details_module .cw_tier_top {
  position: relative;
  float: left;
  width: 100%;
  height: 320px;
}
.cw_service_details_module .cw_tier_top .cw_section {
  box-shadow: none;
  border: 1px solid #d8d8d8;
  height: 294px;
  margin-top: 10px;
}
.cw_service_details_module .cw_tier_top .cw_section_content {
  height: 252px;
}
.cw_service_details_module .cw_tier_bottom {
  position: absolute;
  top: 320px;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 160px;
}
.cw_service_details_module .cw_tier_bottom .cw_section {
  box-shadow: none;
  border: none;
}
.cw_service_details_module .cw_tier_bottom .cw_widget {
  height: 160px;
  margin-top: -160px;
  border-top: 1px solid #d8d8d8;
}
.cw_service_details_module .cw_tier_bottom .cw_widget .cw_section_content {
  height: 116px;
}
.cw_service_details_module .cw_tier_bottom .cw_servicelog_section {
  top: 0;
}
.cw_service_details_module .cw_state_widget2 {
  width: 50% !important;
  padding: 0 5px;
}
.cw_service_details_module .cw_state_widget2 .title {
  padding: 0;
}
.cw_service_details_module .cw_state_widget2 .progress {
  height: 100%;
  border-radius: 0 0 3px 3px;
}
.cw_service_details_module .cw_state_widget2 .days {
  padding-right: 15px;
}
.cw_service_details_module .w333 {
  width: 33.3333% !important;
}
.cw_service_details_module .cw_section_titlebar .cw_mask {
  background-color: rgba(251, 251, 251, 0.65);
  left: auto;
  width: 525px;
}
.cw_operating_state {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  background-color: #fff;
  padding: 4px 8px;
  margin-left: 5px;
  border-radius: 3px;
  text-indent: 0;
  line-height: 1;
}
.cw_operating_state.operational {
  color: #7cc623;
}
.cw_operating_state.non_operational {
  color: #c4c4c4;
}
.cw_operating_state.in_maintenance {
  color: #aaa;
}
.cw_service_title {
  float: left;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_summary_content {
  overflow: hidden !important;
}
.cw_toggle_servicelog {
  margin: 2px 8px 0 0;
}
/* Screen Options */
#screen_options.expanded {
  border-radius: 3px 3px 0 0;
  padding-bottom: 15px;
}
.screen_options {
  float: left;
  position: absolute;
  display: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 25px 5px;
  z-index: 20;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.screen_options label {
  padding: 0 10px 3px;
}
.screen_options input {
  margin: 5px 5px 0;
}
/* Service Change Duration/Time */
.cw_service_lastchange .value {
  font-size: 20px;
}
.cw_service_changetimer .part {
  width: 100%;
  color: #fff;
}
/* Service Model Preview */
.cw_service_create {
  margin-top: 90px;
  padding: 10px 20px;
}
.service_model_preview.custom {
  max-width: 100%;
  max-height: 100%;
}
.service_model_preview {
  max-width: 100%;
  max-height: 100%;
}
#cw_service_sm_preview {
  text-align: center !important;
}
#cw_service_sm_preview .cw_section_content.kinetic-active {
  cursor: pointer!important;
}
.metric_chart {
  overflow: hidden;
  flex: 1;
}
.cw_servicedetails_settings .k-state-disabled .k-button:hover {
  color: #ffffff;
  border-color: #6CA5D6;
  background-color: #5C97CA;
}
.cw_servicedetails_settings .cw_status_indicator,
.cw_servicedetails_settings .status_icon {
  margin: 4px 0 0 10px !important;
  font-size: 20px;
}
.cw_servicedetails_settings .ok-sign {
  color: #7cc623;
}
/* Service Documents */
.cw_service_document .cw_card {
  margin-bottom: 15px;
}
.cw_service_document .k-widget.k-upload {
  float: left;
  width: 100%;
}
.cw_service_document .k-dropzone {
  width: 100%;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
}
.cw_service_document em {
  visibility: visible;
}
#cw_document_name {
  padding-right: 10px;
}
#cw_document_filename,
.cw_service_document em {
  float: right;
  width: 230px;
  padding: 3px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_avatar_service {
  background-color: #fff;
}
.cw_idle {
  color: #dddddd !important;
}
#cw_service_log_show_all .cw_checkbox_label {
  margin-right: 4px;
}
.cw_attachements_container .k-button-icon,
.cw_attachements_container .k-dropdown {
  margin: 0 5px 0 0;
}
.cw_attachements_container .glyphicons {
  font-size: 22px;
}
.cw_download_attachment a {
  color: #54595E;
}
.cw_incidents_link {
  color: #709bba;
}
.cw_incidents_link:hover {
  font-weight: 700;
}
/*  */
.operational {
  color: #7cc623;
}
.non_operational {
  color: #dddddd;
}
.in_maintenance {
  color: #f1ae31;
  font-weight: 700;
}
.cw_service_state_breadcrumb {
  color: #00aeef;
}
/* Service History */
#cw_service_shistory .cw_section_title {
  width: auto;
}
#cw_history_counters {
  float: right;
  margin-right: 10px;
  line-height: 26px;
}
#cw_service_breaches,
#cw_service_downtime {
  margin-left: 5px;
  padding: 5px 10px;
  background: #cfe6f5;
  border: 1px solid #90c5e8;
  border-radius: 3px;
  color: #3276b1;
}
/* Service Log */
.k-grid .glyphicons.paperclip {
  font-size: 14px;
  color: #777;
  padding: 3px 0 0 5px;
}
/* Service Log Details */
.text_preview {
  text-align: left !important;
}
.text_preview,
.html_preview {
  padding: 10px;
}
.text_preview,
.image_preview,
.html_preview,
.cw_service_module .pdf_preview {
  overflow: auto;
}
.cw_log_attachments .cw_list_item,
.cw_log_attachments .cw_list_head {
  text-align: left !important;
  padding-left: 10px;
}
.cw_log_attachments .glyphicons {
  font-size: 18px;
  margin-right: 10px;
}
.cw_reload_logs {
  position: absolute;
  z-index: 10000;
  width: 99%;
  height: 35px;
  margin-top: 63px;
  padding: 10px 0 0 20px;
  background-color: #cfe6f5;
  border-radius: 3px;
  cursor: pointer;
}
.cw_show_acknowledged {
  height: 30px;
  margin-right: 10px;
  font-weight: 700;
  border: 1px solid #cccccc;
  border-radius: 3px;
  color: #54595e;
  padding: 4px;
  background-color: #ffffff;
}
.cw_show_acknowledged.is_selected {
  background: #cfe6f5;
  color: #000000;
}
.cw_show_acknowledged span {
  display: block;
  margin-top: -8px;
  padding: 10px;
}
/* Scheduler */
.cw_service_module .k-scheduler-toolbar {
  display: none !important;
}
.cw_service_module .k-event-delete {
  display: block !important;
}
.cw_service_module .k-event {
  background-color: #428BCA;
  border-color: #2a6496;
}
/* Maintenance */
.cw_maintenance {
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  padding-bottom: 56px;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
.cw_maintenance.is_hidden {
  display: none;
}
.cw_maintenance .form {
  padding: 20px 20px 5px 400px;
}
.cw_maintenance .cw_maintenance_selectors {
  float: left;
  width: 360px;
  margin-left: -380px;
}
.cw_maintenance .k-datetimepicker {
  width: 100%;
}
.cw_maintenance textarea {
  height: 50px;
}
.cw_maintenance textarea.expanded {
  width: 100%!important;
}
.k-window-content .cw_maintenance {
  height: 120px;
  box-shadow: none;
}
.k-window-content .cw_maintenance .form {
  padding: 20px;
}
/* Maintenance Popup */
.cw_maintenance_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  cursor: pointer !important;
}
.cw_maintenance_icon:hover + .cw_maintenance_list {
  display: block !important;
  left: auto;
  right: -100%;
  margin-right: -9px;
}
.cw_maintenance_list {
  display: none !important;
  z-index: 5;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.cw_maintenance_list .cw_title {
  margin-bottom: 10px;
  font-weight: 700;
}
.cw_maintenance_list .cw_item {
  float: left;
  width: 100%;
  padding: 3px 0;
  color: #888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_maintenance #cw_add_exclude_periods {
  margin-left: 20px;
}
/* Summary Documents */
.cw_servicedetails_docs {
  float: left;
  width: 100%;
}
.cw_servicedetails_docs .cw_table {
  float: left;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}
.cw_servicedetails_docs .cw_table thead {
  background-color: #f5f5f5;
}
.cw_servicedetails_docs .cw_table td,
.cw_servicedetails_docs .cw_table th {
  padding: 5px;
}
.cw_servicedetails_docs .cw_table td {
  border-bottom: 1px solid #eee;
}
.cw_servicedetails_docs .cw_grid_link {
  max-width: 145px;
}
.cw_servicedetails_docs.k-grid.k-widget {
  border: 1px solid #d8d8d8;
  border-radius: 0 0 3px 3px;
}
/* Create Service */
.cw_service textarea {
  height: 47px;
}
.cw_error_message textarea {
  min-height: 110px !important;
  position: sticky !important;
}
.cw_error_message.expandable_area {
  margin-bottom: 16px !important;
}
.cw_error_message strong {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_notification_message textarea {
  min-height: 110px !important;
  position: sticky !important;
}
.cw_notification_message strong {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_recalculate_container {
  margin: 15px;
}
.cw_recalculate_container p {
  margin: 10px 0;
}
.maintenance-diff-timezone-info {
  margin: -3px 0 0 40px;
}
.maintenance-diff-box-view {
  height: 34px;
  border-color: initial;
  padding-top: 0px;
  background-color: #d9edf7;
  color: #247fad;
}
#serviceDetailsRevisionsContainer,
#serviceDetailsDependentServicesContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#serviceDetailsDependentServicesContainer .cw_section_servicegrid,
#serviceDetailsDependentServicesContainer .cw_section_content,
#serviceDetailsDependentServicesContainer .services_grid {
  height: 100%;
}
/* Service Board */
/* Layout */
.cw_sboard_area {
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
#cw_sb_layout {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.sboard_scroll {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 0;
}
.cw_serviceboard_name {
  width: auto;
  z-index: 500;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 10px;
  margin-left: -140px;
  margin-top: -30px;
  text-align: center;
  background-color: #f3fcff;
  color: #3B3E42;
  font-weight: 700;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: margin 0.5s;
  cursor: pointer !important;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
}
.cw_serviceboard_name.w500 {
  width: auto;
  margin-left: 0px;
  transform: translate(-50%);
}
.cw_serviceboard_name .glyphicons {
  font-size: 16px;
  line-height: 2;
  margin-left: 10px;
}
.cw_serviceboard_name span:first-of-type {
  line-height: 2.5;
}
.cw_serviceboard_name:hover {
  margin-top: 0;
}
.cw_sboard_resolution {
  padding: 6px 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.cw_sboard_last_update {
  padding: 6px 10px;
  margin-left: 10px;
  color: #3B3E42;
  font-size: 12px;
  font-weight: 700;
  width: auto;
}
.cw_sb_name {
  width: auto;
}
.cw_landing_page {
  float: left;
  margin: -2px 10px 0 0;
  font-size: 16px;
  cursor: pointer !important;
}
#metric_placeholder.glyphicons {
  float: none;
  font-size: 80px !important;
  color: #aaa;
  margin: 50px auto 0;
}
.cw_section_content.kinetic-active {
  text-align: center !important;
}
#cw_kendo_team > span {
  float: left;
  width: 240px;
  margin-left: 120px;
}
#cw_serviceboard_module > .window_titlebar .k-dropdown {
  margin: 5px 0 5px 10px !important;
}
#cw_serviceboard_module .cw_section:hover {
  border-color: #b2b2b2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
#cw_serviceboard_module .cw_section_titlebar:hover {
  cursor: move;
}
#cw_sb_link_container {
  display: none !important;
  position: absolute;
  right: 3px;
  top: 40px;
  width: 360px;
  padding: 0 15px 15px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
#cw_sb_link_container.is_open {
  display: block !important;
}
#cw_sb_link_container input,
#cw_sb_link_container label {
  float: left;
  width: 100%;
  text-indent: 0;
  padding-left: 0;
}
#cw_sb_link_container input {
  margin-top: 15px;
}
#cw_sb_link_container label {
  padding: 5px 15px;
  margin-left: -15px;
  background-color: #eee;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 3px 3px 0 0;
}
#cw_sb_link_container .glyphicons {
  float: right;
  font-size: 22px;
  margin: 2px -5px 0 0;
  color: #00bdf2;
}
.cw_modal #modal .create_service_board {
  position: absolute;
  overflow: auto;
}
.cw_modal #modal .create_service_board textarea {
  resize: vertical;
  max-width: 100%;
}
.create_service_board .cw_multi_toggle .glyphicons {
  margin: -2px 8px -2px 0;
}
.create_service_board .k-widget.k-multiselect {
  max-height: 60px;
}
/* Add Widget */
#create_widget {
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: scroll;
  height: 720px;
}
.cw_selectiveservices_incident .cw_list_columns {
  margin: 0px;
}
.cw_wt_panelbar {
  position: absolute;
  top: 0;
  bottom: 50px;
  left: 0;
  width: 240px;
  border: none;
  border-right: 1px solid #d8d8d8;
  background-color: #f5f5f5;
  box-shadow: none;
}
.cw_wt_panelbar > .cw_item {
  float: left;
  width: 100%;
}
.cw_wt_panelbar > .cw_item .k-link {
  line-height: 3em !important;
}
.cw_wt_panelbar .cw_item .glyphicons {
  float: left;
  clear: left;
  margin: 11px 10px 0 0;
  font-size: 15px;
}
.cw_wt_panelbar .cw_item .k-icon {
  margin: -8px 4px 0 0;
}
.cw_wt_panelbar .cw_item .k-link {
  border: 1px solid #ddd;
  border-right: none;
  border-bottom: none;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  border-left: none;
  background-color: #f5f5f5;
  box-shadow: none;
}
.cw_wt_panelbar .cw_item .k-link.k-state-hover {
  color: #257cba;
}
.cw_wt_panelbar .cw_item .k-link.k-state-hover .glyphicons {
  color: #257cba;
}
.cw_wt_panelbar .cw_item .k-link.k-state-selected {
  background: #fff !important;
  border-right: 1px solid #fff !important;
  margin-right: -1px;
  color: #333;
  border-left: none;
}
.cw_wt_panelbar .cw_item .k-link.k-state-selected.k-state-hover,
.cw_wt_panelbar .cw_item .k-link.k-state-selected.k-state-focused {
  box-shadow: none;
  color: #257cba;
}
.cw_wt_panelbar .cw_item .k-link.k-header {
  border-left: none;
}
.cw_wt_panelbar .cw_item .cw_list {
  border: none;
}
.cw_wt_panelbar .cw_item .cw_list .glyphicons {
  margin-left: 27px;
}
.clear_absolute .cw_wt_panelbar {
  position: unset;
}
.clear_absolute .cw_widget_form {
  position: unset;
}
.cw_widget_form {
  min-height: 500px;
  width: 100%;
}
.cw_widget_form .cw_widget_title {
  float: left;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-indent: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.cw_widget_form .cw_widget_title.full {
  width: 100%;
}
.cw_widget_form .cw_field {
  /*padding-left:150px;*/
  margin-bottom: 10px;
}
.cw_widget_form .label {
  float: left;
  width: 140px;
  padding-right: 10px;
  margin-left: -150px;
  min-height: 30px;
  line-height: 30px;
  text-align: right;
  color: #222;
}
.cw_widget_form .k-dropdown,
.cw_widget_form .k-input,
.cw_widget_form textarea {
  width: 100%;
}
.cw_widget_form .cw_list_item {
  text-align: left;
}
.cw_widget_form .cw_multi_toggle {
  float: left;
}
.cw_widget_form .k-dropzone {
  width: 100%;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
}
.cw_widget_form .cw_checkbox {
  margin-left: 140px;
  margin-top: 6px;
}
.cw_widget_form .cw_field.cw_no_label {
  padding-left: 0;
}
.cw_widget_form .cw_field .k-numerictextbox {
  margin-left: 130px;
}
.cw_widget_form .cw_dropdown_placeholder .cw_no_items {
  padding: 6px 10px 7px 10px;
}
.cw_no_label {
  padding-left: 150px;
}
#cw_summary_elements_expand {
  padding-top: 12px;
}
/* Cw Bars Chart */
#cw_sb_layout .cw_bars_chart .cw_progress {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0px;
  width: auto;
}
.cw_bars_chart .value,
.cw_bars_chart .cw_bar {
  height: 120px;
}
.cw_bars_chart .cw_bar {
  line-height: 160px;
}
#cw_serviceboard_module .cw_bars_chart .cw_bar {
  cursor: default !important;
}
.cw_serviceboard_module .cw_bars span.legend {
  float: none!important;
}
.cw_serviceboard_module .cw_bars .cw_bar span {
  float: none!important;
}
/* Summary / Hierchy Widget */
.cw_summary {
  padding: 10px;
  list-style-type: none;
}
.cw_summary {
  width: 100%;
  padding: 10px;
  list-style-type: none;
}
.cw_summary li {
  float: right;
  width: 100%;
  display: block;
  margin-bottom: 1px;
  cursor: pointer !important;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  height: auto;
  padding: 5px;
  background: url("~styles/shared/images/app_theme//button_overlay.png") repeat-x scroll left -7px #e3e3e3;
  color: #555;
  text-align: left;
  text-indent: 5px;
  text-shadow: 0 1px 0 #fff;
}
.cw_summary span {
  float: right;
  width: 20px;
  height: 20px;
  margin: 1px 2px 1px 0;
  padding: 0 !important;
  text-align: center !important;
  text-indent: 0;
  border-radius: 11px;
}
.cw_summary > li > span {
  margin-right: 7px;
}
.cw_summary ul {
  float: left;
  width: 100%;
}
.cw_summary ul li {
  width: 100%;
  height: auto;
  padding: 5px;
  background-color: #f8f8f8;
}
.cw_summary .glyphicons {
  padding: 0;
  font-size: 22px;
  line-height: 18px;
}
.cw_sum_yellow {
  color: #f7e400;
}
.cw_sum_green {
  color: #7cc623;
}
.cw_sum_blue {
  color: #00bdf2;
}
.cw_sum_white {
  color: #fff;
}
.cw_sum_red {
  color: #e14b32;
}
.cw_sum_orange {
  color: #f1ae31;
}
.widget_hint {
  position: relative;
  margin: 0 0 10px;
  border: 1px dashed #257cba;
  background: #cfe6f5;
  border-radius: 6px;
  box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#cfe6f5), to(#ffffff));
  background-image: -webkit-linear-gradient(top, #cfe6f5, #ffffff);
  background-image: -moz-linear-gradient(top, #cfe6f5, #ffffff);
}
.seh_qualifiers {
  display: block;
  margin-top: 3px;
}
.cw_custom_text_widget {
  color: #000;
  padding: 5px;
}
.k-window-titlebar > .cw_agents_state {
  margin-right: 0;
  margin-top: 0;
  top: 7px;
  right: 55px;
  position: absolute;
}
.cw_widget .cw_agents_state {
  margin-right: 3px;
}
/* Tooltip */
.cw_serviceboard_module .k-widget.k-tooltip {
  max-height: 400px;
}
.cw_serviceboard_module .k-tooltip-content {
  max-height: 400px;
  overflow-y: auto;
}
.cw_serviceboard_module .k-tooltip-button {
  display: none;
}
/*Metrics*/
#cw_show_available_metrics {
  margin: 5px 10px 0 0;
}
#cw_available_metrics_window #cw_available_metrics_add {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: -50px;
}
#cw_available_metrics_window .cw_search_box {
  height: 22px;
}
/*Incidents modal*/
.cw_incidents_counter_grid .k-grid-content {
  overflow-y: auto !important;
}
.cw_header_grid_link {
  cursor: pointer !important;
  display: block;
  color: #709bba;
  text-decoration: none;
}
.cw_header_grid_link:hover {
  font-weight: 700;
}
.cw_exclude_breaches {
  display: inline-block;
}
.cw_exclude_downtime {
  display: inline-block;
  width: 50%;
}
.cw_show_footer {
  margin-top: 10px;
}
.cw_field_title {
  width: 120px;
  height: 30px;
  padding: 0.5em 0;
  line-height: 16px;
  text-indent: 6px;
  background-color: #fbfbfb;
  border-radius: 4px 0 0 4px;
  border: 1px solid #ddd;
  margin-right: 10px;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
.flex-grow {
  display: flex;
  flex-grow: 1;
}
.cw_widget_form .cw_field .nomargin .k-numerictextbox {
  margin-left: unset;
}
.cw_selective_service_input {
  width: 32% !important;
}
.cw_selective_service_input_48 {
  width: 48% !important;
}
.cw_selective_service_input_67 {
  width: 67% !important;
}
.cw_selective_service_input_67.k-multiselect.k-widget {
  float: right !important;
}
.cw_selective_numeric_input {
  width: 18% !important;
  margin-left: 0 !important;
}
.cw_selective_numeric_input.k-input {
  width: 100% !important;
}
.cw_selective_service_input_67.k-input {
  width: 100% !important;
}
.cw_serviceboard_module .k-overlay {
  z-index: 999 !important;
}
.create_service_board .cw_background_color .glyphicons {
  line-height: 23px;
  margin-left: 10px;
}
.cw_background_color .k-colorpicker .k-selected-color {
  width: 74px;
}
.k-flatcolorpicker .k-selected-color {
  border-radius: 0 10px 0 0;
}
.k-flatcolorpicker .k-selected-color .k-selected-color-display {
  border-radius: 0 10px 0 0;
}
.k-flatcolorpicker .k-selected-color .k-selected-color-display input.k-color-value {
  border-radius: 0 10px 0 0;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}
.copy_subaccount_warning_message {
  margin: -10px 0 0 10px;
  background-color: #cfe6f5;
  border: 1px solid #257cba57;
  border-radius: 5px;
  color: #257cba;
  padding: 8px;
  width: 300px;
  display: inline-block;
}
.k-widget.k-window.no_frame {
  border: none;
}
.required_input .k-input {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf;
}
.required_input > input::placeholder {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf;
}
.required_multiselect.k-multiselect-wrap {
  padding-left: 8px;
}
.required_vmware > input::placeholder {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf;
}
/* Message Center */
#cw_messages_content {
  float: left;
}
#cw_messages_content .cw_section {
  border-radius: 4px;
}
#cw_messages_content .cw_section_content {
  padding: 0;
  height: 100%;
}
.cw_messages_list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  padding-bottom: 30px;
}
.cw_messages_list td {
  border-bottom: 1px solid #d8d8d8;
  vertical-align: top;
  text-shadow: 0 1px 0 #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_messages_list td:first-child {
  padding: 0 !important;
  text-align: center !important;
}
.cw_messages_list th:first-child {
  padding: 0;
  text-align: center !important;
}
.cw_messages_list th:first-child .k-icon {
  float: right;
  margin-left: -16px;
}
.cw_messages_list th .glyphicons {
  padding: 0;
  color: #54595E;
  text-shadow: 0 1px 0 #fff;
}
.cw_messages_list th .glyphicons:hover {
  color: #54595E;
  text-shadow: 0 1px 0 #fff;
}
.cw_messages_list .cw_important_message {
  margin-top: 3px;
  color: #e14b32;
}
.cw_messages_list .cw_important_message:hover {
  color: #e14b32;
}
.cw_messages_list .cw_regular_message {
  color: #ddd;
  text-shadow: none;
}
.cw_messages_list .cw_regular_message:hover {
  color: #ddd;
  text-shadow: none;
}
.cw_messages_list .k-grid-pager {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fbfbfb;
}
.cw_agent_edit {
  display: none;
  float: left;
  position: absolute;
  z-index: 10011;
  width: 600px;
  left: 50%;
  margin-left: -320px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}
.cw_compose_form {
  top: -1px;
}
.cw_compose_form .k-button,
.cw_agent_edit .k-button {
  width: auto;
}
.cw_compose_form textarea,
.cw_agent_edit textarea {
  clear: both;
}
.cw_compose_form textarea {
  height: 200px;
}
#cw_message_type {
  float: left;
  list-style: none;
  margin-left: 130px;
}
#cw_message_type li {
  height: 24px;
  line-height: 24px;
}
#cw_message_type .glyphicons {
  float: left;
  padding: 0 10px 0 0;
  height: 24px;
  line-height: 24px;
}
#cw_message_important_section {
  line-height: 28px;
}
.cw_msg_important {
  float: right;
  width: 100%;
}
.cw_msg_important strong {
  float: right;
  margin-right: 10px;
}
.cw_msg_important .cw_checkbox {
  float: right;
}
.cw_msg_unread {
  font-weight: 700;
}
.cw_compose_form .status.relative {
  position: absolute;
  top: 11px;
  left: 0;
}
/* Service Summary - HOME */
#cw_summary_tabs li {
  margin-right: 4px;
  font-size: 13px;
  font-weight: 700;
}
.cw_filters {
  float: right;
  position: relative;
  z-index: 20;
  border-radius: 4px;
  margin: -2px 10px 0 0;
  text-indent: 0;
}
.cw_filters .cw_filter_list {
  margin: 0;
}
.cw_filter_button {
  position: relative;
  z-index: 20;
  background-color: #eee;
  border: 1px solid #d8d8d8;
  margin-left: -1px;
}
.cw_filter_button:hover {
  background: #fff;
  border-color: #a5a5a5;
}
.cw_services_state {
  float: right;
  padding-right: 10px;
  cursor: pointer !important;
}
.cw_content_preview.cw_onscreen {
  left: 0;
  right: 0;
}
.cw_content_preview .cw_section {
  float: left;
  position: relative;
  height: 238px;
  margin: 0 15px;
  top: 0;
  bottom: 0;
}
.cw_content_preview .cw_section_content {
  background: #fff;
  padding: 15px;
}
.cw_content_preview .cw_section_titlebar,
.cw_content_preview .cw_section_title {
  float: left;
  width: 100%;
}
#cw_summary_sla_chart .cw_progress_bar {
  background: #d8d8d8;
}
#cw_servicesummary .cw_progress_bar {
  background: url("~styles/shared/images/app_theme//button_overlay.png") repeat-x scroll 0 -20px;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.8);
}
.cw_service_description form textarea {
  min-height: 36px !important;
}
.cw_service_description strong {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_progress_account_services_up,
.cw_progress_account_services_down,
.cw_progress_account_services_warning {
  background: url("~styles/shared/images/app_theme//button_overlay.png") repeat-x scroll 0 -20px;
  border: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.cw_progress_account_services_up {
  float: right;
  background-color: #7cc623 !important;
  right: 2px;
  left: auto;
  border-radius: 20px 0 0 20px;
}
.cw_progress_account_services_down {
  float: left;
  background-color: #e14b32 !important;
  border-radius: 0 20px 20px 0;
  left: 2px;
}
.cw_progress_account_services_warning {
  float: left;
  background-color: #f1ae31 !important;
  border-radius: 0 0 0 0;
  left: 2px;
}
/* Bar - Buckets */
#cw_summary_states .glyphicons,
#cw_summary_sla .glyphicons {
  margin-top: 25px;
}
#cw_summary_states .remove-sign {
  color: #e14b32;
}
#cw_summary_states .ok-sign {
  color: #7cc623;
}
#cw_summary_states .cw_progress:hover,
#cw_summary_health .cw_progress:hover,
#cw_summary_sla .cw_progress:hover {
  border-color: #a5a5a5;
  cursor: pointer !important;
}
.cw_progress_slas_breaches,
.cw_progress_slas_warnings,
.cw_progress_slas_ok {
  float: left;
  border: none;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.5);
}
.cw_progress_slas_breaches {
  background-color: #e14b32 !important;
}
.cw_progress_slas_warnings {
  background-color: #efa519 !important;
}
.cw_progress_slas_ok {
  background-color: #7cc623 !important;
  left: auto;
  right: 2px;
}
/* Agents state */
.cw_agents_state {
  float: right;
  margin-right: 10px;
  font-size: 11px;
  cursor: pointer !important;
}
.cw_agents_state .glyphicons {
  float: left;
  margin: 6px 5px 0;
  font-size: 18px;
  text-indent: 0;
}
.cw_agents_state .remove-sign {
  color: #e14b32;
}
.cw_agents_state .exclamation-sign {
  color: #f1ae31;
}
.cw_agents_state .label {
  float: left;
  height: 26px;
  line-height: 26px;
  color: #555;
  text-indent: 0;
}
.cw_summary_data .cw_status_widget .remove-sign {
  margin: 0 0 0 2px;
}
/* Summary Table */
.cw_summary_data {
  float: left;
  width: 100%;
}
.cw_summary_data .k-master-row td {
  background: #fff;
  border-bottom: 1px solid #d8d8d8;
}
.cw_summary_data .k-detail-row .k-grid {
  border: 1px solid #d8d8d8;
  margin: -1px 0 5px -1px;
}
.cw_summary_data .k-grid-content {
  /*overflow-y:visible; */
}
.cw_summary_data .k-grid-content td .k-grid-content {
  overflow-y: visible;
}
.cw_summary_data .k-detail-row th {
  background: #eee;
}
.cw_summary_data .k-detail-row th a {
  font-weight: 400 !important;
}
.cw_summary_data .k-detail-cell {
  padding: 0 !important;
}
.cw_summary_data .k-detail-cell .k-grid {
  margin-left: 4px;
  border-radius: 0;
  border-bottom: none;
}
.cw_summary_data .k-detail-cell th {
  background-color: #fbfbfb;
  border-color: #ccc;
}
.cw_summary_data .k-detail-cell td {
  border-color: #ccc;
}
.cw_summary_data .glyphicons.status_icon {
  cursor: default;
}
.cw_filter_panel_custom {
  width: 280px;
}
.cw_filter_panel_custom .k-dropdown {
  width: 100%;
}
.cw_filter_panel_custom .cw_multiselect_tag {
  float: left;
  width: 100%;
  padding-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
/* Service -> Accounts Status Grid */
.cw_servicestate_icon.ok-sign {
  color: #7cc623;
}
.cw_servicestate_icon.remove-sign {
  color: #e14b32;
}
.cw_current_account_grid {
  color: #257cba;
  font-weight: 700;
}
.k-animation-container .cw_status_indicator {
  float: left;
  margin-right: 5px;
}
.k-animation-container .circle-arrow-down {
  color: #e14b32;
  margin: 3px 5px 0 0;
}
.k-animation-container .circle-arrow-right {
  color: #f1ae31;
  margin: 3px 5px 0 0;
}
.k-animation-container .circle-arrow-top {
  color: #7cc623;
  margin: 3px 5px 0 0;
}
.k-animation-container .status_icon {
  font-size: 15px;
  margin: 3px 5px;
}
.k-animation-container .service_state {
  font-size: 15px;
  margin: 0 5px;
}
#cw_servicesummary .cw_content_preview {
  height: 200px;
}
#cw_service_summary_accounts {
  float: left;
  width: 32%;
  margin-right: 1%;
  text-align: left;
}
#cw_service_summary_accounts .cw_section_content {
  padding: 0;
}
#cw_service_summary_accounts .k-panelbar {
  border: none;
}
#cw_service_summary_list {
  float: left;
  width: 100%;
}
#cw_accounts_multiselect {
  border: none;
  margin-left: 120px;
}
.cw_panelbar_placeholder {
  display: none !important;
  float: left;
  position: absolute;
  width: 100%;
  border-radius: 4px;
  background: url("~styles/shared/images/app_theme//button_overlay.png") repeat-x scroll left top #ddd;
  border: 1px solid #d8d8d8;
  margin: -1px;
}
.cw_panelbar_placeholder .glyphicons {
  position: relative;
  top: 50%;
  margin-top: -20px;
}
.cw_collapse_expand_toggle {
  float: left;
  width: 9px;
  height: 11px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer !important;
}
.cw_collapse_expand_toggle.cw_groups_expanded {
  background-image: url("~styles/shared/images/app_theme//cw_btn_expand.png");
}
.cw_collapse_expand_toggle.cw_groups_collapsed {
  background-image: url("~styles/shared/images/app_theme//cw_btn_collapse.png");
}
#cw_service_summary_list .k-grouping-row a.k-icon {
  float: left;
  margin: 0 7px 0 0;
}
#cw_service_summary_list .k-grouping-row {
  height: 29px;
  overflow: hidden;
}
#cw_service_summary_list .k-grouping-row td {
  padding: 2px 5px;
  border-bottom: 1px solid #d8d8d8;
}
.cw_ssumary_acc_name {
  width: 230px;
}
.cw_ssumary_service_state {
  width: 90px;
  text-align: center !important;
}
.cw_ssummary_assets_health {
  width: 230px;
}
.cw_ssumary_health_status {
  width: 100px;
}
.cw_ssumary_acc_name,
.cw_ssumary_service_state,
.cw_ssummary_assets_health,
.cw_ssummary_sla_status,
.cw_ssumary_health_status {
  float: left;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_separator.is_different {
  background-color: #cfe6f5;
  border: 1px solid #b5d2dc;
}
.is_different .cw_separator_title {
  background-color: #cfe6f5;
}
#cw_asset_health_summary .cw_content_preview {
  top: 15px;
  height: 200px;
}
#cw_asset_health_summary .cw_content_preview .cw_section {
  height: 210px;
}
#cw_asset_health_summary .cw_content_main {
  top: 10px;
  bottom: 20px;
}
#cw_asset_group_health_summary .cw_section.cw_section_full #cw_splitter {
  top: 0;
  border-style: none;
}
#cw_asset_group_health_summary .cw_section.cw_section_full #cw_group_treelist_header {
  height: 50px;
  background-color: #fbfbfb;
  padding-top: 10px;
  padding-right: 20px;
}
#cw_asset_group_health_summary .cw_section.cw_section_full .cw_group_grid_header {
  height: 50px;
  background-color: #fbfbfb;
  padding-top: 10px;
  padding-right: 20px;
}
.cw_asset_group_summary_treelist_header {
  margin: 5px 0 0 20px;
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  color: #777777;
}
.cw_asset_group_summary_second_grid_headers {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}
.k-widget.k-tooltip.warning_tooltip {
  background: #fff;
  color: #333;
  text-shadow: none;
  min-width: 350px;
  border: 1px solid #d8d8d8 !important;
}
.k-widget.k-tooltip.warning_tooltip p {
  text-align: left;
  /*border-bottom: 1px solid @border_color;*/
  padding-left: 10px;
}
.k-widget.k-tooltip.warning_tooltip .cw_asset_tooltip {
  padding: 5px 10px;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.k-widget.k-tooltip.warning_tooltip .cw_asset_tooltip span {
  padding: 2px 5px;
  /*border-left: 1px solid #333;*/
}
.k-widget.k-tooltip.warning_tooltip .cw_asset_tooltip .cw_severity {
  padding: 0;
  border-left: none;
  /*position: absolute;*/
}
.k-widget.k-tooltip.warning_tooltip .cw_asset_tooltip .cw_timestamp {
  display: inline;
  margin-left: 20px;
  white-space: nowrap;
}
.k-widget.k-tooltip.warning_tooltip .cw_asset_tooltip .cw_severity .cw_service_indicator {
  border: none;
}
.k-widget.k-tooltip.warning_tooltip .cw_asset_tooltip .cw_severity .glyphicons {
  border: none;
}
.cw_assethealth_module a.k-icon.k-i-close {
  display: none;
}
.cw_assetdetails_settings,
.cw_assetdetails_data {
  position: absolute;
  top: 15px;
  bottom: 0px;
  height: auto;
  width: auto;
}
.cw_assetdetails_settings {
  left: 0;
  width: 400px;
}
.cw_assetdetails_data {
  left: 410px;
  right: 0;
}
.cw_assetgroup_details .k-splitbar-horizontal {
  height: 100% !important;
}
.cw_assetgroup_details .k-splitbar-vertical {
  width: 100% !important;
}
.cw_assetgroup_details .k-splitbar-horizontal .k-collapse-prev {
  top: 4%;
  left: -10px;
  width: 18px;
  height: 30px;
  font-size: 20px;
  background-image: none;
}
.cw_assetgroup_details .k-splitbar-horizontal .k-expand-prev {
  top: 3%;
  background-image: none;
  z-index: 100000;
  left: -15px;
  width: 35px;
  height: 23px;
  font-size: 20px;
}
.cw_assetgroup_details #cw_assets_grid .k-grid-content {
  padding-right: 0px !important;
}
.cw_assetgroup_details #cw_assets_grid .k-virtual-scrollable-wrap {
  overflow: scroll;
}
.cw_assetgroup_details #cw_assets_grid .k-scrollbar-vertical {
  overflow: hidden;
  display: none;
}
.cw_status_widget {
  position: relative;
}
.cw_status_widget .glyphicons {
  border-radius: 20px;
  margin-left: 1px;
  margin-top: 0;
}
.cw_status_widget.critical .glyphicons,
.cw_status_widget.critical .glyphicons:hover {
  color: #e14b32;
  background-color: #fff;
}
.cw_status_widget.major .glyphicons,
.cw_status_widget.major .glyphicons:hover {
  color: #f1ae31;
  background-color: #fff;
}
.cw_status_widget.minor .glyphicons,
.cw_status_widget.minor .glyphicons:hover {
  color: #f7e400;
  background-color: #fff;
}
.cw_status_widget.ok .glyphicons,
.cw_status_widget.ok .glyphicons:hover {
  color: #7cc623;
  background-color: #fff;
}
#cw_incident_widget .progress {
  height: 30px;
  margin-top: -30px;
  background-color: #bcbcbc;
}
.cw_status_widget_color {
  border-radius: 20px;
  text-align: center;
  line-height: 16px;
}
.cw_status_widget_color.cw_color1 .glyphicons {
  color: #e14b32;
}
.cw_status_widget_color.cw_color2 .glyphicons {
  color: #f1ae31;
}
.cw_status_widget_color.cw_color3 .glyphicons {
  color: #f7e400;
}
.cw_status_widget_color.cw_color5 .glyphicons {
  color: #7cc623;
}
.k-animation-container .cw_severity {
  float: left;
  margin-right: 5px;
}
.cw_severity {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-top: 3px;
  margin: 3px auto 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4);
}
.cw_severity_none {
  background: #7cc623;
}
.cw_severity_minor {
  background: #f7e400;
}
.cw_severity_major {
  background: #f1ae31;
}
.cw_severity_critical {
  background: #e14b32;
}
#cw_service_health_tabs li {
  margin-right: 4px;
  font-size: 13px;
  font-weight: 700;
}
.cw_table_header_assetmonitor {
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  width: auto;
}
.cw_reasons {
  position: relative;
  padding: 15px;
  width: 100%;
  height: 100%;
}
.cw_reason_asset {
  float: left;
  width: 100%;
  height: 30px;
}
.cw_health_summary_list .cw_grid_link {
  display: table-cell;
}
.cw_assethealth_details {
  position: absolute;
  left: 0;
}
.cw_assethealth_details .cw_section_content {
  /*.absolute; top:42px; overflow-y:auto;*/
  padding: 15px;
}
.cw_healthtab_container .cw_tier_top,
.cw_summary_container .cw_tier_top,
.cw_inventory_container .cw_tier_top {
  margin-bottom: 1%;
  overflow-y: auto;
}
.cw_healthtab_container .cw_tier_bottom,
.cw_summary_container .cw_tier_bottom,
.cw_inventory_container .cw_tier_bottom {
  bottom: 0;
}
.cw_healthtab_container .cw_tier_top,
.cw_summary_container .cw_tier_top,
.cw_inventory_container .cw_tier_top {
  height: 380px;
}
.cw_healthtab_container .cw_tier_bottom,
.cw_summary_container .cw_tier_bottom,
.cw_inventory_container .cw_tier_bottom {
  top: 380px;
}
.cw_healthtab_container .cw_tier_top,
.cw_summary_container .cw_tier_top,
.cw_inventory_container .cw_tier_top,
.cw_healthtab_container .cw_tier_bottom,
.cw_summary_container .cw_tier_bottom,
.cw_inventory_container .cw_tier_bottom {
  position: absolute;
  left: 0;
  right: 0;
}
.cw_assetdetails .cw_list_head,
.cw_assetdetails .cw_list_item {
  float: left;
  width: 50% !important;
}
.cw_assetdetails .cw_list_head,
.cw_assetdetails .cw_list_item {
  float: left;
  width: 50% !important;
}
.cw_assetdetails .cw_full_row .cw_list_head,
.cw_assetdetails .cw_full_row .cw_list_item {
  float: left;
  width: 100%;
}
.cw_assetdetails .cw_status_indicator .glyphicons {
  padding: 0px;
  background-color: inherit;
}
.cw_aagent {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  line-height: 1;
}
.cw_aagent .glyphicons {
  margin: 0 5px 0 10px;
}
.cw_aagent .label {
  position: relative;
  bottom: 4px;
  padding-left: 4px;
  max-width: 70%;
  color: #709bba;
  font-weight: 700;
}
.cw_aagent .cw_status_indicator {
  margin: 0 4px;
}
.cw_aagent .cw_agent_indicator {
  float: none;
  display: inline-block;
  width: 16px;
  height: 16px;
}
.cw_list_item #asset_type.label {
  position: relative;
  bottom: 4px;
}
.cw_list_item #cw_agent_name.label {
  position: relative;
  bottom: 4px;
}
.cw_asset_states {
  position: absolute;
  width: 34%;
  right: 0;
  overflow: hidden;
}
.cw_asset_states .cw_section_content {
  text-align: center;
}
.cw_reasons_history {
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
#cw_reasons_chart {
  height: 340px;
}
.cw_reasons_datetime {
  float: left;
  width: 100%;
  max-width: 300px;
  padding: 0;
}
.cw_health_reasons_list .cw_service_indicator {
  float: none;
  display: block;
  margin: 0 auto;
}
.cw_health_reasons_list .cw_service_indicator.glyphicons {
  font-size: 16px !important;
}
.k-filter-menu {
  min-width: 215px;
}
/* Summary Tab */
.cw_assethealth_module .cw_tabs_absolute {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.cw_assetdetails_reasons {
  border-top: 1px solid #d8d8d8;
  border-radius: 0;
  box-shadow: none;
}
.cw_asset_details_inventory_products {
  border-top: 1px solid #d8d8d8;
  border-radius: 0;
  box-shadow: none;
}
.cw_assetdetails_reasons .in_incident td {
  background-color: #e5e5e5;
}
.cw_assethealth_module .cw_state_widget {
  padding: 0 5px;
}
.cw_assethealth_module .cw_state_widget:first-child {
  padding: 0 5px 0 10px;
}
.cw_assethealth_module .cw_state_widget:last-child {
  padding: 0 10px 0 5px;
}
.cw_assethealth_module .cw_indicator.glyphicons.wrench {
  color: #fff;
  border-radius: 20px;
  line-height: 14px;
}
/* Health Tab */
.cw_healthhistory_chart {
  box-shadow: none;
}
.cw_healthhistory_chart .k-dropdown {
  margin-left: 10px;
}
.cw_healthhistory_chart .calendar {
  margin: 5px 10px 0 10px;
  font-size: 22px;
}
.cw_history_filter_container {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 10;
}
#cw_all_reasons_grid .glyphicons,
#cw_assetdetails_reasons_list .glyphicons {
  font-size: 16px;
}
#historic_filters_enabled {
  width: 140px;
  float: right;
  margin-left: 14px;
  margin-top: -4px;
}
.cw_monitorwidgets_wrapper .cw_state_widget {
  width: 25%;
}
.cw_monitorwidgets_wrapper .glyphicons.exclamation-sign {
  color: #fff;
  left: 0;
  right: 100%;
  width: 30px;
  font-size: 23px;
}
/* Metrics */
.cw_metrics_range {
  float: left;
  position: relative;
}
.cw_metrics_range .range {
  float: left;
  line-height: 30px;
  margin-left: 50px;
}
#cw_metrics_timerange {
  float: left;
  margin-left: 15px;
  margin-top: 3px;
}
#cw_metrics_timerange li {
  line-height: 1;
  padding: 0.4em;
}
#cw_custom_cw_metrics_timerange {
  width: 400px;
  position: absolute;
  top: 26px;
  right: 10px;
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #ccc;
  border-radius: 3px 0 3px 3px;
}
#cw_custom_cw_metrics_timerange button {
  margin-right: -3px;
  margin-top: -2px;
}
/* Event Log */
.cw_eventlog_main .cw_section_content {
  /*.absolute; top:42px;*/
}
.cw_eventlog_main .plus-sign {
  font-size: 20px;
  margin: 5px 0 0 0;
}
.cw_health_severity .cw_inline_full {
  border-bottom: 1px solid #d8d8d8;
}
.cw_health_severity .cw_inline_full span {
  text-indent: 10px;
  font-weight: bold;
}
.cw_severity_wrapper {
  float: left;
  width: 100%;
  padding: 5px;
  border: 1px solid #d8d8d8;
  border-width: 0 1px;
}
.cw_eventlog_filters {
  position: absolute;
  top: 340px;
  right: 15px;
  bottom: 15px;
  left: 15px;
}
.cw_eventlog_filters .cw_section {
  height: auto;
}
.cw_eventlog_filters .cw_eventlog_filterexclude {
  height: calc(50% - 5px);
}
.cw_eventlog_filters .cw_eventlog_filterinclude {
  height: calc(50% - 5px);
}
.cw_event_type_list {
  float: left;
  width: 100%;
  padding: 10px;
  min-height: 120px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  position: relative;
}
.cw_event_type_list .item {
  float: left;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 0;
}
.cw_event_type_list .item:last-child {
  border-bottom: none;
}
.cw_event_type_list .cw_remove_event_type {
  float: right;
}
.cw_event_type_list .cw_event_type_value {
  float: left;
  display: block;
  width: 138px;
  font-weight: 700;
}
.cw_health_impact_value {
  display: inline-block;
  min-width: 80px;
  height: 20px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  text-align: center;
  border-radius: 3px;
}
.cw_health_impact_value.critical {
  background-color: #e14b32;
}
.cw_health_impact_value.major {
  background-color: #f1ae31;
}
.cw_health_impact_value.minor {
  background-color: #f7e400;
}
.cw_health_impact_value.ok {
  background-color: #7cc623;
}
#cw_add_event_type.is_disabled {
  opacity: 0.5;
  cursor: initial !important;
}
.cw_healthimpact_penalty {
  padding-right: 35px;
}
.cw_healthimpact_penalty .cw_health_impact.k-dropdown {
  width: 100%;
}
.cw_healthimpact_penalty .plus-sign {
  float: right !important;
  margin-right: -30px;
}
.k-widget.cw_eventlog_exp_time,
.k-widget.cw_eventlog_suppression_time {
  width: 120px;
}
.k-widget.cw_eventlog_suppression_time {
  width: 120px;
}
.cw_scheduler_status.active_include {
  border-radius: 4px;
  background-color: #3174ad;
  box-sizing: border-box;
  color: #fff;
  padding: 6px 10px;
  font-size: 11px;
  margin-left: 20px;
  border: 1px #3174ad solid;
  text-shadow: none;
}
.cw_scheduler_status.active_exclude {
  border-radius: 4px;
  background-color: #e45f48;
  box-sizing: border-box;
  color: #fff;
  padding: 6px 10px;
  font-size: 11px;
  margin-left: 20px;
  border: 1px #e45f48 solid;
  text-shadow: none;
}
.cw_scheduler_status.inactive {
  border-radius: 4px;
  background-color: #fcf8e3;
  box-sizing: border-box;
  color: #af9512;
  padding: 6px 10px;
  font-size: 11px;
  margin-left: 20px;
  border: 1px #fbf1d8 solid;
}
/* Asset Console */
.cw_assetconsole_details {
  cursor: pointer !important;
  position: absolute;
  display: inline-block;
  padding: 1px;
  font-size: 16px;
  color: #888;
  z-index: 5;
  right: 0;
  top: 0;
}
.cw_assetconsole_content .cw_list {
  float: left;
}
.cw_assetconsole_content .cw_list .value {
  float: left;
  font-weight: 700;
}
.cw_assetconsole_content .cw_list_item,
.cw_assetconsole_content .cw_list_head {
  text-align: left;
  padding: 7px;
}
.cw_assetconsole_content .cw_list_row .cw_list_head:first-child,
.cw_assetconsole_content .cw_list_row .cw_list_item:first-child {
  width: 40%;
}
.cw_assetconsole_content .cw_list_row .cw_list_head:last-child,
.cw_assetconsole_content .cw_list_row .cw_list_item:last-child {
  width: 60%;
}
.cw_assetconsole_content {
  float: left;
  width: 100%;
  padding: 20px;
}
.cw_assetconsole_content .cw_assetinfo {
  float: left;
}
.cw_assetconsole_content .cw_assetinfo .label {
  padding-right: 10px;
}
.cw_assetconsole_content .cw_assetinfo.left {
  padding: 20px 0 20px 19px;
  margin: -20px 0 0 -20px;
  border: 1px solid #f2f2f2;
  border-left: none;
  border-top: none;
}
.cw_assetconsole_content .cw_assetinfo.right {
  padding: 20px 20px 20px 0;
  margin: -20px -20px 0 0;
  border-bottom: 1px solid #f2f2f2;
}
.cw_sparklines {
  float: left;
  width: 100%;
  padding-left: 20px;
}
.cw_one_column .cw_sparklines {
  padding-left: 0;
}
.cw_sparkline {
  float: left;
  width: 100%;
}
.cw_one_column .cw_sparkline {
  float: left;
  width: 33%;
  background-color: #00aeef;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  height: 70px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
}
.cw_memory_usage {
  margin: 0 0.5%;
}
.cw_one_column .cw_assetinfo {
  width: 100%;
}
.cw_assetconsole_content .cw_chart_content {
  float: left;
  width: 60%;
}
.cw_assetconsole_content .cw_chart_title {
  float: left;
  width: 20%;
  margin-top: 20px;
}
.cw_assetconsole_content .cw_chart_current {
  float: left;
  width: 20%;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 700;
}
.cw_one_column .cw_chart_title {
  margin-top: 10px;
}
.cw_one_column .cw_chart_current {
  margin-top: 10px;
}
.cw_one_column.cw_assetconsole_content .cw_chart_title,
.cw_one_column.cw_assetconsole_content .cw_chart_current {
  width: 100%;
}
.cw_asset_console_disk {
  float: left;
  width: 100%;
  height: 215px;
  overflow-x: auto;
}
.cw_asset_console_disk .cw_piechart_wrapper {
  float: left;
}
.cw_asset_console_disk .cw_piechart {
  float: left;
  width: 220px;
  padding: 10px;
  min-height: 50px;
}
/* New Asset Console */
#modal #cw_console_widget {
  padding-top: 0;
}
.cw_piechart_wrapper {
  float: left;
  width: 100%;
}
.cw_diskchart {
  float: left;
  width: 100%;
  padding: 5px;
  border: 1px solid #d8d8d8;
  margin-bottom: -1px;
}
.cw_diskchart .cw_progress {
  float: left;
  width: 40%;
  height: 20px;
  margin-top: 3px;
}
.cw_diskchart .cw_progress_slice {
  background-color: #00aeef;
  height: 14px;
}
.cw_diskchart_label,
.cw_diskchart_info {
  float: left;
}
.cw_diskchart_label {
  width: 30%;
}
.cw_diskchart_label .label {
  float: left;
  clear: left;
}
.cw_diskchart_info {
  width: 30%;
  text-indent: 20px;
  height: 30px;
  line-height: 30px;
}
/* Asset Details */
#cw_asset_details_form .cw_indicator.status_icon.exclamation-mark {
  width: 16px;
  height: 16px;
}
/* Asset Groups */
.cw_asset_groups_list_window {
  margin: 15px;
}
.cw_asset_groups_list_window .k-listview {
  float: left;
  width: 100%;
  height: 416px;
  overflow-y: auto;
}
.cw_asset_groups_list_window .item,
.cw_ag_name {
  float: left;
  width: 100%;
}
.cw_asset_groups_list_window .item {
  border-bottom: 1px solid #d8d8d8;
}
.cw_ag_name {
  padding: 5px 10px;
  cursor: pointer !important;
}
.cw_search_container {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.cw_search_container .cw_search_box {
  width: 100%;
}
/* Monitors */
/* External Monitor */
.cw_asset_list {
  position: absolute;
  top: 160px;
  left: 20px;
  right: 20px;
  bottom: 70px;
}
/* Nagios */
.cw_resource_pools {
  border: none;
}
.cw_resource_pools li {
  padding: 10px 20px;
  border-bottom: 1px solid #d8d8d8;
}
/* Merged assets */
.cw_asset_merge_container {
  padding: 10px;
}
.cw_asset_merge_container .cw_selected_assets,
.cw_asset_merge_container .cw_all_assets {
  top: 50px;
  bottom: 65px;
}
.cw_asset_merge_container #cw_selected_asset_count,
.cw_asset_merge_container #cw_all_asset_count {
  top: 5px;
}
.cw_asset_merge_container > .cw_field {
  width: 360px !important;
}
#cw_asset_details_form {
  height: auto !important;
}
#cw_reasons_play_events {
  margin-right: 16px;
}
/* Asset Group Form */
.create_asset_group .form {
  padding: 0;
}
.create_asset_group .cw_tier_top {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  padding: 20px;
  height: 230px;
  background-color: #f5f5f5;
}
.create_asset_group .cw_tier_bottom {
  position: absolute;
  top: 230px;
  right: 0;
  bottom: 53px;
  left: 0;
  border-top: 1px solid #d8d8d8;
}
.create_asset_group .k-tabstrip .k-item {
  background: none;
  border: none;
}
.create_asset_group .cw_tabs_absolute .k-tabstrip-items {
  background: #fff;
}
.create_asset_group .k-content {
  border: none;
}
.cw_section_actions .cw_search_container {
  padding-right: 10px;
}
.cw_form_tabs > .k-content {
  padding: 15px;
}
.cw_selected_assets,
.cw_all_assets {
  position: absolute;
  top: 10px;
  bottom: 10px;
  width: auto;
  height: auto;
  border: 1px solid #d8d8d8;
  box-shadow: none;
}
.cw_selected_assets {
  left: 10px;
  right: 55%;
  border-radius: 3px;
}
.cw_all_assets {
  left: 55%;
  right: 10px;
  border-radius: 3px;
}
.asset_group_actions {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #e0e0e0;
  width: 53px;
  font-size: 53px;
  transform: translate(-50%, -50%);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d8d8d8;
  text-shadow: 0 2px 0 rgba(132, 132, 132, 0.6);
}
#cw_remove_selected.is_disabled,
#cw_move_asset.is_disabled {
  opacity: 0.6;
}
.cw_group_tags {
  height: 30px;
}
#cw_selected_asset_count,
#cw_all_asset_count {
  position: absolute;
  right: 175px;
  font-weight: normal;
}
@-moz-document url-prefix() {
  #cw_selected_asset_count,
  #cw_all_asset_count {
    right: 155px;
  }
}
.cw_asset_merge_container .cw_selected_assets {
  right: 10px;
}
.asset_group_wrench {
  font-size: 14px;
  margin: 1px!important;
  background-color: #C4C4C4;
}
.cw_onethird {
  float: left;
  width: 33.333%;
  padding: 0 10px;
}
.cw_onethird:first-child {
  padding: 0 10px 0 0;
}
.cw_onethird:last-child {
  padding: 0 0 0 10px;
}
.cw_filters_active {
  float: right;
  margin-top: 5px;
}
.cw_filters_active .cw_switch {
  float: right;
  margin-left: 10px;
}
#cw_accounts_menu {
  height: 15px!important;
}
.cw_inline_full span {
  text-indent: 0;
}
.cw_accounts_menu .caret {
  margin-right: 10px;
}
.cw_accounts_menu .is_selected,
.cw_accounts_menu li:hover {
  background: #cfe6f5;
  color: #000;
}
.cw_accounts_title .is_selected {
  margin-left: 5px;
}
.cw_filters_settings .k-notification {
  position: absolute;
  left: 1px;
  top: 5px;
  right: 1px;
}
#cw_select_asset .cw_dropdown_container,
#cw_select_asset_groups .cw_dropdown_container {
  padding: 0;
}
.cw_external_settings #cw_select_asset .cw_dropdown_container {
  padding-left: 130px;
}
.cw_view_settings {
  position: absolute;
  z-index: 20;
  top: 15px;
  bottom: 0;
  left: 0;
  width: 400px;
  height: auto;
}
.cw_view_settings .cw_section_title {
  width: 100%;
}
.cw_view_settings.cw_section_expanded > .cw_section_content {
  bottom: 47px;
}
.cw_view_data {
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 410px;
  right: 0;
  width: auto;
  height: auto;
}
.cw_test_results_container {
  padding: 13px;
}
.cw_test_results_container ul {
  margin-left: 9px;
}
.cw_test_results_container ul li {
  padding-top: 2px;
}
input.cw_link_elements {
  color: #709bba !important;
  cursor: pointer;
}
.monitor_grid_maintenance {
  color: #ffffff;
  padding-right: 1px;
  float: left !important;
  background-color: #c4c4c4 !important;
}
.cw_system_monitor .cw_tier_top,
.cw_system_monitor .cw_tier_bottom {
  float: left;
  position: absolute;
  width: 100%;
}
.cw_system_monitor .cw_tier_top {
  height: 310px;
  padding-bottom: 10px;
}
.cw_system_monitor .cw_tier_bottom {
  top: 320px;
  bottom: 0;
}
.cw_system_monitor .cw_side_settings {
  position: absolute;
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 0;
  width: 400px;
}
.cw_system_monitor .cw_side_settings .cw_section_content {
  position: absolute;
  top: 42px;
  bottom: 0;
  left: 0;
  right: 0;
}
.cw_system_monitor .cw_side_settings .cw_actions {
  position: absolute;
}
.cw_system_monitor .cw_data {
  position: absolute;
  left: 415px;
  top: 0;
  right: 0;
  bottom: 0;
}
.cw_system_monitor .cw_frame_top {
  position: relative;
  padding: 10px 0 0;
  border-width: 1px 0 0;
  margin: 0 0 20px;
  padding-top: 25px;
}
.cw_system_monitor .cw_separator {
  position: relative;
  margin: 0 0 20px;
  padding-top: 25px;
}
.cw_system_monitor .cw_separator_title {
  margin-top: -37px;
}
.cw_system_monitor .cw_switch_mini {
  float: right;
  margin-top: -38px;
  margin-right: 10px;
}
.cw_system_monitor .cw_switch_mini .cw_switch {
  margin: 7px 0 -7px;
}
.cw_system_monitor .cw_separator .cw_mask {
  top: 8px;
  bottom: 10px;
  background-color: #ffffff !important;
}
.cw_system_monitor .cw_separator.cw_disk_latency .cw_mask {
  width: 100%;
  left: 0;
  height: auto;
}
.cw_system_time {
  width: 50%;
  box-shadow: none;
}
.cw_system_time .cw_section_content {
  padding: 15px 20px;
}
.cw_system_module .cw_mask {
  top: 0;
  background-color: #dee8ec;
}
.cw_dropdown_container.pr40 {
  padding-right: 47px;
}
.cw_dropdown_container.pr40 .value {
  float: right;
  margin-right: -47px;
  margin-top: 3px;
  width: 35px;
  height: 20px;
  line-height: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  text-align: center;
}
.cw_dropdown_container.pr40 .k-slider {
  float: left;
}
.cw_config_sysdetails .cw_separator {
  padding: 10px;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
}
.cw_config_sysdetails .cw_separator_title {
  padding: 0 10px 5px;
  margin: -25px 0 0;
}
.cw_config_sysdetails .cw_calculations {
  margin-top: 20px;
}
.cw_merged_field {
  margin-bottom: -1px !important;
}
.cw_serviceincident_settings {
  position: relative;
}
.cw_serviceincident_settings .cw_separator_title {
  position: absolute;
  top: 11px;
  left: 10px;
}
.cw_cpu_queue,
.cw_pagefile {
  float: right !important;
}
.cw_enable_metric {
  float: right;
  margin: 5px 0 0 10px;
  min-width: 100px;
}
.cw_enable_metric .label,
.cw_enable_metric .label,
.cw_enable_metric .cw_switch,
.cw_enable_metric .cw_switch {
  float: right;
}
.cw_enable_metric .cw_switch {
  margin-left: 5px;
  margin-right: 10px;
}
.cw_system_disk .cw_separator {
  margin: 0 10px 10px 10px;
  width: calc(50% - 20px);
  float: left;
  padding: 10px 0;
  border-radius: unset;
  padding-top: 25px;
}
.cw_system_cpu .form {
  padding-bottom: 5px;
}
.cw_disk_settings {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.cw_disk_header {
  float: left;
  width: 100%;
  font-weight: 700;
}
.cw_disk_header.cw_section_titlebar {
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-bottom: none;
}
.cw_disk_header.cw_section_titlebar .cw_disk_minimize {
  float: right;
  padding: 6px 0;
  text-indent: 5px;
  margin-right: 5px;
  cursor: pointer !important;
}
.cw_disk_drive {
  float: left;
  margin: 5px 0 5px 20px;
}
.cw_disk_switch {
  float: right;
  margin: 5px 20px 5px 0;
}
.cw_disk_default {
  float: left;
  width: 100%;
  padding: 5px 20px;
}
.cw_disk_default .info {
  float: none;
  padding: 10px;
  background-color: #cfe6f5;
  text-shadow: 0 1px 0 #fff;
  border: 1px solid #00aeef;
  border-radius: 3px;
}
.cw_disk_content .form {
  padding: 15px 10px 10px;
}
.cw_disk_content {
  float: left;
  border: 1px solid #d8d8d8;
}
.cw_disks_enabled {
  width: 100%;
  padding: 15px 20px;
}
.cw_disks_enabled .cw_disk_list.k-widget {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
  border: 1px solid #d8d8d8;
}
.cw_disks_enabled .cw_filter_reset {
  margin-right: 0;
}
.cw_disks_enabled .cw_actions_trigger {
  margin: 5px 10px 0 0;
}
.cw_disks_enabled .cw_disks_title {
  float: left;
  font-weight: 700;
  margin-top: 10px;
}
.cw_disks_enabled .cw_filter_wrapper {
  padding-right: 0;
}
.cw_disks_enabled .cw_text_label {
  float: left;
  margin-right: 10px;
}
.cw_disks_enabled .cw_actions_menu {
  left: 0;
}
.cw_disks_enabled .k-grid-content {
  max-height: 300px;
}
.cw_disks_enabled #cw_disk_default_container {
  border: 1px solid #d8d8d8;
}
.cw_disks_enabled #cw_disk_custom_container {
  border: 1px solid #d8d8d8;
}
.cw_data .cw_box31,
.cw_data .cw_box32_2 {
  bottom: 0;
}
.cw_system_disk .cw_section_content,
.cw_system_memory .cw_section_content {
  position: absolute;
  bottom: 0;
  height: auto;
  top: 42px;
  overflow-y: auto;
}
.cw_system_cpu,
.cw_system_memory {
  float: left;
  width: 50% !important;
}
/*.cw_system_cpu, .cw_system_memory { .absolute; left:0; right:0; height:auto; }
.cw_system_cpu { top:0; height:390px; }
.cw_system_memory { top:400px; bottom:0 }
.cw_system_cpu.advanced_mode { height:500px; }
.cw_system_memory.advanced_mode { top:515px; }*/
.cw_dynamic_memory {
  margin-top: -10px;
  padding-bottom: 10px;
}
.cw_system_cpu.advanced_mode .cw_separator.cw_cpu_queue {
  margin-top: 15px;
}
.cw_enable_monitoring {
  float: right;
  margin-top: 5px;
}
.cw_enable_monitoring .cw_text_label {
  float: left;
  margin-right: 10px;
}
.cw_enable_monitoring #cw_unknown_instances.cw_switch {
  float: left;
}
#cw_disk_default_container .cw_enable_monitoring .cw_text_label {
  font-size: 12px;
}
.cw_servicesm_settings {
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 0;
  width: 400px;
}
.cw_servicesm_settings .form {
  padding-bottom: 0;
}
.cw_services_details {
  height: 340px;
}
.cw_services_groups {
  position: absolute;
  top: 10px;
  bottom: 110px;
  right: 0;
  left: 410px;
  width: auto;
  height: auto;
}
.cw_services_groups.is_shrunken {
  bottom: auto;
  height: 230px;
}
.cw_services_groups > .cw_section_content {
  position: absolute;
  top: 42px;
  bottom: 0;
  overflow-y: auto;
}
.cw_services_groups .cw_actions_menu {
  width: 300px;
}
.cw_services_groups .cw_service_state {
  float: left;
}
.cw_services_groups .cw_section_title {
  padding-right: 25px;
}
.cw_services_groups .k-timepicker {
  margin-left: 15px;
}
.cw_service_group .cw_service_group_remove {
  padding: 8px 0!important;
}
.cw_service_state label {
  padding-right: 15px;
}
#cw_groups_panel #cw_expand {
  position: relative;
  top: 22px;
  left: 7px;
  z-index: 100;
  height: 16px;
  width: 16px;
  padding: 2px;
}
#cw_groups_panel .k-scheduler {
  margin-left: 30px;
}
#cw_groups_panel .k-scheduler-table .k-today {
  background-color: #fff;
  border-color: #e8e8e8;
}
#cw_groups_panel .k-scheduler-table tr[role=row]:hover td {
  background-color: #e4f1f9;
}
.cw_services_groups #cw_expand {
  position: relative;
  top: 22px;
  left: 7px;
  z-index: 100;
  height: 16px;
  width: 16px;
  padding: 2px;
}
.cw_services_groups .k-scheduler {
  margin-left: 30px;
}
.cw_services_groups .k-scheduler-table .k-today {
  background-color: #fff;
  border-color: #e8e8e8;
}
.cw_services_groups .k-scheduler-table tr[role=row]:hover td {
  background-color: #e4f1f9;
}
.cw_buttons_group {
  float: left;
  width: 100%;
  padding: 10px 5px 15px;
  text-align: center;
}
.cw_buttons_group .k-button {
  display: inline-block;
  width: 48%;
  padding: 15px 0;
  text-align: center;
}
.cw_buttons_group .label {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.cw_buttons_group .info {
  float: left;
  width: 100%;
}
.cw_servicesm_data {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 410px;
  width: auto;
  height: 100px;
}
.cw_servicesm_data.is_expanded {
  top: 250px;
  height: auto;
}
.cw_servicesm_data .cw_section_titlebar:hover {
  background-color: #cfe6f5;
}
.cw_servicesm_data .cw_section {
  bottom: 0;
}
.cw_servicesm_data button.expand {
  float: right;
  padding: 2px;
  z-index: 100;
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-right: 10px;
}
.cw_group_name.cw_sliding_panel {
  left: 50%;
  width: 500px;
  margin-left: -250px;
}
.cw_group_name .cw_inline {
  width: 100%;
  padding-left: 10px;
}
p.force_status {
  margin: 5px 32px -10px;
  font-weight: bold;
}
/* Custom Button Group */
.is_shrunken .cw_buttons_group_custom .cw_side {
  border-bottom: none;
}
.cw_buttons_group_custom {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 188px;
  padding: 0;
}
.cw_buttons_group_custom .cw_side {
  position: relative;
  float: left;
  width: 50% !important;
  padding: 120px 10px 20px;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
}
.cw_buttons_group_custom .cw_side:hover {
  cursor: pointer !important;
  background-color: #cfe6f5;
}
.cw_buttons_group_custom .cw_side.left {
  border-right: 1px solid #d8d8d8;
}
.cw_buttons_group_custom .k-button {
  padding: 5px;
}
.cw_buttons_group_custom .glyphicons {
  position: absolute;
  left: 50%;
  top: 40px;
  width: 64px;
  height: 64px;
  margin-left: -32px;
  font-size: 64px;
  color: #ccc;
  transition: color 0.6s, margin 0.4s;
}
.cw_buttons_group_custom .cw_side.left .is_third {
  left: auto;
  right: 50%;
  margin-right: -32px;
  margin-left: auto;
}
.cw_buttons_group_custom .cw_side.left:hover .is_first {
  margin-left: -96px;
  color: #fff;
  transform: scale(0.7, 0.7);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.cw_buttons_group_custom .cw_side.left:hover .is_second {
  margin-top: -20px;
  color: #257cba;
}
.cw_buttons_group_custom .cw_side.left:hover .glyphicons.is_third {
  right: 50%;
  margin-right: -96px;
  margin-left: auto;
  color: #fff;
  transform: scale(0.8, 0.8);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.cw_buttons_group_custom .cw_side.right .is_first {
  font-size: 20px;
  margin-left: -46px;
  color: transparent;
}
.cw_buttons_group_custom .cw_side.right .is_third {
  left: auto;
  right: 50%;
  margin-right: -32px;
  margin-left: auto;
  font-size: 36px;
  color: transparent;
}
.cw_buttons_group_custom .cw_side.right:hover .is_first {
  color: #fff;
  margin-left: -72px;
  margin-top: 39px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.cw_buttons_group_custom .cw_side.right:hover .is_second {
  color: #257cba;
}
.cw_buttons_group_custom .cw_side.right:hover .is_third {
  color: #fff;
  margin-right: -81px;
  margin-top: -8px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
/* Service Indicator */
.cw_service_indicator {
  float: left;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #fff;
  border-radius: 20px;
  text-align: center;
  line-height: 16px;
}
.cw_service_indicator.cw_color1 .glyphicons {
  color: #e14b32;
}
.cw_service_indicator.cw_color2 .glyphicons {
  color: #f1ae31;
}
.cw_service_indicator.cw_color3 .glyphicons {
  color: #f7e400;
}
.cw_service_indicator.cw_color5 .glyphicons {
  color: #7cc623;
}
.cw_service_indicator.is_critical {
  background-color: #e14b32;
}
.cw_service_indicator.is_major {
  background-color: #f1ae31;
}
.cw_service_indicator.is_minor {
  background-color: #f7e400;
}
.cw_service_indicator.is_idle {
  background-color: #c4c4c4;
  color: #fff;
  font-size: 14px;
}
.cw_service_indicator.is_ok {
  background-color: #7cc623;
}
.cw_service_indicator .glyphicons {
  float: left;
  color: #fff;
  font-size: 16px !important;
  margin: 0px !important;
}
.cw_service_details_module .cw_service_indicator.glyphicons {
  float: left;
  color: #fff;
  font-size: 16px !important;
  margin: 0px !important;
}
.cw_summary_data .cw_service_indicator {
  /*float:none; .inline_block*/
  float: none;
  display: block;
  margin: 0 auto;
}
.js_service_status_indicator .cw_service_indicator {
  margin: 6px -5px 0 10px;
  text-indent: 0;
}
.js_service_status_indicator .glyphicons {
  text-shadow: none;
}
.cw_fragment_nav {
  float: left;
  display: inline-flex;
  width: 100%;
  min-height: 18px;
  height: 37px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  z-index: 50;
  background: #fff;
  padding-right: 30px;
}
.cw_fragment_nav.expanded {
  height: auto;
  max-height: 350px;
  overflow: auto;
}
.cw_fragment_nav span.cw_item {
  height: 30px;
}
.cw_fragment_nav .is_sortable {
  cursor: default;
}
.cw_fragment_nav .cw_item,
.cw_fragment_clone {
  float: left;
  padding: 5px 10px;
  margin: 3px 5px 3px 3px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-radius: 3px;
  list-style: none;
}
.cw_fragment_nav .is_selected {
  background-color: #cfe6f5;
  border-color: #7bbae3;
  text-shadow: 0 1px 0 #fff;
}
.cw_fragment_nav .bin {
  margin: 2px 0 0 10px;
  cursor: pointer !important;
}
.cw_fragment_name {
  cursor: default;
}
.cw_fragments ul.cw_fragments_list {
  float: left;
}
.cw_fragments button.expand {
  right: 10px;
  top: 10px;
  width: 16px;
  padding: 2px;
  height: 16px;
  position: absolute;
}
.cw_fragment_content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*overflow:hidden;*/
}
#cw_fragment_add {
  cursor: pointer;
}
.window_area.has_fragments .cw_fragment_content {
  top: 30px;
}
.cw_group_active {
  float: right;
  margin: 5px 10px;
}
.cw_service_group .cw_section_titlebar .cw_service_penalty {
  float: right;
}
.cw_fragment_clone {
  background-color: #343f49;
  color: #fff;
  border-color: #fff;
}
.cw_fragment_clone .bin {
  display: none !important;
}
.cw_box31.cw_hyperv_settings {
  right: auto;
  width: 400px;
  top: 15px;
  bottom: 0;
}
.cw_box32_2.cw_hyperv_data {
  left: 410px;
  top: 15px;
  bottom: 0;
}
.cw_hyperv_settings .k-widget.k-multiselect {
  max-height: 60px;
}
.cw_resource_pools tr td {
  padding-right: 120px;
}
.cw_resource_pools .item {
  float: left;
  width: 100%;
}
.cw_resource_pools .item:hover {
  background-color: #cfe6f5;
}
.cw_resource_pools .cw_label {
  float: right;
  margin-right: -115px;
}
.cw_element_name {
  float: left;
  padding-left: 10px;
}
.cw_element_name.cw_hyperv_host {
  font-weight: 700;
}
.cw_resource_pools_item {
  float: left;
  padding-left: 20px;
}
.cw_resource_pools_item.cw_hyperv_host {
  padding-left: 0;
}
.cw_select_allresources {
  float: right;
  margin: 5px 10px 0 0;
}
.cw_hyperv_data > .cw_section_content {
  position: absolute;
  top: 42px;
  overflow-y: auto;
}
.cw_hyper_enable {
  float: right;
  margin: 3px 10px 0 0;
}
.cw_hyper_enable label {
  margin-right: 10px;
}
.cw_view_data .cw_section.cw_hyperv_settings .form {
  padding-top: 0px;
}
.cw_hyperv_settings .cw_separator .left.w50 {
  padding-top: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.cw_hyperv_settings .cw_separator .right.w50 {
  padding-top: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}
.cw_hyperv_config .left.w50 .form {
  padding-right: 10px;
}
.cw_hyperv_config .right.w50 .form {
  padding-left: 10px;
}
.cw_hyperv_settings .cw_separator .cw_switch_mini {
  margin-top: -17px;
  float: right;
}
.cw_hyperv_config .cw_separator {
  position: relative;
}
.cw_hyperv_config .cw_separator .cw_mask {
  background-color: #fff;
  top: 8px;
}
.cw_hyperv_data .cw_preloader .spinner {
  top: 50%;
}
.cw_process_settings {
  position: absolute;
  top: 15px;
  left: 0;
  bottom: 0px;
  width: 400px;
}
.cw_process_settings .form {
  padding-bottom: 0;
}
.cw_process_data {
  position: absolute;
  top: 15px;
  right: 0;
  bottom: 0px;
  left: 410px;
  width: auto;
  height: auto;
  overflow: hidden;
}
.cw_process_data .cw_box31 .cw_sliding_panel {
  top: 140px;
}
.cw_group_name .cw_inline {
  width: 100%;
  padding-left: 10px;
}
.cw_process_details {
  /*height:425px;*/
}
.cw_process_details textarea {
  height: 47px;
}
.cw_process_groups {
  overflow: auto;
  box-shadow: none;
}
.cw_process_groups .cw_inline_full,
.cw_process_groups .cw_section_titlebar {
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
}
.cw_process_groups .cw_section_titlebar {
  border-bottom: none;
}
.cw_process_groups > .cw_section_content {
  position: absolute;
  top: 42px;
  bottom: 0;
  overflow-y: auto;
}
.cw_section.cw_process_groups > .cw_section_titlebar {
  border-top: 1px solid #fff;
  background-color: #fbfbfb;
  border-bottom: 1px solid #eee;
  border-left: none;
  border-right: none;
}
/*Processes*/
.cw_sliding_panel {
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}
.cw_sliding_panel.cw_group_assign {
  position: absolute;
  width: 400px;
  top: 35px;
  left: 25%;
  margin-left: -200px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}
.cw_sliding_panel button {
  margin: 10px 0 0 10px;
}
.cw_section.cw_service_groups,
.cw_section.cw_monitored_services {
  top: 180px;
}
.cw_monitored_services.cw_monitor_new {
  top: 0;
}
.cw_process_group {
  /*min-height:100px;*/
  margin-bottom: 10px;
  border-color: #afd9ee #afd9ee #9acfea;
  /*max-height: 350px;*/
}
.cw_process_group .k-dropdown {
  margin-right: 10px;
}
.cw_process_group .k-listview {
  border: none;
}
.cw_process_group .glyphicons {
  float: right;
  padding: 6px 0;
  text-indent: 5px;
  margin-right: 5px;
  cursor: pointer !important;
}
.cw_service_group {
  min-height: 32px;
  margin-bottom: 10px;
  border: 1px solid #9acfea;
  box-shadow: none;
}
.cw_service_group .cw_service_group_remove {
  float: right;
  margin-right: 30px;
  padding: 4px 0;
  cursor: pointer !important;
}
.cw_service_group .k-dropdown {
  width: 160px;
  margin-right: 14px;
}
.cw_service_group .k-listview {
  border: none;
}
.cw_service_group td .glyphicons {
  float: right;
  margin: 0 5px;
  padding: 4px 0;
  cursor: pointer !important;
}
.cw_current_group .cw_section_titlebar {
  background-color: #cfe6f5;
}
.cw_process_group .cw_section_content {
  padding: 10px;
  border: 1px solid #d8d8d8;
}
.cw_process_group .cw_section_content .cw_field {
  width: 45%!important;
}
.cw_process_group .cw_section_content .cw_field.cw_command_line_wrapper {
  width: 80%!important;
}
.cw_process_group .cw_list {
  width: 80%;
  float: left;
}
.cw_process_item {
  float: left;
  width: 100%;
  padding: 10px 0;
  text-align: left;
}
.cw_process_item .cw_process_name {
  /*.w30;*/
  text-align: left;
  text-indent: 10px;
  padding-right: 0px;
  font-weight: 700;
}
.cw_process_item .cw_service_expected_state {
  width: 60%;
  text-indent: 10px;
}
.cw_process_item .cw_process_name {
  float: left;
  display: block;
  /*height:20px; line-height: 20px*/
}
.cw_process_item .cw_process_command_line {
  float: left;
  width: 100%;
  padding: 5px 30px 5px 10px;
  margin-top: -1px;
  border: 1px solid #d8d8d8;
  word-break: break-word;
}
.cw_process_item .cw_commandline_edit {
  float: left;
  width: 100%;
  margin-top: -5px;
  position: relative;
}
.cw_process_item .cw_commandline_edit input {
  margin: 2px 0 -2px 5px;
}
.cw_process_item ul.cw_list {
  width: 80%!important;
  float: left;
}
.cw_process_item li.cw_list_row.cw_list_head,
.cw_process_group li.cw_list_row.cw_list_head {
  text-align: left;
  text-indent: 10px;
  padding-bottom: 5px;
}
.cw_process_item_content {
  border: 1px solid #d8d8d8;
  width: 100%;
  float: left;
  margin-top: -1px;
  padding: 10px;
}
.cw_process_item.k-state-selected > .cw_inline_full {
  background: #cfe6f5;
}
.cw_process_item.k-state-selected {
  background-color: #fff;
  color: #333;
}
.cw_service_item {
  float: left;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  text-align: left;
}
.cw_service_item .cw_service_name {
  width: 60%;
  text-align: left;
  text-indent: 10px;
  padding-right: 10px;
  border-right: 1px solid #d8d8d8;
}
.cw_service_item .cw_service_expected_state {
  width: 30%;
  text-indent: 10px;
}
.cw_service_item .cw_service_name,
.cw_service_item .cw_expected_state_dropdown {
  float: left;
  display: block;
  height: 20px;
  line-height: 20px;
}
.cw_service_item .cw_service_item_edit {
  float: left;
  width: 100%;
  padding: 10px;
}
.cw_service_item .cw_service_item_edit input {
  margin: 2px 0 -2px 5px;
}
.cw_command_line {
  float: left;
  clear: left;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin-top: 5px;
  box-shadow: none;
}
textarea.cw_command_line {
  max-width: inherit;
}
.cw_penalty_label {
  float: right;
  width: 110px;
  text-align: left;
}
#cw_processes_menu .cw_actions_menu,
#cw_services_menu .cw_actions_menu {
  width: 250px;
}
.cw_process_selection {
  height: auto;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
}
.cw_process {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
}
.cw_regex_enabled {
  background-color: #cfe6f5;
  padding: 2px;
}
.cw_toggle_process_configuration {
  margin-right: 10px;
}
.cw_command_line_wrapper label {
  text-indent: 0;
}
.cw_group_edit {
  float: none!important;
}
.bluebgk {
  background-color: #cfe6f5 !important;
}
.cw_process_container {
  position: absolute;
  right: 0;
  left: 410px;
  top: 15px;
  width: auto;
  bottom: 0;
}
.cw_section.cw_process_group .cw_section_content {
  padding-left: 50px;
}
/* IBM monitor */
.cw_inline_numeric {
  width: 50px;
  margin: -5px 5px;
  text-align: center;
}
.cw_ibmconsole_content {
  float: left;
  width: 100%;
  padding: 15px;
}
.cw_ibmconsole_content .cw_assetinfo {
  float: left;
  width: 50% !important;
}
.cw_ibmconsole_content .cw_assetinfo:first-child {
  padding-right: 10px;
}
.cw_ibmconsole_content .cw_assetinfo:last-child {
  padding-left: 10px;
}
.cw_ibmconsole_content .cw_list {
  float: left;
}
.cw_ibmconsole_content .cw_list .value {
  float: left;
  font-weight: 700;
}
.cw_ibmconsole_content .cw_list_item,
.cw_ibmconsole_content .cw_list_head {
  text-align: left;
  padding: 7px;
  height: 30px;
}
.cw_ibmconsole_content .cw_list_item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_ibmconsole_content .cw_list_row .cw_list_head:first-child,
.cw_ibmconsole_content .cw_list_row .cw_list_item:first-child {
  width: 40%;
}
.cw_ibmconsole_content .cw_list_row .cw_list_head:last-child,
.cw_ibmconsole_content .cw_list_row .cw_list_item:last-child {
  width: 60%;
}
.cw_section_content .cw_ibm_eventlog_list {
  float: left;
  width: 100%;
  height: 215px;
  border: 1px solid #d8d8d8 !important;
}
.cw_vmm_data .cw_separator {
  border-width: 1px 0 0;
  border-radius: 0;
  position: relative;
}
.cw_vmm_data .left.w50 .cw_separator {
  padding: 10px 5px 0 0;
}
.cw_vmm_data .right.w50 .cw_separator {
  padding: 10px 0 0 5px;
}
.cw_vmm_data .cw_field {
  margin-bottom: 10px;
}
.cw_vmm_data .k-slider {
  float: left;
  width: 100%;
}
.cw_vmm_data .k-slider-selection {
  background-color: #00bdf2;
}
.cw_vmm_data .cw_switch_mini {
  float: right;
  margin-top: -18px;
}
.cw_vmm_data .cw_mask {
  background: #fff;
  top: 8px;
}
.cw_vmm_data .cw_separator.cw_disk_read_speed {
  margin-top: 95px;
}
.cw_vmm_enable {
  float: right;
  margin: 3px 10px 0 0;
}
.cw_vmm_enable label {
  margin-right: 10px;
}
.cw_vmm_groups .cw_resource_pools tr td {
  padding-right: 200px;
}
.cw_resource_pools .cw_vmm_badges {
  float: right;
  width: 196px;
  margin-right: -196px;
  text-align: right;
}
.cw_resource_pools .cw_vmm_path {
  display: inline-block;
}
.cw_resource_pools .cw_vm_manual {
  background-color: #555777;
}
.cw_resource_pools .cw_vm_auto {
  background-color: #666444;
}
.cw_resource_pools .cw_vm_host {
  background-color: #336699;
}
.cw_resource_pools .cw_vm_group {
  background-color: #888999;
}
.cw_vmm_host {
  /*.absolute; top:0; right:410px; left:0; width:auto; height:617px;*/
  height: auto;
}
.cw_vmm_host .cw_separator.cw_cpu {
  margin-top: 45px;
}
.cw_vmm_host .cw_disk_usage {
  margin-top: 5px;
}
.cw_vmm_host .cw_disk_latency .cw_mask {
  width: 100%;
  height: calc(100% - 12px);
  left: 0;
}
.cw_vmm_host .cw_disk_latency .cw_mask span {
  width: calc(100% - 120px);
  top: -17px;
  left: 115px;
}
.cw_disk_latency .cw_mask {
  position: absolute;
  width: 50%;
  /*background-color: rgba(255, 255, 255, 0.5) !important;*/
  left: 50%;
  height: 235px;
}
.cw_disk_latency .cw_mask span {
  top: 56px;
  position: absolute;
  left: 118px;
  background: #fff;
  padding-right: 5px;
  color: #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  width: calc(100% - 150px);
}
.cw_vmm_config {
  height: auto;
  box-shadow: none;
}
/*.cw_vmm_config { .absolute; top:0; right:0; left:auto; width:400px; height:617px; }
.cw_vmm_config .cw_section_content { height: 570px; overflow: auto; }*/
.cw_vmm_config .cw_separator {
  padding: 10px 0 0;
}
.cw_vmm_config .cw_section_titlebar {
  background-color: #fff;
  border-top: 1px solid #eee;
}
/*.cw_vmm_groups { .absolute; top:625px; bottom:0; left:0; height:auto; }*/
.cw_vmm_groups .cw_badge {
  float: right;
}
/*.cw_vmware_settings .k-button { .w100 }*/
.cw_vmware_childgroups {
  float: right;
  margin: 5px 10px 0 0;
}
.cw_vmware_data .cw_separator {
  border-width: 1px 0 0;
  border-radius: 0;
  padding: 10px 0 0;
}
.cw_vmware_data .cw_separator .p:last-child,
.cw_vmware_settings .cw_separator .p:last-child {
  margin-bottom: 0;
}
.cw_vmware_data .cw_separator .cw_separator_title {
  margin-top: -34px;
}
.cw_vmware_data .cw_separator .cw_switch_mini {
  float: right;
  margin-top: -27px;
}
.cw_vmware_data .left.w50 .cw_separator {
  padding: 20px 5px 10px 0;
  position: relative;
}
.cw_vmware_data .right.w50 .cw_separator {
  padding: 20px 5px 10px 5px;
  position: relative;
}
.cw_vmware_data .cw_field {
  margin-bottom: 10px;
}
.cw_vmware_data .k-slider {
  float: left;
  width: 100%;
}
.cw_vmware_data .k-slider-selection {
  background-color: #00bdf2;
}
.cw_vmware_data .actions {
  position: absolute;
}
.cw_vmware_data .cw_mask {
  background-color: #fff;
  top: 8px;
}
.cw_vmware_enable {
  float: right;
  margin: 3px 10px 0 0;
}
.cw_vmware_enable label {
  margin-right: 10px;
}
.cw_vmware_groups .cw_section_content {
  position: absolute;
  top: 42px;
}
.cw_box21 > .cw_section_content {
  position: absolute;
  top: 42px;
  padding-botom: 10px;
  overflow-y: auto;
}
.cw_box21.cw_vmware_host {
  bottom: 0;
}
.cw_view_settings .cw_separator {
  margin-bottom: 15px;
}
.cw_vmware_config.cw_section {
  height: auto;
}
.cw_box22.cw_vmware_groups {
  bottom: 0;
}
.cw_vmware_penalty {
  float: left;
  width: 100%;
  padding-right: 50%;
}
.cw_view_data.cw_vmware_data .form {
  padding: 0 20px;
}
#cw_vmware_tab_nav .cw_section_content .left.w50 {
  padding-right: 10px;
  padding-top: 10px;
  box-sizing: border-box;
}
#cw_vmware_tab_nav .cw_section_content .right.w50 {
  padding-left: 10px;
  padding-top: 10px;
  box-sizing: border-box;
}
.cw_vmware_host {
  padding: 0!important;
}
.cw_vmware_host .cw_section {
  box-shadow: none;
}
.cw_vmware_config {
  padding-bottom: 10px;
}
.cw_vmware_config .cw_section_titlebar {
  background-color: #fff;
  border-top: 1px solid #eee;
}
.cw_data_store {
  overflow: auto;
}
.cw_datastores {
  width: 100%;
  padding: 15px 20px;
  float: left;
}
.cw_datastores .cw_datastores_title {
  float: left;
  font-weight: 700;
  margin-top: 10px;
  margin-left: 10px;
}
.cw_datastores #cw_datastores_grid.k-widget {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
  border: 1px solid #d8d8d8;
}
.cw_datastores .cw_global_actions {
  margin-left: -15px;
}
.cw_datastores #cw_datastore_filterpanel {
  margin-right: -12px;
}
.cw_vmware_container {
  position: absolute;
  right: 0;
  left: 410px;
  top: 15px;
  width: auto;
  bottom: 0;
}
.cw_datastore_settings {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.cw_datastore_header {
  float: left;
  width: 100%;
  font-weight: 700;
}
.cw_datastore_header.cw_section_titlebar {
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-bottom: none;
}
.cw_datastore_drive {
  float: left;
  margin: 5px 0 5px 20px;
}
.cw_datastore_header.cw_section_titlebar .cw_datastore_minimize {
  float: right;
  padding: 6px 0;
  text-indent: 5px;
  margin-right: 5px;
  cursor: pointer !important;
}
.cw_data_store .form .cw_section .cw_section_content {
  float: left;
  border: 1px solid #d8d8d8;
  padding: 0 10px;
}
.cw_section.cw_data_store .cw_preloader .spinner {
  top: 45%;
  z-index: 50;
}
.cw_vmware_data .cw_separator_title.cw_severity_label {
  margin-top: -25px;
}
.cw_vmware_data .cw_disk_usage {
  position: relative;
}
.cw_vmware_data .cw_disk_usage .disks_exclude .exclude-disks-info-icon {
  font-size: 17px;
}
.cw_vmware_data .cw_disk_usage .disks_exclude .cw_switch_mini {
  margin-right: 5px;
}
.cw_vmware_data .cw_disk_usage .disks_exclude textarea {
  resize: none;
}
.cw_vmware_data .cw_disk_usage .disks_exclude .exclude-checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.cw_mg_filters {
  float: left;
  width: 100%;
  padding: 10px;
}
.cw_mg_filter {
  margin-bottom: 10px;
  border: 1px solid #d8d8d8;
}
.cw_mg_filter .cw_column {
  float: left;
  width: 50%;
  padding: 20px 10px 10px;
}
.cw_mg_filter .cw_column .p:last-child {
  margin-bottom: 0;
}
.cw_mg_filter .cw_keys_list {
  min-height: 75px;
  border-radius: 0 0 3px 3px;
  border: 1px solid #d8d8d8;
  border-width: 1px 0 0;
}
.cw_filter_keys > .cw_section {
  margin: 10px 0 0;
  border: 1px solid #d8d8d8;
}
.cw_mg_filter .cw_filter_remove {
  float: right;
  padding: 10px;
  cursor: pointer !important;
}
.cw_mg_filter .cw_filter_minimize {
  float: right;
  padding: 10px;
  cursor: pointer !important;
}
.cw_mg_filter .cw_separator .p:last-child {
  margin-bottom: 0;
}
.cw_view_data > .cw_section_content {
  position: absolute;
  top: 42px;
  overflow-y: auto;
}
.cw_mg_filters .cw_column .cw_field label:nth-child(4) {
  margin-left: 20px;
}
.cw_result_icon {
  font-size: 12px !important;
  margin-right: 20px;
  width: 0 !important;
  float: right;
}
.cw_logmonitor_profiles {
  float: left;
  width: 100%;
  padding: 10px;
}
.cw_logmonitor_profile {
  margin-bottom: 10px;
  border: 1px solid #d8d8d8;
}
.cw_logmonitor_profile .cw_separator {
  margin-top: 0;
}
.cw_logmonitor_profile .cw_top_separator {
  margin-top: 30px;
  margin-bottom: -20px;
}
.cw_section.cw_logmonitor_profile .cw_filter_remove {
  float: right;
  padding: 6px 0;
  text-indent: 5px;
  margin-right: 5px;
  cursor: pointer !important;
}
.cw_logmonitor_profiles .cw_column,
.cw_logmonitor_profiles .cw_column_with_radios {
  float: left;
  width: 50%;
  padding: 20px 10px 10px;
}
.cw_log_keys {
  float: left;
  width: 100%;
}
.cw_column_with_radios .cw_dropdown_container {
  width: 80%;
}
.cw_column_with_radios span.cw_radio {
  float: left;
  padding-top: 5px;
  margin-left: 10px;
}
.cw_mg_profiles {
  padding: 10px;
}
#cw_record_type_menu {
  height: 30px;
}
#cw_record_type_menu > .k-item {
  height: 11px;
}
#cw_record_type_menu .k-item.k-state-hover .k-link {
  background: none!important;
  box-shadow: none;
}
#cw_record_type_menu .last_btn:hover {
  background: #ebebeb;
}
#cw_record_type_menu .last_btn .k-state-active {
  background: #ebebeb;
}
#test_input_modal,
#test_output_modal {
  padding: 10px;
}
#cw_test_input #cw_fetch_selected {
  height: 40px;
  background: #cfe6f5;
  width: 100%;
  float: left;
  line-height: 40px;
  margin-bottom: 10px;
  border: 1px solid #b5d2dc;
  border-radius: 3px;
  text-indent: 10px;
  color: #257cba;
  cursor: pointer;
}
#cw_test_input textarea {
  height: 475px;
  resize: none;
}
.cw_connectivity {
  padding: 10px;
}
.cw_connectivity > .cw_field .cw_dropdown_container {
  width: 330px;
}
.cw_connectivity .cw_column {
  width: 50%;
  padding: 0px 5px 10px;
}
#cw_select_port {
  margin-bottom: 0;
}
.cw_groovy_monitor .groovy_section {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.cw_groovy_monitor .groovy_section .outer {
  width: 20%;
  height: 99%;
  padding-right: 10px;
}
.cw_groovy_monitor .groovy_section .two_column_block {
  width: 80%;
  height: 99%;
  border-top: 1px solid #fff;
  border-radius: 3px 3px 0 0;
  background-color: #fbfbfb;
  border-bottom: 1px solid #eee;
}
.cw_groovy_monitor .groovy_section .two_column_block .cw_groovy_monitor_script .cw_section_content {
  height: 96%;
}
.cw_groovy_monitor .groovy_section .two_column_block .cw_groovy_monitor_script .cw_test {
  margin-right: 10px;
}
.cw_groovy_monitor .groovy_section .two_column_block .cw_groovy_monitor_script .cw_code_input {
  height: 96%;
  margin: 10px;
}
.cw_groovy_monitor .groovy_section .two_column_block .cw_groovy_monitor_script .cw_run_script_code {
  height: 96%;
}
.cw_groovy_monitor .groovy_section .two_column_block .cw_groovy_monitor_output .cw_section_content {
  height: 96%;
}
.cw_groovy_monitor .groovy_section .two_column_block .cw_groovy_monitor_output .cw_code_output {
  height: 96%;
  margin: 10px;
}
.cw_groovy_monitor .groovy_section .two_column_block .cw_groovy_monitor_output .cw_run_script_results {
  width: 100%;
  height: 96%;
}
.cw_eventlog_schedule_box {
  width: 100%;
  margin: 15px 0px 0px 0px;
}
.cw_eventlog_schedule_box label {
  margin-right: 0px;
}
.cw_eventlog_schedule_button {
  display: inline-block;
  box-sizing: border-box;
  margin-top: 7px;
}
.cw_eventlog_schedule_button.text_right label {
  background-color: #fff;
  padding: 6px 15px;
  border-radius: 3px 0px 0px 3px;
  font-size: 13px;
  font-weight: 600;
  box-sizing: border-box;
  border: 1px #ccc solid;
  margin: 0px 0px 0px 10px;
  cursor: pointer;
}
.cw_eventlog_schedule_button.text_right label:hover {
  background-color: #cfe6f5;
}
.cw_eventlog_schedule_button.text_right label.is_selected {
  background-color: #cfe6f5;
}
.cw_eventlog_schedule_button.text_right input {
  position: absolute;
  left: -999999px;
}
.cw_eventlog_schedule_button.text_left label {
  background-color: #fff;
  padding: 6px 15px;
  border-radius: 0px 3px 3px 0px;
  font-size: 13px;
  font-weight: 600;
  box-sizing: border-box;
  border: 1px #ccc solid;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  border-left: 0px;
}
.cw_eventlog_schedule_button.text_left label:hover {
  background-color: #cfe6f5;
}
.cw_eventlog_schedule_button.text_left label.is_selected {
  background-color: #cfe6f5;
}
.cw_eventlog_schedule_button.text_left input {
  position: absolute;
  left: -999999px;
}
.cw_field.event_start_end .cw_event_start,
.cw_field.event_start_end .cw_event_end {
  width: 105px;
  box-shadow: none;
}
.cw_field.event_start_end span.k-picker-wrap.k-state-default {
  padding: 2px 0px 2px 0px;
  background-color: #fff;
}
.k-event.cw_exclude_event {
  background-color: #e45f48;
  border: 1px #e45f48 solid;
}
#cw_eventlog_monitor_details .cw_monitor_current_details.k-splitter {
  height: 100%;
}
#cw_eventlog_monitor_details .cw_monitor_current_details.k-splitter .cw_eventlog_main {
  height: 100%;
}
#cw_eventlog_monitor_details .cw_monitor_current_details.k-splitter .k-splitter .k-ghost-splitbar-horizontal,
#cw_eventlog_monitor_details .cw_monitor_current_details.k-splitter .k-splitter .k-splitbar-horizontal {
  height: 100%;
}
#cw_eventlog_monitor_details .cw_monitor_current_details.k-splitter .k-tabstrip-wrapper {
  height: 100%;
}
.cw_targettype {
  margin: 3px;
}
#cw_targettype_toggle.cw_targettype {
  margin: 0;
}
#cw_targettype_toggle.cw_targettype li {
  line-height: 24px;
}
.cw_targettype.small .item {
  padding: 4px 8px;
}
.cw_accounts_container {
  position: relative;
}
.cw_accounts_container .k-multiselect {
  min-height: 80px;
}
.cw_kpi_module .k-notification {
  position: absolute;
  left: 1px;
  top: 5px;
  right: 1px;
}
.cw_kpi_settings .k-widget.k-multiselect {
  max-height: 60px;
}
/* Incidents Form */
.cw_incident_module .cw_inline_textarea {
  height: 280px;
  z-index: 0;
  border-radius: 4px 0 0 4px;
}
.cw_incident_module .cw_inline_area {
  height: 282px;
  resize: none;
}
.cw_incident_module .expandable_area textarea {
  /* height:280px;*/
  border-radius: 0 0 3px 3px;
}
.cw_incident_module .expandable_area textarea.expanded {
  height: 450px;
}
.cw_incident_module .cw_section,
.cw_incident_module .cw_incident_data {
  top: 0px;
}
.cw_incident_settings {
  position: absolute;
  top: 15px;
  left: 0;
  bottom: 0px;
  width: 400px;
}
.cw_incident_settings.is_expanded,
.cw_incident_data.is_expanded {
  z-index: 21;
}
.cw_incident_settings.is_expanded .cw_section_content,
.cw_incident_data.is_expanded .cw_section_content {
  bottom: 47px;
}
.cw_incident_data {
  position: absolute;
  top: 15px;
  right: 0;
  bottom: 0px;
  left: 410px;
  width: auto;
  height: auto;
  overflow: hidden;
}
.cw_incident_data.is_expanded {
  z-index: 20;
}
.cw_incident_settings.is_expanded .cw_section_content {
  bottom: 0;
}
.cw_incidents_form_reasons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: auto;
}
/* Incidents Grid */
.cw_comment_wrapper {
  position: absolute;
  left: 20px;
  top: 60px;
  right: 20px;
  bottom: 70px;
}
.cw_comment_text {
  width: 100%;
  height: 100%;
  resize: none;
}
.cw_comment_wrapper textarea.cw_comment_text {
  height: 270px;
}
.remove_bottom_margin {
  margin-bottom: 0;
}
span[aria-owns="cw_incident_priority_listbox"] .k-icon.k-i-arrow-s {
  display: none;
}
span[aria-owns="cw_incident_account_listbox"] .k-icon.k-i-arrow-s {
  display: none;
}
span[aria-owns="cw_incident_type_listbox"] .k-icon.k-i-arrow-s {
  display: none;
}
#cw_report_module .cw_content_preview {
  width: auto;
  height: 300px;
}
#cw_report_module .cw_content_preview .cw_section {
  margin: 0;
  height: auto;
}
#cw_report_module .cw_content_main {
  top: 340px;
}
.cw_report .cw_separator label {
  width: auto;
  padding: 3px 0 0;
}
.cw_report .cw_separator .glyphicons {
  font-size: 22px;
  margin: 0 10px 0 0;
}
.cw_include {
  cursor: pointer !important;
  font-size: 16px;
  margin: -2px 6px 0 0;
}
.cw_true {
  color: #7cc623;
}
.pdf_preview {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cw_report_placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cw_report_placeholder .file {
  position: relative;
  top: 100px;
  color: #D9EDF7;
  text-shadow: 0 1px 0 #85c5e5;
}
.cw_report_period {
  position: absolute;
  z-index: 100;
  top: 41px;
  right: 0;
  padding: 5px;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
}
.cw_report_module .actions {
  position: absolute;
}
.cw_report_module td .cw_label {
  margin: 0 0 0 5px;
}
.cw_report_module td .cw_label:hover {
  background-color: #cfe6f5;
}
/* Report Details */
.cw_report_selection {
  height: 415px;
}
.cw_report_selection .cw_checkbox_toggle {
  float: right;
  margin: 0 10px 0 0;
  text-indent: 0;
}
.cw_report_data .k-tabstrip-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cw_report_data .k-content.k-state-active {
  padding: 0;
  overflow: hidden;
}
.cw_report_data .k-grid {
  border: none;
}
.cw_report_data .cw_preloader {
  position: relative;
  width: 100%;
  margin-top: 150px;
}
.cw_report_settings .cw_section_content,
.cw_report_preview {
  top: 0;
  bottom: 0!important;
}
.cw_report_settings .cw_section_content {
  overflow: auto;
  bottom: 0!important;
}
.cw_report_preview {
  overflow: hidden;
  height: 100%;
}
.cw_report_preview .pdf_preview {
  height: 100%;
  width: 100%;
  float: left;
}
.cw_reportsq {
  float: left;
  width: 100%;
  height: auto;
  border-radius: 0;
  border: 1px solid #d8d8d8;
}
.cw_section_expanded .cw_reportsq .cw_section_content {
  bottom: 0;
}
.cw_report_accounts_list {
  max-height: 120px;
  height: 120px;
}
.cw_report_services_list {
  max-height: 180px;
  height: 180px;
}
.cw_separator .cw_field:last-child {
  margin-bottom: 0;
}
.cw_override_cached_container {
  float: right;
  margin: 3px 25px;
}
.cw_report_generate_info_window #cw_generate_info_container {
  float: left;
  width: 10%;
  margin: 15px 0 0 30px;
}
.cw_report_generate_info_window .info-sign {
  font-size: 35px;
  color: #00bdf2;
}
.cw_report_generate_info_window #cw_generate_options {
  margin: 20px 20px 20px 0;
}
.cw_filtering_tags_tooltip {
  font-size: 16px;
}
/* Scheduler */
.cw_schedule.cw_section {
  padding-top: 0;
  margin-top: 40px;
  height: 415px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
.cw_schedule .cw_section_content {
  padding: 15px 20px;
  margin-top: 7px;
  height: 290px;
}
#cw_rule_generator {
  width: calc(50% - 20px);
  float: right;
  margin-top: 0px;
}
#cw_yearly_frequency .k-widget {
  margin-left: 5px;
}
.cw_picker {
  float: left;
  margin-left: 5px;
}
#cw_schedules {
  margin-top: 0px;
}
.cw_schedule_description textarea {
  z-index: 10;
}
/* Dynamic reports */
#cw_splitter {
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
}
#cw_report_modules .cw_separator {
  width: 97%;
}
#cw_report_modules,
#cw_report_submodule_details {
  padding: 10px;
}
#cw_report_submodule_details > .cw_separator {
  width: calc(100% - 60px);
}
.cw_report_module .cw_checkbox_toggle {
  float: right;
  text-indent: 0;
  margin: 0 10px 0 0;
}
.cw_report_module .cw_checkbox_toggle label {
  margin-right: 10px;
}
#cw_module_icons {
  margin-top: 10px;
}
#cw_module_icons li {
  padding: 5px 25px 5px 0;
  cursor: pointer !important;
  display: inline-block;
}
#cw_module_icons li .glyphicons {
  font-size: 2em;
  display: block;
  text-align: center;
  padding-bottom: 5px;
}
#cw_module_icons li .cw_module_name {
  font-size: 14px;
}
li.cw_draggable_module {
  float: left;
  width: 97%;
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin: 5px 0;
  cursor: move;
  position: relative;
}
li.cw_draggable_module .cw_module_draggable_icon {
  background: url("shared/images/app_theme/drag.png") no-repeat;
  width: 15px;
  height: 25px;
  position: absolute;
  left: 5px;
  top: 5px;
}
li.cw_draggable_module .glyphicons {
  float: right;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer !important;
}
li.cw_draggable_module h4 {
  display: inline-block;
}
li.cw_draggable_module .cw_module_icon {
  float: left;
  margin-right: 10px;
  margin-left: 20px;
}
li.cw_draggable_module ul.cw_submodules {
  margin-top: 5px;
  border-top: 1px solid #d8d8d8;
  padding-top: 5px;
}
li.cw_draggable_submodule {
  margin: 5px 0;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  padding: 5px;
  cursor: move;
  position: relative;
}
li.cw_draggable_submodule input {
  margin-left: 18px;
}
li.cw_draggable_submodule .cw_submodule_draggable_icon {
  background: url("shared/images/app_theme/drag_sub.png") no-repeat;
  width: 10px;
  height: 15px;
}
#cw_asset_metrics_search {
  position: absolute;
  z-index: 200;
  top: 2px;
  right: 40px;
  height: 25px;
}
.cw_delivery_type_selector {
  height: 70px;
}
.cw_reports_override_container {
  background-color: #D9EDF7;
  color: #247fad;
  text-shadow: 0 1px 0 #fff;
  padding: 20px;
}
#cw_report_override_label {
  float: right;
  margin-right: 35px;
  font-weight: normal;
}
.required-wildcard {
  color: #ff0000;
  float: left;
  margin: 5px 2px 0 5px;
}
.cw_deploy_report {
  float: right;
  font-size: 24px;
  margin-left: 15px;
  color: #cccccc;
}
.cw_report_schedule_delivery_types {
  float: left;
  width: 100%;
}
.cw_report_schedule_delivery_types .form-entry {
  max-width: inherit;
}
#cw_agent_details .cw_view_settings {
  position: relative!important;
  float: left;
}
.cw_agent_edit {
  top: 14px;
  width: 500px;
  margin-left: -270px;
}
.cw_agent_edit .cw_agent_form_check {
  font-size: 16px;
  margin-right: 5px;
}
.agent_form_label {
  margin: 0 -128px 0 0 !important;
  width: 126px !important;
}
.agent_form_label .cw_checkbox {
  margin-left: 140px;
}
.cw_section.cw_agent_details.cw_section_expanded {
  height: auto;
  padding-bottom: 40px;
}
.cw_section.cw_agent_details .cw_section_expanded {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}
.cw_section.cw_agent_details .cw_section_content {
  bottom: 0;
  overflow: auto;
  height: calc(100% - 48px);
}
.cw_section.cw_agent_asset {
  border-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  height: auto;
  padding-bottom: 40px;
}
#cw_agent_asset_form {
  overflow: visible;
}
.cw_agent_databaseinfo_logs .k-detail-cell tr:last-child td {
  border-bottom: none;
}
.cw_agent_databaseinfo_logs .k-detail-cell .cw_databaseinfo_inner_grid {
  border: 1px solid #d8d8d8;
  border-width: 0 1px;
}
.cw_agent_details .cw_agent_sindicator,
.cw_agent_details .cw_status_indicator {
  float: left;
  width: 16px;
  height: 16px;
  margin: 6px 0 0 6px;
}
.cw_agent_sindicator .cw_agent_indicator {
  float: left;
}
.cw_agent_sindicator .cw_agent_indicator .status_icon {
  margin: 1px;
}
.cw_agent_sindicator .cw_agent_indicator .cw_indicator.glyphicons.status_icon.exclamation-mark {
  margin: 1px!important;
}
.cw_logging_controls .k-button {
  margin-right: 10px;
}
.cw_logging_controls .label {
  float: left;
  margin: 2px 5px 0;
}
.cw_logging_settings .w30 {
  padding: 0 10px 0 0;
}
.cw_logging_settings .w30 label {
  width: 120px;
}
.cw_logging_settings .w30 input {
  width: auto;
}
.cw_settings_title {
  float: left;
  width: 310px;
}
/* Run Groovy Script */
.cw_overlay {
  position: absolute;
  z-index: 20;
  background-color: white;
  opacity: 0.7;
  display: none;
  align-items: center;
  justify-content: center;
}
.cw_overlay .spinner {
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #00aeef #00aeef #fff;
  border-radius: 15px;
  -webkit-animation: spin 0.5s infinite;
  -moz-animation: spin 0.5s infinite;
  -o-animation: spin 0.5s infinite;
  animation: spin 0.5s infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.cw_groovy_script {
  position: absolute;
  right: 20px;
  left: 20px;
  top: 15px;
  width: auto;
  bottom: 0;
  padding: 0;
  border: none;
}
.cw_groovy_script .cw_code_input {
  position: absolute;
  top: 0px;
  left: 0px;
}
.cw_groovy_script .cw_code_input .cw_run_script_code {
  height: 100%;
  font-family: Courier;
}
.cw_groovy_script .cw_code_input.k-scrollable {
  overflow: hidden;
}
.cw_groovy_script .cw_code_output {
  position: absolute;
  top: 0px;
}
.cw_groovy_script .cw_code_output .cw_run_script_results {
  width: 100%;
  height: 85%;
  font-family: Courier;
  font-size: 12px;
}
.cw_groovy_script .cw_code_output.k-scrollable {
  overflow: hidden;
}
.cw_groovy_script .cw_clear_output {
  float: right;
  margin-top: 5px;
}
.cw_script_window_container .actions {
  height: 50px;
}
.cw_scripted_agent {
  float: left;
  width: 100%;
}
.cw_scripted_agent .label {
  float: left;
  width: 100%;
  padding: 5px 0;
  text-indent: 10px;
}
.cw_scripted_agent .cw_agent_script_output {
  float: left;
  width: 100%;
  height: 220px;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid #ccc;
}
/*  */
.cw_ignore_headers {
  float: right;
  text-indent: 0;
  margin-right: 10px;
}
/* Agent Indicator */
.cw_agent_indicator {
  float: left;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #fff;
  border-radius: 20px;
  text-align: center;
  line-height: 16px;
  float: none;
  display: inline-block;
}
.cw_agent_indicator.cw_color1 .glyphicons {
  color: #e14b32;
}
.cw_agent_indicator.cw_color2 .glyphicons {
  color: #f1ae31;
}
.cw_agent_indicator.cw_color3 .glyphicons {
  color: #f7e400;
}
.cw_agent_indicator.cw_color5 .glyphicons {
  color: #7cc623;
}
.cw_agent_indicator.is_critical {
  background-color: #e14b32;
}
.cw_agent_indicator.is_major {
  background-color: #f1ae31;
}
.cw_agent_indicator.is_idle {
  background-color: #c4c4c4;
}
.cw_agent_indicator.is_ok {
  background-color: #7cc623;
}
.cw_agent_indicator .glyphicons {
  float: left;
  width: 14px;
  height: 14px;
  line-height: 14px;
  margin-left: 1px;
  font-size: 14px;
  color: #fff;
  text-indent: 0;
  text-shadow: none;
}
.k-grid .cw_agent_indicator .status_icon {
  font-size: 14px;
  height: 14px;
  margin-left: 1px;
}
.cw_status_indicator .glyphicons.wrench {
  background: #333;
  color: #fff;
  border-radius: 20px;
  line-height: 14px;
}
.cw_filter_item .cw_filter_label {
  margin: 4px 0;
}
#cw_agent_tabs_nav-4 .cw_incoming_queue li.item {
  margin-right: 25px;
  float: left;
}
#cw_agent_tabs_nav-4 .cw_incoming_queue li.item .label {
  font-weight: bold;
}
.cw_status_indicator .glyphicons {
  background-color: #fff;
  color: #333;
  border-radius: 20px;
  font-size: 18px;
  width: 16px;
  height: 16px;
  padding: 0;
}
.cw_agent_states_period {
  width: 400px;
  position: absolute;
  top: 30px;
  right: 10px;
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #ccc;
  z-index: 50;
  border-radius: 3px 0 3px 3px;
}
#cw_agent_states_list .status_icon.exclamation-mark {
  width: 16px!important;
  height: 16px!important;
}
.cw_repository_information,
.cw_deploy_errors_information {
  border: 1px solid #d8d8d8;
  box-shadow: none;
}
.cw_repository_search,
.cw_errors_search {
  float: right;
  width: 240px;
  margin-right: 5px;
}
.cw_repository_list,
.cw_deploy_errors_list {
  float: left;
  width: 100%;
}
.cw_deploy_errors_information .glyphicons {
  float: left;
  font-size: 24px;
  margin: 3px 10px 0 0;
}
.cw_management_queue_tags {
  float: left;
  padding: 6px 10px;
  margin-left: 10px;
  background-color: #e8f1f5;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 700;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.cw_audit_timerange {
  margin: 0 10px 0 0;
}
.cw_audit_timerange li {
  height: 28px;
  line-height: 28px;
  padding: 0 1em;
}
/* Bundle Configuration  */
.cw_management_module .cw_grid_input {
  width: 92%;
}
/* Agents */
/* Incidents Form */
#cw_agent_details .cw_content_preview {
  top: 15px;
  height: 300px;
}
#cw_agent_details .cw_content_preview .cw_box31,
#cw_agent_details .cw_content_preview .cw_box32,
#cw_agent_details .cw_content_preview .cw_box33 {
  height: 280px;
}
#cw_agent_details .cw_content_preview .cw_section {
  width: 100%;
  margin: 0;
  height: 100%;
}
#cw_agent_details .cw_content_preview .cw_section_content {
  float: left;
  height: calc(100% - 42px);
  overflow: visible;
}
#cw_agent_details .cw_content_preview .k-dropdown,
#cw_agent_details .cw_content_preview .k-combobox {
  margin: 0;
  width: 100%;
  background: none;
}
#cw_agent_details .cw_tabs_absolute {
  top: 0;
}
.cw_loglevel {
  width: 200px;
  margin: 0 17px 0 0;
}
.cw_agent_dependencies,
.cw_dependencies_list {
  float: left;
  width: 100%;
  margin-top: 10px;
}
.cw_agent_info {
  float: left;
  margin: 10px 0 0 0;
  background-color: #fbfbfb;
}
.cw_agent_info .item {
  float: left;
  width: 100%;
  padding: 5px;
  border: 1px solid #d8d8d8;
  margin-bottom: -1px;
}
.cw_agent_info .label {
  float: left;
  width: 40%;
}
.cw_agent_info .value {
  float: left;
  width: 60%;
  font-weight: 700;
}
.cw_level_selector.editable {
  background: url("~styles/shared/images/app_theme//dropdown.png") no-repeat 175px center;
}
/* Log Details View */
.cw_details .cw_inline_full {
  z-index: 10;
}
/* Log Setup */
.cw_log_level_edit {
  cursor: pointer !important;
  display: block;
}
/* System Errors */
.cw_system_errors_timerange {
  margin-right: 10px;
}
/* Modules */
.cw_management_modules .green {
  color: #7cc623;
  font-weight: 700;
}
/* Status */
.status {
  position: absolute;
  width: 100%;
  display: none;
  margin-bottom: 10px;
  z-index: 25;
}
.status.global {
  z-index: 24;
  top: 0;
  left: 0;
  right: 0;
}
.status.canvas {
  left: 2px;
  top: 35px;
  right: 21px;
  width: auto;
}
.status.relative {
  position: relative;
}
.status.relative p {
  margin: 0 0 10px;
}
.status p {
  display: block;
  float: none !important;
  width: auto;
  padding: 10px;
  font-weight: 700;
  font-size: 11px;
  text-shadow: 0 1px 0 #fff;
  border-radius: 3px;
}
.status .success {
  border: 1px solid #b2dba1;
  border-bottom-color: #a3d48e;
  background-color: #DFF0D8;
  color: #529138;
}
.status .error {
  border: 1px solid #e4b9b9;
  border-bottom-color: #dca7a7;
  background-color: #F2DEDE;
  color: #963b3b;
}
.status .info {
  border: 1px solid #afd9ee;
  border-bottom-color: #9acfea;
  background-color: #D9EDF7;
  color: #247fad;
}
.status .warning {
  border: 1px solid #f7ecb5;
  border-bottom-color: #f5e79e;
  background-color: #FCF8E3;
  color: #caad16;
}
.status_info {
  border: 1px solid #afd9ee;
  border-bottom-color: #9acfea;
  background-color: #D9EDF7;
  color: #247fad;
}
.status_error {
  border: 1px solid #e4b9b9;
  border-bottom-color: #dca7a7;
  background-color: #F2DEDE;
  color: #963b3b;
}
.status_success {
  border: 1px solid #b2dba1;
  border-bottom-color: #a3d48e;
  background-color: #DFF0D8;
  color: #529138;
}
.status_warning {
  border: 1px solid #f7ecb5;
  border-bottom-color: #f5e79e;
  background-color: #FCF8E3;
  color: #caad16;
}
.cw_section .status p {
  border-radius: 4px !important;
  margin: 10px !important;
}
#modal .status {
  z-index: 10012;
}
#wizard .status {
  z-index: 10100;
}
.wiz_content .status {
  width: 260px;
}
#modal .status p {
  margin: 5px 20px;
}
#wiz_content > .status {
  margin-left: -10px;
}
#create_widget .status {
  left: 245px;
  width: 555px;
}
.invalid,
.invalid.k-combobox .k-input {
  background: #F2DEDE !important;
  color: #B94A48 !important;
  text-shadow: 0 1px 0 #fff;
}
.invalid_text {
  color: #B94A48 !important;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
}
/*Dialog*/
.dialog {
  min-width: 500px !important;
}
.dialog .glyphicons {
  float: left;
  padding: 0 10px;
  font-size: 28px;
}
.dialog .glyphicons.exclamation-sign {
  color: #f1ae31;
}
.dialog .glyphicons.info-sign {
  color: #00bdf2;
}
.dialog .glyphicons.remove-sign {
  color: #e14b32;
}
.dialog .glyphicons.ok-sign {
  color: #7cc623;
}
.dialog .glyphicons.question-sign {
  font-size: 15px;
  padding: 3px 5px;
}
.dialog .cw_dialog_message {
  float: left;
  padding: 10px;
  font-size: 13px;
  width: 85%;
  position: absolute;
  overflow: auto;
}
.cw_dialog_content {
  padding: 15px 15px 65px;
}
.cw_dialog_content textarea,
.cw_dialog_content input {
  float: left;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  font-family: inherit;
  font-size: 100%;
  border-radius: 3px;
}
.cw_dialog_content textarea:focus,
.cw_dialog_content input:focus {
  box-shadow: 0 0 7px 0 #76abd9;
  -webkit-box-shadow: 0 0 7px 0 #76abd9;
  outline: none !important;
}
.cw_dialog_content input {
  height: 30px;
  text-indent: 10px;
}
.cw_advanced_toggle {
  padding: 5px;
  color: #257cba;
  font-size: 13px;
}
.cw_delete_sm_container {
  margin: 15px;
  padding-left: 60px;
}
.cw_delete_sm_container p {
  margin: 10px 0;
}
.cw_delete_sm_container .glyphicons {
  float: left;
  font-size: 28px;
  color: #f7941d;
  margin-left: -50px;
}
.cw_recalculate_sm_container {
  margin: 15px;
}
.cw_recalculate_sm_container p {
  margin: 10px 0;
}
.k-grid .status_icon {
  font-size: 22px;
  height: 18px;
  line-height: 18px;
}
/* Generic */
#modal {
  z-index: 10010;
}
/* Header */
/* Level 0 - Nothing gets on top */
#main_loading_mask {
  z-index: 99990;
}
/* Level 1 - Modal + Overlay + Controls */
/* Level 2 - Menus with class Open - 10000*/
.is_open {
  z-index: 5000;
}
/* Level 3 - Designer elements*/
/* Level 4 - Misc */
/* Level 5 */
.cw_nav_secondary {
  z-index: 110;
  /* To stay on top of the grid loading indicator */
}
.cw_nav_secondary.level_1 {
  z-index: 10;
}
.cw_nav_secondary.level_2 {
  z-index: 20;
}
.cw_mask,
.cw_widget_mask {
  z-index: 15;
}
.cw_sliding_box {
  z-index: 50;
}
.cw_event_summary_time_range {
  margin-right: 10px;
}
.cw_event_summary_event_type {
  margin-right: 10px;
}
.cw_event_indicator {
  float: left;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #fff;
  border-radius: 20px;
  text-align: center;
  line-height: 16px;
  display: inline-block;
  float: none;
}
.cw_event_indicator.cw_color1 .glyphicons {
  color: #e14b32;
}
.cw_event_indicator.cw_color2 .glyphicons {
  color: #f1ae31;
}
.cw_event_indicator.cw_color3 .glyphicons {
  color: #f7e400;
}
.cw_event_indicator.cw_color5 .glyphicons {
  color: #7cc623;
}
.cw_event_indicator.is_critical {
  background-color: #e14b32;
}
.cw_event_indicator.is_major {
  background-color: #f1ae31;
}
.cw_event_indicator.is_idle {
  background-color: #c4c4c4;
}
.cw_event_indicator.is_minor {
  background-color: #f7e400;
}
.cw_event_indicator.is_ok {
  background-color: #7cc623;
}
.cw_event_indicator .glyphicons {
  float: left;
  color: #fff;
  margin: 0px;
}
.cw_es_indicator .wrench {
  color: #fff;
  font-size: 14px;
  margin: 1px 0px 0 0!important;
}
.cw_es_indicator.is_idle .glyphicons.exclamation-mark {
  background-color: #333;
}
.cw_event_summary_period {
  position: absolute;
  top: 47px;
  width: 400px;
  right: 212px;
  padding: 5px;
  background-color: #cfe6f5;
  border-radius: 0 0 3px 3px;
  display: inline-flex;
  border: 1px solid #c3c0c0;
}
.cw_event_summary_period .date_range_container {
  width: 82%;
}
#cw_select_periods {
  height: 30px;
}
.cw_historic_event {
  position: absolute;
  display: block;
  width: 24px;
  border-top: 2px solid black;
  margin-top: 7px;
  margin-left: -4px;
}
.cw_disabled_option {
  pointer-events: none;
  color: #aaaaaa !important;
}
/* Widget */
.cw_widget {
  position: absolute;
}
.cw_widget.no_header {
  padding-top: 0;
  overflow: hidden;
}
.cw_widget.no_header .k-link {
  position: relative;
  z-index: 5;
  margin-bottom: -80px;
}
.cw_widget.no_header .cw_widget_actions {
  padding: 40px 20px 20px;
}
.cw_widget.no_header .cw_section_titlebar {
  margin-top: -42px;
}
.cw_widget.no_header .cw_section_content {
  top: 0;
}
.cw_widget.no_header .cw_move_widget,
.cw_widget.no_header .cw_edit_widget {
  margin-top: 42px;
  margin-left: 5px;
}
.cw_widget.no_header .cw_toggle_options {
  margin-top: 42px;
  margin-right: 2px;
}
.cw_widget.no_header .cw_widget_settings,
.cw_widget.no_header .cw_widget_actions {
  top: 0;
}
.cw_widget.has_footer .cw_section_content {
  padding-bottom: 50px;
}
.cw_widget.has_footer .cw_section_footer {
  height: 50px;
  line-height: 50px;
}
.cw_widget.cw_selector .cw_section_title {
  width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_widget.no_title .cw_widget_settings,
.cw_widget.no_title .cw_widget_actions {
  top: 0;
}
.cw_widget .cw_section_titlebar .cw_status_indicator {
  margin: 4px 0 0 10px !important;
}
.cw_widget .cw_section_title {
  width: 55%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_widget .cw_section_content {
  position: absolute;
  top: 40px;
  overflow: auto;
  margin-bottom: 20px;
}
.cw_widget .cw_section_content.presentation,
.cw_widget .cw_section_content.custom-image {
  margin-bottom: 0;
  display: flex;
}
.cw_widget .cw_widget_settings {
  top: 31px;
}
.cw_widget .cw_widget_actions {
  top: 40px;
}
.cw_widget .cw_search_box {
  margin-right: 6px;
}
.cw_widget .cw_custom_time_selector {
  top: 0;
  float: right;
  z-index: 600;
  width: 340px;
  margin: 35px 10px 0;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
}
.cw_widget .cw_custom_time_selector .k-button {
  margin-top: 10px;
}
.cw_widget .cw_time_input_container {
  display: inline-block;
  width: 50%;
  padding: 3px;
}
.cw_widget .cw_time_input {
  width: 100%;
  height: 23px;
}
.cw_widget .geEditor {
  height: unset;
}
.cw_service_part .cw_service_indicator {
  float: left;
  width: 30px;
  height: 30px;
  margin: 5px 0 0 -44px;
  line-height: 27px;
  font-size: 25px;
}
.cw_widget .cw_section_titlebar .cw_service_indicator {
  margin: 8px -5px 8px 8px;
}
.cw_widget.cw_section_servicegrid .k-grid-content {
  heigh: 271px;
}
.cw_widget_settings .cw_custom_controls {
  float: left;
}
.cw_widget_settings .cw_custom_controls button {
  float: right;
  line-height: 1;
  padding: 0.7em;
  box-shadow: none;
  margin-right: 5px;
}
.cw_widget_settings .cw_custom_controls button.is_selected {
  background: #cfe6f5;
}
.k-window.is_transparent,
.k-window.is_transparent .k-window-content,
.k-window.is_transparent .cw_section_content,
.k-window.is_transparent .cw_section {
  background-color: transparent;
}
.k-window.no_header {
  overflow: hidden;
}
.k-window.no_header .k-window-actions {
  z-index: 6;
}
#cw_sb_layout .k-window.no_header .k-window-actions {
  right: 0;
}
#cw_sb_layout .k-window.no_header .k-window-actions .k-window-action {
  margin-right: 0;
}
#cw_sb_layout .k-window.no_header .k-window-actions .k-link {
  margin-bottom: -45px;
}
.k-window.no_header {
  padding-top: 0 !important;
  overflow: hidden;
}
.k-window.no_header .k-link {
  position: relative;
  z-index: 5;
  margin-bottom: -80px;
}
.k-window.no_header .cw_widget_actions {
  padding: 40px 20px 20px;
}
.k-window.no_header .k-window-titlebar {
  margin-top: -42px;
}
.k-window.no_header .k-window-content {
  top: 0;
}
.k-window.no_header .cw_toggle_options {
  margin-top: 42px;
  margin-right: 2px;
  background-color: #fff;
}
.k-window.no_header .cw_widget_settings,
.cw_widget_actions {
  top: 0;
}
.cw_widget_status {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  text-indent: 10px;
  color: #fff;
  border-bottom: 1px solid #c3341d;
  background-color: #e14b32;
}
.cw_widget_status.info {
  top: 1px;
  color: #54595E;
  background-color: #fff;
  border-color: #ddd;
}
.cw_move_widget,
.cw_edit_widget,
.cw_remove_widget {
  position: relative;
  z-index: 100;
  float: right;
  padding: 0.4em;
  margin-right: 5px;
  cursor: pointer;
}
.cw_toggle_options {
  margin: 3px 10px 0 0;
  cursor: pointer;
}
.cw_viewer_module .cw_widget_settings {
  top: 31px;
}
.cw_widget_settings {
  z-index: 51;
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  left: 0;
  padding: 11px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #d8d8d8;
}
.cw_widget_settings-no_padding {
  padding: 0;
}
.cw_settings_overlay {
  background-color: #ffffff;
  opacity: 0.95;
}
.cw_dropdown_time_selector {
  position: absolute;
  right: 80px;
  top: 0;
  width: 70px;
  background-color: #fbfbfb;
}
.cw_multitoggle_time_selector {
  position: absolute;
  right: 80px;
  top: 3px;
}
.cw_widget_info_sign {
  position: absolute;
  top: 9px;
  font-size: 18px;
  width: 18px;
}
.cw_all_selectors {
  padding: 11px 5px;
}
.cw_all_selectors .cw_custom_controls button {
  margin-right: -2px;
}
.cw_all_selectors .period_multi_toggle {
  margin: 0;
}
.cw_widget_actions {
  display: none !important;
  position: absolute;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px 20px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 3px;
  text-align: center;
}
.cw_widget_actions.expanded {
  display: block !important;
}
.cw_widget_actions .k-button {
  display: block;
  margin: 5px auto;
  width: 40%;
  min-width: 160px;
  max-width: 200px;
}
.cw_widget_actions .k-primary {
  display: inline-block;
  margin: 5px;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
.cw_widget_actions .glyphicons {
  float: left;
}
.cw_widget_buttons {
  position: relative;
  z-index: 5;
  width: 100%;
  text-align: center;
}
.k-icon.k-i-toggle {
  background-position: -32px -80px;
}
.cw_no_items {
  float: left;
  width: 100%;
  padding: 8px 10px;
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.cw_hide_time_selector {
  float: right;
  margin-top: 5px;
}
.cw_toggle_container {
  float: right;
  margin-top: -22px;
  font-size: 12px;
  background-color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
}
.cw_widget_form_legend {
  font-size: 13px;
  font-weight: 700;
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
}
.cw_configuration_placeholder {
  padding: 5px;
}
/* The widget target that appears when dragging the widget */
.preview-holder {
  background-color: #00aeef !important;
  border: 1px solid #fff !important;
  border-radius: 5px;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.5);
}
/* Widget overlay icon on dragging widget */
.widget_placeholder_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  text-align: center;
  font-size: 60px;
  color: #dee8ec;
}
/* Widget Form - Table List */
/* Columns*/
.cw_list_columns {
  float: left;
  width: 100%;
  list-style: none;
  margin: 0 0 16px;
}
.cw_list_columns .cw_list_row {
  float: left;
  width: 100%;
}
.cw_list_columns .cw_list_item,
.cw_list_columns .cw_list_head,
.cw_list_columns .cw_list_item:first-child {
  float: left;
  width: 33.3333%;
  padding: 5px 10px 4px;
}
.cw_list_columns .cw_list_head {
  width: 100%;
  border-radius: 3px 3px 0 0;
}
.cw_show_column {
  float: left;
  cursor: pointer !important;
}
.cw_widget_image {
  max-width: 100%;
  max-height: 100%;
}
.cw_image_container .k-button.k-upload-button {
  min-width: 9.167em;
}
.cw_uploader_actions {
  float: right;
  margin-right: 18px;
}
.cw_upload_image_icon {
  float: right;
  font-size: 17px;
  margin-top: -19px;
  background-color: #ffffff;
}
.cw_preview_text {
  background-color: #cfe6f5;
  border: 1px solid #b5d2dc;
  height: 35px;
  border-radius: 5px;
}
.cw_preview_text span {
  padding: 11px;
  position: absolute;
}
.cw_widget_preview_container {
  border: 1px solid #d8d8d8;
  width: 400px;
  height: 266px;
  margin-left: 10%;
  margin-top: 10%;
}
/* Selective Services */
#cw_st_account .k-multiselect-wrap {
  float: left;
  width: 100%;
  max-height: 140px;
  overflow: auto;
  box-sizing: border-box;
}
#cw_st_account .k-multiselect {
  float: left;
  width: 100%;
}
#cw_st_service .k-multiselect {
  float: left;
  width: 100%;
}
#cw_st_account .k-multiselect .k-button,
#cw_st_service .k-multiselect .k-button {
  max-width: 250px;
}
#cw_st_account .k-multiselect .k-button > span:first-child,
#cw_st_service .k-multiselect .k-button > span:first-child {
  max-width: 230px;
}
#cw_st_service .k-multiselect-wrap {
  float: left;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  box-sizing: border-box;
}
.cw_serviceincident_settings {
  margin-top: 15px;
}
.cw_serviceincident_settings .k-checkbox-label,
.cw_serviceincident_settings .k-checkbox {
  margin-bottom: 5px;
}
/* Custom Image */
.cw_custom_image img {
  margin-left: 150px;
  max-width: 300px;
  max-height: 300px;
}
/* SLA History */
.cw_sla_header {
  position: relative;
  height: 70px;
}
.cw_sla_header div:first-child {
  font-weight: bold;
}
.cw_sla_header div {
  text-indent: 15px;
}
.cw_sla_header #cw_back {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer !important;
}
.cw_time_to_breach_values #cw_legend {
  padding: 0 28px;
  width: 100%;
  box-sizing: border-box;
}
.cw_time_to_breach_values #cw_legend span {
  float: left;
  width: 50%;
  height: 21px;
}
.cw_time_to_breach_values #cw_legend span:first-child {
  text-align: left;
}
.cw_time_to_breach_values #cw_legend span:last-child {
  text-align: right;
}
.cw_widget_tooltip {
  width: 300px;
  min-width: 200px;
  overflow-wrap: break-word;
  white-space: normal;
}
.cw_home_widget_selector {
  position: absolute;
  z-index: 100;
  margin: -29px 0 0 100px;
}
.ant-modal-body .cw_field .k-numerictextbox {
  margin-left: 130px;
}
/* Service History Widget */
.cw_service_state_time .label,
.cw_service_state_duration .label {
  float: left;
  width: 100%;
  line-height: 22px;
  font-size: 13px;
  font-weight: 400;
  color: #777;
}
.cw_service_state_time .value,
.cw_service_state_duration .value {
  float: left;
  width: 100%;
  line-height: 22px;
  font-size: 13px;
  font-weight: 700;
}
#cw_history_counters #cw_service_breaches .glyphicons.remove-sign {
  color: #3276b1;
  margin-top: 6px;
  margin-right: 3px;
}
#cw_history_counters #cw_service_downtime .glyphicons.circle-arrow-down {
  color: #3276b1;
  margin-top: 6px;
  margin-right: 3px;
}
.cw_history_counters_resize {
  padding: 5px 3px !important;
}
.cw_dashboard_history_counters {
  position: absolute;
  right: 250px;
}
.four_cols .cw_section_content {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}
.three_cols .cw_section_content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
.two_cols .cw_section_content {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.cw_service_part {
  display: inline-block;
  width: 400px;
  padding: 3px 5px 4px 60px;
  border-bottom: 1px solid #d8d8d8;
}
.cw_service_part .cw_status_indicator {
  float: left;
  margin: 3px 0 -30px -45px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
}
.cw_service_part .item {
  float: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.cw_service_part .value {
  font-weight: 700;
}
.cw_service_part .cw_operating_state {
  margin: 0;
  padding: 0;
}
.cw_service_part .cw_incident_counter {
  padding: 1px 5px;
  background: #eee;
  border-radius: 50%;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}
.cw_service_part .cw_incident_counter:hover {
  background-color: #343f49;
  color: #fff;
}
.cw_service_part.modify_exclamation_mark .exclamation-mark {
  top: 15px;
  left: 24px;
  position: absolute;
}
.cw_all_accounts,
.cw_all_services {
  float: right;
  margin: 0 10px 0 0;
  text-indent: 0;
}
.cw_all_accounts .k-checkbox-label,
.cw_all_services .k-checkbox-label {
  width: auto;
  line-height: 1.2;
  margin: 0 0 0 5px;
}
.cw_incident_tooltip {
  /*clear:left; .left; */
  width: 540px;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  text-align: left;
}
.cw_incident_tooltip * {
  /*.left;*/
  padding: 2px 5px;
  border-width: 0 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
  margin: 0 -1px;
}
.cw_incident_tooltip .cw_status {
  background-color: rgba(0, 0, 0, 0.5);
}
.cw_incident_tooltip .cw_subject {
  /*clear:left; */
}
.cw_incident_tooltip .cw_modified {
  /*clear:left;*/
}
.cw_incident_tooltip .cw_created {
  display: inline-block;
}
.cw_service_part .cw_service_indicator .cw_indicator.glyphicons.wrench {
  float: none;
  margin-top: 3px;
  font-size: 23px;
}
.cw_service_part .cw_service_indicator .cw_indicator.glyphicons.clock {
  float: none;
  margin-top: 3px;
  font-size: 24px;
}
/* Cw Bars Chart */
.cw_bars_chart {
  float: left;
  width: 100%;
  height: 100%;
  padding-bottom: 25px;
  padding-top: 20px;
}
.cw_bars_chart .cw_bars {
  float: left;
  width: 100%;
  height: 100%;
  text-align: center;
}
.cw_bars_chart .cw_bar {
  float: none;
  display: inline-block;
  min-width: 15%;
  max-width: 200px;
  width: min-content;
  height: 85%;
}
.cw_bars_chart .value {
  height: 100%;
  padding: 24% 0 0;
}
.cw_bars_chart .legend {
  height: 15%;
}
.cw_bars_chart .cw_progress {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 25px;
  width: auto;
}
.cw_bars_chart .cw_dashboard_progress {
  bottom: 10px;
}
/* Layout */
.cw_box21,
.cw_box22,
.cw_box21,
.cw_box31,
.cw_box31_2,
.cw_box32,
.cw_box32_2,
.cw_box33,
.cw_box_full {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  bottom: 10px;
}
.cw_box21 {
  right: 50.5%;
  left: 0;
}
.cw_box22 {
  right: 0%;
  left: 50.5%;
}
.cw_box31 {
  right: 67%;
  left: 0;
}
.cw_box31_2 {
  right: 34%;
  left: 0;
}
.cw_box32_2 {
  right: 0;
  left: 34%;
}
.cw_box32 {
  right: 34%;
  left: 34%;
}
.cw_box33 {
  right: 0%;
  left: 67%;
}
.cw_box_full {
  right: 0;
  left: 0;
}
@media screen and (max-width: 800px) {
  .login_area {
    width: 100%;
    margin: 0;
    background: none;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  #login_form {
    width: 100%;
    margin: 0;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    border-radius: 0;
  }
  #login_form .k-input,
  #login_form .k-button {
    width: 100%;
  }
  #login_form .k-input {
    border-color: #00aeef;
  }
  .login_content {
    width: auto;
  }
  .login .contact,
  .login .support {
    font-size: 11px !important;
    margin-right: 10px !important;
  }
  .logo {
    width: 110px;
  }
}
/* Media Query < 1280*/
@media screen and (min-width: 801px) and (max-width: 1400px) {
  /* Login */
  .login .cw_loginarea {
    background: none;
    border: none;
    padding: 0;
    box-shadow: none;
  }
  .login #login_form {
    margin: 20px auto 0;
    float: none;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  /* Generic */
  .aside {
    width: 60px;
  }
  .aside .cw_logo_negative {
    width: 56px;
    margin: 2px;
  }
  .aside .cw_toggle_nav {
    display: none;
  }
  .cw_account_drop {
    left: 200px;
  }
  .cw_context_action {
    display: none !important;
  }
  .cw_multi_content .cw_multi_caret {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    text-align: center !important;
    border-radius: 3px;
  }
  .cw_multi_content .cw_multi_caret .glyphicons {
    height: 40px;
    line-height: 38px;
  }
  .cw_multi_options {
    left: 65px;
  }
  .cw_main_nav {
    width: 60px;
  }
  .cw_main_nav .cw_item {
    width: 100%;
  }
  .cw_main_nav .label {
    display: none !important;
  }
  .cw_nav_secondary {
    left: 60px;
    width: 200px;
  }
  .main_content #content_area {
    left: 60px;
  }
  #cpanel .k-dropzone,
  #cpanel .k-upload {
    width: 100%;
  }
  .cw_canvas_toolbox li {
    padding: 4px 6px;
  }
  .window_titlebar {
    height: 50px;
  }
  .window_title,
  .cw_window_actions {
    margin-top: 10px;
  }
  .window_area {
    top: 50px;
  }
  .window_area.inner15 {
    top: 50px;
  }
  .cw_view_settings,
  .cw_view_data,
  .cw_section.cw_section_full {
    top: 0;
  }
  /*Service Details*/
  .cw_service_details_module .cw_tier_top {
    height: 240px;
  }
  .cw_service_details_module .cw_tier_bottom {
    top: 340px;
  }
  .cw_service_details_module .cw_tier_top .cw_section {
    height: 214px;
  }
  .cw_service_details_module .cw_tier_top .cw_section_content {
    height: 172px;
  }
  .cw_service_details_module .cw_tier_middle {
    top: 240px;
    height: 100px;
  }
  .cw_service_details_module .cw_tier_middle .cw_section_content {
    height: 59px;
  }
  .cw_state_widget2 .content {
    height: 70px;
    margin: 10px auto 0;
  }
  .cw_state_widget2 .value {
    font-size: 20px;
    font-weight: 600;
    height: auto;
  }
}
/* end 1200 */
/* Media Query -1619-1281*/
/* end 1620 */
/* Media Query > 1620*/
@media screen and (min-width: 1620px) {
  #cpanel,
  #my_profile {
    width: 600px;
  }
}
@media screen and (max-width: 906px) {
  .cw_view li {
    width: 100%;
  }
}
@media screen and (min-width: 1320px) and (max-width: 1400px) {
  .cw_view li {
    width: 33%;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1460px) {
  .cw_view li {
    width: 50%;
  }
}
@media screen and (min-width: 1461px) and (max-width: 1848px) {
  .cw_view li {
    width: 33%;
  }
}
@media screen and (min-width: 1848px) {
  .cw_view li {
    width: 25%;
  }
}
@media screen and (min-width: 2248px) {
  .cw_view li {
    width: 20%;
  }
}
@media screen and (max-height: 1081px) {
  #monitors_submenu li.cw_item .glyphicons,
  #monitors_submenu li.cw_item .label {
    height: 23px;
    line-height: 23px;
  }
}
/** Added by Sergiu **/
.cw_grid_sort {
  font-size: 12px;
  margin-left: 3px;
}
#cw_account_context.no_rights {
  background-color: transparent;
  opacity: 1 !important;
}
.cw_incident_asset_check_wrapper {
  padding: 0 9px;
}
/** Event log patches */
.cw_eventlog_exp_time,
.cw_eventlog_suppression_time {
  text-align: right;
  direction: rtl;
}
.cw_eventlog_hours {
  margin: 5px 0 0 10px;
}
/* Catalin: create user form, avatar close mini button position */
.avatar.cw_avatar_user .close_minibtn {
  left: 55px;
  position: absolute;
  top: -8px;
}
.cw_asset_console_disk .k-notification,
.cw_syshealth_chart_container .k-notification {
  position: static !important;
}
/*by Sergiu */
.cw_white_mask {
  background-color: #dee8ec;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 1000;
}
.node.cw_highlight_node {
  webkit-box-shadow: 0 0 0 6px #fff, 0 0 0 8px rgba(0, 0, 0, 0.5) !important;
  -moz-box-shadow: 0 0 0 6px #fff, 0 0 0 8px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 0 0 6px #fff, 0 0 0 8px rgba(0, 0, 0, 0.5) !important;
}
/*By Cosmin - scheduler event start/end date validation error container*/
.k-widget.k-tooltip.k-tooltip-validation.k-invalid-msg {
  top: 33px;
  position: absolute;
  left: 0;
}
/*By Cosmin - scheduler event start/end date validation error icon*/
.k-widget.k-tooltip.k-tooltip-validation.k-invalid-msg .k-icon.k-warning {
  background-image: none;
  width: 0;
}
/*By Cosmin - serviceDetails: after adding height replacing height 400 with 300 had to remove padding-bottom to remove empty space from under the serviceHistoryWidget*/
#cw_service_shistory_area {
  padding-bottom: 0 !important;
}
.cw_multitoggle_custom_period {
  position: absolute;
  z-index: 100;
  top: 41px;
  right: 0;
  padding: 5px;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
}
div.k-popup.k-group.k-reset.k-flatcolorpicker {
  border-radius: 0 10px 0 0;
  border: 1px solid #dddddd;
}
