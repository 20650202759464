.data-source-properties {
  padding: 20px;
}
.data-source-properties > * {
  margin-bottom: 10px;
}
.data-source-properties__severity-indicators > .form-entry__field {
  align-items: center;
}
.data-source-properties__severity-indicators > .form-entry__field > * {
  margin: 0 5px;
}
.data-source-properties .form-entry_horizontal {
  overflow: hidden;
}
.data-source-properties .form-entry_horizontal .form-entry__field {
  max-width: 100%;
  overflow: hidden;
}
.data-source-properties .form-entry_horizontal .form-entry__field .drop-down-list {
  overflow: hidden;
  max-width: 100%;
}
