.diskView__add_form .qualifier-wizard__field {
  margin: 5px 0;
}
.diskView__add_form__actions {
  display: flex;
  padding: 10px 0 10px 10px;
}
.diskView__add_form__actions button {
  margin-right: 10px;
}
.diskView__add_form__buffer {
  flex-grow: 1;
}
.diskView__add_form .section_appearance_frame {
  border: unset;
  margin-top: unset;
}
.diskView__add_form .form-entry__field .required-wildcard {
  position: absolute;
  color: red;
  margin-top: 2px;
  margin-left: 5px;
}
