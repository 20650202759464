.qualifier-wizard {
  position: relative;
}
.qualifier-wizard-form__form {
  position: relative;
}
.qualifier-wizard-form__form .section {
  width: 400px;
}
.qualifier-wizard-form__form .form-entry_horizontal .form-entry__label {
  width: 145px;
}
.qualifier-wizard-form__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}
.qualifier-wizard-form__create-multiple {
  margin-left: 20px;
  padding-bottom: 5px;
  color: #709bba;
  cursor: pointer;
}
