.autoLayout__container {
  line-height: 30px;
  margin-left: 20px;
}
.autoLayout__container input {
  margin-top: 8px;
  margin-right: 5px;
}
#cw_servicelog_time_selector {
  position: relative;
  margin-top: -28px;
  margin-right: 65px;
}
#cw_servicelog_time_selector .multitoggle__item {
  padding: 0.4em;
}
.cw_show_acknowledged_viewer {
  position: relative;
  margin-top: -28px;
  margin-right: 770px;
  font-weight: 700;
  border: 1px solid #cccccc;
  border-radius: 3px;
  color: #54595E;
  padding: 4px;
  background-color: #ffffff;
}
.cw_show_acknowledged_viewer.is_selected {
  background: #cfe6f5;
  color: #000000;
}
.cw_show_acknowledged_viewer span {
  padding: 10px;
}
