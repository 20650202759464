.file-chooser .k-window-content {
  display: flex;
  flex-direction: column;
  min-height: 300px;
}
.file-chooser__tree {
  flex-grow: 1;
  overflow-y: scroll;
}
.file-chooser__text {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
}
.file-chooser__folder-icon {
  font-size: 20px;
  flex: unset;
  width: 20px;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
}
.file-chooser__spinner {
  height: 461px;
  position: relative;
}
