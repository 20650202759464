.formBuilder-error-message {
  position: absolute;
  background-color: rgba(255, 0, 0, 0.6);
  color: white;
  width: 100%;
  z-index: 50;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.formBuilder-error-message__close {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
