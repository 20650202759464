#filters_enabled,
#health_filters_enabled,
#inventory_filters_enabled {
  float: left;
  margin-left: 10px;
}
#filters_enabled .box-view_info,
#health_filters_enabled .box-view_info,
#inventory_filters_enabled .box-view_info {
  padding: 2px 10px;
  line-height: 21px;
}
#reload_toolbar {
  float: right;
  position: relative;
}
.cw_asset_details_module_title .cw_page_title {
  display: inline-block;
}
.cw_asset_details_module_title .cw_additional_warning {
  display: none;
  height: 35px;
  margin-left: 50px;
}
.cw_asset_details_module_title .cw_additional_warning .icon_block {
  margin-right: 5px;
  font-size: 16px;
}
.cw_asset_details_module_title .cw_notification {
  padding: 6px;
  border-radius: 5px;
  text-decoration: none;
  text-shadow: none;
  background: #c4c4c4;
  color: #ffffff;
  border: 1px solid #9b9ea2;
}
.monitor_maintenance {
  color: #ffffff;
  padding-right: 1px !important;
  font-size: 16px !important;
  line-height: 16px !important;
}
.asset_maintenance {
  color: #ffffff;
  padding-right: 1px !important;
  font-size: 16px !important;
  line-height: 16px !important;
  background-color: #c4c4c4;
}
.cw_asset_details_multitoggle_time_selector {
  position: absolute;
  right: 30px;
  top: 3px;
}
.cw_asset_details_dropdown_time_selector {
  position: absolute;
  right: 30px;
  top: 0;
  width: 70px;
  background-color: #fbfbfb;
}
#geotag_header {
  width: 100%;
  padding: 5px 0 0;
  text-align: center;
  border: 1px solid #d8d8d8;
  border-left: none;
  border-bottom: none;
  background: #fbfbfb;
}
#geotag_header:first-child {
  border-radius: 3px 0 0 0;
  border-left: 1px solid #d8d8d8;
}
#geotag_header:last-child {
  border-radius: 0 3px 0 0;
}
