.ceinterval__title .form-entry__label-text {
  display: flex;
}
.ceinterval__title .title {
  flex-grow: 1;
}
.ceinterval {
  display: flex;
  flex-direction: column;
}
.ceinterval .c-textbox__wrapper {
  width: 150px;
}
.ceinterval .k-datetimepicker {
  width: 195px;
}
.ceinterval__error {
  justify-content: center;
}
.ceinterval__error div {
  background-color: #e14b32;
  color: white;
  padding: 3px 10px;
  border-radius: 5px;
}
.ceinterval__error .exclamation-sign {
  color: white;
  margin-top: 2px;
  margin-right: 5px;
}
.ceinterval__pencil {
  font-size: 20px;
  flex: unset;
  width: 20px;
  margin-top: 5px;
  margin-right: 20px;
  cursor: pointer;
}
.ceinterval__window-inner {
  padding: 10px;
}
.ceinterval__row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.ceinterval__row > div {
  margin-right: 10px;
}
.ceinterval__row > div:last-child {
  margin-right: unset;
}
.ceinterval__row input[type="radio"] {
  margin-top: 7px;
  margin-left: 52px;
}
.ceinterval__row label {
  width: 60px;
  margin-top: 9px;
  margin-left: 5px;
}
.ceinterval__label {
  width: 120px;
  border-radius: 4px 0 0 4px;
  margin-right: 7px;
  height: 30px;
  line-height: 30px;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 10px;
}
.ceinterval__on-day .ceinterval__text {
  margin-left: 15px;
  margin-right: 78px;
}
.ceinterval__text {
  line-height: 30px;
}
.ceinterval__days {
  display: flex;
  margin-left: 129px;
}
.ceinterval__day {
  width: 50px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ceinterval__day.selected {
  background-color: #cfe6f5;
}
.ceinterval__day:last-child {
  margin-right: unset;
}
