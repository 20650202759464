.event-state-chart__bars-chart {
  float: left;
  width: 100%;
  height: 100%;
  padding-bottom: 25px;
  padding-top: 20px;
}
.event-state-chart__bars {
  float: left;
  width: 100%;
  height: 100%;
  text-align: center;
}
.event-state-chart__bar {
  float: none;
  display: inline-block;
  min-width: 15%;
  max-width: 200px;
  width: min-content;
  height: 85%;
  line-height: 160px;
  cursor: pointer;
  position: relative;
  border-width: 1px 1px 3px;
  border-style: solid;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.3) 20%, transparent);
}
.event-state-chart__bar span {
  float: left;
  width: 100%;
  display: block;
}
.event-state-chart__bar .event-state-chart__service-indicator {
  width: 16px;
}
.event-state-chart__bar.is-critical {
  border-color: #da3a20;
  border-bottom-color: #c3341d;
  background-color: #e14b32;
}
.event-state-chart__bar.is-major {
  border-color: #efa519;
  background-color: #f1ae31;
}
.event-state-chart__bar.is-minor {
  border-color: #c4b500;
  background-color: #f7e400;
}
.event-state-chart__bar.is-ok {
  border-color: #6eb01f;
  background-color: #7cc623;
}
.event-state-chart__bar.is-inactive {
  border-color: #c4c4c4;
  background-color: #dddddd;
}
.event-state-chart__bar.zero-count-bar {
  background: none;
  cursor: default;
}
.event-state-chart__bar.zero-count-bar .event-state-chart__value {
  cursor: default;
  color: #555555;
  text-shadow: none;
}
.event-state-chart__bar.zero-count-bar .event-state-chart__legend {
  cursor: default;
}
.event-state-chart__value {
  height: 100%;
  padding: 24% 0 0;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.event-state-chart__legend {
  line-height: 20px;
  margin-top: 5px;
  color: #888888;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
}
.event-state-chart__service-indicator {
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  right: 3px;
  top: 3px;
  opacity: 0.9;
  margin: 0;
  height: 16px;
  background: url("~styles/shared/images/app_theme//cw_exclamation_white_circle.png") no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  text-align: center;
  line-height: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
}
.event-state-chart__progress-container {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 10px;
  width: auto;
  float: left;
  height: 24px;
  padding: 2px;
  margin: 1px 0;
  border: 1px solid #d8d8d8;
  background: #ffffff;
  border-radius: 20px;
}
.event-state-chart__progress-container .is-critical {
  background-color: #e14b32;
}
.event-state-chart__progress-container .is-major {
  background-color: #f1ae31;
}
.event-state-chart__progress-container .is-minor {
  background-color: #f7e400;
}
.event-state-chart__progress-container .is-ok {
  background-color: #7cc623;
}
.event-state-chart__progress-container .is-inactive {
  background-color: #dddddd;
}
.event-state-chart__progress-bar {
  float: left;
  height: 18px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), transparent);
  cursor: pointer;
}
.event-state-chart__progress-bar:first-child {
  border-radius: 20px 0 0 20px;
}
.event-state-chart__progress-bar:last-child {
  border-radius: 0 20px 20px 0;
}
#eventSummary_modal .cw_section_full {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#eventSummary_modal .window_title,
#eventSummary_modal .cw_window_actions {
  margin-top: 9px;
}
