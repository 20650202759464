.view_mode_notifier {
  border: 1px solid #b2dba1;
  border-bottom-color: #a3d48e;
  background-color: #DFF0D8;
  color: #529138;
  width: 250px;
  float: right;
  height: 30px;
  border-radius: 5px;
  padding: 5px 0;
  text-align: center;
}
.free_title_container {
  position: absolute;
  top: 4px;
  right: 65px;
  background-color: white;
}
