.breadcrumb {
  margin: 0;
  display: flex;
  flex-direction: row;
}
.breadcrumb__item {
  font-size: 14px;
}
.breadcrumb__item::after {
  content: '\E811';
  font-family: 'Glyphicons Regular';
  margin: 0 10px;
  color: #555;
  font-size: 14px;
}
.breadcrumb__item:last-child::after {
  display: none;
}
.breadcrumb__item:first-child::before {
  content: '\E811';
  font-family: 'Glyphicons Regular';
  margin: 0 10px;
  color: #555;
  font-size: 14px;
}
