.section {
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  flex-shrink: 0;
}
.section__header {
  flex: 0 0 auto;
}
.section__content {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.section__content_children-padding.section__content_direction_column > * {
  margin-bottom: 15px;
}
.section__content_children-padding.section__content_direction_column > *:last-child {
  margin-bottom: 0;
}
.section__content_children-padding.section__content_direction_row > * {
  margin-right: 15px;
}
.section__content_children-padding.section__content_direction_row > *:last-child {
  margin-right: 0;
}
.section__content_scrollable {
  height: 0;
  flex: 1 0 auto;
  overflow: auto;
  white-space: nowrap;
}
.section__content_direction_row {
  flex-direction: row;
}
.section__content_hidden {
  display: none;
}
.section__content_layout_equal > * {
  flex: 1;
}
.section__content_layout_sidebar-left > :nth-child(2) {
  flex: 1 0 auto;
  width: 0;
}
.section__content_layout_sidebar-left > :nth-child(1) {
  flex: 0 0 400px;
  margin-right: 10px;
}
.section__content_layout_sidebar-left > :nth-child(n+3) {
  display: none;
}
.section__content_layout_sidebar-right > :nth-child(1) {
  flex: 1 0 auto;
  width: 0;
  margin-right: 10px;
}
.section__content_layout_sidebar-right > :nth-child(2) {
  flex: 0 0 400px;
}
.section__content_layout_sidebar-right > :nth-child(n+3) {
  display: none;
}
.section__content_has-overlay {
  position: relative;
}
.section_has-overlay {
  position: relative;
}
.section_height_fit {
  flex: 1 1 auto;
}
.section_height_fit-expand-only {
  flex: 1 0 auto;
}
.section_height_size1 {
  flex: 0 0 200px;
}
.section_width_half {
  width: 50%;
}
.section_width_full {
  width: 100%;
}
.section_width_threeQuarter {
  width: 75%;
}
.section_margin_bottom {
  margin-bottom: 30px;
}
.section_margin_top {
  margin-top: 30px;
}
.section_margin_half-top {
  margin-top: 15px;
}
.section_appearance_card {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.section_appearance_card > .section__content {
  border-radius: 0 0 4px 4px;
}
.section_content-padding.section_appearance_card > .section__content {
  padding: 15px;
}
.section_appearance_card > .section__header.toolbar {
  border-radius: 3px 3px 0 0;
}
.section_appearance_frame {
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  margin-top: 13px;
}
.section_appearance_frame > .section__content {
  border-radius: 0 0 4px 4px;
}
.section_content-padding.section_appearance_frame > .section__content {
  padding: 0 10px 10px 10px;
}
.section_appearance_frame > .section__content:first-child {
  padding-top: 10px;
}
.section_appearance_frame > .section__header {
  margin-top: -11px;
  height: 20px;
}
.section_appearance_frame > .section__header.toolbar {
  border: none;
  background: transparent;
}
.section_appearance_frame > .section__header.toolbar > .toolbar__entry {
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}
.section_content-padding.section_appearance_none > .section__content {
  padding: 15px;
}
.section_border-top-only {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.section .enlarged-height {
  height: 85px;
}
.section_display_table-display {
  display: table;
}
