/** 
 * Kendo UI v2016.3.1118 (http://www.telerik.com/kendo-ui)                                                                                                                                              
 * Copyright 2016 Telerik AD. All rights reserved.                                                                                                                                                      
 *                                                                                                                                                                                                      
 * Kendo UI commercial licenses may be obtained at                                                                                                                                                      
 * http://www.telerik.com/purchase/license-agreement/kendo-ui-complete                                                                                                                                  
 * If you do not own a commercial license, this file shall be governed by the trial license terms.                                                                                                      
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/
.fake {
  color: red;
}
.k-common-test-class {
  opacity: 0;
}
.k-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.k-floatwrap:after,
.k-grid-toolbar:after,
.k-slider-items:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.k-floatwrap,
.k-grid-toolbar,
.k-slider-items {
  display: inline-block;
}
.k-floatwrap,
.k-grid-toolbar,
.k-slider-items {
  display: block;
}
.k-autocomplete,
.k-autocomplete.k-state-hover,
.k-block,
.k-button,
.k-draghandle,
.k-dropdown-wrap,
.k-dropdown-wrap.k-state-hover,
.k-grid-header,
.k-grouping-header,
.k-header,
.k-link.k-state-hover,
.k-numeric-wrap,
.k-numeric-wrap.k-state-hover,
.k-pager-wrap,
.k-picker-wrap,
.k-picker-wrap.k-state-hover,
.k-tabstrip-items .k-item,
.k-textbox,
.k-textbox:hover,
.k-toolbar,
.k-tooltip {
  background-repeat: repeat;
  background-position: 0 center;
}
.k-link:hover {
  text-decoration: none;
}
.k-state-highlight > .k-link {
  color: inherit;
}
.k-button,
.k-input[type=text],
.k-input[type=number],
.k-picker-wrap .k-input,
.k-textbox,
.k-textbox > input {
  font-size: 100%;
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
}
.k-block,
.k-draghandle,
.k-inline-block,
.k-widget {
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
}
.k-block,
.k-widget {
  line-height: normal;
  outline: 0;
}
.k-list-filter ::-ms-clear,
.k-widget ::-ms-clear {
  width: 0;
  height: 0;
}
.k-block {
  padding: 2px;
}
.k-button,
.k-button-bare {
  display: inline-block;
  margin: 0;
  padding: 2px 7px 2px;
  font-family: inherit;
  line-height: 1.72em;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
.k-button-bare.k-state-disabled,
.k-button-bare.k-state-disabled:active,
.k-button-bare.k-state-disabled:hover,
.k-button-bare[disabled],
.k-button.k-state-disabled,
.k-button.k-state-disabled:active,
.k-button.k-state-disabled:hover,
.k-button[disabled],
.k-state-disabled .k-button,
.k-state-disabled .k-button-bare,
.k-state-disabled .k-button-bare:active,
.k-state-disabled .k-button-bare:hover,
.k-state-disabled .k-button:active,
.k-state-disabled .k-button:hover {
  cursor: default;
}
a.k-button,
a.k-button-bare {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}
.k-ff input.k-button {
  padding-bottom: 0.37em;
  padding-top: 0.37em;
}
.button.k-button-bare::-moz-focus-inner,
button.k-button::-moz-focus-inner,
input.k-button-bare::-moz-focus-inner,
input.k-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a.k-button-expand {
  display: block;
}
button.k-button-expand,
input[type=submit].k-button-expand,
input[type=button].k-button-expand,
input[type=reset].k-button-expand {
  width: 100%;
}
body .k-button-icon,
body .k-split-button-arrow {
  padding-left: 0.4em;
  padding-right: 0.4em;
}
.k-button-icontext {
  overflow: visible;
}
.k-toolbar .k-button-icontext {
  padding-right: 0.8em;
}
.k-button-icontext .k-icon,
.k-button-icontext .k-image,
.k-button-icontext .k-sprite {
  margin-right: 3px;
  margin-right: 0.3rem;
  margin-left: -3px;
  margin-left: -0.3rem;
}
.k-button.k-button-icontext .k-icon,
.k-button.k-button-icontext .k-image {
  vertical-align: text-top;
}
html body .k-button-bare {
  background: 0 0!important;
  color: inherit;
  border-width: 0;
  opacity: 0.7;
  -webkit-box-shadow: none;
  box-shadow: none;
}
html body .k-button-bare,
html body .k-button-bare.k-state-active,
html body .k-button-bare.k-state-active:hover,
html body .k-button-bare.k-state-hover,
html body .k-button-bare:active,
html body .k-button-bare:hover {
  color: inherit;
}
html body .k-button-bare .k-state-focused,
html body .k-button-bare:focus {
  background: 0 0;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  border-color: transparent;
  opacity: 0.8;
}
html body .k-button-bare.k-state-active,
html body .k-button-bare.k-state-hover,
html body .k-button-bare:active,
html body .k-button-bare:hover {
  opacity: 1;
}
html body .k-button-bare.k-upload-button:hover {
  color: inherit;
}
.k-link {
  cursor: pointer;
  outline: 0;
  text-decoration: none;
}
.k-grid-header span.k-link {
  cursor: default;
}
.k-state-disabled,
.k-state-disabled .k-button,
.k-state-disabled .k-draghandle,
.k-state-disabled .k-icon,
.k-state-disabled .k-link,
.k-state-disabled .k-upload-button input {
  cursor: default!important;
  outline: 0;
}
@media print {
  .k-state-disabled,
  .k-state-disabled .k-input {
    opacity: 1 !important;
  }
}
.k-state-error {
  border-style: ridge;
}
.k-state-empty {
  font-style: italic;
}
.k-icon,
.k-sprite,
.k-tool-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  text-align: center;
  -ms-high-contrast-adjust: none;
}
.k-button-bare .k-font-icon {
  overflow: visible;
}
.k-icon.k-i-none {
  background-image: none !important;
}
.k-ie8 .k-icon,
.k-ie8 .k-sprite,
.k-ie8 .k-tool-icon {
  vertical-align: middle;
}
:root * > .k-icon,
:root * > .k-sprite,
:root * > .k-tool-icon {
  vertical-align: middle;
}
.k-icon,
.k-sprite {
  background-color: transparent;
}
.k-numerictextbox .k-select .k-link span.k-i-arrow-n {
  background-position: 0 -3px;
}
.k-numerictextbox .k-select .k-link span.k-i-arrow-s {
  background-position: 0 -35px;
}
.k-state-selected .k-i-arrow-n {
  background-position: -16px 0;
}
.k-button:active .k-state-selected .k-i-arrow-n,
.k-button:not(.k-state-disabled):hover .k-state-selected .k-i-arrow-n,
.k-link:not(.k-state-disabled):hover > .k-state-selected .k-i-arrow-n,
.k-state-hover > * > .k-state-selected .k-i-arrow-n,
.k-state-hover > .k-state-selected .k-i-arrow-n,
.k-textbox:hover .k-state-selected .k-i-arrow-n {
  background-position: -32px 0;
}
.k-numerictextbox .k-link.k-state-selected span.k-i-arrow-n,
.k-numerictextbox .k-state-hover .k-link span.k-i-arrow-n {
  background-position: -16px -3px;
}
.k-state-selected .k-i-arrow-s {
  background-position: -16px -32px;
}
.k-button:active .k-state-selected .k-i-arrow-s,
.k-button:not(.k-state-disabled):hover .k-state-selected .k-i-arrow-s,
.k-link:not(.k-state-disabled):hover > .k-state-selected .k-i-arrow-s,
.k-state-hover > * > .k-state-selected .k-i-arrow-s,
.k-state-hover > .k-state-selected .k-i-arrow-s,
.k-textbox:hover .k-state-selected .k-i-arrow-s {
  background-position: -32px -32px;
}
.k-numerictextbox .k-link.k-state-selected span.k-i-arrow-s,
.k-numerictextbox .k-state-hover .k-link span.k-i-arrow-s {
  background-position: -16px -35px;
}
.k-grid-header th > .k-link:hover span.k-i-arrow-n {
  background-position: 0 0;
}
.k-grid-header th > .k-link:hover span.k-i-arrow-s {
  background-position: 0 -32px;
}
.k-group-indicator .k-link:hover span.k-i-sarrow-n,
.k-group-indicator .k-link:hover span.k-si-arrow-n {
  background-position: 0 -129px;
}
.k-group-indicator .k-link:hover span.k-i-sarrow-s,
.k-group-indicator .k-link:hover span.k-si-arrow-s {
  background-position: 0 -159px;
}
.k-group-indicator .k-button:hover span.k-group-delete,
.k-group-indicator .k-button:hover span.k-i-group-delete {
  background-position: -32px -16px;
}
.k-scheduler .k-scheduler-toolbar .k-nav-current .k-link .k-i-calendar {
  background-position: -32px -176px;
}
.k-i-arrow-n {
  background-position: 0 0;
}
.k-button:active .k-i-arrow-n,
.k-button:not(.k-state-disabled):hover .k-i-arrow-n,
.k-link:not(.k-state-disabled):hover > .k-i-arrow-n,
.k-state-hover > * > .k-i-arrow-n,
.k-state-hover > .k-i-arrow-n,
.k-textbox:hover .k-i-arrow-n {
  background-position: -16px 0;
}
.k-i-arrow-e {
  background-position: 0 -16px;
}
.k-button:active .k-i-arrow-e,
.k-button:not(.k-state-disabled):hover .k-i-arrow-e,
.k-link:not(.k-state-disabled):hover > .k-i-arrow-e,
.k-state-hover > * > .k-i-arrow-e,
.k-state-hover > .k-i-arrow-e,
.k-textbox:hover .k-i-arrow-e {
  background-position: -16px -16px;
}
.k-rtl .k-i-arrow-w {
  background-position: 0 -16px;
}
.k-rtl .k-button:active .k-i-arrow-w,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-arrow-w,
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-arrow-w,
.k-rtl .k-state-hover > * > .k-i-arrow-w,
.k-rtl .k-state-hover > .k-i-arrow-w,
.k-rtl .k-textbox:hover .k-i-arrow-w {
  background-position: -16px -16px;
}
.k-i-arrow-s {
  background-position: 0 -32px;
}
.k-button:active .k-i-arrow-s,
.k-button:not(.k-state-disabled):hover .k-i-arrow-s,
.k-link:not(.k-state-disabled):hover > .k-i-arrow-s,
.k-state-hover > * > .k-i-arrow-s,
.k-state-hover > .k-i-arrow-s,
.k-textbox:hover .k-i-arrow-s {
  background-position: -16px -32px;
}
.k-i-arrow-w {
  background-position: 0 -48px;
}
.k-button:active .k-i-arrow-w,
.k-button:not(.k-state-disabled):hover .k-i-arrow-w,
.k-link:not(.k-state-disabled):hover > .k-i-arrow-w,
.k-state-hover > * > .k-i-arrow-w,
.k-state-hover > .k-i-arrow-w,
.k-textbox:hover .k-i-arrow-w {
  background-position: -16px -48px;
}
.k-rtl .k-i-arrow-e {
  background-position: 0 -48px;
}
.k-rtl .k-button:active .k-i-arrow-e,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-arrow-e,
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-arrow-e,
.k-rtl .k-state-hover > * > .k-i-arrow-e,
.k-rtl .k-state-hover > .k-i-arrow-e,
.k-rtl .k-textbox:hover .k-i-arrow-e {
  background-position: -16px -48px;
}
.k-i-seek-n {
  background-position: 0 -64px;
}
.k-button:active .k-i-seek-n,
.k-button:not(.k-state-disabled):hover .k-i-seek-n,
.k-link:not(.k-state-disabled):hover > .k-i-seek-n,
.k-state-hover > * > .k-i-seek-n,
.k-state-hover > .k-i-seek-n,
.k-textbox:hover .k-i-seek-n {
  background-position: -16px -64px;
}
.k-i-seek-e {
  background-position: 0 -80px;
}
.k-button:active .k-i-seek-e,
.k-button:not(.k-state-disabled):hover .k-i-seek-e,
.k-link:not(.k-state-disabled):hover > .k-i-seek-e,
.k-state-hover > * > .k-i-seek-e,
.k-state-hover > .k-i-seek-e,
.k-textbox:hover .k-i-seek-e {
  background-position: -16px -80px;
}
.k-rtl .k-i-seek-w {
  background-position: 0 -80px;
}
.k-rtl .k-button:active .k-i-seek-w,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-seek-w,
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-seek-w,
.k-rtl .k-state-hover > * > .k-i-seek-w,
.k-rtl .k-state-hover > .k-i-seek-w,
.k-rtl .k-textbox:hover .k-i-seek-w {
  background-position: -16px -80px;
}
.k-i-seek-s {
  background-position: 0 -96px;
}
.k-button:active .k-i-seek-s,
.k-button:not(.k-state-disabled):hover .k-i-seek-s,
.k-link:not(.k-state-disabled):hover > .k-i-seek-s,
.k-state-hover > * > .k-i-seek-s,
.k-state-hover > .k-i-seek-s,
.k-textbox:hover .k-i-seek-s {
  background-position: -16px -96px;
}
.k-i-seek-w {
  background-position: 0 -112px;
}
.k-button:active .k-i-seek-w,
.k-button:not(.k-state-disabled):hover .k-i-seek-w,
.k-link:not(.k-state-disabled):hover > .k-i-seek-w,
.k-state-hover > * > .k-i-seek-w,
.k-state-hover > .k-i-seek-w,
.k-textbox:hover .k-i-seek-w {
  background-position: -16px -112px;
}
.k-rtl .k-i-seek-e {
  background-position: 0 -112px;
}
.k-rtl .k-button:active .k-i-seek-e,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-seek-e,
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-seek-e,
.k-rtl .k-state-hover > * > .k-i-seek-e,
.k-rtl .k-state-hover > .k-i-seek-e,
.k-rtl .k-textbox:hover .k-i-seek-e {
  background-position: -16px -112px;
}
.k-i-sarrow-n,
.k-si-arrow-n {
  background-position: 0 -129px;
}
.k-button:active .k-i-sarrow-n,
.k-button:active .k-si-arrow-n,
.k-button:not(.k-state-disabled):hover .k-i-sarrow-n,
.k-button:not(.k-state-disabled):hover .k-si-arrow-n,
.k-link:not(.k-state-disabled):hover > .k-i-sarrow-n,
.k-link:not(.k-state-disabled):hover > .k-si-arrow-n,
.k-state-hover > * > .k-i-sarrow-n,
.k-state-hover > * > .k-si-arrow-n,
.k-state-hover > .k-i-sarrow-n,
.k-state-hover > .k-si-arrow-n,
.k-textbox:hover .k-i-sarrow-n,
.k-textbox:hover .k-si-arrow-n {
  background-position: -16px -129px;
}
.k-i-sarrow-e,
.k-si-arrow-e {
  background-position: 0 -144px;
}
.k-button:active .k-i-sarrow-e,
.k-button:active .k-si-arrow-e,
.k-button:not(.k-state-disabled):hover .k-i-sarrow-e,
.k-button:not(.k-state-disabled):hover .k-si-arrow-e,
.k-link:not(.k-state-disabled):hover > .k-i-sarrow-e,
.k-link:not(.k-state-disabled):hover > .k-si-arrow-e,
.k-state-hover > * > .k-i-sarrow-e,
.k-state-hover > * > .k-si-arrow-e,
.k-state-hover > .k-i-sarrow-e,
.k-state-hover > .k-si-arrow-e,
.k-textbox:hover .k-i-sarrow-e,
.k-textbox:hover .k-si-arrow-e {
  background-position: -16px -144px;
}
.k-i-sarrow-s,
.k-si-arrow-s {
  background-position: 0 -159px;
}
.k-button:active .k-i-sarrow-s,
.k-button:active .k-si-arrow-s,
.k-button:not(.k-state-disabled):hover .k-i-sarrow-s,
.k-button:not(.k-state-disabled):hover .k-si-arrow-s,
.k-link:not(.k-state-disabled):hover > .k-i-sarrow-s,
.k-link:not(.k-state-disabled):hover > .k-si-arrow-s,
.k-state-hover > * > .k-i-sarrow-s,
.k-state-hover > * > .k-si-arrow-s,
.k-state-hover > .k-i-sarrow-s,
.k-state-hover > .k-si-arrow-s,
.k-textbox:hover .k-i-sarrow-s,
.k-textbox:hover .k-si-arrow-s {
  background-position: -16px -159px;
}
.k-i-sarrow-w,
.k-si-arrow-w {
  background-position: 0 -176px;
}
.k-button:active .k-i-sarrow-w,
.k-button:active .k-si-arrow-w,
.k-button:not(.k-state-disabled):hover .k-i-sarrow-w,
.k-button:not(.k-state-disabled):hover .k-si-arrow-w,
.k-link:not(.k-state-disabled):hover > .k-i-sarrow-w,
.k-link:not(.k-state-disabled):hover > .k-si-arrow-w,
.k-state-hover > * > .k-i-sarrow-w,
.k-state-hover > * > .k-si-arrow-w,
.k-state-hover > .k-i-sarrow-w,
.k-state-hover > .k-si-arrow-w,
.k-textbox:hover .k-i-sarrow-w,
.k-textbox:hover .k-si-arrow-w {
  background-position: -16px -176px;
}
.k-i-arrowhead-n {
  background-position: 0 -256px;
}
.k-button:active .k-i-arrowhead-n,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-n,
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-n,
.k-state-hover > * > .k-i-arrowhead-n,
.k-state-hover > .k-i-arrowhead-n,
.k-textbox:hover .k-i-arrowhead-n {
  background-position: -16px -256px;
}
.k-i-arrowhead-e {
  background-position: 0 -272px;
}
.k-button:active .k-i-arrowhead-e,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-e,
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-e,
.k-state-hover > * > .k-i-arrowhead-e,
.k-state-hover > .k-i-arrowhead-e,
.k-textbox:hover .k-i-arrowhead-e {
  background-position: -16px -272px;
}
.k-i-arrowhead-s {
  background-position: 0 -288px;
}
.k-button:active .k-i-arrowhead-s,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-s,
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-s,
.k-state-hover > * > .k-i-arrowhead-s,
.k-state-hover > .k-i-arrowhead-s,
.k-textbox:hover .k-i-arrowhead-s {
  background-position: -16px -288px;
}
.k-i-arrowhead-w {
  background-position: 0 -304px;
}
.k-button:active .k-i-arrowhead-w,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-w,
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-w,
.k-state-hover > * > .k-i-arrowhead-w,
.k-state-hover > .k-i-arrowhead-w,
.k-textbox:hover .k-i-arrowhead-w {
  background-position: -16px -304px;
}
.k-i-expand,
.k-i-plus,
.k-i-plus-disabled,
.k-plus,
.k-plus-disabled {
  background-position: 0 -192px;
}
.k-button:active .k-i-expand,
.k-button:active .k-i-plus,
.k-button:active .k-i-plus-disabled,
.k-button:active .k-plus,
.k-button:active .k-plus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-expand,
.k-button:not(.k-state-disabled):hover .k-i-plus,
.k-button:not(.k-state-disabled):hover .k-i-plus-disabled,
.k-button:not(.k-state-disabled):hover .k-plus,
.k-button:not(.k-state-disabled):hover .k-plus-disabled,
.k-link:not(.k-state-disabled):hover > .k-i-expand,
.k-link:not(.k-state-disabled):hover > .k-i-plus,
.k-link:not(.k-state-disabled):hover > .k-i-plus-disabled,
.k-link:not(.k-state-disabled):hover > .k-plus,
.k-link:not(.k-state-disabled):hover > .k-plus-disabled,
.k-state-hover > * > .k-i-expand,
.k-state-hover > * > .k-i-plus,
.k-state-hover > * > .k-i-plus-disabled,
.k-state-hover > * > .k-plus,
.k-state-hover > * > .k-plus-disabled,
.k-state-hover > .k-i-expand,
.k-state-hover > .k-i-plus,
.k-state-hover > .k-i-plus-disabled,
.k-state-hover > .k-plus,
.k-state-hover > .k-plus-disabled,
.k-textbox:hover .k-i-expand,
.k-textbox:hover .k-i-plus,
.k-textbox:hover .k-i-plus-disabled,
.k-textbox:hover .k-plus,
.k-textbox:hover .k-plus-disabled {
  background-position: -16px -192px;
}
.k-i-expand-w,
.k-rtl .k-i-expand,
.k-rtl .k-i-plus,
.k-rtl .k-i-plus-disabled,
.k-rtl .k-plus,
.k-rtl .k-plus-disabled {
  background-position: 0 -208px;
}
.k-button:active .k-i-expand-w,
.k-button:active .k-rtl .k-i-expand,
.k-button:active .k-rtl .k-i-plus,
.k-button:active .k-rtl .k-i-plus-disabled,
.k-button:active .k-rtl .k-plus,
.k-button:active .k-rtl .k-plus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-expand-w,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-expand,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-plus,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-plus-disabled,
.k-button:not(.k-state-disabled):hover .k-rtl .k-plus,
.k-button:not(.k-state-disabled):hover .k-rtl .k-plus-disabled,
.k-link:not(.k-state-disabled):hover > .k-i-expand-w,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-expand,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-plus,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-plus-disabled,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-plus,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-plus-disabled,
.k-state-hover > * > .k-i-expand-w,
.k-state-hover > * > .k-rtl .k-i-expand,
.k-state-hover > * > .k-rtl .k-i-plus,
.k-state-hover > * > .k-rtl .k-i-plus-disabled,
.k-state-hover > * > .k-rtl .k-plus,
.k-state-hover > * > .k-rtl .k-plus-disabled,
.k-state-hover > .k-i-expand-w,
.k-state-hover > .k-rtl .k-i-expand,
.k-state-hover > .k-rtl .k-i-plus,
.k-state-hover > .k-rtl .k-i-plus-disabled,
.k-state-hover > .k-rtl .k-plus,
.k-state-hover > .k-rtl .k-plus-disabled,
.k-textbox:hover .k-i-expand-w,
.k-textbox:hover .k-rtl .k-i-expand,
.k-textbox:hover .k-rtl .k-i-plus,
.k-textbox:hover .k-rtl .k-i-plus-disabled,
.k-textbox:hover .k-rtl .k-plus,
.k-textbox:hover .k-rtl .k-plus-disabled {
  background-position: -16px -208px;
}
.k-i-collapse,
.k-i-minus,
.k-i-minus-disabled,
.k-minus,
.k-minus-disabled {
  background-position: 0 -224px;
}
.k-button:active .k-i-collapse,
.k-button:active .k-i-minus,
.k-button:active .k-i-minus-disabled,
.k-button:active .k-minus,
.k-button:active .k-minus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-collapse,
.k-button:not(.k-state-disabled):hover .k-i-minus,
.k-button:not(.k-state-disabled):hover .k-i-minus-disabled,
.k-button:not(.k-state-disabled):hover .k-minus,
.k-button:not(.k-state-disabled):hover .k-minus-disabled,
.k-link:not(.k-state-disabled):hover > .k-i-collapse,
.k-link:not(.k-state-disabled):hover > .k-i-minus,
.k-link:not(.k-state-disabled):hover > .k-i-minus-disabled,
.k-link:not(.k-state-disabled):hover > .k-minus,
.k-link:not(.k-state-disabled):hover > .k-minus-disabled,
.k-state-hover > * > .k-i-collapse,
.k-state-hover > * > .k-i-minus,
.k-state-hover > * > .k-i-minus-disabled,
.k-state-hover > * > .k-minus,
.k-state-hover > * > .k-minus-disabled,
.k-state-hover > .k-i-collapse,
.k-state-hover > .k-i-minus,
.k-state-hover > .k-i-minus-disabled,
.k-state-hover > .k-minus,
.k-state-hover > .k-minus-disabled,
.k-textbox:hover .k-i-collapse,
.k-textbox:hover .k-i-minus,
.k-textbox:hover .k-i-minus-disabled,
.k-textbox:hover .k-minus,
.k-textbox:hover .k-minus-disabled {
  background-position: -16px -224px;
}
.k-i-collapse-w,
.k-rtl .k-i-collapse,
.k-rtl .k-i-minus,
.k-rtl .k-i-minus-disabled,
.k-rtl .k-minus,
.k-rtl .k-minus-disabled {
  background-position: 0 -240px;
}
.k-button:active .k-i-collapse-w,
.k-button:active .k-rtl .k-i-collapse,
.k-button:active .k-rtl .k-i-minus,
.k-button:active .k-rtl .k-i-minus-disabled,
.k-button:active .k-rtl .k-minus,
.k-button:active .k-rtl .k-minus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-collapse-w,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-collapse,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-minus,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-minus-disabled,
.k-button:not(.k-state-disabled):hover .k-rtl .k-minus,
.k-button:not(.k-state-disabled):hover .k-rtl .k-minus-disabled,
.k-link:not(.k-state-disabled):hover > .k-i-collapse-w,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-collapse,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-minus,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-minus-disabled,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-minus,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-minus-disabled,
.k-state-hover > * > .k-i-collapse-w,
.k-state-hover > * > .k-rtl .k-i-collapse,
.k-state-hover > * > .k-rtl .k-i-minus,
.k-state-hover > * > .k-rtl .k-i-minus-disabled,
.k-state-hover > * > .k-rtl .k-minus,
.k-state-hover > * > .k-rtl .k-minus-disabled,
.k-state-hover > .k-i-collapse-w,
.k-state-hover > .k-rtl .k-i-collapse,
.k-state-hover > .k-rtl .k-i-minus,
.k-state-hover > .k-rtl .k-i-minus-disabled,
.k-state-hover > .k-rtl .k-minus,
.k-state-hover > .k-rtl .k-minus-disabled,
.k-textbox:hover .k-i-collapse-w,
.k-textbox:hover .k-rtl .k-i-collapse,
.k-textbox:hover .k-rtl .k-i-minus,
.k-textbox:hover .k-rtl .k-i-minus-disabled,
.k-textbox:hover .k-rtl .k-minus,
.k-textbox:hover .k-rtl .k-minus-disabled {
  background-position: -16px -240px;
}
.k-edit,
.k-i-edit,
.k-i-pencil {
  background-position: -32px 0;
}
.k-button:active .k-edit,
.k-button:active .k-i-edit,
.k-button:active .k-i-pencil,
.k-button:not(.k-state-disabled):hover .k-edit,
.k-button:not(.k-state-disabled):hover .k-i-edit,
.k-button:not(.k-state-disabled):hover .k-i-pencil,
.k-link:not(.k-state-disabled):hover > .k-edit,
.k-link:not(.k-state-disabled):hover > .k-i-edit,
.k-link:not(.k-state-disabled):hover > .k-i-pencil,
.k-state-hover > * > .k-edit,
.k-state-hover > * > .k-i-edit,
.k-state-hover > * > .k-i-pencil,
.k-state-hover > .k-edit,
.k-state-hover > .k-i-edit,
.k-state-hover > .k-i-pencil,
.k-textbox:hover .k-edit,
.k-textbox:hover .k-i-edit,
.k-textbox:hover .k-i-pencil {
  background-position: -48px 0;
}
.k-delete,
.k-group-delete,
.k-i-close,
.k-i-delete,
.k-i-group-delete {
  background-position: -32px -16px;
}
.k-button:active .k-delete,
.k-button:active .k-group-delete,
.k-button:active .k-i-close,
.k-button:active .k-i-delete,
.k-button:active .k-i-group-delete,
.k-button:not(.k-state-disabled):hover .k-delete,
.k-button:not(.k-state-disabled):hover .k-group-delete,
.k-button:not(.k-state-disabled):hover .k-i-close,
.k-button:not(.k-state-disabled):hover .k-i-delete,
.k-button:not(.k-state-disabled):hover .k-i-group-delete,
.k-link:not(.k-state-disabled):hover > .k-delete,
.k-link:not(.k-state-disabled):hover > .k-group-delete,
.k-link:not(.k-state-disabled):hover > .k-i-close,
.k-link:not(.k-state-disabled):hover > .k-i-delete,
.k-link:not(.k-state-disabled):hover > .k-i-group-delete,
.k-state-hover > * > .k-delete,
.k-state-hover > * > .k-group-delete,
.k-state-hover > * > .k-i-close,
.k-state-hover > * > .k-i-delete,
.k-state-hover > * > .k-i-group-delete,
.k-state-hover > .k-delete,
.k-state-hover > .k-group-delete,
.k-state-hover > .k-i-close,
.k-state-hover > .k-i-delete,
.k-state-hover > .k-i-group-delete,
.k-textbox:hover .k-delete,
.k-textbox:hover .k-group-delete,
.k-textbox:hover .k-i-close,
.k-textbox:hover .k-i-delete,
.k-textbox:hover .k-i-group-delete {
  background-position: -48px -16px;
}
.k-si-close {
  background-position: -160px -80px;
}
.k-button:active .k-si-close,
.k-button:not(.k-state-disabled):hover .k-si-close,
.k-link:not(.k-state-disabled):hover > .k-si-close,
.k-state-hover > * > .k-si-close,
.k-state-hover > .k-si-close,
.k-textbox:hover .k-si-close {
  background-position: -176px -80px;
}
.k-multiselect .k-delete,
.k-multiselect .k-i-delete {
  background-position: -160px -80px;
}
.k-multiselect .k-state-hover .k-delete,
.k-multiselect .k-state-hover .k-i-delete {
  background-position: -176px -80px;
}
.k-i-insert,
.k-i-tick,
.k-i-update,
.k-insert,
.k-update {
  background-position: -32px -32px;
}
.k-button:active .k-i-insert,
.k-button:active .k-i-tick,
.k-button:active .k-i-update,
.k-button:active .k-insert,
.k-button:active .k-update,
.k-button:not(.k-state-disabled):hover .k-i-insert,
.k-button:not(.k-state-disabled):hover .k-i-tick,
.k-button:not(.k-state-disabled):hover .k-i-update,
.k-button:not(.k-state-disabled):hover .k-insert,
.k-button:not(.k-state-disabled):hover .k-update,
.k-link:not(.k-state-disabled):hover > .k-i-insert,
.k-link:not(.k-state-disabled):hover > .k-i-tick,
.k-link:not(.k-state-disabled):hover > .k-i-update,
.k-link:not(.k-state-disabled):hover > .k-insert,
.k-link:not(.k-state-disabled):hover > .k-update,
.k-state-hover > * > .k-i-insert,
.k-state-hover > * > .k-i-tick,
.k-state-hover > * > .k-i-update,
.k-state-hover > * > .k-insert,
.k-state-hover > * > .k-update,
.k-state-hover > .k-i-insert,
.k-state-hover > .k-i-tick,
.k-state-hover > .k-i-update,
.k-state-hover > .k-insert,
.k-state-hover > .k-update,
.k-textbox:hover .k-i-insert,
.k-textbox:hover .k-i-tick,
.k-textbox:hover .k-i-update,
.k-textbox:hover .k-insert,
.k-textbox:hover .k-update {
  background-position: -48px -32px;
}
.k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox],
.k-mobile-list .k-edit-field [type=radio] {
  background-position: -32px -32px;
}
.k-button:active .k-check:checked,
.k-button:active .k-mobile-list .k-edit-field [type=checkbox],
.k-button:active .k-mobile-list .k-edit-field [type=radio],
.k-button:not(.k-state-disabled):hover .k-check:checked,
.k-button:not(.k-state-disabled):hover .k-mobile-list .k-edit-field [type=checkbox],
.k-button:not(.k-state-disabled):hover .k-mobile-list .k-edit-field [type=radio],
.k-link:not(.k-state-disabled):hover > .k-check:checked,
.k-link:not(.k-state-disabled):hover > .k-mobile-list .k-edit-field [type=checkbox],
.k-link:not(.k-state-disabled):hover > .k-mobile-list .k-edit-field [type=radio],
.k-state-hover > * > .k-check:checked,
.k-state-hover > * > .k-mobile-list .k-edit-field [type=checkbox],
.k-state-hover > * > .k-mobile-list .k-edit-field [type=radio],
.k-state-hover > .k-check:checked,
.k-state-hover > .k-mobile-list .k-edit-field [type=checkbox],
.k-state-hover > .k-mobile-list .k-edit-field [type=radio],
.k-textbox:hover .k-check:checked,
.k-textbox:hover .k-mobile-list .k-edit-field [type=checkbox],
.k-textbox:hover .k-mobile-list .k-edit-field [type=radio] {
  background-position: -48px -32px;
}
.k-cancel,
.k-denied,
.k-i-cancel,
.k-i-denied {
  background-position: -32px -48px;
}
.k-button:active .k-cancel,
.k-button:active .k-denied,
.k-button:active .k-i-cancel,
.k-button:active .k-i-denied,
.k-button:not(.k-state-disabled):hover .k-cancel,
.k-button:not(.k-state-disabled):hover .k-denied,
.k-button:not(.k-state-disabled):hover .k-i-cancel,
.k-button:not(.k-state-disabled):hover .k-i-denied,
.k-link:not(.k-state-disabled):hover > .k-cancel,
.k-link:not(.k-state-disabled):hover > .k-denied,
.k-link:not(.k-state-disabled):hover > .k-i-cancel,
.k-link:not(.k-state-disabled):hover > .k-i-denied,
.k-state-hover > * > .k-cancel,
.k-state-hover > * > .k-denied,
.k-state-hover > * > .k-i-cancel,
.k-state-hover > * > .k-i-denied,
.k-state-hover > .k-cancel,
.k-state-hover > .k-denied,
.k-state-hover > .k-i-cancel,
.k-state-hover > .k-i-denied,
.k-textbox:hover .k-cancel,
.k-textbox:hover .k-denied,
.k-textbox:hover .k-i-cancel,
.k-textbox:hover .k-i-denied {
  background-position: -48px -48px;
}
.k-add,
.k-i-add,
.k-i-plus {
  background-position: -32px -64px;
}
.k-button:active .k-add,
.k-button:active .k-i-add,
.k-button:active .k-i-plus,
.k-button:not(.k-state-disabled):hover .k-add,
.k-button:not(.k-state-disabled):hover .k-i-add,
.k-button:not(.k-state-disabled):hover .k-i-plus,
.k-link:not(.k-state-disabled):hover > .k-add,
.k-link:not(.k-state-disabled):hover > .k-i-add,
.k-link:not(.k-state-disabled):hover > .k-i-plus,
.k-state-hover > * > .k-add,
.k-state-hover > * > .k-i-add,
.k-state-hover > * > .k-i-plus,
.k-state-hover > .k-add,
.k-state-hover > .k-i-add,
.k-state-hover > .k-i-plus,
.k-textbox:hover .k-add,
.k-textbox:hover .k-i-add,
.k-textbox:hover .k-i-plus {
  background-position: -48px -64px;
}
.k-filter,
.k-i-filter,
.k-i-funnel {
  background-position: -32px -80px;
}
.k-button:active .k-filter,
.k-button:active .k-i-filter,
.k-button:active .k-i-funnel,
.k-button:not(.k-state-disabled):hover .k-filter,
.k-button:not(.k-state-disabled):hover .k-i-filter,
.k-button:not(.k-state-disabled):hover .k-i-funnel,
.k-link:not(.k-state-disabled):hover > .k-filter,
.k-link:not(.k-state-disabled):hover > .k-i-filter,
.k-link:not(.k-state-disabled):hover > .k-i-funnel,
.k-state-hover > * > .k-filter,
.k-state-hover > * > .k-i-filter,
.k-state-hover > * > .k-i-funnel,
.k-state-hover > .k-filter,
.k-state-hover > .k-i-filter,
.k-state-hover > .k-i-funnel,
.k-textbox:hover .k-filter,
.k-textbox:hover .k-i-filter,
.k-textbox:hover .k-i-funnel {
  background-position: -48px -80px;
}
.k-clear-filter,
.k-i-clear-filter,
.k-i-funnel-clear {
  background-position: -32px -96px;
}
.k-button:active .k-clear-filter,
.k-button:active .k-i-clear-filter,
.k-button:active .k-i-funnel-clear,
.k-button:not(.k-state-disabled):hover .k-clear-filter,
.k-button:not(.k-state-disabled):hover .k-i-clear-filter,
.k-button:not(.k-state-disabled):hover .k-i-funnel-clear,
.k-link:not(.k-state-disabled):hover > .k-clear-filter,
.k-link:not(.k-state-disabled):hover > .k-i-clear-filter,
.k-link:not(.k-state-disabled):hover > .k-i-funnel-clear,
.k-state-hover > * > .k-clear-filter,
.k-state-hover > * > .k-i-clear-filter,
.k-state-hover > * > .k-i-funnel-clear,
.k-state-hover > .k-clear-filter,
.k-state-hover > .k-i-clear-filter,
.k-state-hover > .k-i-funnel-clear,
.k-textbox:hover .k-clear-filter,
.k-textbox:hover .k-i-clear-filter,
.k-textbox:hover .k-i-funnel-clear {
  background-position: -48px -96px;
}
.k-i-lock {
  background-position: -64px 0;
}
.k-button:active .k-i-lock,
.k-button:not(.k-state-disabled):hover .k-i-lock,
.k-link:not(.k-state-disabled):hover > .k-i-lock,
.k-state-hover > * > .k-i-lock,
.k-state-hover > .k-i-lock,
.k-textbox:hover .k-i-lock {
  background-position: -80px 0;
}
.k-i-unlock {
  background-position: -64px -16px;
}
.k-button:active .k-i-unlock,
.k-button:not(.k-state-disabled):hover .k-i-unlock,
.k-link:not(.k-state-disabled):hover > .k-i-unlock,
.k-state-hover > * > .k-i-unlock,
.k-state-hover > .k-i-unlock,
.k-textbox:hover .k-i-unlock {
  background-position: -80px -16px;
}
.k-i-refresh {
  background-position: -32px -112px;
}
.k-button:active .k-i-refresh,
.k-button:not(.k-state-disabled):hover .k-i-refresh,
.k-link:not(.k-state-disabled):hover > .k-i-refresh,
.k-state-hover > * > .k-i-refresh,
.k-state-hover > .k-i-refresh,
.k-textbox:hover .k-i-refresh {
  background-position: -48px -112px;
}
.k-i-exception {
  background-position: -160px -304px;
}
.k-button:active .k-i-exception,
.k-button:not(.k-state-disabled):hover .k-i-exception,
.k-link:not(.k-state-disabled):hover > .k-i-exception,
.k-state-hover > * > .k-i-exception,
.k-state-hover > .k-i-exception,
.k-textbox:hover .k-i-exception {
  background-position: -176px -304px;
}
.k-i-restore {
  background-position: -32px -128px;
}
.k-button:active .k-i-restore,
.k-button:not(.k-state-disabled):hover .k-i-restore,
.k-link:not(.k-state-disabled):hover > .k-i-restore,
.k-state-hover > * > .k-i-restore,
.k-state-hover > .k-i-restore,
.k-textbox:hover .k-i-restore {
  background-position: -48px -128px;
}
.k-i-maximize {
  background-position: -32px -144px;
}
.k-button:active .k-i-maximize,
.k-button:not(.k-state-disabled):hover .k-i-maximize,
.k-link:not(.k-state-disabled):hover > .k-i-maximize,
.k-state-hover > * > .k-i-maximize,
.k-state-hover > .k-i-maximize,
.k-textbox:hover .k-i-maximize {
  background-position: -48px -144px;
}
.k-i-minimize {
  background-position: -64px -288px;
}
.k-button:active .k-i-minimize,
.k-button:not(.k-state-disabled):hover .k-i-minimize,
.k-link:not(.k-state-disabled):hover > .k-i-minimize,
.k-state-hover > * > .k-i-minimize,
.k-state-hover > .k-i-minimize,
.k-textbox:hover .k-i-minimize {
  background-position: -80px -288px;
}
.k-i-pin {
  background-position: -160px -256px;
}
.k-button:active .k-i-pin,
.k-button:not(.k-state-disabled):hover .k-i-pin,
.k-link:not(.k-state-disabled):hover > .k-i-pin,
.k-state-hover > * > .k-i-pin,
.k-state-hover > .k-i-pin,
.k-textbox:hover .k-i-pin {
  background-position: -176px -256px;
}
.k-i-unpin {
  background-position: -160px -272px;
}
.k-button:active .k-i-unpin,
.k-button:not(.k-state-disabled):hover .k-i-unpin,
.k-link:not(.k-state-disabled):hover > .k-i-unpin,
.k-state-hover > * > .k-i-unpin,
.k-state-hover > .k-i-unpin,
.k-textbox:hover .k-i-unpin {
  background-position: -176px -272px;
}
.k-i-resize-se,
.k-resize-se {
  background-position: -32px -160px;
}
.k-button:active .k-i-resize-se,
.k-button:active .k-resize-se,
.k-button:not(.k-state-disabled):hover .k-i-resize-se,
.k-button:not(.k-state-disabled):hover .k-resize-se,
.k-link:not(.k-state-disabled):hover > .k-i-resize-se,
.k-link:not(.k-state-disabled):hover > .k-resize-se,
.k-state-hover > * > .k-i-resize-se,
.k-state-hover > * > .k-resize-se,
.k-state-hover > .k-i-resize-se,
.k-state-hover > .k-resize-se,
.k-textbox:hover .k-i-resize-se,
.k-textbox:hover .k-resize-se {
  background-position: -48px -160px;
}
.k-i-calendar {
  background-position: -32px -176px;
}
.k-button:active .k-i-calendar,
.k-button:not(.k-state-disabled):hover .k-i-calendar,
.k-link:not(.k-state-disabled):hover > .k-i-calendar,
.k-state-hover > * > .k-i-calendar,
.k-state-hover > .k-i-calendar,
.k-textbox:hover .k-i-calendar {
  background-position: -48px -176px;
}
.k-i-clock {
  background-position: -32px -192px;
}
.k-button:active .k-i-clock,
.k-button:not(.k-state-disabled):hover .k-i-clock,
.k-link:not(.k-state-disabled):hover > .k-i-clock,
.k-state-hover > * > .k-i-clock,
.k-state-hover > .k-i-clock,
.k-textbox:hover .k-i-clock {
  background-position: -48px -192px;
}
.k-i-splus,
.k-si-plus {
  background-position: -32px -208px;
}
.k-button:active .k-i-splus,
.k-button:active .k-si-plus,
.k-button:not(.k-state-disabled):hover .k-i-splus,
.k-button:not(.k-state-disabled):hover .k-si-plus,
.k-link:not(.k-state-disabled):hover > .k-i-splus,
.k-link:not(.k-state-disabled):hover > .k-si-plus,
.k-state-hover > * > .k-i-splus,
.k-state-hover > * > .k-si-plus,
.k-state-hover > .k-i-splus,
.k-state-hover > .k-si-plus,
.k-textbox:hover .k-i-splus,
.k-textbox:hover .k-si-plus {
  background-position: -48px -208px;
}
.k-i-sminus,
.k-si-minus {
  background-position: -32px -224px;
}
.k-button:active .k-i-sminus,
.k-button:active .k-si-minus,
.k-button:not(.k-state-disabled):hover .k-i-sminus,
.k-button:not(.k-state-disabled):hover .k-si-minus,
.k-link:not(.k-state-disabled):hover > .k-i-sminus,
.k-link:not(.k-state-disabled):hover > .k-si-minus,
.k-state-hover > * > .k-i-sminus,
.k-state-hover > * > .k-si-minus,
.k-state-hover > .k-i-sminus,
.k-state-hover > .k-si-minus,
.k-textbox:hover .k-i-sminus,
.k-textbox:hover .k-si-minus {
  background-position: -48px -224px;
}
.k-i-search {
  background-position: -32px -240px;
}
.k-button:active .k-i-search,
.k-button:not(.k-state-disabled):hover .k-i-search,
.k-link:not(.k-state-disabled):hover > .k-i-search,
.k-state-hover > * > .k-i-search,
.k-state-hover > .k-i-search,
.k-textbox:hover .k-i-search {
  background-position: -48px -240px;
}
.k-i-custom {
  background-position: -115px -113px;
}
.k-button:active .k-i-custom,
.k-button:not(.k-state-disabled):hover .k-i-custom,
.k-link:not(.k-state-disabled):hover > .k-i-custom,
.k-state-hover > * > .k-i-custom,
.k-state-hover > .k-i-custom,
.k-textbox:hover .k-i-custom {
  background-position: -141px -113px;
}
.k-editor .k-i-custom {
  background-position: -111px -109px;
}
.k-i-view-html,
.k-viewHtml {
  background-position: -288px -120px;
}
.k-i-insert-n,
.k-i-insert-top,
.k-insert-top {
  background-position: -160px -32px;
}
.k-button:active .k-i-insert-n,
.k-button:active .k-i-insert-top,
.k-button:active .k-insert-top,
.k-button:not(.k-state-disabled):hover .k-i-insert-n,
.k-button:not(.k-state-disabled):hover .k-i-insert-top,
.k-button:not(.k-state-disabled):hover .k-insert-top,
.k-link:not(.k-state-disabled):hover > .k-i-insert-n,
.k-link:not(.k-state-disabled):hover > .k-i-insert-top,
.k-link:not(.k-state-disabled):hover > .k-insert-top,
.k-state-hover > * > .k-i-insert-n,
.k-state-hover > * > .k-i-insert-top,
.k-state-hover > * > .k-insert-top,
.k-state-hover > .k-i-insert-n,
.k-state-hover > .k-i-insert-top,
.k-state-hover > .k-insert-top,
.k-textbox:hover .k-i-insert-n,
.k-textbox:hover .k-i-insert-top,
.k-textbox:hover .k-insert-top {
  background-position: -176px -32px;
}
.k-i-insert-m,
.k-i-insert-middle,
.k-insert-middle {
  background-position: -160px -48px;
}
.k-button:active .k-i-insert-m,
.k-button:active .k-i-insert-middle,
.k-button:active .k-insert-middle,
.k-button:not(.k-state-disabled):hover .k-i-insert-m,
.k-button:not(.k-state-disabled):hover .k-i-insert-middle,
.k-button:not(.k-state-disabled):hover .k-insert-middle,
.k-link:not(.k-state-disabled):hover > .k-i-insert-m,
.k-link:not(.k-state-disabled):hover > .k-i-insert-middle,
.k-link:not(.k-state-disabled):hover > .k-insert-middle,
.k-state-hover > * > .k-i-insert-m,
.k-state-hover > * > .k-i-insert-middle,
.k-state-hover > * > .k-insert-middle,
.k-state-hover > .k-i-insert-m,
.k-state-hover > .k-i-insert-middle,
.k-state-hover > .k-insert-middle,
.k-textbox:hover .k-i-insert-m,
.k-textbox:hover .k-i-insert-middle,
.k-textbox:hover .k-insert-middle {
  background-position: -176px -48px;
}
.k-i-insert-bottom,
.k-i-insert-s,
.k-insert-bottom {
  background-position: -160px -64px;
}
.k-button:active .k-i-insert-bottom,
.k-button:active .k-i-insert-s,
.k-button:active .k-insert-bottom,
.k-button:not(.k-state-disabled):hover .k-i-insert-bottom,
.k-button:not(.k-state-disabled):hover .k-i-insert-s,
.k-button:not(.k-state-disabled):hover .k-insert-bottom,
.k-link:not(.k-state-disabled):hover > .k-i-insert-bottom,
.k-link:not(.k-state-disabled):hover > .k-i-insert-s,
.k-link:not(.k-state-disabled):hover > .k-insert-bottom,
.k-state-hover > * > .k-i-insert-bottom,
.k-state-hover > * > .k-i-insert-s,
.k-state-hover > * > .k-insert-bottom,
.k-state-hover > .k-i-insert-bottom,
.k-state-hover > .k-i-insert-s,
.k-state-hover > .k-insert-bottom,
.k-textbox:hover .k-i-insert-bottom,
.k-textbox:hover .k-i-insert-s,
.k-textbox:hover .k-insert-bottom {
  background-position: -176px -64px;
}
.k-drop-hint {
  background-position: 0 -326px;
}
.k-i-error,
.k-i-info,
.k-i-note,
.k-i-success,
.k-i-warning,
.k-warning {
  background-position: -160px -240px;
}
.k-button:active .k-i-error,
.k-button:active .k-i-info,
.k-button:active .k-i-note,
.k-button:active .k-i-success,
.k-button:active .k-i-warning,
.k-button:active .k-warning,
.k-button:not(.k-state-disabled):hover .k-i-error,
.k-button:not(.k-state-disabled):hover .k-i-info,
.k-button:not(.k-state-disabled):hover .k-i-note,
.k-button:not(.k-state-disabled):hover .k-i-success,
.k-button:not(.k-state-disabled):hover .k-i-warning,
.k-button:not(.k-state-disabled):hover .k-warning,
.k-link:not(.k-state-disabled):hover > .k-i-error,
.k-link:not(.k-state-disabled):hover > .k-i-info,
.k-link:not(.k-state-disabled):hover > .k-i-note,
.k-link:not(.k-state-disabled):hover > .k-i-success,
.k-link:not(.k-state-disabled):hover > .k-i-warning,
.k-link:not(.k-state-disabled):hover > .k-warning,
.k-state-hover > * > .k-i-error,
.k-state-hover > * > .k-i-info,
.k-state-hover > * > .k-i-note,
.k-state-hover > * > .k-i-success,
.k-state-hover > * > .k-i-warning,
.k-state-hover > * > .k-warning,
.k-state-hover > .k-i-error,
.k-state-hover > .k-i-info,
.k-state-hover > .k-i-note,
.k-state-hover > .k-i-success,
.k-state-hover > .k-i-warning,
.k-state-hover > .k-warning,
.k-textbox:hover .k-i-error,
.k-textbox:hover .k-i-info,
.k-textbox:hover .k-i-note,
.k-textbox:hover .k-i-success,
.k-textbox:hover .k-i-warning,
.k-textbox:hover .k-warning {
  background-position: -176px -240px;
}
.k-i-sort-asc {
  background-position: -112px -240px;
}
.k-button:active .k-i-sort-asc,
.k-button:not(.k-state-disabled):hover .k-i-sort-asc,
.k-link:not(.k-state-disabled):hover > .k-i-sort-asc,
.k-state-hover > * > .k-i-sort-asc,
.k-state-hover > .k-i-sort-asc,
.k-textbox:hover .k-i-sort-asc {
  background-position: -128px -240px;
}
.k-i-sort-desc {
  background-position: -112px -256px;
}
.k-button:active .k-i-sort-desc,
.k-button:not(.k-state-disabled):hover .k-i-sort-desc,
.k-link:not(.k-state-disabled):hover > .k-i-sort-desc,
.k-state-hover > * > .k-i-sort-desc,
.k-state-hover > .k-i-sort-desc,
.k-textbox:hover .k-i-sort-desc {
  background-position: -128px -256px;
}
.k-i-group {
  background-position: -112px -272px;
}
.k-button:active .k-i-group,
.k-button:not(.k-state-disabled):hover .k-i-group,
.k-link:not(.k-state-disabled):hover > .k-i-group,
.k-state-hover > * > .k-i-group,
.k-state-hover > .k-i-group,
.k-textbox:hover .k-i-group {
  background-position: -128px -272px;
}
.k-i-ungroup {
  background-position: -112px -288px;
}
.k-button:active .k-i-ungroup,
.k-button:not(.k-state-disabled):hover .k-i-ungroup,
.k-link:not(.k-state-disabled):hover > .k-i-ungroup,
.k-state-hover > * > .k-i-ungroup,
.k-state-hover > .k-i-ungroup,
.k-textbox:hover .k-i-ungroup {
  background-position: -128px -288px;
}
.k-i-columns {
  background-position: -112px -304px;
}
.k-button:active .k-i-columns,
.k-button:not(.k-state-disabled):hover .k-i-columns,
.k-link:not(.k-state-disabled):hover > .k-i-columns,
.k-state-hover > * > .k-i-columns,
.k-state-hover > .k-i-columns,
.k-textbox:hover .k-i-columns {
  background-position: -128px -304px;
}
.k-i-hbars {
  background-position: -64px -32px;
}
.k-button:active .k-i-hbars,
.k-button:not(.k-state-disabled):hover .k-i-hbars,
.k-link:not(.k-state-disabled):hover > .k-i-hbars,
.k-state-hover > * > .k-i-hbars,
.k-state-hover > .k-i-hbars,
.k-textbox:hover .k-i-hbars {
  background-position: -80px -32px;
}
.k-i-vbars {
  background-position: -64px -48px;
}
.k-button:active .k-i-vbars,
.k-button:not(.k-state-disabled):hover .k-i-vbars,
.k-link:not(.k-state-disabled):hover > .k-i-vbars,
.k-state-hover > * > .k-i-vbars,
.k-state-hover > .k-i-vbars,
.k-textbox:hover .k-i-vbars {
  background-position: -80px -48px;
}
.k-i-sum {
  background-position: -64px -64px;
}
.k-button:active .k-i-sum,
.k-button:not(.k-state-disabled):hover .k-i-sum,
.k-link:not(.k-state-disabled):hover > .k-i-sum,
.k-state-hover > * > .k-i-sum,
.k-state-hover > .k-i-sum,
.k-textbox:hover .k-i-sum {
  background-position: -80px -64px;
}
.k-i-pdf {
  background-position: -64px -80px;
}
.k-button:active .k-i-pdf,
.k-button:not(.k-state-disabled):hover .k-i-pdf,
.k-link:not(.k-state-disabled):hover > .k-i-pdf,
.k-state-hover > * > .k-i-pdf,
.k-state-hover > .k-i-pdf,
.k-textbox:hover .k-i-pdf {
  background-position: -80px -80px;
}
.k-i-excel {
  background-position: -64px -96px;
}
.k-button:active .k-i-excel,
.k-button:not(.k-state-disabled):hover .k-i-excel,
.k-link:not(.k-state-disabled):hover > .k-i-excel,
.k-state-hover > * > .k-i-excel,
.k-state-hover > .k-i-excel,
.k-textbox:hover .k-i-excel {
  background-position: -80px -96px;
}
.k-i-rotatecw {
  background-position: -64px -112px;
}
.k-button:active .k-i-rotatecw,
.k-button:not(.k-state-disabled):hover .k-i-rotatecw,
.k-link:not(.k-state-disabled):hover > .k-i-rotatecw,
.k-state-hover > * > .k-i-rotatecw,
.k-state-hover > .k-i-rotatecw,
.k-textbox:hover .k-i-rotatecw {
  background-position: -80px -112px;
}
.k-i-rotateccw {
  background-position: -64px -128px;
}
.k-button:active .k-i-rotateccw,
.k-button:not(.k-state-disabled):hover .k-i-rotateccw,
.k-link:not(.k-state-disabled):hover > .k-i-rotateccw,
.k-state-hover > * > .k-i-rotateccw,
.k-state-hover > .k-i-rotateccw,
.k-textbox:hover .k-i-rotateccw {
  background-position: -80px -128px;
}
.k-i-undo {
  background-position: -64px -160px;
}
.k-button:active .k-i-undo,
.k-button:not(.k-state-disabled):hover .k-i-undo,
.k-link:not(.k-state-disabled):hover > .k-i-undo,
.k-state-hover > * > .k-i-undo,
.k-state-hover > .k-i-undo,
.k-textbox:hover .k-i-undo {
  background-position: -80px -160px;
}
.k-i-redo {
  background-position: -64px -144px;
}
.k-button:active .k-i-redo,
.k-button:not(.k-state-disabled):hover .k-i-redo,
.k-link:not(.k-state-disabled):hover > .k-i-redo,
.k-state-hover > * > .k-i-redo,
.k-state-hover > .k-i-redo,
.k-textbox:hover .k-i-redo {
  background-position: -80px -144px;
}
.k-i-shape {
  background-position: -64px -176px;
}
.k-button:active .k-i-shape,
.k-button:not(.k-state-disabled):hover .k-i-shape,
.k-link:not(.k-state-disabled):hover > .k-i-shape,
.k-state-hover > * > .k-i-shape,
.k-state-hover > .k-i-shape,
.k-textbox:hover .k-i-shape {
  background-position: -80px -176px;
}
.k-i-connector {
  background-position: -64px -192px;
}
.k-button:active .k-i-connector,
.k-button:not(.k-state-disabled):hover .k-i-connector,
.k-link:not(.k-state-disabled):hover > .k-i-connector,
.k-state-hover > * > .k-i-connector,
.k-state-hover > .k-i-connector,
.k-textbox:hover .k-i-connector {
  background-position: -80px -192px;
}
.k-i-kpi {
  background-position: -64px -208px;
}
.k-button:active .k-i-kpi,
.k-button:not(.k-state-disabled):hover .k-i-kpi,
.k-link:not(.k-state-disabled):hover > .k-i-kpi,
.k-state-hover > * > .k-i-kpi,
.k-state-hover > .k-i-kpi,
.k-textbox:hover .k-i-kpi {
  background-position: -80px -208px;
}
.k-i-dimension {
  background-position: -64px -224px;
}
.k-button:active .k-i-dimension,
.k-button:not(.k-state-disabled):hover .k-i-dimension,
.k-link:not(.k-state-disabled):hover > .k-i-dimension,
.k-state-hover > * > .k-i-dimension,
.k-state-hover > .k-i-dimension,
.k-textbox:hover .k-i-dimension {
  background-position: -80px -224px;
}
.k-file,
.k-i-file {
  background-position: 0 0;
}
.k-button:active .k-file,
.k-button:active .k-i-file,
.k-button:not(.k-state-disabled):hover .k-file,
.k-button:not(.k-state-disabled):hover .k-i-file,
.k-link:not(.k-state-disabled):hover > .k-file,
.k-link:not(.k-state-disabled):hover > .k-i-file,
.k-state-hover > * > .k-file,
.k-state-hover > * > .k-i-file,
.k-state-hover > .k-file,
.k-state-hover > .k-i-file,
.k-textbox:hover .k-file,
.k-textbox:hover .k-i-file {
  background-position: -16px 0;
}
.k-addfolder,
.k-i-add-folder,
.k-i-folder-add {
  background-position: -32px -272px;
}
.k-button:active .k-addfolder,
.k-button:active .k-i-add-folder,
.k-button:active .k-i-folder-add,
.k-button:not(.k-state-disabled):hover .k-addfolder,
.k-button:not(.k-state-disabled):hover .k-i-add-folder,
.k-button:not(.k-state-disabled):hover .k-i-folder-add,
.k-link:not(.k-state-disabled):hover > .k-addfolder,
.k-link:not(.k-state-disabled):hover > .k-i-add-folder,
.k-link:not(.k-state-disabled):hover > .k-i-folder-add,
.k-state-hover > * > .k-addfolder,
.k-state-hover > * > .k-i-add-folder,
.k-state-hover > * > .k-i-folder-add,
.k-state-hover > .k-addfolder,
.k-state-hover > .k-i-add-folder,
.k-state-hover > .k-i-folder-add,
.k-textbox:hover .k-addfolder,
.k-textbox:hover .k-i-add-folder,
.k-textbox:hover .k-i-folder-add {
  background-position: -48px -272px;
}
.k-goup,
.k-i-folder-up,
.k-i-go-up {
  background-position: -32px -288px;
}
.k-button:active .k-goup,
.k-button:active .k-i-folder-up,
.k-button:active .k-i-go-up,
.k-button:not(.k-state-disabled):hover .k-goup,
.k-button:not(.k-state-disabled):hover .k-i-folder-up,
.k-button:not(.k-state-disabled):hover .k-i-go-up,
.k-link:not(.k-state-disabled):hover > .k-goup,
.k-link:not(.k-state-disabled):hover > .k-i-folder-up,
.k-link:not(.k-state-disabled):hover > .k-i-go-up,
.k-state-hover > * > .k-goup,
.k-state-hover > * > .k-i-folder-up,
.k-state-hover > * > .k-i-go-up,
.k-state-hover > .k-goup,
.k-state-hover > .k-i-folder-up,
.k-state-hover > .k-i-go-up,
.k-textbox:hover .k-goup,
.k-textbox:hover .k-i-folder-up,
.k-textbox:hover .k-i-go-up {
  background-position: -48px -288px;
}
.k-i-more {
  background-position: -64px -32px;
}
.k-button:active .k-i-more,
.k-button:not(.k-state-disabled):hover .k-i-more,
.k-link:not(.k-state-disabled):hover > .k-i-more,
.k-state-hover > * > .k-i-more,
.k-state-hover > .k-i-more,
.k-textbox:hover .k-i-more {
  background-position: -80px -32px;
}
.k-i-gantt-toggle {
  background-position: -64px -240px;
}
.k-button:active .k-i-gantt-toggle,
.k-button:not(.k-state-disabled):hover .k-i-gantt-toggle,
.k-link:not(.k-state-disabled):hover > .k-i-gantt-toggle,
.k-state-hover > * > .k-i-gantt-toggle,
.k-state-hover > .k-i-gantt-toggle,
.k-textbox:hover .k-i-gantt-toggle {
  background-position: -80px -240px;
}
.k-file > .k-icon {
  background-position: -115px -91px;
}
.k-image {
  border: 0;
}
.k-breadcrumbs:hover .k-i-arrow-n {
  background-position: 0 0;
}
.k-breadcrumbs:hover .k-i-arrow-e {
  background-position: 0 -16px;
}
.k-gantt-views > .k-current-view > .k-link:after,
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after {
  background-position: 0 -32px;
}
.k-button:active .k-gantt-views > .k-current-view > .k-link:after,
.k-button:active .k-pager-numbers .k-current-page .k-link:after,
.k-button:active .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-button:not(.k-state-disabled):hover .k-gantt-views > .k-current-view > .k-link:after,
.k-button:not(.k-state-disabled):hover .k-pager-numbers .k-current-page .k-link:after,
.k-button:not(.k-state-disabled):hover .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-link:not(.k-state-disabled):hover > .k-gantt-views > .k-current-view > .k-link:after,
.k-link:not(.k-state-disabled):hover > .k-pager-numbers .k-current-page .k-link:after,
.k-link:not(.k-state-disabled):hover > .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-state-hover > * > .k-gantt-views > .k-current-view > .k-link:after,
.k-state-hover > * > .k-pager-numbers .k-current-page .k-link:after,
.k-state-hover > * > .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-state-hover > .k-gantt-views > .k-current-view > .k-link:after,
.k-state-hover > .k-pager-numbers .k-current-page .k-link:after,
.k-state-hover > .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-textbox:hover .k-gantt-views > .k-current-view > .k-link:after,
.k-textbox:hover .k-pager-numbers .k-current-page .k-link:after,
.k-textbox:hover .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after {
  background-position: -16px -32px;
}
html .k-success-colored {
  color: #507f50;
  border-color: #d0dfd0;
  background-color: #f0fff0;
}
html .k-info-colored {
  color: #50607f;
  border-color: #d0d9df;
  background-color: #f0f9ff;
}
html .k-error-colored {
  color: #7f5050;
  border-color: #dfd0d0;
  background-color: #fff0f0;
}
.k-inline-block {
  padding: 0 2px;
}
.k-i-loading,
.k-loading,
.k-loading-image {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-loading-image,
.k-loading-mask,
.k-loading-text {
  position: absolute;
}
.k-loading-mask {
  z-index: 100;
}
.k-loading-mask .k-loading-progress {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.k-loading-text {
  text-indent: -4000px;
  text-align: center;
}
.k-loading-color,
.k-loading-image {
  width: 100%;
  height: 100%;
}
.k-loading-image {
  top: 0;
  left: 0;
  z-index: 2;
}
.k-loading-color {
  filter: alpha(opacity=30);
  opacity: 0.3;
}
.k-content-frame {
  border: 0;
  width: 100%;
  height: 100%;
}
.k-pane > .k-splitter-overlay {
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
}
.k-drag-clue {
  position: absolute;
  z-index: 10003;
  border-style: solid;
  border-width: 1px;
  font-size: 0.9em;
  padding: 0.2em 0.4em;
  white-space: nowrap;
  cursor: default;
}
.k-drag-status {
  margin-top: -3px;
  margin-right: 4px;
  vertical-align: middle;
}
.k-reorder-cue {
  position: absolute;
  width: 1px;
  overflow: visible;
}
.k-reorder-cue .k-icon {
  position: absolute;
  left: -4px;
  width: 8px;
  height: 4px;
}
.k-reorder-cue .k-i-arrow-s {
  top: -4px;
  background-position: -4px -166px;
}
.k-reorder-cue .k-i-arrow-n {
  bottom: -4px;
  background-position: -4px -134px;
}
.k-scrollbar {
  position: absolute;
  overflow: scroll;
}
.k-scrollbar-vertical {
  top: 0;
  right: 0;
  width: 17px;
  height: 100%;
  overflow-x: hidden;
}
.k-touch-scrollbar {
  display: none;
  position: absolute;
  z-index: 200000;
  height: 8px;
  width: 8px;
  border: 1px solid #8a8a8a;
  background-color: #858585;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  body .k-touch-scrollbar {
    height: 12px;
    width: 12px;
    border-radius: 7px;
  }
}
.k-virtual-scrollable-wrap {
  overflow-x: auto;
}
.k-current-time {
  background: red;
  position: absolute;
}
.k-current-time-arrow-down {
  width: 0;
  height: 0;
  background: 0 0;
  border-bottom: 4px solid transparent;
  border-top: 4px solid red;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.k-current-time-arrow-left {
  width: 0;
  height: 0;
  background: 0 0;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid red;
}
.k-current-time-arrow-right {
  width: 0;
  height: 0;
  background: 0 0;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid red;
  border-right: 4px solid transparent;
}
.k-animation-container,
.k-animation-container *,
.k-animation-container :after,
.k-block .k-header,
.k-list-container,
.k-widget,
.k-widget *,
.k-widget :before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.k-autocomplete,
.k-block,
.k-button,
.k-button-bare,
.k-edit-cell .k-widget,
.k-grid-edit-row .k-widget,
.k-grid-edit-row .text-box,
.k-tabstrip > .k-content > .km-scroll-container,
.k-textbox,
.km-actionsheet > li,
.km-shim,
div.k-window-content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.input-group .form-control {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.form-control.k-widget {
  padding: 0;
}
a.k-button-bare:hover,
a.k-button:hover {
  text-decoration: none;
}
.km-widget,
.km-widget * {
  -webkit-background-clip: border-box;
  background-clip: border-box;
}
.k-radio,
input.k-checkbox {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  -webkit-appearance: none;
  overflow: hidden;
}
.k-ff .k-radio,
.k-ff input.k-checkbox {
  position: absolute;
}
.k-checkbox-label,
.k-radio-label {
  display: inline-block;
  position: relative;
  padding-left: 18px;
  vertical-align: text-top;
  line-height: 16px;
  cursor: pointer;
}
.k-checkbox-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-width: 1px;
  border-style: solid;
  width: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}
.k-checkbox:indeterminate + .k-checkbox-label:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 8px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-width: 1px;
  border-style: solid;
  width: 8px;
  height: 8px;
  font-size: 14px;
  text-align: center;
  content: " ";
}
.k-checkbox:checked + .k-checkbox-label:before {
  content: "\2713";
}
.k-checkbox:disabled + .k-checkbox-label {
  cursor: auto;
}
.k-radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  border-style: solid;
}
.k-radio:checked + .k-radio-label:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
}
.k-radio:disabled + .k-radio-label {
  cursor: auto;
}
.k-ie8 .k-radio,
.k-ie8 input.k-checkbox {
  display: inline-block;
  width: auto;
}
.k-ie8 .k-checkbox-label,
.k-ie8 .k-radio-label {
  padding-left: 0;
}
.k-ie8 .k-checkbox-label:before,
.k-ie8 .k-radio-label:after,
.k-ie8 .k-radio-label:before {
  display: none;
}
.k-rtl .k-checkbox-label,
.k-rtl .k-radio-label {
  padding-right: 1.5em;
}
.k-rtl .k-checkbox-label:before,
.k-rtl .k-radio-label:before {
  right: 0;
}
.k-rtl .k-radio:checked + .k-radio-label:after {
  right: 3px;
}
input.k-checkbox + label {
  -webkit-user-select: none;
}
.k-pdf-export-shadow {
  position: absolute;
  overflow: hidden;
  left: -15000px;
  width: 14400px;
}
.km-native-scroller {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: pan-x pan-y;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-scroll-snap-type: proximity;
}
.k-rpanel-left {
  -webkit-transform: translateX(-100%) translateZ(0);
  -ms-transform: translateX(-100%) translateZ(0);
  transform: translateX(-100%) translateZ(0);
  left: 0;
}
.k-rpanel-right {
  -webkit-transform: translateX(100%) translateZ(0);
  -ms-transform: translateX(100%) translateZ(0);
  transform: translateX(100%) translateZ(0);
  right: 0;
}
.k-rpanel-left,
.k-rpanel-right {
  position: fixed;
  display: block;
  overflow: auto;
  min-width: 320px;
  height: 100%;
  top: 0;
}
.k-rpanel-left.k-rpanel-expanded,
.k-rpanel-right.k-rpanel-expanded {
  -webkit-transform: translateX(0) translateZ(0);
  -ms-transform: translateX(0) translateZ(0);
  transform: translateX(0) translateZ(0);
}
.k-rpanel-left + *,
.k-rpanel-right + * {
  overflow: auto;
}
.k-ie9 .k-rpanel-left {
  left: -100%;
}
.k-ie9 .k-rpanel-left.k-rpanel-expanded {
  left: 0;
}
.k-rpanel-top {
  position: static;
  max-height: 0;
}
.k-rpanel-top.k-rpanel-expanded {
  max-height: 568px;
  overflow: visible !important;
}
.k-edit-form {
  margin: 0;
  padding: 0;
}
.k-window > div.k-popup-edit-form {
  padding: 1em 0;
}
.k-grid-edit-row .k-edit-form td {
  border-bottom-width: 0;
}
.k-edit-form-container {
  position: relative;
  width: 400px;
}
.k-edit-form-container .editor-label,
.k-edit-label {
  float: left;
  clear: both;
  width: 30%;
  padding: 0.4em 0 1em;
  margin-left: 2%;
  text-align: right;
}
.k-edit-field,
.k-edit-form-container .editor-field {
  float: right;
  clear: right;
  width: 60%;
  margin-right: 2%;
  padding: 0 0 0.6em;
}
.k-edit-field > input[type=checkbox],
.k-edit-field > input[type=radio] {
  margin-top: 0.4em;
}
.k-edit-form-container .k-button {
  margin: 0 0.16em;
}
.k-edit-field > .k-button:first-child,
.k-edit-field > input[type=checkbox]:first-child,
.k-edit-field > input[type=radio]:first-child,
.k-edit-field > label:first-child > input[type=checkbox] {
  margin-left: 0;
}
.k-edit-form-container .k-edit-buttons {
  clear: both;
  text-align: right;
  border-width: 1px 0 0;
  border-style: solid;
  position: relative;
  bottom: -1em;
  padding: 0.6em;
}
div.k-window {
  display: inline-block;
  position: absolute;
  z-index: 10001;
  border-style: solid;
  border-width: 1px;
  padding-top: 2em;
}
.k-block > .k-header,
.k-window-titlebar {
  position: absolute;
  width: 100%;
  height: 1.1em;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-top: -2em;
  padding: 0.4em 0;
  font-size: 1.2em;
  white-space: nowrap;
  min-height: 16px;
}
.k-block > .k-header {
  position: relative;
  margin: -2px 0 10px -2px;
  padding: 0.3em 2px;
}
.k-window-title {
  position: absolute;
  left: 0.44em;
  right: 0.44em;
  overflow: hidden;
  cursor: default;
  text-overflow: ellipsis;
}
.k-window-title .k-image {
  margin: 0 5px 0 0;
  vertical-align: middle;
}
div.k-window-titleless {
  padding-top: 0;
}
div.k-window-content {
  position: relative;
  height: 100%;
  padding: 0.58em;
  overflow: auto;
  outline: 0;
}
div.k-window-iframecontent {
  padding: 0;
  overflow: visible;
}
.k-window-content > .km-scroll-container {
  height: 100%;
}
.k-window-titlebar .k-window-actions {
  position: absolute;
  top: 0;
  right: 0.3em;
  padding-top: 0.3em;
  white-space: nowrap;
}
.k-window-titlebar .k-window-action {
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  text-decoration: none;
  vertical-align: middle;
  opacity: 0.7;
}
.k-window-titlebar .k-state-hover {
  border-style: solid;
  border-width: 1px;
  padding: 1px;
  opacity: 1;
}
.k-window-action .k-icon {
  margin: 0;
  vertical-align: top;
}
.k-window > .k-resize-handle {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  font-size: 0;
  line-height: 6px;
  filter: alpha(opacity=0);
  opacity: 0;
  zoom: 1;
}
.k-resize-n {
  top: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: n-resize;
}
.k-resize-e {
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  cursor: e-resize;
}
.k-resize-s {
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: s-resize;
}
.k-resize-w {
  top: 0;
  left: -3px;
  width: 6px;
  height: 100%;
  cursor: w-resize;
}
.k-resize-se {
  bottom: -3px;
  right: -3px;
  width: 16px;
  height: 16px;
  cursor: se-resize;
}
.k-resize-sw {
  bottom: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  cursor: sw-resize;
}
.k-resize-ne {
  top: -3px;
  right: -3px;
  width: 6px;
  height: 6px;
  cursor: ne-resize;
}
.k-resize-nw {
  top: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  cursor: nw-resize;
}
.k-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: #000;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.k-window .k-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  filter: alpha(opacity=0);
  opacity: 0;
}
.k-action-buttons {
  clear: both;
  text-align: right;
  border-width: 1px 0 0;
  border-style: solid;
  position: relative;
  bottom: -1em;
  padding: 0.6em;
  margin: 0 -1em;
}
.k-action-buttons .k-button {
  display: inline-block;
  margin: 0 0 0 6px;
  min-width: 75px;
}
.k-tabstrip {
  margin: 0;
  padding: 0;
  zoom: 1;
  position: relative;
}
.k-tabstrip-items {
  padding: 0.3em 0.3em 0;
}
.k-tabstrip-scrollable .k-tabstrip-items {
  white-space: nowrap;
  overflow: hidden;
}
.k-tabstrip > .k-button {
  position: absolute;
  top: 0.4em;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.k-tabstrip-bottom > .k-button {
  top: auto;
  bottom: 0.4em;
}
.k-tabstrip-prev {
  left: 0.4em;
}
.k-tabstrip-next {
  right: 0.4em;
}
.k-panelbar .k-tabstrip-items .k-item,
.k-tabstrip-items .k-item {
  list-style-type: none;
  display: inline-block;
  position: relative;
  border-style: solid;
  border-width: 1px 1px 0;
  margin: 0 -1px 0 0;
  padding: 0;
  vertical-align: top;
}
.k-panelbar .k-tabstrip-items .k-state-active,
.k-tabstrip-items .k-state-active,
.k-tabstrip-items .k-tab-on-top {
  margin-bottom: -1px;
  padding-bottom: 1px;
}
.k-panelbar .k-tabstrip-top .k-tabstrip-items .k-state-active,
.k-tabstrip-top .k-tabstrip-items .k-state-active {
  border-bottom-width: 1px;
  margin-bottom: -2px;
  padding-bottom: 0;
}
.k-tabstrip-items .k-tab-on-top {
  z-index: 1;
}
.k-panelbar .k-tabstrip-items .k-link,
.k-tabstrip-items .k-link {
  display: inline-block;
  border-bottom-width: 0;
  padding: 0.5em 0.92em;
}
.k-panelbar .k-tabstrip-items .k-icon,
.k-tabstrip-items .k-icon {
  margin: -1px 4px 0 -3px;
  vertical-align: top;
}
.k-panelbar .k-tabstrip-items .k-item .k-image,
.k-panelbar .k-tabstrip-items .k-item .k-sprite,
.k-tabstrip-items .k-item .k-image,
.k-tabstrip-items .k-item .k-sprite {
  margin: -3px 3px 0 -6px;
  vertical-align: middle;
}
.k-tabstrip-items .k-loading {
  top: 0;
  left: 0;
  height: 0;
  width: 20%;
  position: absolute;
  background: 0 0;
  border-top: 1px solid transparent;
  border-color: inherit;
  -webkit-transition: width 0.2s linear;
  transition: width 0.2s linear;
  -webkit-transition: "width 200ms linear";
  transition: "width 200ms linear";
  -webkit-animation: k-tab-loader 1s ease-in-out infinite;
  animation: k-tab-loader 1s ease-in-out infinite;
}
.k-tabstrip-items .k-progress {
  -webkit-animation: none;
  animation: none;
}
.k-tabstrip-items .k-loading.k-complete {
  width: 100%;
  -webkit-animation: none;
  animation: none;
}
.k-panelbar .k-tabstrip > .k-content,
.k-tabstrip > .k-content {
  position: static;
  border-style: solid;
  border-width: 1px;
  margin: 0 0.286em 0.3em;
  padding: 0.92em;
  zoom: 1;
}
.k-tabstrip > .k-content {
  display: none;
  overflow: auto;
}
.k-tabstrip > .k-content.km-scroll-wrapper {
  padding: 0;
}
.k-tabstrip > .k-content > .km-scroll-container {
  padding: 0.3em 0.92em;
}
@-webkit-keyframes k-tab-loader {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}
@keyframes k-tab-loader {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}
.k-tabstrip-left > div.k-content,
.k-tabstrip-right > div.k-content {
  margin: 0.286em 0.3em;
}
.k-tabstrip-left > .k-tabstrip-items .k-item,
.k-tabstrip-right > .k-tabstrip-items .k-item {
  display: block;
  margin-bottom: -1px;
}
.k-tabstrip-left > .k-tabstrip-items .k-link,
.k-tabstrip-right > .k-tabstrip-items .k-link {
  display: block;
}
.k-panelbar .k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-right > .k-tabstrip-items .k-state-active,
.k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-tabstrip-left > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-right > .k-tabstrip-items .k-state-active,
.k-tabstrip-right > .k-tabstrip-items .k-tab-on-top {
  margin-bottom: -1px;
  padding-bottom: 0;
}
.k-tabstrip-left > .k-tabstrip-items {
  float: left;
  padding: 0.25em 0 0.3em 0.3em;
}
.k-tabstrip-left > .k-tabstrip-items .k-item {
  border-width: 1px 0 1px 1px;
  border-radius: 3px 0 0 3px;
}
.k-tabstrip-left > .k-tabstrip-items .k-state-active {
  border-width: 1px 0 1px 1px;
}
.k-panelbar .k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-tabstrip-left > .k-tabstrip-items .k-tab-on-top {
  margin-right: -2px;
  padding-right: 1px;
}
.k-tabstrip-right > .k-tabstrip-items {
  float: right;
  padding: 0.25em 0.3em 0.3em 0;
}
.k-tabstrip-right > .k-tabstrip-items .k-item {
  border-width: 1px 1px 1px 0;
  border-radius: 0 3px 3px 0;
}
.k-tabstrip-right > .k-tabstrip-items .k-state-active {
  border-width: 1px 1px 1px 0;
}
.k-panelbar .k-tabstrip-right > .k-tabstrip-items .k-state-active,
.k-tabstrip-right > .k-tabstrip-items .k-state-active,
.k-tabstrip-right > .k-tabstrip-items .k-tab-on-top {
  margin-left: -1px;
  padding-left: 1px;
}
.k-tabstrip-bottom > .k-tabstrip-items {
  margin-top: -1px;
  padding: 0 0.3em 0.3em;
}
.k-panelbar .k-tabstrip-bottom > .k-content,
.k-tabstrip-bottom > .k-content {
  margin: 0.3em 0.286em 0;
  z-index: 1;
  position: relative;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-item {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-state-active {
  margin-bottom: 0;
  padding-bottom: 0;
}
.k-tabstrip-bottom > .k-content {
  min-height: 100px;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-loading {
  top: auto;
  bottom: 0;
}
.k-panelbar {
  zoom: 1;
}
.k-panel > .k-item,
.k-panelbar > .k-item {
  list-style-type: none;
  display: block;
  border-width: 0;
  margin: 0;
  zoom: 1;
  border-radius: 0;
}
.k-panelbar .k-link > .k-image,
.k-panelbar .k-link > .k-sprite {
  float: left;
  margin-top: 4px;
  margin-right: 5px;
  vertical-align: middle;
}
.k-panel > .k-item > .k-link,
.k-panelbar > .k-item > .k-link {
  display: block;
  position: relative;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0 1em;
  line-height: 2.34em;
  text-decoration: none;
  zoom: 1;
}
.k-panelbar-collapse,
.k-panelbar-expand {
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -8px;
}
.k-panelbar .k-content,
.k-panelbar .k-panel {
  position: relative;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 0;
  padding: 0;
  zoom: 1;
}
.k-panel > .k-item > .k-link {
  border-bottom: 0;
  font-size: 0.95em;
  line-height: 2.2;
}
.k-panel .k-panel > .k-item > .k-link {
  padding-left: 2em;
}
.k-panelbar .k-i-seek-e .k-link {
  border-bottom: 0;
}
.k-panel .k-panel {
  border-bottom: 0;
}
.k-menu {
  cursor: default;
}
.k-menu,
.k-menu .k-menu-group {
  list-style: none;
  margin: 0;
  padding: 0;
  zoom: 1;
}
.k-menu:after {
  content: '';
  display: block;
  width: 99%;
  height: 0;
  float: inherit;
  clear: both;
}
.k-menu .k-item {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.k-menu .k-item div {
  -webkit-user-select: default;
  -moz-user-select: default;
  -ms-user-select: default;
  user-select: default;
}
.k-menu .k-item .k-item,
ul.k-menu-vertical > .k-item {
  display: block;
  float: none;
  border-width: 0;
}
.k-menu .k-image,
.k-menu .k-item > .k-link > .k-icon,
.k-menu .k-sprite {
  margin: -2px 4px 0 -4px;
  vertical-align: middle;
}
.k-menu .k-item > .k-link > .k-icon {
  margin: -2px 0 0;
}
.k-menu .k-item > .k-link {
  display: block;
  padding: 0.5em 1.1em 0.4em;
  line-height: 1.34em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.k-menu .k-menu-group {
  display: none;
  border-style: solid;
  border-width: 1px;
  overflow: visible;
  white-space: nowrap;
}
.k-menu .k-menu-group > .k-item {
  display: block;
  border-width: 0;
}
.k-menu .k-item,
.k-widget.k-menu-horizontal > .k-item {
  position: relative;
  float: left;
  border-style: solid;
  border-width: 0 1px 0 0;
  vertical-align: top;
  zoom: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.k-context-menu.k-menu-vertical > .k-item > .k-link,
.k-menu .k-menu-group .k-item > .k-link {
  padding: 0.28em 1.8em 0.38em 0.9em;
}
.k-context-menu.k-menu-horizontal > .k-separator {
  display: none;
}
.k-context-menu.k-menu-horizontal > .k-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.k-context-menu.k-menu-horizontal > .k-last {
  border: 0;
}
.k-menu .k-item > .k-link > .k-i-arrow-s {
  margin-right: -8px;
}
.k-menu .k-item > .k-link > .k-i-arrow-e {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 2px;
  right: 0.2rem;
}
.k-menu .k-animation-container {
  border: 0;
}
.k-menu .k-animation-container,
.k-menu .k-menu-group {
  position: absolute;
  left: 0;
}
.k-menu .k-animation-container .k-animation-container,
.k-menu .k-menu-group .k-menu-group,
.k-menu-vertical .k-animation-container,
.k-menu-vertical .k-menu-group {
  top: 0;
  left: 0;
}
.k-menu .k-animation-container .k-menu-group {
  top: auto;
  left: auto;
  margin-left: -1px;
}
.k-menu .k-animation-container,
.k-popup .k-animation-container {
  margin-top: -1px;
  padding-left: 1px;
}
.k-ie .k-menu .k-animation-container,
.k-ie .k-popup .k-animation-container {
  margin-top: -2px;
}
.k-popup .k-animation-container .k-popup {
  margin-left: -1px;
}
ul.k-menu .k-separator {
  padding: 0.25em 0;
  height: 100%;
  width: 1px;
  font-size: 0;
  line-height: 0;
  border-width: 0 1px 0 0;
}
.k-menu .k-menu-group .k-separator,
ul.k-menu-vertical .k-separator {
  padding: 0;
  height: 1px;
  width: 100%;
  border-width: 1px 0 0;
}
.k-context-menu {
  border: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.k-grid,
.k-listview {
  position: relative;
  zoom: 1;
}
.k-grid table {
  width: 100%;
  margin: 0;
  max-width: none;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  border-width: 0;
  outline: 0;
}
.k-header.k-drag-clue {
  overflow: hidden;
}
.k-filter-row th,
.k-grid-header th.k-header {
  overflow: hidden;
  border-style: solid;
  border-width: 0 0 1px 1px;
  padding: 0.5em 0.6em 0.4em 0.6em;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.k-grid-header th.k-header {
  vertical-align: bottom;
}
.k-filtercell,
.k-filtercell .k-widget,
.k-filtercell > span {
  display: block;
  width: auto;
}
.k-filtercell > span {
  padding-right: 4.8em;
  position: relative;
  min-height: 2em;
  line-height: 2em;
}
.k-filtercell > .k-operator-hidden {
  padding-right: 2.3em;
}
.k-filter-row .k-dropdown-operator,
.k-filtercell > span > .k-button {
  position: absolute;
  top: 0;
  right: 0;
}
.k-filter-row .k-dropdown-operator {
  width: 2.1em;
  right: 2.5em;
}
.k-filtercell > span > label {
  vertical-align: middle;
}
.k-filter-row label > input[type=radio] {
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}
.k-ie10 .k-grid-header a:active {
  background-color: transparent;
}
.k-grid-header th.k-header > .k-link {
  display: block;
  min-height: 18px;
  line-height: 18px;
  margin: -0.5em -0.6em -0.4em 0;
  padding: 0.5em 0.6em 0.4em 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-grid-header th.k-with-icon .k-link {
  margin-right: 18px;
}
.k-grid-header th.k-header .k-icon {
  position: static;
}
.k-grid-header th > .k-link > .k-icon {
  vertical-align: text-top;
}
.k-grid .k-state-hover {
  cursor: pointer;
}
.k-grid-column-resizing,
.k-grid-column-resizing .k-button,
.k-grid-column-resizing .k-grid-filter,
.k-grid-column-resizing .k-grid-toolbar,
.k-grid-column-resizing .k-link {
  cursor: col-resize;
}
.k-grid td {
  border-style: solid;
  border-width: 0 0 0 1px;
  padding: 0.4em 0.6em;
  overflow: hidden;
  line-height: 1.6em;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.k-grid .k-grouping-row td,
.k-grid .k-hierarchy-cell {
  overflow: visible;
}
.k-grid-edit-row td {
  text-overflow: clip;
}
.k-grid-edit-row .k-textbox,
.k-grid-edit-row .text-box {
  margin-top: 0;
  margin-bottom: 0;
}
.k-grid-footer-wrap,
.k-grid-header-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-style: solid;
  border-width: 0 1px 0 0;
  zoom: 1;
}
div.k-grid-footer,
div.k-grid-header {
  padding-right: 17px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  zoom: 1;
}
.k-grid-header-locked > table,
.k-grid-header-wrap > table {
  margin-bottom: -1px;
}
.k-grid-content {
  position: relative;
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  overflow-y: scroll;
  zoom: 1;
  min-height: 0;
}
.k-mobile .k-grid tbody {
  -webkit-backface-visibility: hidden;
}
.k-mobile .k-grid-backface tbody {
  -webkit-backface-visibility: visible;
}
.k-grid-content-expander {
  position: absolute;
  visibility: hidden;
  height: 1px;
}
.k-grid-norecords {
  width: 100%;
  height: 100%;
  text-align: center;
}
.k-grid-norecords-template {
  width: 20em;
  height: 4em;
  line-height: 4em;
  vertical-align: middle;
  margin: 0 auto;
}
.k-grid-content > .k-grid-norecords > .k-grid-norecords-template {
  top: 50%;
  left: 50%;
  margin-left: -10em;
  margin-top: -2em;
  position: absolute;
}
@media print {
  .k-grid {
    height: auto !important;
  }
  .k-grid-header {
    padding: 0 !important;
  }
  .k-grid-content,
  .k-grid-header-wrap {
    overflow: visible;
    height: auto !important;
  }
}
.k-grid .k-scrollbar {
  -ms-overflow-style: scrollbar;
}
.k-virtual-scrollable-wrap {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}
.k-grid-content table,
.k-grid-content-locked > table,
.k-grid-footer table,
.k-grid-header table {
  table-layout: fixed;
}
.k-grid-lockedcolumns {
  white-space: nowrap;
}
.k-grid-content,
.k-grid-content-locked,
.k-pager-wrap {
  white-space: normal;
}
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  border-style: solid;
  border-width: 0 1px 0 0;
}
.k-grid-content-locked + .k-grid-content,
.k-grid-footer-locked + .k-grid-footer-wrap,
.k-grid-header-locked + .k-grid-header-wrap {
  display: inline-block;
  vertical-align: top;
}
.k-grid-toolbar {
  border-style: solid;
  border-width: 1px 0 0;
}
.k-filter-row > th:first-child,
.k-grid tbody td:first-child,
.k-grid tfoot td:first-child,
.k-grid-header th.k-header:first-child {
  border-left-width: 0;
}
.k-grid-header th.k-header.k-first {
  border-left-width: 1px;
}
.k-grid-toolbar:first-child,
.k-grouping-header + .k-grid-toolbar {
  border-width: 0 0 1px;
}
.k-footer-template td {
  border-style: solid;
  border-width: 1px 0 0 1px;
}
.k-group-footer td {
  border-style: solid;
  border-width: 1px 0;
}
.k-group-footer .k-group-cell + td {
  border-left-width: 1px;
}
.k-grid-footer {
  border-style: solid;
  border-width: 1px 0 0;
}
.k-grid-footer td {
  border-top-width: 0;
}
.k-grid-footer > td {
  border-top-width: 1px;
}
.k-pager-wrap {
  clear: both;
  overflow: hidden;
  position: relative;
  border-style: solid;
  border-width: 1px;
  line-height: 2em;
  padding: 0.333em 0 0.333em 0.25em;
}
.k-grid-pager {
  border-width: 1px 0 0;
}
.k-grid .k-pager-numbers,
.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected {
  display: inline-block;
  vertical-align: top;
  margin-right: 1px;
}
.k-pager-numbers {
  margin: 0 2px;
}
.k-pager-numbers .k-state-selected {
  vertical-align: top;
}
.k-pager-numbers .k-current-page {
  display: none;
}
.k-pager-input,
.k-pager-numbers li {
  float: left;
}
.k-grid .k-pager-numbers {
  float: left;
  cursor: default;
}
.k-widget.k-grid .k-pager-numbers {
  position: relative;
}
.k-pager-info {
  float: right;
  padding: 0 1.333em;
}
.k-pager-numbers .k-link {
  text-decoration: none;
}
.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected,
.k-pager-wrap > .k-link {
  min-width: 2em;
}
.k-pager-wrap > .k-link {
  float: left;
  margin: 0 0.08333em;
  height: 2em;
  line-height: 2em;
  border-radius: 1.0833em;
  cursor: pointer;
  text-align: center;
}
.k-pager-wrap > a.k-state-disabled:hover {
  background: 0 0;
  cursor: default;
}
.k-pager-numbers .k-link {
  text-align: center;
  line-height: 2em;
  border-style: solid;
  border-width: 1px;
  border-radius: 1.0833em;
}
.k-pager-wrap > .k-link {
  border-style: solid;
  border-width: 1px;
}
.k-pager-wrap .k-pager-refresh {
  float: right;
  margin-right: 0.5em;
  border-width: 0;
  border-radius: 0;
}
.k-pager-numbers .k-state-selected {
  border-style: solid;
  border-width: 1px;
  text-align: center;
  border-radius: 1.0833em;
}
.k-pager-wrap .k-textbox {
  width: 3.333em;
}
.k-pager-wrap .k-dropdown {
  width: 4.5em;
}
.k-pager-refresh {
  float: right;
}
.k-pager-input,
.k-pager-sizes {
  padding: 0 1.4166em;
}
.k-pager-sizes {
  display: inline-block;
  padding-top: 1px;
}
.k-pager-sizes .k-widget.k-dropdown {
  margin-top: -2px;
}
.k-pager-wrap .k-textbox,
.k-pager-wrap .k-widget {
  margin: 0 0.4em 0;
}
.k-header > .k-grid-filter,
.k-header > .k-header-column-menu {
  float: right;
  margin: -0.5em -0.6em -0.4em 0;
  padding: 0.5em 0.2em 0.4em;
  position: relative;
  z-index: 1;
}
.k-grid .k-animation-container {
  position: absolute;
}
.k-filter-menu {
  padding: 0.5em;
}
.k-list-filter {
  display: block;
}
form.k-filter-menu .k-textbox,
form.k-filter-menu .k-widget {
  display: block;
}
form.k-filter-menu .k-textbox {
  width: 100%;
  margin-bottom: 3px;
}
.k-filter-help-text,
.k-filter-menu .k-textbox,
.k-filter-menu .k-widget {
  margin: 0.19em 0 0;
}
.k-filter-menu span.k-filter-and {
  width: 6em;
  margin: 0.5em 0 0.5em;
}
.k-filter-menu .k-button {
  width: 48%;
  margin: 0.5em 4% 0 0;
}
.k-filter-menu .k-button + .k-button {
  margin-right: 0;
}
.k-filter-menu .k-filter-selected-items {
  font-weight: 700;
  margin: 0.5em;
}
.k-multicheck-wrap {
  overflow: auto;
  overflow-x: hidden;
  white-space: nowrap;
  max-height: 300px;
}
.k-multicheck-wrap .k-item {
  line-height: 2.2em;
}
.k-grouping-row .k-icon {
  margin: -3px 4px 0 2px;
}
.k-grouping-row p {
  display: inline-block;
  vertical-align: middle;
  margin-left: -0.6em;
  padding: 0 0.6em;
}
.k-grouping-row + tr td {
  border-top-width: 1px;
}
.k-grouping-row .k-group-cell,
.k-grouping-row + tr .k-group-cell {
  border-top-width: 0;
  text-overflow: none;
}
.k-grid .k-hierarchy-cell + td {
  border-left-width: 0;
}
.k-grid .k-group-col,
.k-grid .k-hierarchy-col {
  width: 27px;
}
.k-grouping-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.k-grouping-header {
  line-height: 2;
}
.k-grouping-dropclue {
  position: absolute;
  width: 6px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: -165px -148px;
}
.k-grouping-header .k-group-indicator {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  margin: 0 3px;
  padding: 0.15em 0.15em 0.15em 0.4em;
  line-height: 1.5em;
}
.k-grouping-header .k-link {
  display: inline-block;
  border-width: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}
.k-grouping-header .k-button {
  border: 0;
  padding: 0;
  background: 0 0;
  line-height: 1;
}
.k-grouping-header .k-link .k-icon {
  margin: 0 0 0 -3px;
}
.k-grouping-header .k-button .k-icon {
  margin: 0 0 0 3px;
}
.k-grouping-header .k-button,
.k-grouping-header a {
  display: inline-block;
  vertical-align: middle;
}
.k-dirty-cell:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  float: left;
}
.k-dirty {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px;
  border-color: red transparent transparent red;
  margin: -0.45em 0 0 -0.6em;
  padding: 0;
  overflow: hidden;
  vertical-align: top;
}
.k-grid-toolbar,
.k-grouping-header {
  margin: 0;
  padding: 0.22em 0.2em 0.28em;
  cursor: default;
}
.k-grid .k-edit-container {
  padding: 0;
}
.k-grid .field-validation-error {
  display: block;
}
.k-grid .input-validation-error {
  border-style: ridge;
  border-color: red;
  background-color: pink;
}
.k-grid-toolbar .k-button {
  vertical-align: middle;
}
.k-grid-actions {
  display: inline-block;
}
.k-grid .k-button {
  margin: 0 0.16em;
}
.k-grid tbody .k-button,
.k-ie8 .k-grid tbody button.k-button {
  min-width: 64px;
}
.k-grid tbody button.k-button {
  min-width: 78px;
}
html body .k-grid tbody .k-button-icon {
  width: auto;
  min-width: 0;
}
.k-detail-row {
  position: relative;
}
.k-grid .k-detail-cell {
  overflow: visible;
}
.k-grid .k-edit-cell {
  padding: 0 0.3em;
  white-space: nowrap;
}
.k-grid .k-edit-cell .k-tooltip {
  white-space: normal;
}
.k-edit-cell > .k-textbox,
.k-edit-cell > .k-widget,
.k-grid-edit-row > td > .k-textbox,
.k-grid-edit-row > td > .k-widget,
.k-grid-edit-row > td > .text-box {
  width: 100%;
}
html .k-edit-cell .k-tooltip,
html .k-grid-edit-row .k-tooltip {
  width: auto;
  max-width: 300px;
}
.k-edit-cell input[type=checkbox] {
  margin-left: 0.6em;
}
.k-grid tbody td > .k-grid-delete {
  margin-top: -0.2em;
  margin-bottom: -0.2em;
}
.k-grid-resize-indicator {
  position: absolute;
  width: 2px;
  background-color: #aaa;
}
.k-grid-header .k-resize-handle,
.k-grid > .k-resize-handle {
  position: absolute;
  height: 25px;
  cursor: col-resize;
  z-index: 2;
}
.k-marquee {
  position: absolute;
  z-index: 100000;
}
.k-marquee-color,
.k-marquee-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.k-marquee-color {
  filter: alpha(opacity=60);
  opacity: 0.6;
}
.k-ie9 .k-column-menu {
  width: 160px;
}
.k-ie8 .k-grid-filter,
.k-ie8 .k-header-column-menu {
  font-size: 100%;
}
.k-column-menu {
  min-width: 160px;
}
.k-column-menu .k-sprite {
  margin-right: 10px;
}
.k-column-menu > .k-menu {
  border-width: 0;
}
.k-column-menu .k-calendar .k-link {
  white-space: normal;
}
.k-columns-item .k-group {
  max-height: 200px;
  overflow: auto;
}
.k-pdf-export-shadow .k-grid {
  float: left;
  width: auto !important;
}
.k-pdf-export-shadow .k-grid,
.k-pdf-export-shadow .k-grid-content,
.k-pdf-export-shadow .k-grid-content-locked {
  height: auto!important;
  overflow: visible;
}
.k-pdf-export-shadow .k-grid-content-locked + .k-grid-content,
.k-pdf-export-shadow .k-grid-footer-locked + .k-grid-footer-wrap,
.k-pdf-export-shadow .k-grid-header-locked + .k-grid-header-wrap {
  width: auto !important;
}
.k-pdf-export-shadow .k-grid-footer,
.k-pdf-export-shadow .k-grid-header {
  padding: 0 !important;
}
.k-loading-pdf-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.k-loading-pdf-mask .k-loading-color {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.k-loading-pdf-mask .k-loading-pdf-progress {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.k-pdf-export .k-loading-pdf-mask {
  display: none;
}
.k-autofitting {
  width: auto!important;
  table-layout: auto !important;
}
.k-autofitting td,
.k-autofitting th.k-header {
  white-space: nowrap !important;
}
.k-autofitting .k-detail-row {
  display: none !important;
}
@media only screen and (max-width: 1024px) {
  .k-edge .k-pager-wrap,
  .k-ff .k-pager-wrap,
  .k-ie11 .k-pager-wrap,
  .k-safari .k-pager-wrap,
  .k-webkit .k-pager-wrap {
    overflow: visible;
    min-height: 2.1em;
  }
  .k-edge .k-pager-input,
  .k-edge .k-pager-wrap .k-pager-nav,
  .k-ff .k-pager-input,
  .k-ff .k-pager-wrap .k-pager-nav,
  .k-ie11 .k-pager-input,
  .k-ie11 .k-pager-wrap .k-pager-nav,
  .k-safari .k-pager-input,
  .k-safari .k-pager-wrap .k-pager-nav,
  .k-webkit .k-pager-input,
  .k-webkit .k-pager-wrap .k-pager-nav {
    display: inline-block;
    vertical-align: top;
  }
  .k-edge .k-grid .k-pager-numbers,
  .k-edge .k-pager-numbers,
  .k-ff .k-grid .k-pager-numbers,
  .k-ff .k-pager-numbers,
  .k-ie11 .k-grid .k-pager-numbers,
  .k-ie11 .k-pager-numbers,
  .k-safari .k-grid .k-pager-numbers,
  .k-safari .k-pager-numbers,
  .k-webkit .k-grid .k-pager-numbers,
  .k-webkit .k-pager-numbers {
    position: absolute;
    left: 4.8em;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    overflow: visible;
    height: auto;
  }
  .k-edge .k-grid .k-pager-numbers:first-child,
  .k-edge .k-pager-numbers:first-child,
  .k-ff .k-grid .k-pager-numbers:first-child,
  .k-ff .k-pager-numbers:first-child,
  .k-ie11 .k-grid .k-pager-numbers:first-child,
  .k-ie11 .k-pager-numbers:first-child,
  .k-safari .k-grid .k-pager-numbers:first-child,
  .k-safari .k-pager-numbers:first-child,
  .k-webkit .k-grid .k-pager-numbers:first-child,
  .k-webkit .k-pager-numbers:first-child {
    left: 0.2em;
  }
  .k-edge .k-grid .k-pager-numbers.k-state-expanded,
  .k-edge .k-pager-numbers.k-state-expanded,
  .k-ff .k-grid .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-grid .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-safari .k-grid .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded,
  .k-webkit .k-grid .k-pager-numbers.k-state-expanded,
  .k-webkit .k-pager-numbers.k-state-expanded {
    -ms-transform: translatey(-100%);
    transform: translatey(-100%);
    -webkit-transform: translatey(-100%);
  }
  .k-edge .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-edge .km-pane-wrapper .k-pager-numbers,
  .k-ff .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ff .km-pane-wrapper .k-pager-numbers,
  .k-ie11 .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ie11 .km-pane-wrapper .k-pager-numbers,
  .k-safari .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-safari .km-pane-wrapper .k-pager-numbers,
  .k-webkit .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-webkit .km-pane-wrapper .k-pager-numbers {
    position: relative;
    left: 50%;
    -ms-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    -webkit-transform: translate(-50%, -100%);
  }
  .k-edge .km-pane-wrapper .k-pager-numbers .k-link,
  .k-edge .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-edge .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-edge .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-link,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-link,
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-pager-info {
    padding-top: 0;
    padding-bottom: 0;
  }
  .k-edge .k-rtl .k-grid .k-pager-numbers,
  .k-edge .k-rtl .k-pager-numbers,
  .k-ff .k-rtl .k-grid .k-pager-numbers,
  .k-ff .k-rtl .k-pager-numbers,
  .k-ie11 .k-rtl .k-grid .k-pager-numbers,
  .k-ie11 .k-rtl .k-pager-numbers,
  .k-safari .k-rtl .k-grid .k-pager-numbers,
  .k-safari .k-rtl .k-pager-numbers,
  .k-webkit .k-rtl .k-grid .k-pager-numbers,
  .k-webkit .k-rtl .k-pager-numbers {
    left: auto;
    right: 4.8em;
    width: 4.5em;
  }
  .k-edge .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-edge .k-rtl .k-pager-numbers:first-child,
  .k-ff .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-ff .k-rtl .k-pager-numbers:first-child,
  .k-ie11 .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-ie11 .k-rtl .k-pager-numbers:first-child,
  .k-safari .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-safari .k-rtl .k-pager-numbers:first-child,
  .k-webkit .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-webkit .k-rtl .k-pager-numbers:first-child {
    left: auto;
    right: 0.2em;
  }
  .k-edge .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-edge .k-rtl .km-pane-wrapper .k-pager-numbers,
  .k-ff .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ff .k-rtl .km-pane-wrapper .k-pager-numbers,
  .k-ie11 .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ie11 .k-rtl .km-pane-wrapper .k-pager-numbers,
  .k-safari .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-safari .k-rtl .km-pane-wrapper .k-pager-numbers,
  .k-webkit .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-webkit .k-rtl .km-pane-wrapper .k-pager-numbers {
    right: 5.8em;
  }
  .k-edge .k-grid .k-pager-numbers .k-current-page,
  .k-edge .k-pager-numbers .k-current-page,
  .k-ff .k-grid .k-pager-numbers .k-current-page,
  .k-ff .k-pager-numbers .k-current-page,
  .k-ie11 .k-grid .k-pager-numbers .k-current-page,
  .k-ie11 .k-pager-numbers .k-current-page,
  .k-safari .k-grid .k-pager-numbers .k-current-page,
  .k-safari .k-pager-numbers .k-current-page,
  .k-webkit .k-grid .k-pager-numbers .k-current-page,
  .k-webkit .k-pager-numbers .k-current-page {
    display: block;
    border-left: 0;
  }
  .k-edge .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-edge .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ff .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ie11 .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page,
  .k-safari .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page,
  .k-webkit .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page {
    -ms-transform: translatey(100%);
    transform: translatey(100%);
    -webkit-transform: translatey(100%);
  }
  .k-edge .k-pager-numbers li:not(.k-current-page),
  .k-ff .k-pager-numbers li:not(.k-current-page),
  .k-ie11 .k-pager-numbers li:not(.k-current-page),
  .k-safari .k-pager-numbers li:not(.k-current-page),
  .k-webkit .k-pager-numbers li:not(.k-current-page) {
    display: none;
  }
  .k-edge .k-pager-numbers .k-current-page .k-link,
  .k-ff .k-pager-numbers .k-current-page .k-link,
  .k-ie11 .k-pager-numbers .k-current-page .k-link,
  .k-safari .k-pager-numbers .k-current-page .k-link,
  .k-webkit .k-pager-numbers .k-current-page .k-link {
    width: 3.2em;
    padding: 0 0.429em 0 0.714em;
    border-radius: 1.0833em;
  }
  .k-edge .k-pager-numbers + .k-link,
  .k-ff .k-pager-numbers + .k-link,
  .k-ie11 .k-pager-numbers + .k-link,
  .k-safari .k-pager-numbers + .k-link,
  .k-webkit .k-pager-numbers + .k-link {
    margin-left: 4.8em;
  }
  .k-edge .k-rtl .k-pager-numbers + .k-link,
  .k-ff .k-rtl .k-pager-numbers + .k-link,
  .k-ie11 .k-rtl .k-pager-numbers + .k-link,
  .k-safari .k-rtl .k-pager-numbers + .k-link,
  .k-webkit .k-rtl .k-pager-numbers + .k-link {
    margin-right: 5.1em;
    margin-left: 0;
  }
  .k-edge .k-pager-numbers .k-link,
  .k-edge .k-pager-numbers .k-state-selected,
  .k-ff .k-pager-numbers .k-link,
  .k-ff .k-pager-numbers .k-state-selected,
  .k-ie11 .k-pager-numbers .k-link,
  .k-ie11 .k-pager-numbers .k-state-selected,
  .k-safari .k-pager-numbers .k-link,
  .k-safari .k-pager-numbers .k-state-selected,
  .k-webkit .k-pager-numbers .k-link,
  .k-webkit .k-pager-numbers .k-state-selected {
    display: block;
    margin-right: 0;
    padding: 1px 5px 1px 5px;
    text-align: left;
  }
  .k-edge .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded,
  .k-webkit .k-pager-numbers.k-state-expanded {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 2px 2px 0;
  }
  .k-edge .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page,
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page {
    margin: -2em -3px 0;
    padding: 0;
  }
  .k-edge .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page .k-link {
    border-radius: 0 0 1.0833em 1.0833em;
  }
  .k-edge .k-pager-numbers.k-state-expanded li,
  .k-ff .k-pager-numbers.k-state-expanded li,
  .k-ie11 .k-pager-numbers.k-state-expanded li,
  .k-safari .k-pager-numbers.k-state-expanded li,
  .k-webkit .k-pager-numbers.k-state-expanded li {
    display: inline-block;
  }
}
@media only screen and (max-width: 640px) {
  .k-edge .k-pager-info,
  .k-ff .k-pager-info,
  .k-ie11 .k-pager-info,
  .k-safari .k-pager-info,
  .k-webkit .k-pager-info {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .k-edge .k-pager-sizes,
  .k-ff .k-pager-sizes,
  .k-ie11 .k-pager-sizes,
  .k-safari .k-pager-sizes,
  .k-webkit .k-pager-sizes {
    display: none;
  }
}
.k-treelist .k-status {
  padding: 0.4em 0.6em;
  line-height: 1.6em;
}
.k-treelist .k-status .k-loading {
  vertical-align: baseline;
  margin-right: 5px;
}
.k-treelist tr.k-hidden {
  display: none;
}
.k-treelist.k-treelist-dragging,
.k-treelist.k-treelist-dragging .k-state-hover {
  cursor: default;
}
.k-treelist .k-drop-hint {
  position: absolute;
  z-index: 10000;
  visibility: hidden;
  width: 80px;
  height: 5px;
  margin-top: -3px;
  background-color: transparent;
  background-repeat: no-repeat;
}
.k-drag-separator {
  display: inline-block;
  border-right: 1px solid;
  height: 1em;
  vertical-align: top;
  margin: 0 0.5em;
}
.k-gantt {
  white-space: nowrap;
  position: relative;
}
.k-gantt-layout {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.k-gantt .k-splitbar {
  position: relative;
  cursor: e-resize;
  width: 5px;
  border-width: 0 1px;
  background-repeat: repeat-y;
}
.k-gantt .k-gantt-layout th {
  vertical-align: bottom;
}
.k-gantt td {
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
}
.k-gantt .k-grid .k-edit-cell {
  vertical-align: middle;
}
.k-gantt-timeline > .k-timeline,
.k-gantt-treelist > .k-treelist {
  border-width: 0;
  height: 100%;
}
.k-gantt-toolbar {
  border-style: solid;
  border-width: 0 0 1px;
  line-height: 2.4em;
  padding: 0.5em;
}
.k-gantt-layout + .k-gantt-toolbar {
  border-width: 1px 0 0;
}
.k-gantt-actions,
.k-gantt-toolbar > ul {
  float: left;
  margin-right: 0.6em;
}
.k-gantt-actions > .k-button {
  margin-right: 0.5em;
  vertical-align: top;
}
.k-gantt-toolbar > .k-gantt-views {
  float: right;
  margin-right: 0;
}
.k-gantt-toolbar > .k-gantt-views > li.k-current-view {
  display: none;
}
.k-gantt-toolbar > ul > li {
  display: inline-block;
  border-style: solid;
  border-width: 1px 1px 1px 0;
}
.k-gantt-toolbar > ul > li:first-child + li {
  border-left-width: 1px;
}
.k-gantt-toolbar .k-link {
  display: inline-block;
  padding: 0 1.1em;
}
.k-gantt-toolbar li:first-child + li,
.k-gantt-toolbar li:first-child + li > .k-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.k-gantt-toolbar li:last-child,
.k-gantt-toolbar li:last-child > .k-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-gantt-toolbar li.k-button {
  line-height: inherit;
  padding-top: 0;
  padding-bottom: 0;
}
.k-gantt-treelist .k-grid-header tr {
  height: 5em;
}
.k-gantt .k-treelist .k-grid-header {
  padding: 0 !important;
}
.k-gantt .k-treelist .k-grid-content {
  overflow-y: hidden;
  overflow-x: scroll;
}
.k-treelist-group > tr > span {
  font-weight: 700;
}
.k-treelist-group .k-widget {
  font-weight: 400;
}
.k-gantt-timeline .k-grid-header tr {
  height: 2.5em;
}
.k-gantt-tasks {
  position: relative;
}
.k-gantt .k-grid-content tr,
.k-gantt-rows tr,
.k-gantt-tasks tr {
  height: 2.3em;
}
.k-gantt .k-gantt-tasks td:after {
  content: "\a0";
}
.k-gantt-timeline {
  background: 0 0;
}
.k-gantt-columns,
.k-gantt-dependencies,
.k-gantt-rows {
  position: absolute;
  top: 0;
  left: 0;
}
.k-gantt-tables {
  position: relative;
}
.k-gantt .k-timeline .k-grid-content {
  overflow-x: scroll;
}
.k-gantt .k-gantt-timeline th {
  text-align: center;
}
.k-gantt .k-gantt-timeline tr:first-child th {
  border-bottom-width: 1px;
}
.k-task-summary {
  height: 10px;
  display: inline-block;
  vertical-align: top;
  margin-top: 3px;
}
.k-task-summary-complete {
  height: 10px;
  position: relative;
  z-index: 2;
}
.k-task-summary-progress {
  height: 15px;
  overflow: hidden;
}
.k-task-summary-complete:after,
.k-task-summary-complete:before,
.k-task-summary:after,
.k-task-summary:before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent;
}
.k-task-summary-complete:before,
.k-task-summary:before {
  left: 0;
  border-left-color: inherit;
}
.k-task-summary-complete:after,
.k-task-summary:after {
  right: 0;
  border-right-color: inherit;
}
.k-line-h,
.k-line-v {
  position: absolute;
}
.k-line-h {
  height: 2px;
}
.k-line-v {
  width: 2px;
}
.k-arrow-e,
.k-arrow-w {
  position: absolute;
  top: -4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px;
}
.k-arrow-e {
  right: -6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.k-arrow-w {
  left: -6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.k-task-milestone {
  width: 13px;
  height: 13px;
  margin-top: 3px;
  border-style: solid;
  border-width: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.k-ie8 .k-task-milestone {
  margin-left: 1px;
}
.k-gantt .k-gantt-tasks .k-button-icon,
.k-gantt .k-gantt-treelist .k-button {
  padding-top: 0;
  padding-bottom: 0;
}
.k-gantt .k-gantt-tasks .k-button-icon {
  margin-top: 4px;
}
.k-gantt .k-gantt-treelist .k-button {
  margin-top: -4px;
  margin-bottom: -2px;
}
.k-gantt .k-gantt-tasks .k-button-icon {
  padding-left: 2px;
  padding-right: 2px;
}
.k-gantt .k-gantt-tasks .k-button .k-icon,
.k-gantt .k-gantt-treelist .k-button .k-icon {
  vertical-align: text-top;
}
.k-rel .k-button-icon {
  position: absolute;
  left: 200px;
}
.k-rel {
  position: relative;
  height: 0;
  top: -0.3em;
}
.k-task-wrap {
  position: absolute;
  padding: 0 23px 5px;
  margin: -1px -23px 0;
  z-index: 2;
}
.k-line.k-state-selected,
.k-task-wrap:hover {
  z-index: 3;
}
.k-milestone-wrap {
  margin: 0 -13px 0 -27px;
}
.k-task-content {
  position: relative;
  z-index: 2;
}
.k-task-complete {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20%;
  z-index: 1;
}
.k-task-dot {
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: none;
  cursor: pointer;
}
.k-task-dot.k-state-hover {
  background-color: transparent;
}
.k-task-single + .k-task-dot,
.k-task-single + .k-task-dot + .k-task-dot {
  top: 0.2em;
}
.k-task-wrap-active .k-task-dot,
.k-task-wrap:hover .k-task-dot {
  display: block;
}
.k-task-dot:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  height: 16px;
}
.k-task-dot:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-left: 4px;
}
.k-task-dot.k-state-hover:after,
.k-task-dot:hover:after,
.k-task-wrap-active .k-task-dot:after {
  border-style: solid;
  border-width: 1px;
  margin-left: 3px;
}
.k-task-start {
  left: 0;
}
.k-task-end {
  right: 0;
}
.k-task-single {
  border-style: solid;
  border-width: 1px;
  text-align: left;
  overflow: hidden;
  cursor: default;
  min-height: 1.3em;
  white-space: nowrap;
}
.k-task-template {
  padding: 0.2em 1.4em 0.2em 0.6em;
  line-height: normal;
}
.k-task-actions,
.k-task-content > .k-link {
  position: absolute;
  top: 0;
  right: 4px;
  white-space: nowrap;
}
.k-task-actions {
  z-index: 1;
}
.k-task-actions:first-child {
  position: static;
  float: left;
  margin: 4px 2px 0 4px;
}
.k-webkit .k-task-actions:first-child {
  margin-top: 3px;
}
.k-task-actions:first-child > .k-link {
  display: inline-block;
}
.k-task-delete {
  display: none;
}
.k-task-wrap-active .k-task-delete,
.k-task-wrap:hover .k-task-delete {
  display: inline-block;
}
.k-task-single .k-resize-handle {
  position: absolute;
  visibility: hidden;
  z-index: 2;
  height: auto;
}
.k-task-single:hover .k-resize-handle,
.k-task-wrap-active .k-resize-handle {
  visibility: visible;
}
.k-task-single .k-resize-handle:after {
  content: "";
  position: absolute;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.k-task-content > .k-resize-e {
  right: 0;
  top: 0;
  bottom: 0;
  width: 0.4em;
}
.k-task-content > .k-resize-w {
  left: 0;
  top: 0;
  bottom: 0;
  width: 0.4em;
}
.k-task-content > .k-resize-e:after,
.k-task-content > .k-resize-w:after {
  left: 1px;
  top: 50%;
  margin-top: -0.7em;
  height: 1.4em;
  width: 1px;
}
.k-task-content > .k-resize-e:after {
  left: auto;
  right: 1px;
}
.k-task-draghandle {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  margin-left: 16px;
  border-width: 5px;
  border-style: solid;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  display: none;
  cursor: e-resize;
}
.k-task-wrap-active .k-task-draghandle,
.k-task-wrap:hover .k-task-draghandle {
  display: block;
}
.k-dependency-hint {
  z-index: 4;
}
.k-gantt-rowheight .k-task-dot,
.k-gantt-rowheight .k-task-milestone,
.k-gantt-rowheight .k-task-single + .k-task-dot,
.k-gantt-rowheight .k-task-single + .k-task-dot + .k-task-dot,
.k-gantt-rowheight .k-task-summary {
  top: 50%;
}
.k-gantt-rowheight .k-task-milestone,
.k-gantt-rowheight .k-task-summary {
  margin-top: -6px;
}
.k-gantt-rowheight .k-task-dot,
.k-gantt-rowheight .k-task-single + .k-task-dot,
.k-gantt-rowheight .k-task-single + .k-task-dot + .k-task-dot {
  margin-top: -11px;
}
.k-gantt-rowheight .k-task-single {
  height: -webkit-calc(98%);
  height: calc(98%);
}
.k-ie .k-gantt-rowheight .k-task-single {
  height: 99%;
}
.k-gantt-rowheight .k-task-content {
  height: 100%;
}
.k-gantt-rowheight .k-task-content > .k-resize-e:after,
.k-gantt-rowheight .k-task-content > .k-resize-w:after {
  top: 0;
  margin-top: 0;
  height: 100%;
}
.k-task-details {
  padding: 0.4em;
  text-align: left;
  white-space: nowrap;
}
.k-task-details > strong {
  font-size: 120%;
  display: block;
}
.k-task-pct {
  margin: 0.5em 0 0.1em;
  font-size: 170%;
}
.k-task-details > ul {
  line-height: 1.2;
}
.k-resources-wrap {
  position: absolute;
  z-index: 2;
  zoom: 1;
  margin-left: 20px;
  margin-top: -2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.k-resources-wrap .k-resource {
  margin: 0 5px;
}
.k-gantt-edit-form > .k-edit-form-container {
  width: 430px;
}
.k-gantt-edit-form > .k-resources-form-container {
  width: 506px;
}
.k-resources-form-container > .k-grid {
  margin: 0 0.9em;
}
.k-gantt-edit-form > .k-edit-form-container .k-numerictextbox,
.k-gantt-edit-form > .k-edit-form-container .k-textbox {
  width: 15em;
}
.k-gantt-edit-form .k-edit-buttons .k-gantt-delete {
  float: left;
}
.k-pdf-export-shadow .k-gantt {
  float: left;
}
.k-pdf-export-shadow .k-gantt,
.k-pdf-export-shadow .k-gantt .k-grid-content,
.k-pdf-export-shadow .k-gantt-timeline {
  width: auto!important;
  height: auto!important;
  overflow: visible !important;
}
.k-pdf-export-shadow .k-gantt-treelist {
  height: auto!important;
  overflow: visible !important;
}
.k-pdf-export-shadow .k-gantt-timeline .k-grid-header {
  padding: 0 !important;
}
.k-pdf-export-shadow .k-gantt .k-splitbar,
.k-pdf-export-shadow .k-pdf-export {
  display: none;
}
button.k-gantt-toggle {
  display: none;
  float: left;
  margin-right: 0.5em;
}
.k-rtl button.k-gantt-toggle {
  float: right;
  margin-right: 0;
  margin-left: 0.5em;
}
@media only screen and (max-width: 1024px) {
  .k-gantt-toolbar > ul.k-gantt-views {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10000;
  }
  .k-rtl .k-gantt-toolbar > ul.k-gantt-views {
    right: auto;
    left: 6px;
  }
  .k-gantt-toolbar > ul.k-gantt-views > li:not(.k-current-view) {
    display: none;
  }
  .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view {
    display: block;
    border-width: 1px;
  }
  .k-rtl .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view {
    text-align: left;
    padding-left: 1em;
  }
  .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link {
    display: block;
    position: relative;
    padding-right: 2.5em;
    padding-left: 1em;
  }
  .k-rtl .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link {
    padding-left: 0;
  }
  .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.6em;
    right: 0.333em;
    width: 16px;
    height: 16px;
  }
  .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li,
  .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li:first-child + li {
    display: block;
    border: 0;
    border-radius: 0;
  }
  .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded {
    border: 1px solid #c5c5c5;
    background-color: #fff;
    background-image: none;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .k-rtl .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded {
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  button.k-gantt-toggle {
    display: inline-block;
  }
  .k-gantt-create span + span,
  .k-gantt-pdf span + span {
    display: none;
  }
  .k-gantt-create .k-icon,
  .k-gantt-pdf .k-icon {
    margin: 0;
  }
  .k-gantt .k-splitbar {
    display: none;
    width: 0;
    border-width: 0;
  }
  .k-gantt .k-gantt-treelist {
    display: none;
    max-width: 0;
  }
  .k-gantt .k-treelist .k-grid-content {
    overflow-y: scroll;
  }
  .k-gantt .k-gantt-timeline {
    width: 100%;
  }
}
.k-pivot {
  position: relative;
}
.k-pivot-toolbar {
  padding: 0.2em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-pivot .k-pivot-toolbar {
  padding: 0.6em;
}
.k-pivot-toolbar .k-button {
  margin-right: 0.4em;
  line-height: 1.2em;
  font-size: 0.9em;
  text-align: left;
  position: relative;
  padding: 0.3em 5em 0.3em 0.3em;
  cursor: move;
}
.k-field-actions {
  position: absolute;
  right: 2px;
  top: 3px;
  cursor: pointer;
}
.k-pivot .k-grid td {
  white-space: nowrap;
}
.k-pivot-layout {
  border-spacing: 0;
  table-layout: auto;
}
.k-pivot-layout > tbody > tr > td {
  vertical-align: top;
  padding: 0;
}
.k-pivot td {
  vertical-align: top;
}
.k-pivot-rowheaders > .k-grid,
.k-pivot-table > .k-grid {
  border-width: 0;
}
.k-pivot-rowheaders > .k-grid td:first-child,
.k-pivot-table .k-grid-header .k-header.k-first {
  border-left-width: 1px;
}
.k-pivot-rowheaders > .k-grid td.k-first {
  border-left-width: 0;
}
.k-pivot-rowheaders > .k-grid {
  overflow: hidden;
}
.k-pivot-table {
  border-left-width: 1px;
  border-left-style: solid;
}
.k-pivot-table .k-grid-header-wrap > table {
  height: 100%;
}
.k-pivot .k-grid-header .k-header {
  vertical-align: top;
}
.k-header.k-alt,
td.k-alt {
  font-weight: 700;
}
.k-header.k-alt {
  background-image: none;
}
.k-pivot-layout .k-grid td {
  border-bottom-width: 1px;
}
.k-pivot-layout .k-grid-footer > td {
  border-top-width: 0;
}
.k-pivot-filter-window .k-treeview {
  max-height: 600px;
}
.k-fieldselector .k-edit-buttons {
  bottom: auto;
}
.k-fieldselector .k-edit-label {
  width: 16%;
}
.k-fieldselector .k-edit-field {
  width: 77%;
}
.k-fieldselector .k-edit-field > .k-textbox,
.k-fieldselector .k-edit-field > .k-widget {
  width: 99%;
}
.k-fieldselector .k-edit-buttons > input,
.k-fieldselector .k-edit-buttons > label {
  float: left;
  margin-top: 0.4em;
}
.k-fieldselector p {
  margin: 0 0 0.2em 0.5em;
  text-transform: uppercase;
}
.k-fieldselector p .k-icon {
  margin: 0 5px 0 0;
}
.k-fieldselector .k-columns {
  border-style: solid;
  border-width: 0;
}
.k-fieldselector .k-columns > div {
  overflow: auto;
  padding: 0.6em;
  border-style: solid;
  border-width: 0 0 0 1px;
  float: left;
  width: 45%;
}
.k-fieldselector .k-columns > div:first-child {
  border-width: 0;
  margin-right: -1px;
}
.k-fieldselector .k-columns > div + div {
  float: right;
  border-width: 0;
}
.k-fieldselector div.k-treeview {
  border-width: 0;
  margin-right: -1px;
  padding-left: 4px;
  overflow: visible;
}
.k-fieldselector .k-list-container {
  margin-left: 0.5em;
  margin-bottom: 1em;
  padding: 0.2em 0 0;
  border-style: solid;
  border-width: 1px;
}
.k-fieldselector .k-list {
  padding-bottom: 2em;
}
.k-fieldselector .k-list li.k-item {
  padding: 0.3em 3.3em 0.3em 0.3em;
  margin: 0 0.2em 0.2em;
  position: relative;
  font-size: 0.9em;
  line-height: 1.2em;
  min-height: 1em;
}
.k-i-kpi-decrease {
  background-position: 0 0;
}
.k-i-kpi-denied {
  background-position: -16px 0;
}
.k-i-kpi-equal {
  background-position: -32px 0;
}
.k-i-kpi-hold {
  background-position: -48px 0;
}
.k-i-kpi-increase {
  background-position: -64px 0;
}
.k-i-kpi-open {
  background-position: -80px 0;
}
.k-pdf-export-shadow .k-pivot .k-grid-content {
  overflow: visible !important;
}
.k-pdf-export-shadow .k-pivot .k-grid-content,
.k-pdf-export-shadow .k-pivot .k-pivot-rowheaders .k-grid {
  height: 100% !important;
}
.k-pdf-export-shadow .k-pivot .k-grid-header {
  padding-right: 0 !important;
}
.k-pdf-export-shadow .k-pivot {
  width: auto!important;
  height: auto !important;
}
.k-pdf-export-shadow .k-pivot .k-grid-content > table,
.k-pdf-export-shadow .k-pivot .k-grid-header-wrap > table {
  width: 100%!important;
  height: auto !important;
}
.k-calendar {
  position: relative;
  display: inline-block;
  width: 16.917em;
  overflow: hidden;
}
.k-calendar .k-link,
.k-calendar td {
  text-decoration: none;
}
.k-calendar .k-action-link {
  text-decoration: underline;
}
.k-calendar .k-footer,
.k-calendar .k-header {
  position: relative;
  text-align: center;
  zoom: 1;
}
.k-widget.k-calendar .k-nav-next,
.k-widget.k-calendar .k-nav-prev {
  position: absolute;
  top: 0.16666em;
  line-height: 1.8333em;
  height: 1.8333em;
}
.k-widget.k-calendar .k-nav-prev {
  left: 1%;
}
.k-widget.k-calendar .k-nav-next {
  right: 1%;
}
.k-calendar .k-content {
  float: left;
  border-spacing: 0;
  width: 100%;
  height: 14.167em;
  border-width: 0;
  margin: 0;
  table-layout: fixed;
  outline: 0;
}
.k-calendar .k-content,
.k-calendar .k-content th {
  text-align: right;
}
.k-calendar .k-animation-container .k-content {
  height: 100%;
}
.k-widget.k-calendar .k-nav-fast {
  display: inline-block;
  width: 75%;
  height: 1.8333em;
  line-height: 1.8333em;
  margin: 0.16666em -0.08333em 0.3333em 0;
}
.k-calendar .k-header .k-icon {
  vertical-align: middle;
}
.k-calendar .k-header .k-link.k-nav-next,
.k-calendar .k-header .k-link.k-nav-prev {
  height: 1.8333em;
  width: 1.8333em;
}
.k-calendar th {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0.4em 0.45em 0.4em 0.1em;
  font-weight: 400;
  cursor: default;
}
.k-calendar td {
  padding: 0.08333em;
  cursor: pointer;
}
.k-calendar .k-state-focus {
  border-style: dotted;
  border-width: 0.08333em;
  padding: 0;
}
.k-calendar .k-content .k-link {
  display: block;
  overflow: hidden;
  min-height: 1.8333em;
  line-height: 1.8333em;
  padding: 0 0.45em 0 0.1em;
}
.k-calendar .k-meta-view .k-link {
  padding: 0.25em 0 0.3em;
  text-align: center;
}
.k-calendar .k-footer {
  clear: both;
}
.k-calendar .k-footer .k-nav-today,
.k-calendar .k-footer > .k-state-disabled {
  display: block;
  height: 100%;
  padding: 0.5em 0;
}
.k-calendar .k-nav-today:hover {
  text-decoration: underline;
}
div.k-treeview {
  border-width: 0;
  background: 0 0;
  overflow: auto;
  white-space: nowrap;
}
.k-treeview .k-item {
  display: block;
  border-width: 0;
  margin: 0;
  padding: 0 0 0 16px;
}
.k-treeview .k-content,
.k-treeview .k-item > .k-group,
.k-treeview > .k-group {
  margin: 0;
  padding: 0;
  background: 0 0;
  list-style-type: none;
  position: relative;
}
.k-treeview .k-checkbox,
.k-treeview .k-icon,
.k-treeview .k-image,
.k-treeview .k-in,
.k-treeview .k-sprite {
  display: inline-block;
  vertical-align: top;
}
.k-treeview .k-checkbox {
  margin-top: 0.2em;
}
.k-treeview .k-icon,
.k-treeview .k-in {
  vertical-align: middle;
}
.k-treeview .k-request-retry {
  vertical-align: baseline;
}
.k-treeview .k-i-collapse,
.k-treeview .k-i-expand,
.k-treeview .k-i-minus,
.k-treeview .k-i-minus-disabled,
.k-treeview .k-i-plus,
.k-treeview .k-i-plus-disabled,
.k-treeview .k-minus,
.k-treeview .k-minus-disabled,
.k-treeview .k-plus,
.k-treeview .k-plus-disabled {
  margin-top: 0.25em;
  margin-left: -16px;
  cursor: pointer;
}
.k-treeview .k-i-minus-disabled,
.k-treeview .k-i-plus-disabled,
.k-treeview .k-minus-disabled,
.k-treeview .k-plus-disabled {
  cursor: default;
}
.k-treeview .k-image,
.k-treeview .k-sprite {
  margin-right: 3px;
}
.k-treeview .k-in {
  margin: 1px 0 1px 0.16666em;
  padding: 1px 0.3333em 1px 0.25em;
  line-height: 1.3333em;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
.k-treeview span.k-in {
  cursor: default;
}
.k-treeview .k-drop-hint {
  position: absolute;
  z-index: 10000;
  visibility: hidden;
  width: 80px;
  height: 5px;
  margin-top: -3px;
  background-color: transparent;
  background-repeat: no-repeat;
}
.k-toolbar .k-split-button,
span.k-colorpicker,
span.k-combobox,
span.k-datepicker,
span.k-datetimepicker,
span.k-dropdown,
span.k-numerictextbox,
span.k-timepicker {
  background-image: none;
}
.k-autocomplete,
.k-colorpicker,
.k-combobox,
.k-datepicker,
.k-datetimepicker,
.k-dropdown,
.k-numerictextbox,
.k-selectbox,
.k-textbox,
.k-timepicker,
.k-toolbar .k-split-button {
  position: relative;
  display: inline-block;
  width: 12.4em;
  overflow: visible;
  border-width: 0;
  vertical-align: middle;
}
.k-autocomplete > .k-i-close,
.k-combobox > .k-dropdown-wrap > .k-i-close,
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  display: none;
  position: absolute;
  right: 0;
}
.k-autocomplete > .k-i-close,
.k-combobox > .k-dropdown-wrap > .k-i-close {
  bottom: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.k-ie8 .k-autocomplete > .k-i-close,
.k-ie8 .k-combobox > .k-dropdown-wrap > .k-i-close {
  margin-top: -8px;
}
.k-combobox > .k-dropdown-wrap > .k-i-close {
  font-size: 100%;
  right: 2.4em;
}
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  top: 6px;
}
.k-autocomplete > .k-i-close,
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  margin-right: 6px;
}
.k-autocomplete.k-state-focused > .k-i-close,
.k-autocomplete.k-state-hover > .k-i-close,
.k-combobox > .k-dropdown-wrap.k-state-focused > .k-i-close,
.k-combobox > .k-dropdown-wrap.k-state-hover > .k-i-close {
  display: inline-block;
  outline: 0;
}
.k-autocomplete > .k-i-close.k-loading-hidden,
.k-combobox > .k-dropdown-wrap > .k-i-close.k-loading-hidden {
  display: none;
}
.k-multiselect.k-state-focused > .k-multiselect-wrap > .k-i-close,
.k-multiselect.k-state-hover > .k-multiselect-wrap > .k-i-close {
  display: inline-block;
  outline: 0;
}
.k-autocomplete.k-state-border-down > .k-i-close,
.k-combobox > .k-dropdown-wrap.k-state-border-down > .k-i-close {
  margin-top: -1px;
}
.k-filter-menu .k-autocomplete,
.k-filter-menu .k-combobox,
.k-filter-menu .k-datepicker,
.k-filter-menu .k-datetimepicker,
.k-filter-menu .k-dropdown,
.k-filter-menu .k-numerictextbox,
.k-filter-menu .k-textbox,
.k-filter-menu .k-timepicker {
  width: 13.2em;
}
.k-autocomplete,
.k-colorpicker,
.k-combobox,
.k-datepicker,
.k-datetimepicker,
.k-dropdown,
.k-numerictextbox,
.k-selectbox,
.k-timepicker,
.k-toolbar .k-split-button {
  white-space: nowrap;
}
.k-colorpicker,
.k-toolbar .k-split-button {
  width: auto;
}
.k-datetimepicker {
  width: 15em;
}
.k-autocomplete,
.k-numeric-wrap,
.k-picker-wrap {
  position: relative;
  cursor: default;
}
.k-dropdown-wrap {
  position: relative;
}
.k-dropdown-wrap,
.k-numeric-wrap,
.k-picker-wrap {
  display: block;
}
.k-block,
.k-content,
.k-grid,
.k-header-column-menu,
.k-panelbar,
.k-slider,
.k-splitter,
.k-treeview,
.k-widget {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-block,
.k-calendar,
.k-grid-header .k-link,
.k-header-column-menu,
.k-pager-wrap,
.k-slider,
.k-splitbar,
.k-treeview {
  -webkit-touch-callout: none;
}
.k-list-scroller {
  position: relative;
  overflow: auto;
}
.k-popup.k-calendar-container,
.k-popup.k-list-container {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 2px;
  border-width: 1px;
  border-style: solid;
}
.k-autocomplete.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-list-container.k-state-border-down,
.k-numeric-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down {
  border-bottom-width: 0;
  padding-bottom: 1px;
}
.k-list-container .km-scroll-container {
  padding-bottom: 6px;
}
.k-autocomplete,
.k-dropdown-wrap,
.k-numeric-wrap,
.k-picker-wrap,
.k-textbox {
  border-width: 1px;
  border-style: solid;
  padding: 0 1.9em 0 0;
}
.k-numeric-wrap.k-expand-padding {
  padding-right: 0;
}
.k-autocomplete,
.k-textbox {
  padding: 0;
}
.k-textbox.k-space-left {
  padding-left: 1.9em;
}
.k-textbox.k-space-right {
  padding-right: 1.9em;
}
.k-textbox .k-icon {
  top: 50%;
  margin: -8px 0 0;
  position: absolute;
}
.k-space-left .k-icon {
  left: 3px;
}
.k-space-right .k-icon {
  right: 3px;
}
span.k-textbox:after {
  content: "\a0";
  display: block;
  height: 0.4px;
  overflow: hidden;
}
.k-autocomplete,
.k-dropdown-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-picker-wrap.k-state-hover {
  -webkit-transition: -webkit-box-shadow 0.15s ease-out;
  transition: -webkit-box-shadow 0.15s ease-out;
  transition: box-shadow 0.15s ease-out;
  transition: box-shadow 0.15s ease-out, -webkit-box-shadow 0.15s ease-out;
  -webkit-transition: "box-shadow .15s ease-out";
  transition: "box-shadow .15s ease-out";
}
.k-combobox .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input,
.k-textbox > input {
  width: 100%;
  vertical-align: top;
}
.k-dropdown-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input,
.k-selectbox .k-input {
  font-family: inherit;
  border-width: 0;
  outline: 0;
}
.k-dropdown .k-input,
.k-selectbox .k-input {
  background: 0 0;
}
.k-dropdown-wrap .k-select,
.k-numeric-wrap .k-select,
.k-picker-wrap .k-select {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
}
.k-combobox .k-select,
.k-numeric-wrap .k-select,
.k-picker-wrap .k-select {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: inherit;
}
span.k-datetimepicker .k-select,
span.k-datetimepicker .k-select + .k-select {
  right: 0;
}
.k-autocomplete .k-input,
.k-textbox > input {
  display: block;
}
.k-dropdown .k-select,
.k-selectbox .k-select {
  overflow: hidden;
  border: 0;
  text-decoration: none;
  font: inherit;
  color: inherit;
}
.k-dropdown .k-input,
.k-selectbox .k-input {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input,
.k-selectbox .k-input,
.k-textbox > input {
  height: 1.65em;
  line-height: 1.65em;
  padding: 0.177em 0;
  text-indent: 0.33em;
  border: 0;
  margin: 0;
}
.k-combobox .k-dropdown-wrap:before,
.k-numeric-wrap:before,
.k-picker-wrap:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  height: 1.65em;
  padding-bottom: 0.3em;
}
.k-combobox-clearable .k-input {
  padding-right: 1.9em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.004em;
}
.k-webkit .k-combobox .k-dropdown-wrap:before,
.k-webkit .k-numeric-wrap:before,
.k-webkit .k-picker-wrap:before {
  padding-bottom: 0.38em;
}
.km.root .k-combobox .k-dropdown-wrap:before,
.km.root .k-numeric-wrap:before,
.km.root .k-picker-wrap:before {
  content: none;
}
.k-combobox .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input {
  display: inline;
}
.k-dropdown-wrap .k-select,
.k-numeric-wrap .k-select,
.k-picker-wrap .k-select {
  min-height: 1.65em;
  line-height: 2em;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  text-align: center;
  width: 1.9em;
  height: 100%;
}
.k-numeric-wrap .k-select {
  padding: 0;
}
body .k-datetimepicker .k-select {
  border-radius: 0;
}
.k-combobox .k-select,
.k-dropdown,
.k-selectbox .k-icon {
  cursor: pointer;
}
.k-popup {
  border-style: solid;
  border-width: 1px;
}
.k-list-optionlabel,
.k-popup .k-item {
  cursor: default;
}
.k-popup .k-calendar {
  border: 0;
}
.k-list {
  height: auto;
}
.k-nodata {
  min-height: 138px;
  width: 100%;
  display: table;
  text-transform: uppercase;
  font-size: 0.85em;
  font-weight: lighter;
}
.k-nodata > div {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 11px;
}
.k-fieldselector .k-list .k-item,
.k-list-optionlabel,
.k-popup .k-list .k-item,
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  padding: 1px 5px 1px 5px;
  line-height: 1.8em;
  min-height: 1.8em;
}
.k-popup .k-list .k-item {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding: 0 4px;
}
.k-popup .k-list .k-item > .k-group {
  top: -1px;
}
.k-group-header + div > .k-list > .k-item.k-first:before {
  content: " ";
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  padding-right: 22px;
}
.k-overflow-container .k-item {
  padding: 1px;
}
.k-overflow-container .k-button.k-state-disabled,
.k-overflow-container .k-button.k-state-disabled:hover,
.k-overflow-container > .k-state-disabled .k-button {
  border: 0;
  background: 0 0;
}
.k-fieldselector .k-list .k-item,
.k-list-optionlabel.k-state-focused,
.k-list-optionlabel.k-state-selected,
.k-overflow-container .k-state-focused,
.k-overflow-container .k-state-hover,
.k-overflow-container .k-state-selected,
.k-popup .k-list .k-state-focused,
.k-popup .k-list .k-state-hover,
.k-popup .k-list .k-state-selected {
  padding: 0 4px;
  border-width: 1px;
  border-style: solid;
}
.k-list-filter {
  position: relative;
  margin-bottom: 2px;
}
.k-list-filter > .k-textbox {
  padding-right: 20px;
  width: 100%;
}
.k-list-filter > .k-icon {
  position: absolute;
  right: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.km-root .k-list-filter > .k-textbox {
  padding-left: 0;
  padding-right: 0;
  border-left-width: 0;
  border-right-width: 0;
}
.k-multiselect-wrap {
  position: relative;
  border-width: 0;
  border-style: solid;
  border-radius: 4px;
  border-color: #C5C5C5;
  background-color: #FFF;
  min-height: 2.04em;
  padding-right: 22px;
}
.k-multiselect-wrap .k-input {
  background-color: transparent;
  height: 1.31em;
  line-height: 1.31em;
  padding: 0.18em 0;
  text-indent: 0.33em;
  border: 0;
  margin: 1px 0 0;
  float: left;
}
.k-multiselect-wrap .k-input::-ms-clear {
  display: none;
}
.k-multiselect-wrap li {
  margin: 1px 0 1px 1px;
  padding: 0.1em 1.6em 0.1em 0.4em;
  line-height: 1.5em;
  float: left;
  position: relative;
}
.k-autocomplete .k-i-loading,
.k-autocomplete .k-loading,
.k-multiselect .k-i-loading,
.k-multiselect .k-loading {
  position: absolute;
  right: 3px;
  bottom: 4px;
}
.k-multiselect .k-loading-hidden {
  visibility: hidden;
}
.k-multiselect-wrap .k-select {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0.1em 0.2em;
}
.k-datetimepicker .k-picker-wrap {
  padding-right: 3.8em;
}
.k-datetimepicker .k-select {
  width: 3.8em;
}
.k-datetimepicker .k-picker-wrap .k-icon {
  margin: 0 2px;
}
.k-picker-wrap .k-icon {
  cursor: pointer;
}
.k-button,
.k-datepicker,
.k-datetimepicker,
.k-textbox,
.k-timepicker {
  display: inline-block;
  vertical-align: middle;
}
.k-picker-wrap .k-input {
  margin: 0;
}
.k-time-popup .k-item {
  padding: 1px 3px;
}
.k-input {
  padding: 0.25em 0;
}
.k-input,
.k-textbox > input {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-textbox {
  outline: 0;
}
input.k-textbox,
textarea.k-textbox {
  padding: 2px 0.3em;
}
input.k-textbox {
  height: 2.13em;
  text-indent: 0.33em;
  line-height: 1.6em;
}
.k-ie input.k-textbox {
  text-indent: 0.165em;
}
.k-ff input.k-textbox {
  height: 2.17em;
}
textarea.k-textbox {
  height: auto;
}
span.k-numerictextbox {
  background-color: transparent;
}
.k-numerictextbox .k-input {
  margin: 0;
}
.k-numerictextbox .k-link {
  display: block;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
  border-width: 0;
  padding: 0;
}
.k-numerictextbox .k-icon {
  height: 11px;
}
.k-numeric-wrap .k-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.k-colorpicker .k-picker-wrap {
  line-height: 2em;
}
.k-colorpicker .k-selected-color {
  vertical-align: top;
  line-height: 0;
  display: inline-block;
  height: 2em;
  width: 2em;
}
.k-colorpicker .k-tool-icon {
  position: relative;
  top: -2px;
  display: inline-block;
  padding: 3px 3px 2px;
  font-size: 0;
  line-height: 0;
  margin-right: 3px;
  margin-left: 2px;
  margin-bottom: 3px;
  background-repeat: no-repeat;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  -ms-high-contrast-adjust: none;
}
.k-colorpicker .k-tool-icon .k-selected-color {
  display: block;
  height: 3px;
  width: 16px;
  position: absolute;
  left: 3px;
  bottom: -3px;
  border-radius: 0 !important;
}
.k-colorpicker .k-select {
  cursor: pointer;
}
.k-disabled-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.k-colorpalette {
  position: relative;
  line-height: 0;
  border-width: 0;
  display: inline-block;
}
.k-colorpalette .k-palette {
  border-collapse: collapse;
  position: relative;
  width: 100%;
  height: 100%;
}
.k-colorpalette .k-item {
  width: 14px;
  height: 14px;
  overflow: hidden;
  -ms-high-contrast-adjust: none;
}
.k-colorpalette .k-item.k-state-selected,
.k-colorpalette .k-item.k-state-selected:hover {
  z-index: 100;
  background: 0 0;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.7), inset 0 0 0 1px rgba(255, 255, 255, 0.45);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.7), inset 0 0 0 1px rgba(255, 255, 255, 0.45);
  position: relative;
}
.k-colorpalette .k-item:hover {
  z-index: 101;
  position: relative;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.3);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.3);
}
.k-flatcolorpicker {
  position: relative;
  display: inline-block;
  width: 250px;
  padding-bottom: 5px;
}
div.k-flatcolorpicker {
  background-color: transparent;
  background-image: none;
}
.k-flatcolorpicker .k-selected-color {
  background-image: url(textures/transtexture.png);
  background-position: 50% 50%;
  text-align: right;
}
.k-flatcolorpicker .k-selected-color input.k-color-value {
  font-family: Consolas, "Ubuntu Mono", "Lucida Console", "Courier New", monospace;
  padding: 0.75em 0.3em 0.65em 1em;
  border: 0;
  margin: 0;
  width: 70%;
}
.k-flatcolorpicker .k-hsv-rectangle {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pinch-zoom double-tap-zoom;
}
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border: 1px solid #eee;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 2px #444;
  box-shadow: 0 1px 2px #444;
  background: 0 0;
}
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle:focus,
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle:hover {
  background: 0 0;
  border-color: #fff;
  -webkit-box-shadow: 0 1px 5px #000;
  box-shadow: 0 1px 5px #000;
}
.k-flatcolorpicker .k-hsv-rectangle.k-dragging,
.k-flatcolorpicker .k-hsv-rectangle.k-dragging * {
  cursor: none;
}
.k-flatcolorpicker .k-slider-horizontal {
  height: 20px;
  width: 90%;
  margin: 0 5%;
}
.k-flatcolorpicker .k-slider-horizontal .k-slider-track {
  -webkit-box-shadow: 0 1px 0 #fff, 0 -1px 0 #999;
  box-shadow: 0 1px 0 #fff, 0 -1px 0 #999;
}
.k-flatcolorpicker .k-hue-slider,
.k-flatcolorpicker .k-transparency-slider {
  display: block;
}
.k-flatcolorpicker .k-hue-slider .k-slider-selection,
.k-flatcolorpicker .k-transparency-slider .k-slider-selection {
  background: 0 0;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle,
.k-flatcolorpicker .k-transparency-slider .k-draghandle {
  background: 0 0;
  border: 3px solid #eee;
  margin-top: 1px;
  height: 8px;
  width: 8px;
  -webkit-box-shadow: 0 1px 4px #444;
  box-shadow: 0 1px 4px #444;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle:focus,
.k-flatcolorpicker .k-hue-slider .k-draghandle:hover,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:focus,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:hover {
  background: 0 0;
  border-color: #fff;
  -webkit-box-shadow: 0 1px 5px #000;
  box-shadow: 0 1px 5px #000;
  border-width: 2px;
  padding: 1px;
}
.k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: -webkit-gradient(linear, left top, right top, from(red), color-stop(16%, #ff0), color-stop(33%, #0f0), color-stop(50%, #0ff), color-stop(67%, #00f), color-stop(84%, #f0f), to(#ff0004));
  background: -webkit-linear-gradient(left, red 0, #ff0 16%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 84%, #ff0004 100%);
  background: linear-gradient(to right, red 0, #ff0 16%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 84%, #ff0004 100%);
}
.k-flatcolorpicker .k-transparency-slider .k-slider-track {
  background-image: url(textures/transparency.png);
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.k-flatcolorpicker .k-controls {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 90%;
}
.k-flatcolorpicker .k-controls .k-button {
  width: 6em;
}
.k-flatcolorpicker .k-hsv-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)), -webkit-gradient(linear, left top, right top, from(#fff), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, #000 100%), -webkit-linear-gradient(left, #fff 0, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%), linear-gradient(to right, #fff 0, rgba(255, 255, 255, 0) 100%);
  height: 180px;
  margin-bottom: 5px;
}
.k-ie9 .k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjE2JSIgc3RvcC1jb2xvcj0iI2ZmZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMzJSIgc3RvcC1jb2xvcj0iIzAwZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY3JSIgc3RvcC1jb2xvcj0iIzAwMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg0JSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjAwMDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
}
.k-ie9 .k-flatcolorpicker .k-hsv-gradient {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+), url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
}
.k-ie8 .k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: url(textures/hue.png) repeat 0 50%;
}
.k-ie8 .k-flatcolorpicker .k-transparency-slider .k-slider-track {
  background: url(textures/transparency.png) repeat 0 50%;
}
.k-ie8 .k-flatcolorpicker .k-hsv-gradient {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#00ffffff', GradientType=1) progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#ff000000', GradientType=0);
}
table.k-editor {
  width: 100%;
  height: 250px;
  table-layout: fixed;
  border-style: solid;
  border-width: 1px;
  border-collapse: separate;
  border-spacing: 4px;
  font-size: 100%;
  vertical-align: top;
  position: relative;
}
.k-editor-inline {
  border-width: 2px;
  padding: 0.3em 0.5em;
  word-wrap: break-word;
  overflow: auto;
  position: relative;
}
.k-editortoolbar-dragHandle {
  cursor: move;
  padding-left: 0;
  padding-right: 3px;
  -webkit-box-shadow: none!important;
  box-shadow: none !important;
}
.k-editor-widget > .k-window-content {
  overflow: hidden;
}
.k-editor .k-editor-toolbar-wrap {
  border: 0;
  padding: 0;
}
.k-editor-toolbar {
  margin: 0;
  padding: 0.1em 0;
  list-style-type: none;
  line-height: 1.3em;
  cursor: default;
  word-wrap: break-word;
}
.k-editor-toolbar li {
  display: inline-block;
  vertical-align: middle;
}
.k-edge .k-editor-toolbar,
.k-ff .k-editor-toolbar,
.k-ie9 .k-editor-toolbar,
.k-webkit .k-editor-toolbar {
  padding: 0;
}
.k-edge .k-editor-toolbar li,
.k-ff .k-editor-toolbar li,
.k-ie10 .k-editor-toolbar li,
.k-ie9 .k-editor-toolbar li,
.k-safari .k-editor-toolbar li,
.k-webkit .k-editor-toolbar li {
  display: inline-block;
  padding: 0.1em 0;
}
.k-editor-toolbar .k-editor-widget,
.k-editor-toolbar > li {
  margin-right: 6px;
}
.k-group-start.k-group-end .k-editor-widget {
  margin-right: 0;
}
.k-editor-toolbar .k-editor-dropdown {
  position: relative;
}
.k-select-overlay {
  -webkit-appearance: none;
  opacity: 0;
  z-index: 11000;
  top: 0;
  left: 0;
  position: absolute;
  height: 26px;
  width: 100%;
  margin: -4px 0 0;
}
.k-editor-toolbar .k-separator {
  position: relative;
  top: 1px;
  border-style: solid;
  border-width: 0 1px 0 0;
  margin: 0 0.3em 0 0.1em;
  padding: 0 0 0 1px;
  font-size: 1.3em;
}
.k-editor-toolbar .k-break {
  display: block;
  height: 1px;
  font-size: 0;
  line-height: 0;
}
.k-editor-toolbar .k-colorpicker,
.k-editor-toolbar .k-combobox,
.k-editor-toolbar .k-dropdown,
.k-editor-toolbar .k-selectbox {
  vertical-align: middle;
}
.k-button-group {
  white-space: nowrap;
}
.k-button-group .k-tool {
  display: inline-block;
  vertical-align: middle;
  margin: 1px 0;
  width: 2em;
  height: 2em;
  line-height: 2em;
}
.k-button-group .k-tool-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  -ms-high-contrast-adjust: none;
}
.k-i-move {
  background-position: -160px -288px;
}
.k-bold,
.k-i-bold {
  background-position: -240px 0;
}
.k-state-hover .k-bold,
.k-state-hover .k-i-bold,
.k-state-selected .k-bold,
.k-state-selected .k-i-bold {
  background-position: -264px 0;
}
.k-i-italic,
.k-italic {
  background-position: -240px -24px;
}
.k-state-hover .k-i-italic,
.k-state-hover .k-italic,
.k-state-selected .k-i-italic,
.k-state-selected .k-italic {
  background-position: -264px -24px;
}
.k-i-underline,
.k-underline {
  background-position: -240px -48px;
}
.k-state-hover .k-i-underline,
.k-state-hover .k-underline,
.k-state-selected .k-i-underline,
.k-state-selected .k-underline {
  background-position: -264px -48px;
}
.k-i-strikethrough,
.k-strikethrough {
  background-position: -240px -72px;
}
.k-state-hover .k-i-strikethrough,
.k-state-hover .k-strikethrough,
.k-state-selected .k-i-strikethrough,
.k-state-selected .k-strikethrough {
  background-position: -264px -72px;
}
.k-foreColor {
  background-position: -240px -96px;
}
.k-state-hover .k-foreColor,
.k-state-selected .k-foreColor {
  background-position: -264px -96px;
}
.k-backColor {
  background-position: -240px -120px;
}
.k-state-hover .k-backColor,
.k-state-selected .k-backColor {
  background-position: -264px -120px;
}
.k-colorpicker .k-foreColor {
  background-position: -240px -96px;
}
.k-colorpicker .k-backColor {
  background-position: -240px -120px;
}
.k-i-align-left,
.k-i-justify-left,
.k-justifyLeft {
  background-position: -240px -144px;
}
.k-state-hover .k-i-align-left,
.k-state-hover .k-i-justify-left,
.k-state-hover .k-justifyLeft,
.k-state-selected .k-i-align-left,
.k-state-selected .k-i-justify-left,
.k-state-selected .k-justifyLeft {
  background-position: -264px -144px;
}
.k-i-align-center,
.k-i-justify-center,
.k-justifyCenter {
  background-position: -240px -168px;
}
.k-state-hover .k-i-align-center,
.k-state-hover .k-i-justify-center,
.k-state-hover .k-justifyCenter,
.k-state-selected .k-i-align-center,
.k-state-selected .k-i-justify-center,
.k-state-selected .k-justifyCenter {
  background-position: -264px -168px;
}
.k-i-align-right,
.k-i-justify-right,
.k-justifyRight {
  background-position: -240px -192px;
}
.k-state-hover .k-i-align-right,
.k-state-hover .k-i-justify-right,
.k-state-hover .k-justifyRight,
.k-state-selected .k-i-align-right,
.k-state-selected .k-i-justify-right,
.k-state-selected .k-justifyRight {
  background-position: -264px -192px;
}
.k-i-align-justify,
.k-i-justify-full,
.k-justifyFull {
  background-position: -240px -216px;
}
.k-state-hover .k-i-align-justify,
.k-state-hover .k-i-justify-full,
.k-state-hover .k-justifyFull,
.k-state-selected .k-i-align-justify,
.k-state-selected .k-i-justify-full,
.k-state-selected .k-justifyFull {
  background-position: -264px -216px;
}
.k-i-insert-unordered-list,
.k-i-unordered-list,
.k-insertUnorderedList {
  background-position: -240px -264px;
}
.k-state-hover .k-i-insert-unordered-list,
.k-state-hover .k-i-unordered-list,
.k-state-hover .k-insertUnorderedList,
.k-state-selected .k-i-insert-unordered-list,
.k-state-selected .k-i-unordered-list,
.k-state-selected .k-insertUnorderedList {
  background-position: -264px -264px;
}
.k-i-insert-ordered-list,
.k-i-ordered-list,
.k-insertOrderedList {
  background-position: -240px -288px;
}
.k-state-hover .k-i-insert-ordered-list,
.k-state-hover .k-i-ordered-list,
.k-state-hover .k-insertOrderedList,
.k-state-selected .k-i-insert-ordered-list,
.k-state-selected .k-i-ordered-list,
.k-state-selected .k-insertOrderedList {
  background-position: -264px -288px;
}
.k-i-indent,
.k-indent,
.k-rtl .k-i-outdent,
.k-rtl .k-outdent {
  background-position: -288px 0;
}
.k-state-hover .k-i-indent,
.k-state-hover .k-indent,
.k-state-hover .k-rtl .k-i-outdent,
.k-state-hover .k-rtl .k-outdent,
.k-state-selected .k-i-indent,
.k-state-selected .k-indent,
.k-state-selected .k-rtl .k-i-outdent,
.k-state-selected .k-rtl .k-outdent {
  background-position: -312px 0;
}
.k-i-outdent,
.k-outdent,
.k-rtl .k-i-indent,
.k-rtl .k-indent {
  background-position: -288px -24px;
}
.k-state-hover .k-i-outdent,
.k-state-hover .k-outdent,
.k-state-hover .k-rtl .k-i-indent,
.k-state-hover .k-rtl .k-indent,
.k-state-selected .k-i-outdent,
.k-state-selected .k-outdent,
.k-state-selected .k-rtl .k-i-indent,
.k-state-selected .k-rtl .k-indent {
  background-position: -312px -24px;
}
.k-createLink,
.k-i-create-link {
  background-position: -288px -48px;
}
.k-state-hover .k-createLink,
.k-state-hover .k-i-create-link,
.k-state-selected .k-createLink,
.k-state-selected .k-i-create-link {
  background-position: -312px -48px;
}
.k-unlink {
  background-position: -288px -72px;
}
.k-state-hover .k-unlink,
.k-state-selected .k-unlink {
  background-position: -312px -72px;
}
.k-i-insert-image,
.k-insertImage {
  background-position: -288px -96px;
}
.k-state-hover .k-i-insert-image,
.k-state-hover .k-insertImage,
.k-state-selected .k-i-insert-image,
.k-state-selected .k-insertImage {
  background-position: -312px -96px;
}
.k-i-insert-file,
.k-insertFile {
  background-position: -288px -216px;
}
.k-state-hover .k-i-insert-file,
.k-state-hover .k-insertFile,
.k-state-selected .k-i-insert-file,
.k-state-selected .k-insertFile {
  background-position: -312px -216px;
}
.k-i-subscript,
.k-subscript {
  background-position: -288px -144px;
}
.k-state-hover .k-i-subscript,
.k-state-hover .k-subscript,
.k-state-selected .k-i-subscript,
.k-state-selected .k-subscript {
  background-position: -312px -144px;
}
.k-i-superscript,
.k-superscript {
  background-position: -288px -168px;
}
.k-state-hover .k-i-superscript,
.k-state-hover .k-superscript,
.k-state-selected .k-i-superscript,
.k-state-selected .k-superscript {
  background-position: -312px -168px;
}
.k-cleanFormatting,
.k-i-clean-formatting {
  background-position: -288px -192px;
}
.k-state-hover .k-cleanFormatting,
.k-state-hover .k-i-clean-formatting,
.k-state-selected .k-cleanFormatting,
.k-state-selected .k-i-clean-formatting {
  background-position: -312px -192px;
}
.k-createTable,
.k-i-create-table {
  background-position: -192px 0;
}
.k-state-hover .k-createTable,
.k-state-hover .k-i-create-table,
.k-state-selected .k-createTable,
.k-state-selected .k-i-create-table {
  background-position: -216px 0;
}
.k-addColumnLeft,
.k-i-add-column-left {
  background-position: -192px -24px;
}
.k-state-hover .k-addColumnLeft,
.k-state-hover .k-i-add-column-left,
.k-state-selected .k-addColumnLeft,
.k-state-selected .k-i-add-column-left {
  background-position: -216px -24px;
}
.k-addColumnRight,
.k-i-add-column-right {
  background-position: -192px -48px;
}
.k-state-hover .k-addColumnRight,
.k-state-hover .k-i-add-column-right,
.k-state-selected .k-addColumnRight,
.k-state-selected .k-i-add-column-right {
  background-position: -216px -48px;
}
.k-addRowAbove,
.k-i-add-row-above {
  background-position: -192px -72px;
}
.k-state-hover .k-addRowAbove,
.k-state-hover .k-i-add-row-above,
.k-state-selected .k-addRowAbove,
.k-state-selected .k-i-add-row-above {
  background-position: -216px -72px;
}
.k-addRowBelow,
.k-i-add-row-below {
  background-position: -192px -96px;
}
.k-state-hover .k-addRowBelow,
.k-state-hover .k-i-add-row-below,
.k-state-selected .k-addRowBelow,
.k-state-selected .k-i-add-row-below {
  background-position: -216px -96px;
}
.k-deleteRow,
.k-i-delete-row {
  background-position: -192px -120px;
}
.k-state-hover .k-deleteRow,
.k-state-hover .k-i-delete-row,
.k-state-selected .k-deleteRow,
.k-state-selected .k-i-delete-row {
  background-position: -216px -120px;
}
.k-deleteColumn,
.k-i-delete-column {
  background-position: -192px -144px;
}
.k-state-hover .k-deleteColumn,
.k-state-hover .k-i-delete-column,
.k-state-selected .k-deleteColumn,
.k-state-selected .k-i-delete-column {
  background-position: -216px -144px;
}
.k-i-merge-cells,
.k-mergeCells {
  background-position: -192px -168px;
}
.k-state-hover .k-i-merge-cells,
.k-state-hover .k-mergeCells,
.k-state-selected .k-i-merge-cells,
.k-state-selected .k-mergeCells {
  background-position: -216px -168px;
}
.k-pdf,
.k-tool .k-i-pdf,
.k-tool-group .k-tool .k-i-pdf,
.k-tool-icon .k-i-pdf {
  background-position: -288px -240px;
}
.k-state-hover .k-pdf,
.k-state-hover .k-tool .k-i-pdf,
.k-state-hover .k-tool-group .k-tool .k-i-pdf,
.k-state-hover .k-tool-icon .k-i-pdf,
.k-state-selected .k-pdf,
.k-state-selected .k-tool .k-i-pdf,
.k-state-selected .k-tool-group .k-tool .k-i-pdf,
.k-state-selected .k-tool-icon .k-i-pdf {
  background-position: -312px -240px;
}
.k-i-print,
.k-print {
  background-position: -288px -264px;
}
.k-state-hover .k-i-print,
.k-state-hover .k-print,
.k-state-selected .k-i-print,
.k-state-selected .k-print {
  background-position: -312px -264px;
}
.k-i-table-wizard,
.k-i-table-wizard-insert {
  background-position: -192px -192px;
}
.k-state-hover .k-i-table-wizard,
.k-state-hover .k-i-table-wizard-insert,
.k-state-selected .k-i-table-wizard,
.k-state-selected .k-i-table-wizard-insert {
  background-position: -216px -192px;
}
.k-fontName {
  width: 110px;
}
.k-fontSize {
  width: 124px;
}
.k-formatBlock {
  width: 147px;
}
.k-editortoolbar-dragHandle {
  float: left;
  margin: 1px 0 0;
}
.k-editor-toolbar .k-button-group {
  padding: 1px;
}
.k-editor .k-editor-toolbar .k-row-break {
  display: block;
  height: 0;
  font-size: 0;
  line-height: 0;
}
.k-button-group .k-tool {
  border-style: solid;
  border-width: 1px;
  margin-right: -1px;
}
.k-button-group .k-tool.k-state-hover,
.k-button-group .k-tool:focus {
  position: relative;
  z-index: 1;
}
.k-rtl .k-button-group .k-tool {
  border-style: solid;
  border-width: 1px;
}
.k-button-group .k-tool.k-group-end {
  border-right-width: 1px;
}
.k-rtl .k-button-group .k-tool.k-group-end {
  border-left-width: 1px;
}
.k-button-group .k-state-disabled {
  display: none;
}
.k-button-group .k-state-active,
.k-button-group .k-state-hover {
  vertical-align: middle;
}
.k-button-group .k-state-disabled {
  filter: alpha(opacity=30);
  opacity: 0.3;
}
.k-editor .k-editable-area {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 1px;
  outline: 0;
}
.k-editor .k-content {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  background: #fff;
}
.k-editor .k-tool {
  outline: 0;
}
.k-editor iframe.k-content {
  display: inline;
  vertical-align: top;
}
.k-editor .k-raw-content {
  border: 0;
  margin: 0;
  padding: 0;
}
.k-editor .k-raw-content,
.k-editor-dialog .k-editor-textarea {
  font-size: inherit;
  font-family: consolas, "courier new", monospace;
}
.k-editor-dialog {
  padding: 1em;
  width: 400px;
}
.k-editor-dialog .k-edit-label {
  width: 25%;
}
.k-editor-dialog .k-edit-field {
  width: 66%;
}
.k-editor-dialog .k-edit-field .k-textbox {
  width: 96%;
}
.k-viewhtml-dialog {
  width: auto;
}
.k-filebrowser-dialog {
  width: auto;
  min-width: 350px;
}
.k-filebrowser-dialog .k-filebrowser {
  margin: 0 1em 0;
}
.k-filebrowser-dialog .k-edit-label {
  width: 18%;
}
.k-filebrowser-dialog .k-edit-field {
  width: 75%;
}
.k-filebrowser-dialog .k-edit-field .k-textbox {
  width: 70%;
}
#k-editor-image-height,
#k-editor-image-width {
  width: 5em;
}
.k-editor-dialog .k-button {
  display: inline-block;
}
.k-editor-dialog .k-editor-textarea {
  width: 600px;
  height: 350px;
  padding: 0.2em 0.2em 0.2em 0.4em;
  border-width: 1px;
  border-style: solid;
  overflow: auto;
}
.k-button-wrapper .k-link:hover {
  text-decoration: underline;
}
.k-ct-popup {
  width: 180.4px;
  padding: 0.65em 0.5em 0.5em;
}
.k-ct-popup .k-status {
  margin: 0.3em 0;
}
.k-ct-cell {
  border-width: 1px;
  border-style: solid;
  width: 18px;
  height: 18px;
  margin: 1px;
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
  -ms-high-contrast-adjust: none;
}
.k-editor .k-resize-handle {
  position: absolute;
  padding: 5px;
  right: 0;
  bottom: 0;
}
.k-editor .k-overlay {
  position: absolute;
  background-color: #fff;
  opacity: 0;
}
.k-toolbar-resizable {
  min-height: 2.4375em;
  position: relative;
}
.k-overflow-tools {
  position: absolute;
  right: 0;
  top: 0;
}
.k-editor-overflow-popup .k-tool {
  width: auto;
  height: auto;
  display: block;
  text-align: left;
  text-decoration: initial;
  border: 0;
  padding-right: 0.5em;
  margin: 0;
  padding: 0 6px;
}
.k-editor-overflow-popup .k-tool.k-state-disabled {
  display: none;
}
.k-editor-toolbar .k-tool-text {
  display: none;
}
.k-editor-inline .k-table-resize-handle-wrapper {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 100;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle {
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-east {
  cursor: e-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-north {
  cursor: n-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-northeast {
  cursor: ne-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-northwest {
  cursor: nw-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-south {
  cursor: s-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-southeast {
  cursor: se-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-southwest {
  cursor: sw-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-west {
  cursor: w-resize;
}
.k-editor-inline .k-column-resize-handle-wrapper {
  position: absolute;
  height: 10px;
  width: 10px;
  cursor: col-resize;
  z-index: 2;
}
.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle {
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle .k-column-resize-marker {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #00b0ff;
  display: none;
  opacity: 0.8;
}
.k-table.k-table-resizing {
  opacity: 0.6;
}
.k-editor-inline .k-row-resize-handle-wrapper {
  position: absolute;
  z-index: 2;
  cursor: row-resize;
  width: 10px;
  height: 10px;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-handle {
  display: table;
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker {
  display: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 2px;
  background-color: #00b0ff;
  opacity: 0.8;
}
.k-editor-dialog.k-editor-table-wizard-dialog.k-window-content {
  overflow: hidden;
  min-width: 325px;
  width: 24em;
  padding: 1em 0;
}
.k-editor-dialog .k-tabstrip {
  margin: -1.333em 0 0;
}
.k-editor-dialog .k-root-tabs {
  margin: -1em -1em -1.5em;
  padding: 0.5em 1em 0;
  border-width: 0;
}
.k-editor-dialog ul.k-tabstrip-items {
  padding: 0.5em 1em 0;
  border-width: 0 0 1px;
  border-style: solid;
}
ul.k-tabstrip-items .k-loading {
  border-top-width: 0;
}
.k-editor-dialog .k-tabstrip:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-tabstrip-wrapper .k-content {
  overflow: hidden;
  min-height: 440px;
  height: 35em;
  margin: 0;
  padding: 1em 0.5em 0;
  border-width: 0;
}
.k-editor-dialog .k-edit-field .k-numerictextbox {
  width: 10.6em;
  margin: 0 0.35714285em 0 0;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-numerictextbox + .k-dropdown {
  width: 4em;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-dropdown,
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-textbox {
  width: 15em;
}
.k-editor-table-wizard-dialog .k-numerictextbox + .k-dropdown {
  width: 5.35714285em;
}
.k-editor-dialog textarea.k-textbox {
  height: 14.28571428em;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-dropdown.k-align {
  width: 4em;
}
.k-popup.k-list-container.k-align {
  padding: 0;
  text-align: center;
}
.k-align-group {
  vertical-align: text-top;
}
.k-popup.k-align .k-list .k-item {
  display: inline-block;
  width: 2.57142857em;
  height: 2.57142857em;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
}
.k-popup.k-align .k-list .k-font-icon {
  vertical-align: bottom;
  vertical-align: -webkit-baseline-middle;
}
.k-popup.k-align .k-list .k-item:last-child {
  width: auto;
  display: block;
  border-width: 1px 0 0;
  border-style: solid;
}
.k-popup.k-align .k-list .k-item:before {
  width: 2.57142857em;
  height: 2.57142857em;
  line-height: 2.57142857em;
}
.k-animation-container .k-editor-toolbar {
  text-align: center;
  line-height: 1.3em;
}
.k-animation-container .k-editor-toolbar .k-tool {
  display: inline-block;
  height: 24px;
  padding: 0 0.5em;
  text-decoration: none;
  font-size: 0.85em;
  color: inherit;
}
.k-animation-container .k-editor-toolbar .k-i-table-wizard-insert {
  width: 24px;
  height: 24px;
}
.k-animation-container .k-editor-toolbar .k-tool-text {
  display: inline;
  vertical-align: sub;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-buttons {
  padding: 1em 0.8em;
  margin: 0.5em 0 0;
}
.k-notification-wrap {
  padding: 0.6em 0.5em;
  cursor: default;
  position: relative;
  white-space: nowrap;
}
.k-notification-button .k-notification-wrap {
  padding-right: 20px;
}
.k-notification-wrap > .k-i-error,
.k-notification-wrap > .k-i-info,
.k-notification-wrap > .k-i-note,
.k-notification-wrap > .k-i-success,
.k-notification-wrap > .k-i-warning {
  vertical-align: text-bottom;
  margin-right: 4px;
}
.k-notification-wrap > .k-i-close {
  position: absolute;
  top: 7px;
  right: 4px;
  display: none;
}
.k-notification-button .k-notification-wrap > .k-i-close {
  display: block;
}
.k-progressbar {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.k-progressbar {
  border-radius: 4px;
}
.k-progressbar-horizontal {
  width: 27em;
  height: 1.9em;
}
.k-progressbar-vertical {
  width: 1.9em;
  height: 27em;
}
.k-progressbar > .k-state-selected {
  position: absolute;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
}
.k-progressbar-horizontal > .k-state-selected,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected {
  left: -1px;
  right: auto;
  top: -1px;
  height: 100%;
  border-radius: 4px 0 0 4px;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected,
.k-rtl .k-progressbar-horizontal > .k-state-selected {
  left: auto;
  right: -1px;
  border-radius: 0 4px 4px 0;
}
.k-progressbar-vertical > .k-state-selected {
  left: -1px;
  bottom: -1px;
  width: 100%;
  border-radius: 0 0 4px 4px;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-state-selected {
  bottom: auto;
  top: -1px;
  border-radius: 4px 4px 0 0;
}
.k-progressbar > .k-state-selected.k-complete,
.k-rtl .k-progressbar > .k-state-selected.k-complete {
  border-radius: 4px;
}
.k-progressbar > .k-reset {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  white-space: nowrap;
}
.k-progressbar-horizontal .k-item {
  display: inline-block;
  height: 100%;
  border-style: solid;
  margin-left: -1px;
}
.k-progressbar-horizontal .k-item.k-first {
  margin-left: 0;
}
.k-progressbar-horizontal .k-item.k-last {
  border-right-width: 0;
}
.k-progressbar-horizontal .k-item,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-item {
  border-width: 1px 0 1px 1px;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-item,
.k-rtl .k-progressbar-horizontal .k-item {
  border-width: 1px 0 1px 1px;
}
.k-progressbar-horizontal .k-first,
.k-rtl .k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-last {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}
.k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal .k-first {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-last,
.k-rtl .k-progressbar-horizontal .k-first {
  border-right-width: 1px;
}
.k-progressbar-horizontal .k-last.k-state-selected {
  border-right-width: 1px;
}
.k-progressbar-vertical .k-item {
  width: 100%;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  margin-top: -1px;
}
.k-progressbar-vertical .k-item.k-first {
  margin-top: 0;
}
.k-progressbar-vertical li.k-item.k-last {
  border-bottom-width: 0;
}
.k-progressbar-vertical .k-first {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.k-progressbar-vertical .k-last {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-width: 1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-item {
  border-width: 0 1px 1px 1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-first {
  border-top-width: 1px;
}
.k-progress-status-wrap {
  position: absolute;
  top: -1px;
  border: 1px solid transparent;
  line-height: 2em;
  width: 100%;
  height: 100%;
}
.k-progress-status-wrap,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap {
  left: -1px;
  right: auto;
  text-align: right;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap,
.k-rtl .k-progressbar-horizontal .k-progress-status-wrap {
  left: auto;
  right: -1px;
  text-align: left;
}
.k-progressbar-vertical .k-progress-status-wrap {
  top: auto;
  bottom: -1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status-wrap {
  bottom: auto;
  top: -1px;
}
.k-progress-status {
  display: inline-block;
  padding: 0 0.5em;
  min-width: 10px;
  white-space: nowrap;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  position: absolute;
  bottom: 0;
  left: 0;
}
.k-progressbar-vertical .k-progress-status {
  -webkit-transform: rotate(-90deg) translateX(-100%);
  -ms-transform: rotate(-90deg) translateX(-100%);
  transform: rotate(-90deg) translateX(-100%);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  -webkit-transform: rotate(90deg) translateX(-100%);
  -ms-transform: rotate(90deg) translateX(-100%);
  transform: rotate(90deg) translateX(-100%);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.k-ie8 .k-progressbar-vertical .k-progress-status {
  -webkit-writing-mode: bt-lr;
  -ms-writing-mode: bt-lr;
  writing-mode: bt-lr;
  padding: 0.5em 0;
}
div.k-slider {
  position: relative;
  border-width: 0;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.k-slider-vertical {
  width: 26px;
  height: 200px;
}
.k-slider-horizontal {
  display: inline-block;
  width: 200px;
  height: 26px;
}
.k-slider-wrap {
  width: 100%;
  height: 100%;
}
.k-grid .k-slider .k-button,
.k-slider .k-button {
  position: absolute;
  top: 0;
  width: 24px;
  min-width: 0;
  height: 24px;
  margin: 0;
  padding: 0;
  outline: 0;
}
.k-slider .k-button .k-icon {
  margin-top: 3px;
  vertical-align: top;
}
.k-state-disabled .k-slider-wrap {
  filter: alpha(opacity=60);
  opacity: 0.6;
}
.k-state-disabled .k-slider-wrap .k-slider-items {
  color: #333;
}
.k-slider .k-button-decrease {
  left: 0;
}
.k-grid .k-slider-vertical .k-button-decrease,
.k-slider-vertical .k-button-decrease {
  top: auto;
  bottom: 0;
}
.k-slider .k-button-increase {
  right: 0;
}
.k-slider .k-icon,
.k-slider .k-tick,
.k-slider-track {
  cursor: pointer;
}
.k-slider-selection,
.k-slider-track {
  position: absolute;
  margin: 0;
  padding: 0;
}
.k-slider-horizontal .k-slider-selection,
.k-slider-horizontal .k-slider-track {
  top: 50%;
  left: 0;
  height: 8px;
  margin-top: -4px;
  background-repeat: repeat-x;
}
.k-slider-horizontal .k-slider-buttons .k-slider-track {
  left: 34px;
}
.k-slider-vertical .k-slider-selection,
.k-slider-vertical .k-slider-track {
  left: 50%;
  bottom: 0;
  width: 8px;
  margin-left: -4px;
  background-repeat: repeat-y;
}
.k-slider-vertical .k-slider-buttons .k-slider-track {
  bottom: 34px;
}
.k-draghandle {
  position: absolute;
  background-repeat: no-repeat;
  background-color: transparent;
  text-indent: -3333px;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  outline: 0;
}
.k-slider-horizontal .k-draghandle {
  top: -4px;
  width: 13px;
  height: 14px;
}
.k-slider-vertical .k-draghandle {
  left: -4px;
  width: 14px;
  height: 13px;
}
.k-slider-buttons .k-slider-items {
  margin-left: 34px;
}
.k-slider-horizontal .k-slider-items {
  height: 100%;
}
.k-slider-vertical .k-slider-items {
  padding-top: 1px;
}
.k-slider-vertical .k-slider-buttons .k-slider-items {
  padding-top: 0;
}
.k-slider-vertical .k-slider-buttons .k-slider-items {
  margin: 0;
  padding-top: 35px;
}
.k-slider .k-tick {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-slider-horizontal .k-tick {
  float: left;
  height: 100%;
  text-align: center;
}
.k-slider-horizontal .k-tick {
  background-position: center -92px;
}
.k-slider-horizontal .k-slider-topleft .k-tick {
  background-position: center -122px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick {
  background-position: center -152px;
}
.k-slider-horizontal .k-tick-large {
  background-position: center -2px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large {
  background-position: center -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large {
  background-position: center -62px;
}
.k-slider-vertical .k-tick {
  background-position: -92px center;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  background-position: -122px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick {
  background-position: -152px center;
}
.k-slider-vertical .k-tick-large {
  background-position: -2px center;
}
.k-slider-vertical .k-slider-topleft .k-tick-large {
  background-position: -32px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large {
  background-position: -62px center;
}
.k-slider-horizontal .k-first {
  background-position: 0 -92px;
}
.k-slider-horizontal .k-tick-large.k-first {
  background-position: 0 -2px;
}
.k-slider-horizontal .k-slider-topleft .k-first {
  background-position: 0 -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
  background-position: 0 -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-first {
  background-position: 0 -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
  background-position: 0 -62px;
}
.k-slider-horizontal .k-last {
  background-position: 100% -92px;
}
.k-slider-horizontal .k-tick-large.k-last {
  background-position: 100% -2px;
}
.k-slider-horizontal .k-slider-topleft .k-last {
  background-position: 100% -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
  background-position: 100% -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-last {
  background-position: 100% -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
  background-position: 100% -62px;
}
.k-slider-vertical .k-first {
  background-position: -92px 100%;
}
.k-slider-vertical .k-tick-large.k-first {
  background-position: -2px 100%;
}
.k-slider-vertical .k-slider-topleft .k-first {
  background-position: -122px 100%;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
  background-position: -32px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-first {
  background-position: -152px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
  background-position: -62px 100%;
}
.k-slider-vertical .k-last {
  background-position: -92px 0;
}
.k-slider-vertical .k-tick-large.k-last {
  background-position: -2px 0;
}
.k-slider-vertical .k-slider-topleft .k-last {
  background-position: -122px 0;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
  background-position: -32px 0;
}
.k-slider-vertical .k-slider-bottomright .k-last {
  background-position: -152px 0;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
  background-position: -62px 0;
}
.k-slider-vertical .k-tick {
  text-align: right;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  text-align: left;
}
.k-slider .k-label {
  position: absolute;
  white-space: nowrap;
  font-size: 0.92em;
}
.k-slider-horizontal .k-label {
  left: 0;
  width: 100%;
  line-height: 1;
}
.k-slider-horizontal .k-first .k-label {
  left: -50%;
}
.k-slider-horizontal .k-last .k-label {
  left: auto;
  right: -50%;
}
.k-slider-horizontal .k-label {
  bottom: -1.2em;
}
.k-slider-horizontal .k-slider-topleft .k-label {
  top: -1.2em;
}
.k-slider-vertical .k-label {
  left: 120%;
  display: block;
  text-align: left;
}
.k-slider-vertical .k-last .k-label {
  top: -0.5em;
}
.k-slider-vertical .k-first .k-label {
  bottom: -0.5em;
}
.k-slider-vertical .k-slider-topleft .k-label {
  left: auto;
  right: 120%;
}
.k-slider-tooltip {
  top: -4444px;
}
.k-scheduler-footer,
.k-scheduler-toolbar {
  border-style: solid;
}
.k-scheduler-footer,
.k-scheduler-toolbar {
  line-height: 28px;
  padding: 6px;
}
.k-scheduler-toolbar {
  position: relative;
  border-width: 0 0 1px;
}
.k-edit-field.k-scheduler-toolbar {
  border-width: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.k-scheduler-header {
  text-align: center;
}
.k-scheduler-footer {
  border-width: 1px 0 0;
}
.k-scheduler-toolbar > ul {
  float: right;
}
.k-scheduler-toolbar > ul:first-child {
  float: left;
}
.k-scheduler-toolbar > .k-scheduler-tools {
  float: left;
  margin-bottom: 0.5em;
}
.k-scheduler-tools + .k-scheduler-navigation {
  float: left;
  clear: left;
}
.k-scheduler-footer > ul > li,
.k-scheduler-toolbar > ul > li {
  display: inline-block;
  border-style: solid;
  border-width: 1px 1px 1px 0;
}
.k-scheduler .k-scheduler-toolbar .k-nav-current,
.k-scheduler .k-scheduler-toolbar .k-scheduler-tools > li {
  border-width: 0;
}
.k-scheduler-toolbar > ul.k-scheduler-views > li:first-child + li,
.k-scheduler-toolbar > ul > li:first-child {
  border-left-width: 1px;
}
.k-scheduler div.k-scheduler-footer ul li {
  margin-right: 0.6em;
  border-width: 1px;
}
.k-scheduler-footer .k-link,
.k-scheduler-toolbar .k-link {
  display: inline-block;
  padding: 0 1.1em;
}
.k-scheduler-toolbar .k-nav-next .k-link,
.k-scheduler-toolbar .k-nav-prev .k-link {
  padding-left: 0.6em;
  padding-right: 0.6em;
}
.k-scheduler-toolbar .k-nav-current .k-link {
  padding: 0;
}
.k-scheduler-toolbar .k-nav-current {
  margin: 0 1.1em;
}
.k-scheduler .k-nav-current > .k-state-active,
.k-scheduler div.k-scheduler-toolbar > ul > li.k-nav-current {
  background: 0 0;
}
.k-scheduler-phone .k-scheduler-toolbar + .k-scheduler-toolbar .k-scheduler-navigation {
  width: 100%;
  text-align: center;
}
.k-scheduler-phone .k-scheduler-toolbar + .k-scheduler-toolbar .k-scheduler-navigation > li {
  background: 0 0;
  border: 0;
}
.k-scheduler-phone .k-toolbar .k-nav-next {
  float: right;
}
.k-scheduler-phone .k-toolbar .k-nav-prev {
  float: left;
}
.k-scheduler-footer .k-icon,
.k-scheduler-toolbar .k-i-calendar {
  margin: -2px 6px 0 0;
}
.k-scheduler-header,
.k-scheduler-header-wrap {
  overflow: hidden;
}
.k-scheduler-header-wrap {
  position: relative;
  border-style: solid;
  border-width: 0;
}
.k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
  border-right-width: 1px;
}
.k-scheduler-content,
.k-scheduler-times {
  position: relative;
}
.k-scheduler-times {
  overflow: hidden;
  border-style: solid;
  border-width: 0;
}
.k-scheduler-content {
  overflow: auto;
}
.k-scheduler-layout,
.k-scheduler-table {
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border-collapse: separate;
}
.k-scheduler-layout > tbody > tr > td {
  padding: 0;
  vertical-align: top;
}
.k-safari .k-scheduler-layout > tbody > tr > td + td {
  width: 100%;
}
.k-scheduler-table {
  table-layout: fixed;
  max-width: none;
}
.k-scheduler-times .k-scheduler-table {
  table-layout: auto;
}
.k-scheduler-monthview .k-scheduler-content .k-scheduler-table {
  height: 100%;
}
.k-scheduler-table td,
.k-scheduler-table th {
  height: 1.5em;
  padding: 0.334em 0.5em;
  font-size: 100%;
}
.k-scheduler .k-scheduler-table td,
.k-scheduler .k-scheduler-table th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.k-scheduler-monthview .k-hidden,
.k-scheduler-monthview .k-hidden > div {
  width: 0!important;
  overflow: hidden !important;
}
.k-scheduler-monthview .k-hidden {
  padding-left: 0!important;
  padding-right: 0!important;
  border-right-width: 0 !important;
}
.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times {
  margin-right: 1px;
}
.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times .k-hidden {
  height: auto;
}
.k-scheduler-monthview .k-hidden,
.k-scheduler-monthview .k-scheduler-table td {
  height: 80px;
  text-align: right;
}
.k-scheduler-phone .k-scheduler-monthview .k-hidden,
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td {
  height: 40px;
}
.k-scheduler-table td,
.k-slot-cell {
  vertical-align: top;
}
.k-scheduler-layout tr + tr .k-scheduler-times th:last-child {
  vertical-align: top;
}
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td {
  text-align: center;
  vertical-align: middle;
}
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td span {
  font-size: 1.5em;
}
.k-scheduler-header th {
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-scheduler-header th,
.k-scheduler-table td {
  border-style: solid;
  border-width: 0 0 1px 1px;
}
.k-scheduler-header th:first-child,
.k-scheduler-table td:first-child {
  border-left-width: 0;
}
.k-scheduler-agendaview .k-scheduler-table td:first-child {
  border-left-width: 1px;
}
.k-scheduler-agendaview .k-scheduler-table td.k-first {
  border-left-width: 0;
}
.k-scheduler-layout tr + tr .k-scheduler-table > tbody > tr:last-child > td,
.k-scheduler-layout tr + tr .k-scheduler-times tr:last-child > th,
.k-scheduler-table > tbody > tr > .k-last {
  border-bottom-width: 0;
}
.k-scheduler-agendaview.k-scrollbar-h .k-scheduler-table > tbody > tr > td.k-last,
.k-scrollbar-h .k-scheduler-content .k-scheduler-table > tbody > tr:last-child > td,
.k-scrollbar-h tr + tr .k-scheduler-times {
  border-bottom-width: 1px;
}
.k-scheduler-times th {
  text-align: right;
  padding-right: 0.6em;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: transparent;
  white-space: nowrap;
}
.k-scheduler-layout tr + tr .k-scheduler-times th {
  border-bottom-color: transparent;
}
.k-scheduler-layout tr + tr .k-scheduler-times th.k-scheduler-times-all-day,
.k-scheduler-layout tr + tr .k-scheduler-times th.k-slot-cell {
  border-bottom-color: inherit;
}
.k-scheduler .k-middle-row td {
  border-bottom-style: dotted;
}
.k-scheduler-now-arrow,
.k-scheduler-now-line {
  position: absolute;
}
.k-scheduler-now-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  left: 0;
}
.k-scheduler-now-line {
  left: 5px;
  right: 0;
  height: 1px;
}
.k-task {
  position: relative;
}
div.k-more-events {
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  padding: 0;
}
.k-more-events > span {
  display: block;
  margin-top: -0.6em;
}
.k-event,
.k-more-events {
  position: absolute;
  border-style: solid;
  border-width: 1px;
  text-align: left;
  overflow: hidden;
}
.k-event {
  cursor: default;
  min-height: 1.3em;
}
.k-event-drag-hint {
  filter: alpha(opacity=60);
  opacity: 0.6;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.k-scheduler-header .k-event {
  white-space: nowrap;
}
.k-event-template {
  padding: 0.3em 1.4em 0.3em 0.6em;
}
.k-event-time {
  display: none;
  padding-bottom: 0;
  font-size: 0.9em;
}
.k-event-drag-hint .k-event-time {
  display: block;
}
.k-event-actions,
.k-event > .k-link,
.k-task > .k-link {
  position: absolute;
  top: 3px;
  right: 4px;
  white-space: nowrap;
}
.k-event-actions {
  z-index: 1;
}
.k-scheduler-agendaview .k-task > .k-link {
  top: 0;
  right: 0;
}
.k-event-actions:first-child {
  position: static;
  float: left;
  margin: 4px 2px 0 4px;
}
.k-webkit .k-event-actions:first-child {
  margin-top: 3px;
}
.k-event-actions:first-child > .k-link {
  display: inline-block;
}
.k-event-delete {
  display: none;
}
.k-event:hover .k-event-delete,
tr:hover > td > .k-task .k-event-delete {
  display: inline-block;
}
.k-event .k-event-bottom-actions,
.k-event .k-event-top-actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.k-event .k-event-bottom-actions {
  top: auto;
  bottom: 0;
}
.k-event .k-resize-handle,
.k-scheduler-mobile .k-event:hover .k-resize-handle {
  position: absolute;
  visibility: hidden;
  z-index: 2;
}
.k-event-active .k-resize-handle,
.k-event:hover .k-resize-handle,
.k-scheduler-mobile .k-event-active:hover .k-resize-handle {
  visibility: visible;
}
.k-event .k-resize-handle:after {
  content: "";
  position: absolute;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.k-scheduler-mobile .k-event .k-resize-handle:after {
  -webkit-filter: none;
  filter: none;
  opacity: 1;
}
.k-event > .k-resize-n {
  top: 0;
  left: 0;
  right: 0;
  height: 0.4em;
}
.k-event > .k-resize-s {
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.4em;
}
.k-event > .k-resize-e {
  right: 0;
  top: 0;
  bottom: 0;
  width: 0.4em;
}
.k-event > .k-resize-w {
  left: 0;
  top: 0;
  bottom: 0;
  width: 0.4em;
}
.k-event > .k-resize-n:after,
.k-event > .k-resize-s:after {
  top: 1px;
  left: 50%;
  margin-left: -1em;
  width: 2em;
  height: 1px;
}
.k-event > .k-resize-s:after {
  top: auto;
  bottom: 1px;
}
.k-event > .k-resize-e:after,
.k-event > .k-resize-w:after {
  left: 1px;
  top: 50%;
  margin-top: -0.7em;
  height: 1.4em;
  width: 1px;
}
.k-event > .k-resize-e:after {
  left: auto;
  right: 1px;
}
.k-scheduler-mobile .k-event > .k-resize-n,
.k-scheduler-mobile .k-event > .k-resize-s {
  height: 0.6em;
}
.k-scheduler-mobile .k-event > .k-resize-e,
.k-scheduler-mobile .k-event > .k-resize-w {
  width: 0.6em;
}
.k-scheduler-mobile .k-event > .k-resize-n:after,
.k-scheduler-mobile .k-event > .k-resize-s:after {
  top: 0;
  margin-left: -3em;
  width: 4em;
  height: 0.6em;
}
.k-scheduler-mobile .k-event > .k-resize-s:after {
  bottom: 0;
}
.k-scheduler-mobile .k-event > .k-resize-e:after,
.k-scheduler-mobile .k-event > .k-resize-w:after {
  left: 0;
  margin-top: -0.7em;
  height: 1.4em;
  width: 0.6em;
}
.k-scheduler-mobile .k-event > .k-resize-e:after {
  right: 0;
}
.k-scheduler-mobile .k-event > .k-resize-n:after {
  border-radius: 0 0 4px 4px;
}
.k-scheduler-mobile .k-event > .k-resize-s:after {
  border-radius: 4px 4px 0 0;
}
.k-scheduler-mobile .k-event > .k-resize-w:after {
  border-radius: 0 4px 4px 0;
}
.k-scheduler-mobile .k-event > .k-resize-e:after {
  border-radius: 4px 0 0 4px;
}
.k-scheduler-phone .k-scheduler-monthview .k-events-container {
  position: absolute;
  text-align: center;
  height: 6px;
  line-height: 6px;
}
.k-scheduler-phone .k-scheduler-monthview .k-event {
  position: static;
  display: inline-block;
  width: 4px;
  height: 4px;
  min-height: 0;
  margin: 1px;
}
.k-scheduler-marquee {
  border-style: solid;
  border-width: 0;
}
.k-scheduler-marquee.k-first:before,
.k-scheduler-marquee.k-last:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px;
}
div.k-scheduler-marquee:before {
  top: 0;
  left: 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
div.k-scheduler-marquee:after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
}
.k-scheduler-marquee .k-label-top {
  position: absolute;
  top: 0.3em;
  left: 0.8em;
  font-size: 0.8em;
}
.k-scheduler-marquee .k-label-bottom {
  position: absolute;
  bottom: 0.3em;
  right: 0.81em;
  font-size: 0.8em;
}
.k-scheduler-quickedit .k-textbox {
  width: 200px;
}
.k-tooltip-bottom {
  text-align: left;
}
.k-tooltip-bottom .k-button {
  float: left;
  margin-right: 0.3em;
}
.k-tooltip-bottom .k-quickedit-details {
  float: right;
  margin-right: 0;
}
.k-scheduler-agendaview .k-scheduler-table td,
.k-scheduler-agendaview .k-scheduler-table th {
  text-align: left;
}
.k-scheduler-groupcolumn,
.k-scheduler-times .k-slot-cell {
  width: 6em;
}
.k-scheduler-datecolumn {
  width: 12em;
}
.k-scheduler-timecolumn {
  width: 11em;
}
.k-scheduler-timecolumn > div {
  position: relative;
  vertical-align: top;
}
.k-webkit .k-scheduler-timecolumn > div > .k-icon {
  vertical-align: top;
}
.k-scheduler-timecolumn > div > .k-i-arrow-e {
  position: absolute;
  right: -4px;
}
.k-scheduler-timecolumn .k-i-arrow-w {
  margin-left: -4px;
}
.k-scheduler-mark {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.5em;
}
.k-scheduler-agendaday {
  float: left;
  margin: 0 0.2em 0 0;
  font-size: 3em;
  font-weight: 400;
}
.k-scheduler-agendaweek {
  display: block;
  margin: 0.4em 0 0;
  font-size: 1.1em;
  font-style: normal;
}
.k-scheduler-agendadate {
  font-size: 0.8em;
}
.k-scheduler-timecolumn {
  white-space: nowrap;
}
.k-scheduler-edit-form .k-edit-form-container,
.k-scheduler-timezones .k-edit-form-container {
  width: 520px;
}
.k-scheduler-edit-form .k-edit-label {
  width: 17%;
}
.k-scheduler-edit-form .k-edit-field {
  width: 77%;
}
.k-scheduler-edit-form .k-textbox[name=title],
.k-scheduler-edit-form textarea.k-textbox {
  width: 100%;
}
.k-scheduler-edit-form textarea.k-textbox {
  min-height: 4em;
  resize: vertical;
}
.k-scheduler-edit-form > .k-edit-box:first-child .k-datetimepicker {
  margin-right: 1em;
}
.km-pane-wrapper .k-scheduler-edit-form .k-edit-buttons {
  clear: right;
  margin-right: 2%;
  margin-left: 2%;
  padding: 0 0 0.6em;
}
.k-edit-box {
  float: left;
}
.k-edit-box + .k-edit-box {
  float: right;
}
.k-scheduler-edit-form label + input {
  margin-left: 1em;
}
.k-edit-field > ul.k-reset > li {
  margin: 0.2em 0 0.4em;
  line-height: 2.4;
}
.k-edit-field > ul.k-reset.k-toolbar > li {
  margin: 0;
}
.k-edit-field > ul.k-reset .k-widget {
  margin-left: 0.8em;
}
.k-edit-field span.k-recur-interval,
.k-edit-field > ul.k-reset .k-numerictextbox {
  width: 5em;
}
.k-edit-field > ul.k-reset .k-datepicker,
.k-edit-field > ul.k-reset .k-dropdown,
div[name=recurrenceRule] > .k-dropdown {
  width: 9em;
}
.k-scheduler-edit-form .k-edit-buttons .k-scheduler-delete {
  float: left;
}
.k-popup-message {
  margin: 0;
  padding: 1em 0 2em;
  text-align: center;
}
.k-scheduler-timezones .k-dropdown:first-child {
  width: 100%;
}
.k-scheduler-timezones .k-dropdown + .k-dropdown {
  margin: 0.5em 0 0.7em;
}
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
  display: none;
}
.k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
  display: none;
}
.k-scheduler-refresh {
  float: right;
  padding: 0 0.5em;
}
@media only screen and (max-width: 1024px) {
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10000;
  }
  .k-edge .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-ff .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-ie11 .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-safari .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-webkit .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views {
    right: auto;
    left: 6px;
  }
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view),
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view),
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view),
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view),
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view) {
    display: none;
  }
  .k-edge .k-scheduler-toolbar li.k-nav-current .k-lg-date-format,
  .k-ff .k-scheduler-toolbar li.k-nav-current .k-lg-date-format,
  .k-ie11 .k-scheduler-toolbar li.k-nav-current .k-lg-date-format,
  .k-safari .k-scheduler-toolbar li.k-nav-current .k-lg-date-format,
  .k-webkit .k-scheduler-toolbar li.k-nav-current .k-lg-date-format {
    display: none;
  }
  .k-edge .k-scheduler-toolbar li.k-nav-current .k-sm-date-format,
  .k-ff .k-scheduler-toolbar li.k-nav-current .k-sm-date-format,
  .k-ie11 .k-scheduler-toolbar li.k-nav-current .k-sm-date-format,
  .k-safari .k-scheduler-toolbar li.k-nav-current .k-sm-date-format,
  .k-webkit .k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
    display: inline;
  }
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    display: block;
    border-width: 1px;
  }
  .k-edge .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ff .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ie11 .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-safari .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-webkit .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    text-align: left;
    padding-left: 1em;
  }
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    display: block;
    position: relative;
    padding-right: 2.5em;
    padding-left: 1em;
  }
  .k-edge .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ff .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ie11 .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-safari .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-webkit .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    padding-left: 0;
  }
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.6em;
    right: 0.333em;
    width: 1.333em;
    height: 1.333em;
  }
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li {
    display: block;
    border: 0;
    border-radius: 0;
  }
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border: 1px solid #c5c5c5;
    background-color: #fff;
    background-image: none;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .k-edge .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-webkit .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  .k-edge .k-scheduler:not(.k-scheduler-mobile) .k-scheduler-toolbar li.k-nav-current .k-sm-date-format,
  .k-ff .k-scheduler:not(.k-scheduler-mobile) .k-scheduler-toolbar li.k-nav-current .k-sm-date-format,
  .k-ie11 .k-scheduler:not(.k-scheduler-mobile) .k-scheduler-toolbar li.k-nav-current .k-sm-date-format,
  .k-safari .k-scheduler:not(.k-scheduler-mobile) .k-scheduler-toolbar li.k-nav-current .k-sm-date-format,
  .k-webkit .k-scheduler:not(.k-scheduler-mobile) .k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
    display: none;
  }
  .k-edge .k-scheduler-timecolumn,
  .k-ff .k-scheduler-timecolumn,
  .k-ie11 .k-scheduler-timecolumn,
  .k-safari .k-scheduler-timecolumn,
  .k-webkit .k-scheduler-timecolumn {
    width: 5em;
  }
  .k-edge .k-scheduler-datecolumn,
  .k-ff .k-scheduler-datecolumn,
  .k-ie11 .k-scheduler-datecolumn,
  .k-safari .k-scheduler-datecolumn,
  .k-webkit .k-scheduler-datecolumn {
    width: 6em;
    overflow: hidden;
  }
  .k-edge .k-scheduler-datecolumn > div,
  .k-edge .k-scheduler-timecolumn > div,
  .k-ff .k-scheduler-datecolumn > div,
  .k-ff .k-scheduler-timecolumn > div,
  .k-ie11 .k-scheduler-datecolumn > div,
  .k-ie11 .k-scheduler-timecolumn > div,
  .k-safari .k-scheduler-datecolumn > div,
  .k-safari .k-scheduler-timecolumn > div,
  .k-webkit .k-scheduler-datecolumn > div,
  .k-webkit .k-scheduler-timecolumn > div {
    white-space: normal;
  }
}
.k-scheduler-pdf-export {
  overflow: hidden;
}
.k-pdf-export-shadow .k-scheduler,
.k-scheduler-pdf-export .k-scheduler-content,
.k-scheduler-pdf-export .k-scheduler-times {
  height: auto!important;
  overflow: visible !important;
}
.k-scheduler-pdf-export .k-scheduler-header {
  padding: 0 !important;
}
.k-scheduler-pdf-export .k-scheduler-header-wrap {
  border-width: 0 !important;
}
.k-scheduler-pdf-export .k-scheduler-content .k-scheduler-table,
.k-scheduler-pdf-export .k-scheduler-header .k-scheduler-table {
  width: 100% !important;
}
.k-tooltip {
  position: absolute;
  z-index: 12000;
  border-style: solid;
  border-width: 0;
  padding: 5px 5px 5px 6px;
  background-repeat: repeat-x;
  min-width: 20px;
  text-align: center;
}
.k-tooltip-button {
  text-align: right;
  height: 0;
}
.k-tooltip-content {
  height: 100%;
}
.k-tooltip-closable .k-tooltip-content {
  padding-right: 20px;
}
span.k-tooltip {
  position: static;
  display: inline-block;
  border-width: 1px;
  padding: 2px 5px 1px 6px;
}
.k-invalid-msg {
  display: none;
}
.k-callout {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
  pointer-events: none;
}
.k-callout-n {
  top: -12px;
  left: 50%;
  pointer-events: none;
}
.k-callout-w {
  top: 50%;
  left: -12px;
  pointer-events: none;
}
.k-callout-s {
  left: 50%;
  bottom: -12px;
  pointer-events: none;
}
.k-callout-e {
  top: 50%;
  right: -12px;
  pointer-events: none;
}
.k-slider-tooltip .k-callout-n,
.k-slider-tooltip .k-callout-s {
  margin-left: -6px;
}
.k-slider-tooltip .k-callout-e,
.k-slider-tooltip .k-callout-w {
  margin-top: -6px;
}
.k-tooltip-validation .k-warning {
  vertical-align: text-top;
  margin-right: 3px;
}
.k-tooltip-validation {
  z-index: 9999;
}
.k-toolbar {
  position: relative;
  display: block;
  vertical-align: middle;
  line-height: 2.9em;
}
.k-overflow-container .k-button .k-icon,
.k-overflow-container .k-button .k-sprite,
.k-toolbar .k-button .k-icon,
.k-toolbar .k-button .k-sprite {
  vertical-align: middle;
  margin-top: -7px;
  margin-bottom: -5px;
}
.k-toolbar .k-input {
  line-height: inherit;
  height: inherit;
  padding-top: 2px;
  padding-bottom: 2px;
}
.k-toolbar .k-input:before {
  content: "\a0";
  display: inline-block;
  width: 0;
}
.k-ie .k-toolbar .k-input {
  height: 1.65em;
}
.k-toolbar .k-combobox .k-dropdown-wrap:before,
.k-toolbar .k-numeric-wrap:before,
.k-toolbar .k-picker-wrap:before {
  display: none;
}
.k-overflow-container .k-sprite {
  margin-left: -4px;
}
.k-toolbar-resizable {
  overflow: hidden;
  white-space: nowrap;
}
.k-toolbar > .k-align-left {
  float: none;
}
.k-toolbar > .k-align-right {
  float: right;
}
.k-toolbar .k-button,
.k-toolbar > * {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.72em;
}
.k-toolbar .k-separator {
  border-width: 0 0 0 1px;
  border-style: solid;
  width: 1px;
  line-height: inherit;
}
.k-toolbar .k-button-group {
  list-style-type: none;
}
.k-toolbar .k-button-group > li {
  display: inline-block;
}
.k-toolbar .k-button-group .k-button {
  margin: 0 0 0 -1px;
  border-radius: 0;
}
.k-toolbar .k-button,
.k-toolbar .k-button-group,
.k-toolbar .k-separator,
.k-toolbar .k-split-button,
.k-toolbar .k-textbox,
.k-toolbar .k-widget,
.k-toolbar label {
  margin: 0 0.2em;
  line-height: 1.72em;
  vertical-align: middle;
}
.k-toolbar .k-split-button {
  padding-left: 0;
}
.k-toolbar .k-button-group .k-group-start,
.k-toolbar .k-split-button .k-button {
  margin: 0;
}
.k-toolbar .k-split-button .k-split-button-arrow {
  margin: 0 0 0 -1px;
}
.k-toolbar .k-overflow-anchor {
  border-width: 0 0 0 1px;
  border-style: solid;
  height: 3em;
  width: 3em;
  line-height: inherit;
  padding: 0 0.5em;
  margin: 0;
  position: relative;
  float: right;
  border-radius: 0;
}
.k-overflow-container .k-item {
  float: none;
  border: 0;
}
.k-overflow-container .k-separator {
  border-width: 0 0 1px;
  border-style: solid;
  height: 1px;
  line-height: 0;
  font-size: 0;
  padding: 0;
}
.k-overflow-container .k-overflow-button,
.k-split-container .k-button {
  text-align: left;
  display: block;
  background: 0 0;
  border-color: transparent;
  white-space: nowrap;
}
.k-split-container {
  margin-top: -1px;
}
.k-overflow-container .k-button-group {
  padding: 0;
}
.k-overflow-container .k-button-group > li {
  display: block;
}
.k-overflow-container .k-overflow-group {
  border-width: 1px 0;
  border-style: solid;
  border-radius: 0;
  padding: 2px 0;
  margin: 1px 0;
}
.k-overflow-container .k-overflow-hidden {
  display: none;
}
.k-overflow-container .k-overflow-group + .k-overflow-group,
.k-overflow-container .k-separator + .k-overflow-group,
.k-overflow-container .k-toolbar-first-visible {
  border-top: 0;
  margin-top: 0;
  padding-top: 1px;
}
.k-overflow-container .k-overflow-group + .k-separator {
  display: none;
}
.k-overflow-container .k-toolbar-last-visible {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 1px;
}
.k-splitter {
  position: relative;
  height: 300px;
}
.k-pane > .k-splitter {
  border-width: 0;
  overflow: hidden;
}
.k-splitter .k-pane {
  overflow: hidden;
}
.k-splitter .k-scrollable {
  overflow: auto;
}
.k-splitter .k-pane-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
}
.k-ghost-splitbar,
.k-splitbar {
  position: absolute;
  border-style: solid;
  font-size: 0;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.k-splitter .k-ghost-splitbar-horizontal,
.k-splitter .k-splitbar-horizontal {
  top: 0;
  width: 5px;
  border-width: 0 1px;
  background-repeat: repeat-y;
}
.k-ghost-splitbar-vertical,
.k-splitbar-vertical {
  left: 0;
  height: 5px;
  border-width: 1px 0;
  background-repeat: repeat-x;
}
.k-splitbar-draggable-horizontal {
  cursor: w-resize;
}
.k-splitbar-draggable-vertical {
  cursor: n-resize;
}
.k-splitbar .k-resize-handle {
  display: none;
}
.k-restricted-size-horizontal,
.k-restricted-size-vertical {
  background-color: red;
}
.k-splitbar-horizontal .k-icon {
  position: absolute;
  top: 50%;
  width: 5px;
  height: 20px;
  margin-top: -10px;
}
.k-collapse-next,
.k-collapse-prev,
.k-expand-next,
.k-expand-prev {
  cursor: pointer;
}
.k-splitbar-horizontal .k-collapse-prev {
  margin-top: -31px;
}
.k-splitbar-horizontal .k-collapse-next {
  margin-top: 11px;
}
.k-splitbar-static-horizontal {
  width: 1px;
}
.k-splitbar-static-vertical {
  height: 1px;
}
.k-splitbar-vertical .k-icon {
  position: absolute;
  left: 50%;
  width: 20px;
  height: 5px;
  margin-left: -10px;
}
.k-splitbar-vertical .k-collapse-prev {
  margin-left: -31px;
}
.k-splitbar-vertical .k-collapse-next {
  margin-left: 11px;
}
.k-splitbar-draggable-horizontal .k-resize-handle,
.k-splitbar-draggable-vertical .k-resize-handle {
  display: inline-block;
}
.k-splitbar-horizontal .k-resize-handle {
  background-position: -165px -6px;
}
.k-splitbar-horizontal-hover > .k-resize-handle {
  background-position: -181px -6px;
}
.k-splitbar-horizontal .k-collapse-prev,
.k-splitbar-horizontal .k-expand-next {
  background-position: -6px -174px;
}
.k-splitbar-horizontal-hover > .k-collapse-prev,
.k-splitbar-horizontal-hover > .k-expand-next {
  background-position: -22px -174px;
}
.k-splitbar-horizontal .k-collapse-next,
.k-splitbar-horizontal .k-expand-prev {
  background-position: -5px -142px;
}
.k-splitbar-horizontal-hover > .k-collapse-next,
.k-splitbar-horizontal-hover > .k-expand-prev {
  background-position: -21px -142px;
}
.k-splitbar-vertical .k-resize-handle {
  background-position: -38px -309px;
}
.k-splitbar-vertical-hover > .k-resize-handle {
  background-position: -70px -309px;
}
.k-splitbar-vertical .k-collapse-prev,
.k-splitbar-vertical .k-expand-next {
  background-position: 2px -134px;
}
.k-splitbar-vertical-hover > .k-collapse-prev,
.k-splitbar-vertical-hover > .k-expand-next {
  background-position: -14px -134px;
}
.k-splitbar-vertical .k-collapse-next,
.k-splitbar-vertical .k-expand-prev {
  background-position: 2px -165px;
}
.k-splitbar-vertical-hover > .k-collapse-next,
.k-splitbar-vertical-hover > .k-expand-prev {
  background-position: -14px -165px;
}
.k-splitter-resizing {
  overflow: hidden;
}
html .k-upload {
  position: relative;
}
.k-dropzone em,
.k-upload-button {
  vertical-align: middle;
}
.k-dropzone,
.k-file {
  position: relative;
}
.k-dropzone {
  border-style: solid;
  border-width: 0;
  padding: 0.8em;
  background-color: transparent;
}
.k-dropzone em {
  visibility: hidden;
  margin-left: 0.6em;
}
.k-dropzone-active em {
  visibility: visible;
}
.k-upload-button {
  position: relative;
  overflow: hidden;
  direction: ltr;
}
.k-upload .k-upload-button {
  min-width: 7.167em;
}
.k-ie8 .k-upload-button,
.k-ie9 .k-upload-button,
.k-upload-sync .k-upload-button {
  margin: 0.8em;
}
.k-upload-button input {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  font: 170px monospace!important;
  filter: alpha(opacity=0);
  opacity: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.k-upload-files {
  line-height: 2.66;
  border-style: solid;
  border-width: 1px 0 0;
  padding-bottom: 5px;
}
.k-upload-files .k-button {
  padding: 0;
}
.k-upload-status .k-warning {
  display: none;
}
.k-upload-status-total .k-icon {
  display: inline-block;
  margin-right: 4px;
}
.k-dropzone .k-upload-status-total {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.k-upload .k-clear-selected,
.k-upload .k-upload-selected {
  display: inline-block;
  width: 50%;
  margin: 0;
  line-height: 1em;
  padding: 1.214em 0;
  border: 0;
}
.k-upload .k-upload-selected {
  border-left-width: 1px;
  border-left-style: solid;
  margin-left: -1px;
}
.k-upload .k-clear-selected {
  border-radius: 0 0 0 4px;
}
.k-upload .k-upload-selected {
  border-radius: 0 0 4px 0;
}
.k-upload-files ~ .k-clear-selected,
.k-upload-files ~ .k-upload-selected {
  margin-top: -5px;
}
.k-upload .k-fail {
  background-position: -161px -111px;
}
.k-i-srefresh,
.k-si-refresh {
  background-position: -160px -128px;
}
.k-button:active .k-i-srefresh,
.k-button:active .k-si-refresh,
.k-button:not(.k-state-disabled):hover .k-i-srefresh,
.k-button:not(.k-state-disabled):hover .k-si-refresh,
.k-link:not(.k-state-disabled):hover > .k-i-srefresh,
.k-link:not(.k-state-disabled):hover > .k-si-refresh,
.k-state-hover > * > .k-i-srefresh,
.k-state-hover > * > .k-si-refresh,
.k-state-hover > .k-i-srefresh,
.k-state-hover > .k-si-refresh,
.k-textbox:hover .k-i-srefresh,
.k-textbox:hover .k-si-refresh {
  background-position: -176px -128px;
}
.k-i-stick,
.k-si-tick,
.k-success {
  background-position: -160px -96px;
}
.k-button:active .k-i-stick,
.k-button:active .k-si-tick,
.k-button:active .k-success,
.k-button:not(.k-state-disabled):hover .k-i-stick,
.k-button:not(.k-state-disabled):hover .k-si-tick,
.k-button:not(.k-state-disabled):hover .k-success,
.k-link:not(.k-state-disabled):hover > .k-i-stick,
.k-link:not(.k-state-disabled):hover > .k-si-tick,
.k-link:not(.k-state-disabled):hover > .k-success,
.k-state-hover > * > .k-i-stick,
.k-state-hover > * > .k-si-tick,
.k-state-hover > * > .k-success,
.k-state-hover > .k-i-stick,
.k-state-hover > .k-si-tick,
.k-state-hover > .k-success,
.k-textbox:hover .k-i-stick,
.k-textbox:hover .k-si-tick,
.k-textbox:hover .k-success {
  background-position: -176px -96px;
}
.k-i-scancel,
.k-si-cancel {
  background-position: -160px -112px;
}
.k-button:active .k-i-scancel,
.k-button:active .k-si-cancel,
.k-button:not(.k-state-disabled):hover .k-i-scancel,
.k-button:not(.k-state-disabled):hover .k-si-cancel,
.k-link:not(.k-state-disabled):hover > .k-i-scancel,
.k-link:not(.k-state-disabled):hover > .k-si-cancel,
.k-state-hover > * > .k-i-scancel,
.k-state-hover > * > .k-si-cancel,
.k-state-hover > .k-i-scancel,
.k-state-hover > .k-si-cancel,
.k-textbox:hover .k-i-scancel,
.k-textbox:hover .k-si-cancel {
  background-position: -176px -112px;
}
.k-file {
  border-style: solid;
  border-width: 0 0 1px;
  padding: 0.65em 0.17em 0.65em 1em;
}
.k-file .k-icon {
  position: relative;
}
.k-file > .k-icon {
  background-position: -112px -288px;
}
.k-button:active .k-file > .k-icon,
.k-button:not(.k-state-disabled):hover .k-file > .k-icon,
.k-link:not(.k-state-disabled):hover > .k-file > .k-icon,
.k-state-hover > * > .k-file > .k-icon,
.k-state-hover > .k-file > .k-icon,
.k-textbox:hover .k-file > .k-icon {
  background-position: -128px -288px;
}
.k-file-extension-wrapper,
.k-file-invalid-extension-wrapper,
.k-file-name-size-wrapper,
.k-multiple-files-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  display: inline-block;
}
.k-file-extension-wrapper,
.k-file-invalid-extension-wrapper,
.k-multiple-files-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  position: relative;
  width: 24px;
  height: 34px;
  border-width: 2px;
  border-style: solid;
  vertical-align: top;
  font-size: 0.57em;
  text-transform: uppercase;
}
.k-file-invalid-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  font-size: 1.2em;
}
.k-multiple-files-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  margin-top: 4px;
}
.k-file-state {
  visibility: hidden;
}
.k-file-name-size-wrapper {
  vertical-align: middle;
  margin-left: 1em;
}
.k-file-extension-wrapper:before,
.k-file-invalid-extension-wrapper:before,
.k-multiple-files-extension-wrapper:after,
.k-multiple-files-extension-wrapper:before,
.k-multiple-files-invalid-extension-wrapper:after,
.k-multiple-files-invalid-extension-wrapper:before {
  position: absolute;
  content: "";
  display: inline-block;
  border-style: solid;
}
.k-file-extension-wrapper:before,
.k-file-invalid-extension-wrapper:before,
.k-multiple-files-extension-wrapper:before,
.k-multiple-files-invalid-extension-wrapper:before {
  top: -1px;
  right: -1px;
  width: 0;
  height: 0;
  border-width: 6px;
  margin-top: -1px;
  margin-right: -1px;
}
.k-multiple-files-extension-wrapper:after,
.k-multiple-files-invalid-extension-wrapper:after {
  top: -6px;
  left: -6px;
  width: 15px;
  height: 35px;
  border-width: 2px 0 0 2px;
}
.k-file-extension,
.k-file-invalid-icon {
  position: absolute;
  bottom: 0;
  line-height: normal;
}
.k-file-extension {
  margin-left: 0.4em;
  margin-bottom: 0.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.k-file-invalid-icon {
  margin-left: 5px;
}
.k-file-information,
.k-file-name,
.k-file-size,
.k-file-validation-message {
  display: block;
}
.k-file-name {
  position: relative;
  min-width: 10em;
  max-width: 16.667em;
  vertical-align: middle;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.k-file-information,
.k-file-size,
.k-file-validation-message {
  font-size: 0.78em;
  line-height: 1.5em;
}
.k-file-information,
.k-file-validation-message {
  line-height: 1.5em;
}
.k-file-size {
  line-height: 1.2em;
}
.k-upload-status {
  position: absolute;
  right: 10px;
  top: 0;
}
.k-upload-status .k-button {
  vertical-align: text-bottom;
}
.k-dropzone .k-upload-status {
  line-height: 2.4;
}
.k-upload-pct {
  margin-right: 0.75em;
}
.k-ie8 .k-upload-status-total {
  line-height: 29px;
}
.k-ie8 .k-upload-status-total,
.k-ie9 .k-upload-status-total {
  top: 1.5em;
}
.k-ie8 .k-upload-status-total > .k-icon,
.k-ie9 .k-upload-status-total > .k-icon {
  margin-top: -3px;
}
.k-upload-action {
  line-height: normal;
}
.k-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
}
.k-file-invalid > .k-progress {
  width: 100%;
}
.k-upload-selected {
  min-width: 7.167em;
  margin: 0.25em 0 0;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.k-upload-cancel,
.k-upload-selected {
  margin-bottom: 0.8em;
}
.k-upload-selected {
  margin-left: 0.8em;
  margin-right: 0.2em;
}
.k-rtl .k-file-extension-wrapper {
  margin-left: 14px;
}
.k-mediaplayer {
  position: relative;
}
.k-mediaplayer > iframe,
.k-mediaplayer > video {
  vertical-align: top;
}
.k-mediaplayer .k-mediaplayer-titlebar,
.k-mediaplayer .k-mediaplayer-toolbar {
  z-index: 2;
}
.k-mediaplayer .k-mediaplayer-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}
.k-mediaplayer-titlebar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), color-stop(80%, rgba(0, 0, 0, 0.05)), color-stop(80%, rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.05) 80%, rgba(0, 0, 0, 0));
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.05) 80%, rgba(0, 0, 0, 0));
  color: #fff;
  padding: 12px 12px 30px;
}
.k-mediaplayer-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  border-width: 0;
}
html .k-mediaplayer-toolbar .k-button-bare {
  padding-left: 9px;
  padding-right: 9px;
}
.k-mediaplayer-toolbar div.k-mediaplayer-volume {
  margin: 2px 6px 0;
}
.k-mediaplayer-toolbar .k-slider-selection,
.k-mediaplayer-toolbar .k-slider-track {
  border-radius: 0;
}
.k-mediaplayer-toolbar .k-mediaplayer-seekbar {
  position: absolute;
  top: -17px;
  left: 0;
  right: 0;
  margin: 0;
  width: auto;
}
.k-mediaplayer-toolbar .k-mediaplayer-seekbar .k-slider-track {
  width: 100% !important;
}
.k-mediaplayer-toolbar .k-mediaplayer-seekbar .k-draghandle {
  opacity: 0;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}
.k-mediaplayer-toolbar .k-mediaplayer-seekbar .k-draghandle.k-state-selected,
.k-mediaplayer-toolbar .k-mediaplayer-seekbar:hover .k-draghandle {
  opacity: 1;
}
.k-mediaplayer-toolbar .k-mediaplayer-quality {
  width: auto;
  padding: 0;
  background: none transparent;
}
.k-mediaplayer-toolbar .k-mediaplayer-quality .k-dropdown-wrap {
  border: 0 none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  background: none transparent;
  color: inherit;
}
.k-mediaplayer-toolbar .k-mediaplayer-quality .k-input {
  display: none;
}
.k-mediaplayer-toolbar .k-mediaplayer-quality .k-select {
  position: relative;
  width: auto;
  min-height: 0;
  line-height: inherit;
}
.k-mediaplayer-toolbar .k-align-right {
  line-height: inherit;
}
.k-mediaplayer-toolbar .k-align-right > * {
  display: inline-block;
}
.k-mediaplayer-currenttime {
  display: inline-block;
  margin-top: 2px;
}
.k-quality-list {
  width: 80px!important;
  margin-left: -32px !important;
}
.k-toolbar-wrap .k-dropzone em,
.k-toolbar-wrap .k-upload-files {
  display: none;
}
.k-toolbar-wrap .k-dropzone {
  border: 0;
  padding: 0;
}
.k-toolbar-wrap .k-dropzone-active {
  text-align: center;
}
.k-toolbar-wrap .k-dropzone-active em {
  display: inline;
  margin: 0;
  font-size: 5em;
  font-style: normal;
}
.k-toolbar-wrap .k-dropzone-active .k-upload-button {
  display: none;
}
.k-filebrowser-dropzone {
  z-index: 10010;
  filter: alpha(opacity=40);
  opacity: 0.4;
  position: fixed;
}
.k-search-wrap {
  position: relative;
  float: right;
  width: 20%;
  padding: 0;
}
.k-search-wrap label {
  position: absolute;
  top: 0;
  left: 4px;
  line-height: 20px;
  font-style: italic;
}
.k-search-wrap input.k-input {
  padding-left: 0;
  padding-right: 0;
}
.k-search-wrap .k-search {
  position: absolute;
  top: 4px;
  right: 2px;
  margin: 0;
}
.k-breadcrumbs {
  position: relative;
  float: left;
  width: 79%;
}
.k-breadcrumbs-wrap {
  position: absolute;
  top: 3px;
  left: 0;
  z-index: 1;
  padding-left: 5px;
  line-height: 18px;
}
.k-breadcrumbs > .k-input {
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  border: 0;
}
.k-breadcrumbs .k-link,
.k-breadcrumbs-wrap .k-icon {
  margin-top: 0;
  text-decoration: none;
  vertical-align: middle;
  position: static;
}
.k-breadcrumbs .k-link:hover {
  text-decoration: underline;
}
.k-filebrowser .k-breadcrumbs .k-i-seek-w {
  text-decoration: none;
  cursor: default;
}
.k-filebrowser .k-filebrowser-toolbar {
  border-style: solid;
  border-width: 1px;
  margin: 8px 0 0;
  padding: 0.25em;
  line-height: 23px;
  white-space: nowrap;
}
.k-filebrowser .k-filebrowser-toolbar .k-button.k-state-disabled {
  display: none;
}
.k-filebrowser .k-toolbar-wrap {
  float: left;
}
.k-filebrowser .k-tiles-arrange {
  float: right;
}
.k-filebrowser .k-tiles-arrange .k-dropdown {
  width: 75px;
}
.k-filebrowser .k-upload {
  float: left;
  z-index: 10010;
  border-width: 0;
  background-color: transparent;
}
.k-filebrowser .k-upload .k-upload-status {
  display: none;
}
.k-filebrowser .k-upload .k-upload-button {
  width: auto;
  margin-left: 0;
  vertical-align: top;
}
.k-filebrowser .k-upload .k-icon {
  vertical-align: bottom;
}
.k-tiles {
  clear: both;
  height: 390px;
  border-style: solid;
  border-width: 1px;
  border-top-width: 0;
  margin: 0 0 1.4em;
  padding: 9px;
  overflow: auto;
  line-height: 1.2;
}
.k-tile {
  float: left;
  width: 223px;
  height: 88px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  margin: 1px;
  padding: 0 0 4px;
  background-position: 0 100px;
  background-repeat: repeat-x;
  cursor: pointer;
}
.k-tiles li.k-state-hover,
.k-tiles li.k-state-selected {
  background-position: 0 center;
}
.k-filebrowser .k-thumb {
  float: left;
  display: inline;
  width: 80px;
  height: 80px;
  margin: 4px 10px 0 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.k-filebrowser .k-file {
  width: 80px;
  height: 80px;
}
.k-filebrowser .k-image {
  margin: 2px 0 0 2px;
}
.k-filebrowser .k-folder {
  width: 80px;
  height: 80px;
  background-position: 0 -200px;
  background-repeat: no-repeat;
}
.k-filebrowser .k-loading {
  margin: 35px 0 0 33px;
}
.k-tile input,
.k-tile strong {
  margin: 10px 0 4px;
  font-weight: 400;
}
.k-tile strong {
  float: left;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-tile input {
  width: 100px;
}
.k-tile input,
.k-tile strong {
  display: block;
}
.k-tile .k-filesize {
  display: inline-block;
}
.k-filebrowser .k-form-text-row {
  text-align: right;
}
.k-filebrowser .k-form-text-row label {
  width: 14%;
}
.k-filebrowser .k-form-text-row input {
  width: 80%;
}
.k-tile-empty {
  margin: 160px 0 0;
}
.k-tile-empty .k-dialog-upload {
  font-weight: 700;
  font-size: 120%;
}
.k-tile-empty strong {
  display: block;
  margin: 0 0 0.2em;
  font-size: 3em;
  font-weight: 400;
}
.k-tile-empty,
.k-tile-empty .k-button-wrapper {
  text-align: center;
}
.k-chart,
.k-gauge,
.k-sparkline,
.k-stockchart {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-chart,
.k-stockchart {
  height: 400px;
}
.k-stockchart,
div.k-chart,
div.k-gauge,
span.k-sparkline {
  background-color: transparent;
}
.k-gauge {
  text-align: left;
  position: relative;
}
.k-baseline-marker {
  zoom: 1;
  *display: inline;
}
.k-chart-tooltip {
  border-radius: 4px;
  padding: 6px;
  white-space: nowrap;
  z-index: 12000;
  line-height: normal;
  background-repeat: repeat-x;
  background-position: 0 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNJREFUeNpi/P//vwMDFQELEP8beQb+HTWQYgP/DHoD/466cAR4edRAyg38P6hLbIAAAwCnWhhVsxvdCAAAAABJRU5ErkJggg==);
  color: #fff;
}
.k-chart-tooltip-inverse {
  color: #000;
}
.k-chart-tooltip table {
  border-spacing: 0;
  border-collapse: collapse;
}
.k-chart-tooltip th {
  width: auto;
  text-align: center;
  padding: 1px;
}
.k-chart-tooltip td {
  width: auto;
  text-align: left;
  padding: 0.1em 0.2em;
}
.k-selector {
  position: absolute;
  -webkit-transform: translateZ(0);
}
.k-selection {
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: #d2d2d2;
  border-bottom: 0;
  height: 100%;
}
.k-selection-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.01);
  filter: alpha(opacity=1);
}
.k-handle {
  background: #d2d2d2;
  width: 7px;
  height: 26px;
  cursor: e-resize;
  z-index: 1;
  border-radius: 6px;
  position: absolute;
}
.k-handle div {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.k-leftHandle {
  left: -4px;
}
.k-rightHandle {
  right: -4px;
}
.k-leftHandle div {
  margin: -20px 0 0 -15px;
  padding: 40px 30px 0 0;
}
.k-leftHandle.k-handle-active div {
  margin-left: -40px;
  padding-right: 55px;
}
.k-rightHandle div {
  margin: -20px 0 0 -15px;
  padding: 40px 0 0 30px;
}
.k-rightHandle.k-handle-active div {
  padding-left: 55px;
}
.k-mask {
  position: absolute;
  height: 100%;
  background-color: #fff;
  filter: alpha(opacity=80);
  opacity: 0.8;
}
.k-border {
  background: #d2d2d2;
  width: 1px;
  height: 100%;
  position: absolute;
}
.k-navigator-hint div {
  position: absolute;
}
.k-navigator-hint .k-scroll {
  position: absolute;
  height: 4px;
  border-radius: 4px;
  background: #d2d2d2;
}
.k-navigator-hint .k-tooltip {
  margin-top: 20px;
  min-width: 160px;
  opacity: 1;
  text-align: center;
  border: 0;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  background: #fff;
}
.k-sparkline,
.k-sparkline span {
  display: inline-block;
  vertical-align: top;
}
.k-sparkline span {
  height: 100%;
  width: 100%;
}
.k-diagram,
.k-map {
  height: 600px;
}
.k-diagram .km-scroll-wrapper,
.k-map .km-scroll-wrapper {
  padding-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.k-diagram .km-scroll-container,
.k-diagram .km-scroll-wrapper,
.k-map .km-scroll-container,
.k-map .km-scroll-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.k-diagram .k-layer,
.k-map .k-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.k-diagram .km-touch-scrollbar,
.k-map .km-touch-scrollbar {
  display: none;
}
.k-map .k-marker {
  position: absolute;
  width: 28px;
  height: 40px;
  margin: -40px 0 0 -14px;
  cursor: pointer;
}
.k-map .k-marker-pin {
  background-position: 0 40px;
}
.k-map .k-marker-pin-target {
  background-position: 0 0;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .k-map .k-marker {
    width: 56px;
    height: 80px;
    margin: -80px 0 0 -28px;
  }
  .k-map .k-marker-pin {
    background-position: 0 80px;
  }
}
.k-map .k-pos-top {
  top: 0;
}
.k-map .k-pos-bottom {
  bottom: 0;
}
.k-map .k-pos-left {
  left: 0;
}
.k-map .k-pos-right {
  right: 0;
}
.k-map-controls {
  position: absolute;
}
.k-map-controls.k-pos-left .k-widget:first-child {
  margin-right: 0;
}
.k-map-controls.k-pos-right .k-widget:first-child {
  margin-left: 0;
}
.k-navigator {
  width: 50px;
  height: 50px;
  margin: 20px;
  border-radius: 80px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.k-pdf-export .k-navigator {
  display: none;
}
.k-navigator > button {
  border-color: transparent;
  background: 0 0;
}
div.k-navigator > .k-button {
  margin: 0;
  padding: 0;
  line-height: 10px;
  border-radius: 16px;
  position: absolute;
  font-size: 1px;
  line-height: 1px;
}
div.k-navigator .k-navigator-n {
  top: 2px;
  left: 50%;
  margin-left: -9px;
}
div.k-navigator .k-navigator-e {
  right: 2px;
  top: 50%;
  margin-top: -9px;
}
div.k-navigator .k-navigator-s {
  bottom: 2px;
  left: 50%;
  margin-left: -9px;
}
div.k-navigator .k-navigator-w {
  left: 2px;
  top: 50%;
  margin-top: -9px;
}
.k-map .k-attribution {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
  padding: 2px 4px;
  z-index: 1000;
}
.k-zoom-control {
  margin: 14px;
  vertical-align: middle;
}
.k-pdf-export .k-zoom-control {
  display: none;
}
.k-button-wrap {
  border-radius: 4px;
  display: inline-block;
}
.k-button-wrap .k-button {
  position: relative;
  font: 700 17px/1.18 monospace;
}
.k-buttons-horizontal :first-child {
  border-radius: 4px 0 0 4px;
}
.k-buttons-horizontal :first-child + .k-zoom-in {
  border-radius: 0;
  margin-left: -1px;
}
.k-buttons-horizontal .k-zoom-out {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}
.k-button-wrap .k-button:hover {
  z-index: 1;
}
.k-buttons-vertical .k-button {
  display: block;
}
.k-buttons-vertical :first-child {
  border-radius: 4px 4px 0 0;
}
.k-buttons-vertical .k-zoom-out {
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}
.k-zoom-text {
  margin: 0;
  width: 4.3em;
  vertical-align: top;
}
.k-rtl .k-buttons-horizontal :first-child {
  border-radius: 0 4px 4px 0;
}
.k-rtl .k-buttons-horizontal :first-child + .k-zoom-in {
  border-radius: 0;
  margin-left: 0;
  margin-right: -1px;
}
.k-rtl .k-buttons-horizontal .k-zoom-out {
  border-radius: 4px 0 0 4px;
  margin-left: 0;
  margin-right: -1px;
}
.k-diagram {
  height: 600px;
}
.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.k-canvas-container {
  width: 100%;
  height: 100%;
}
.k-diagram img {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.k-treemap {
  overflow: hidden;
  height: 400px;
}
.k-treemap-tile {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  margin: -1px 0 0 -1px;
  overflow: hidden;
}
.k-treemap-tile.k-leaf {
  padding: 0.6em;
}
.k-treemap-wrap.k-last > .k-treemap-tile {
  padding: 0.3em;
}
.k-treemap-tile.k-state-hover,
.k-treemap-tile.k-state-hover:hover {
  z-index: 2;
  background-image: none;
}
.k-treemap > .k-treemap-tile {
  position: relative;
  height: 100%;
}
.k-treemap-title {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.42em;
  height: 2.42em;
  padding: 0 0.6em;
  white-space: nowrap;
}
.k-treemap-wrap .k-treemap-title {
  border-width: 0 0 1px;
  border-style: solid;
}
.k-treemap-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.k-treemap-title + .k-treemap-wrap {
  top: 2.42em;
}
.k-treemap-title-vertical {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2.42em;
  line-height: 2.42em;
  overflow: hidden;
  padding: 0.6em 0;
  white-space: nowrap;
}
.k-treemap-title-vertical > div {
  position: absolute;
  top: 0;
  right: 1.23em;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.k-treemap-title-vertical + .k-treemap-wrap {
  left: 2.42em;
}
.km-pane,
.km-pane-wrapper,
.km-root {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  -ms-content-zooming: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}
.km-pane-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.km-pane,
.km-shim {
  font-family: sans-serif;
}
.km-pane {
  overflow-x: hidden;
}
.km-view {
  top: 0;
  left: 0;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  vertical-align: top;
}
.k-ff .km-pane,
.k-ff .km-view {
  overflow: hidden;
}
.k-ff18 .km-pane,
.k-ff18 .km-view,
.k-ff19 .km-pane,
.k-ff19 .km-view,
.k-ff20 .km-pane,
.k-ff20 .km-view,
.k-ff21 .km-pane,
.k-ff21 .km-view {
  position: relative;
}
.k-ff .km-view {
  display: -moz-inline-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.km-content {
  min-height: 1px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-align: stretch;
  display: block;
  width: auto;
  overflow: hidden;
  position: relative;
}
.km-actionsheet > li {
  list-style-type: none;
  padding: inherit 1em;
  line-height: 2em;
}
.km-actionsheet {
  padding: 0;
  margin: 0;
}
.km-shim {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10001;
}
.km-actionsheet-wrapper,
.km-shim .k-animation-container {
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}
.km-shim .k-animation-container {
  width: auto;
}
.km-pane-wrapper .k-grid-column-menu > .km-header,
.km-pane-wrapper .k-grid-edit-form > .km-header,
.km-pane-wrapper .k-grid-filter-menu > .km-header,
.km-pane-wrapper .k-scheduler-edit-form > .km-header {
  border-style: solid;
  border-width: 1px;
  padding: 0.3em 0.6em;
  text-align: center;
  width: auto;
  line-height: 2em;
}
.k-ie .km-pane-wrapper .k-scheduler > .k-scheduler-footer,
.k-ie .km-pane-wrapper .k-scheduler > .k-scheduler-toolbar {
  line-height: 2em;
}
.km-pane-wrapper .k-grid-edit-form .k-multiselect,
.km-pane-wrapper .k-scheduler-edit-form .k-multiselect {
  width: 15em;
}
.km-pane-wrapper .k-grid-edit-form .k-dropdown-wrap,
.km-pane-wrapper .k-scheduler-edit-form .k-dropdown-wrap {
  display: block;
}
.km-pane-wrapper .k-grid-column-menu .k-done,
.km-pane-wrapper .k-grid-edit-form .k-grid-update,
.km-pane-wrapper .k-grid-filter-menu .k-submit,
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-update {
  float: right;
}
.km-pane-wrapper .k-grid-edit-form .k-grid-cancel,
.km-pane-wrapper .k-grid-filter-menu .k-cancel,
.km-pane-wrapper .k-grid-filter-menu .k-i-cancel,
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-cancel {
  float: left;
}
.km-pane-wrapper .k-grid-edit-row .k-textbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-delete,
:not(.km-pane) > .km-shim .km-actionsheet .k-button {
  display: block;
  text-align: center;
}
:not(.km-pane) > .km-shim .km-actionsheet .k-button {
  font-size: 1.4em;
  margin: 0.3em 1em;
}
:not(.km-pane) > .km-shim .km-actionsheet-title {
  text-align: center;
  line-height: 3em;
  margin-bottom: -0.3em;
}
:not(.km-pane) > .km-shim > .k-animation-container {
  margin: 0!important;
  padding: 0!important;
  left: 0 !important;
}
.km-pane-wrapper > div.km-pane {
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
}
.km-pane-wrapper .k-grid-column-menu .km-content > .km-scroll-container,
.km-pane-wrapper .k-grid-edit-form .km-content > .km-scroll-container,
.km-pane-wrapper .k-grid-filter-menu .km-content > .km-scroll-container,
.km-pane-wrapper .k-popup-edit-form .km-content > .km-scroll-container {
  position: absolute;
  width: 100%;
  min-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  width: 74%;
}
.km-pane-wrapper .k-grid-edit-form .k-edit-form-container,
.km-pane-wrapper .k-grid-edit-form .k-popup-edit-form {
  width: auto;
}
.km-pane-wrapper .k-filter-menu .k-button {
  width: 100%;
  margin: 0;
}
.k-grid-mobile {
  border-width: 0;
}
.k-grid-mobile .k-resize-handle-inner {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: -7px;
  width: 17px;
  height: 17px;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
}
.k-grid-mobile .k-resize-handle-inner:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -3px;
  left: 1px;
  width: 6px;
  height: 6px;
  background-position: -5px -53px;
}
.k-grid-mobile .k-resize-handle-inner:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1px;
  width: 6px;
  height: 6px;
  background-position: -5px -21px;
}
.km-pane-wrapper .km-pane * {
  -webkit-background-clip: border-box;
  background-clip: border-box;
}
.km-pane-wrapper .k-mobile-list ul,
.km-pane-wrapper .km-pane .k-mobile-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-radius: 0;
  background: 0 0;
}
.km-pane-wrapper .km-switch {
  top: 50%;
  right: 0.8rem;
  position: absolute;
  margin-top: -1.1rem;
}
.km-pane-wrapper .k-mobile-list .k-state-disabled {
  opacity: 1;
}
.km-pane-wrapper .k-mobile-list .k-state-disabled > * {
  opacity: 0.7;
}
.km-pane-wrapper .k-mobile-list .k-edit-label,
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-item > .k-label,
.km-pane-wrapper .k-mobile-list .k-item > .k-link {
  display: block;
  position: relative;
  list-style-type: none;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5em 0 0.5em 1em;
  font-size: 1em;
}
.km-pane-wrapper .k-edit-form-container,
.km-pane-wrapper .k-scheduler-edit-form .km-scroll-container {
  padding-top: 1em;
  width: 100%;
}
.km-pane-wrapper .k-mobile-list .k-edit-label {
  position: absolute;
  margin: 0;
  float: none;
  clear: none;
  width: 100%;
}
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label label {
  display: block;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.1em 0;
  margin: 0;
}
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label,
.km-pane-wrapper .k-mobile-list .k-item {
  font-size: 1em;
  line-height: 1.6em;
  overflow: hidden;
}
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label {
  width: 100%;
  float: none;
  clear: none;
  min-height: 2.7em;
}
.km-pane-wrapper .k-grid-delete .k-icon,
.km-pane-wrapper .k-grid-edit .k-icon,
.km-pane-wrapper .k-grid-toolbar .k-icon,
.km-pane-wrapper .km-header .k-icon {
  display: none;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  padding: 0.5em 0;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-toolbar {
  padding: 0.3em 0;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-toolbar ul li {
  line-height: 2em;
}
.km-pane-wrapper .k-mobile-list .k-item > * {
  line-height: normal;
}
.km-pane-wrapper .k-mobile-list .k-button-container,
.km-pane-wrapper .k-mobile-list .k-edit-buttons {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5em 1em;
  margin: 0;
}
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child,
.km-pane-wrapper .k-mobile-list .k-filter-help-text > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper .k-mobile-list > ul > li > .k-link {
  display: block;
  padding: 0.2em 1em;
  font-size: 0.95em;
  position: -webkit-sticky;
  margin: 0;
  font-weight: 400;
  line-height: 2em;
  background: 0 0;
  border-top: 1em solid transparent;
}
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3) {
  position: relative;
}
.km-pane-wrapper .k-mobile-list .k-item:first-child {
  border-top: 0;
}
.km-pane-wrapper .k-mobile-list .k-item:last-child {
  border-bottom: 0;
}
.km-pane-wrapper .k-mobile-list .k-item > .k-label,
.km-pane-wrapper .k-mobile-list .k-item > .k-link {
  line-height: inherit;
  text-decoration: none;
  margin: -0.5em 0 -0.5em -1em;
}
.k-check[type=checkbox],
.k-check[type=radio],
.k-mobile-list .k-edit-field [type=checkbox],
.k-mobile-list .k-edit-field [type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
}
.k-mobile-list .k-edit-field [type=checkbox],
.k-mobile-list .k-edit-field [type=radio],
.km-pane-wrapper .k-mobile-list .k-label .k-check,
.km-pane-wrapper .k-mobile-list .k-link .k-check {
  border: 0;
  font-size: inherit;
  width: 13px;
  height: 13px;
  margin: 0.26em 1em 0.26em 0;
}
.k-ie .k-mobile-list .k-edit-field [type=checkbox],
.k-ie .k-mobile-list .k-edit-field [type=radio],
.k-ie .km-pane-wrapper .k-icon,
.k-ie .km-pane-wrapper .k-mobile-list .k-label .k-check,
.k-ie .km-pane-wrapper .k-mobile-list .k-link .k-check {
  font-size: inherit;
  text-indent: -9999px;
  width: 1.01em;
  height: 1em;
}
.km-pane-wrapper .k-column-menu .k-label .k-check {
  height: 16px;
  width: 16px;
}
.km-pane-wrapper .k-edit-field > :not([type=checkbox]):not([type=radio]):not(.k-button),
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper .km-pane .k-mobile-list input:not([type=checkbox]):not([type=radio]),
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .km-pane .k-mobile-list textarea {
  text-indent: 0;
  font-size: 1em;
  line-height: 1.6em;
  vertical-align: middle;
  height: auto;
  padding: 0;
  border: 0;
  margin: 0;
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
.km-pane-wrapper .k-mobile-list .k-widget {
  border: 0;
  border-radius: 0;
}
.k-ie .km-pane-wrapper .k-mobile-list .k-widget {
  height: initial;
}
.km-pane-wrapper .k-mobile-list .k-widget .k-input,
.km-pane-wrapper .k-mobile-list .k-widget .k-state-default {
  border: 0;
  background: 0 0;
}
.km-pane-wrapper .k-edit-field > :not([type=checkbox]):not([type=radio]):not(.k-button),
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper .k-mobile-list select:not([multiple]),
.km-pane-wrapper .k-mobile-list textarea,
.km-pane-wrapper :not(.k-state-default):not(.k-pager-input) > input:not([type=checkbox]):not([type=radio]) {
  width: 80%;
  padding: 0.6em 0;
  margin: -0.5em 0;
}
.km-pane-wrapper .k-mobile-list .k-edit-field > *,
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper .km-pane .k-mobile-list input,
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .km-pane .k-mobile-list textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  float: right;
  z-index: 1;
  position: relative;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-space-right {
  padding: 10px;
  border-radius: 0;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-space-right > input {
  float: none;
  width: 100%;
  padding: 1px 0;
  margin: 0;
  border-radius: 3px;
  text-indent: 30px;
  border-width: 1px;
  border-style: solid;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-font-icon {
  position: absolute;
  left: 20px;
  z-index: 1;
}
.km-pane-wrapper .k-scheduler-views {
  width: 18em;
}
.km-pane-wrapper .k-mobile-list .k-edit-field.k-scheduler-toolbar {
  background: 0 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0.5em 1em;
}
.km-pane-wrapper #recurrence .k-scheduler-navigation {
  width: 100%;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-navigation,
.km-pane-wrapper .k-scheduler-views {
  display: table;
  table-layout: fixed;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-navigation li,
.km-pane-wrapper .k-scheduler-views li {
  display: table-cell;
  text-align: center;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check {
  margin: 0;
  padding-left: 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check:first-child {
  margin-top: -0.5em;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check:last-child {
  margin-bottom: -0.5em;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-timezones .k-edit-field label.k-check {
  text-indent: 1em;
}
.km-pane-wrapper .k-mobile-list .k-edit-field > .k-button {
  margin-left: 20%;
  float: left;
}
.km-pane-wrapper .k-mobile-list .k-dropdown-wrap,
.km-pane-wrapper .k-mobile-list .k-numeric-wrap,
.km-pane-wrapper .k-mobile-list .k-picker-wrap {
  position: static;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.km-pane-wrapper .k-mobile-list .k-datepicker .k-select,
.km-pane-wrapper .k-mobile-list .k-datetimepicker .k-select,
.km-pane-wrapper .k-mobile-list .k-numerictextbox .k-select {
  position: absolute;
  top: 0;
  right: 0;
  line-height: normal;
}
.km-pane-wrapper .k-mobile-list .k-datepicker .k-select:before,
.km-pane-wrapper .k-mobile-list .k-datetimepicker .k-select:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
.km-pane-wrapper .k-mobile-list .k-numerictextbox .k-link {
  height: 50%;
}
.km-pane-wrapper .k-edit-form-container .k-button,
.km-pane-wrapper .k-grid .k-button {
  margin: 0;
}
.km-pane-wrapper .k-edit-form-container .k-button + .k-button,
.km-pane-wrapper .k-grid .k-button + .k-button {
  margin: 0 0 0 0.18em;
}
.km-pane-wrapper .k-pager-numbers .k-link,
.km-pane-wrapper .k-pager-numbers .k-state-selected,
.km-pane-wrapper .k-pager-wrap > .k-link {
  width: 2.4em;
  height: 2.4em;
  line-height: 2.1em;
  border-radius: 2em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.km-pane-wrapper .k-pager-numbers .k-link,
.km-pane-wrapper .k-pager-numbers .k-state-selected {
  width: auto;
  line-height: 2.2em;
  padding: 0 0.86em;
  min-width: 0.7em;
}
.km-pane-wrapper .k-pager-wrap {
  line-height: 2.4em;
}
@media all and (max-width: 1024px) {
  .km-pane-wrapper .k-pager-nav + .k-pager-nav ~ .k-pager-nav {
    position: absolute;
    right: 0.3em;
    top: 0.3em;
  }
  .km-pane-wrapper .k-pager-nav:first-child + .k-pager-nav + .k-pager-nav,
  .km-pane-wrapper .k-pager-wrap .k-pager-numbers + .k-pager-nav {
    right: 3em;
  }
  .km-pane-wrapper .k-pager-info,
  .km-pane-wrapper .k-pager-refresh {
    display: none;
  }
}
@media all and (max-width: 699px), (-ms-high-contrast: active) and (-ms-high-contrast: none) and (max-width: 800px) {
  .km-pane-wrapper .k-edit-field > :not([type=checkbox]):not([type=radio]):not(.k-button),
  .km-pane-wrapper .k-mobile-list .k-widget,
  .km-pane-wrapper .k-mobile-list select:not([multiple]),
  .km-pane-wrapper .k-mobile-list textarea,
  .km-pane-wrapper :not(.k-state-default):not(.k-pager-input) > input:not([type=checkbox]):not([type=radio]) {
    width: 50%;
  }
  .km-pane-wrapper .k-mobile-list .k-edit-field > .k-button {
    margin-left: 50%;
  }
  .km-pane-wrapper .k-mobile-list .k-edit-field > .k-timezone-button {
    margin-left: 1em;
  }
  .km-pane-wrapper .k-nav-today a {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }
  .km-pane-wrapper li.k-nav-current {
    margin-left: 0;
    margin-right: 0;
  }
  .km-pane-wrapper .k-pager-wrap {
    position: relative;
  }
  .km-pane-wrapper .k-pager-numbers {
    width: auto;
    display: block;
    overflow: hidden;
    margin-right: 5.5em;
    float: none;
    text-overflow: ellipsis;
    height: 2.4em;
    text-align: center;
  }
  .km-pane-wrapper .k-pager-numbers li {
    float: none;
    display: inline-block;
  }
  .km-pane-wrapper .k-pager-nav {
    float: left;
  }
}
.km-pane-wrapper .k-mobile-list .k-edit-field > * > select:not([multiple]),
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check,
.km-pane-wrapper .k-mobile-list .k-scheduler-timezones .k-edit-field label.k-check {
  width: 100%;
}
.km-scroll-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-margin-collapse: separate;
  -webkit-transform: translatez(0);
}
.k-widget .km-scroll-wrapper {
  position: relative;
  padding-bottom: 0;
}
.km-touch-scrollbar {
  position: absolute;
  visibility: hidden;
  z-index: 200000;
  height: 0.3em;
  width: 0.3em;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  -webkit-transition: "opacity 0.3s linear";
  transition: "opacity 0.3s linear";
}
.km-vertical-scrollbar {
  height: 100%;
  right: 2px;
  top: 2px;
}
.km-horizontal-scrollbar {
  width: 100%;
  left: 2px;
  bottom: 2px;
}
@media only screen and (max-width: 1024px) {
  .k-scheduler-toolbar > ul.k-scheduler-views {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10000;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views {
    right: auto;
    left: 6px;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view) {
    display: none;
  }
  .k-scheduler-toolbar li.k-nav-current .k-lg-date-format {
    display: none;
  }
  .k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
    display: inline;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    display: block;
    border-width: 1px;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    text-align: left;
    padding-left: 1em;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    display: block;
    position: relative;
    padding-right: 2.5em;
    padding-left: 1em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    padding-left: 0;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.6em;
    right: 0.333em;
    width: 1.333em;
    height: 1.333em;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li {
    display: block;
    border: 0;
    border-radius: 0;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border: 1px solid #c5c5c5;
    background-color: #fff;
    background-image: none;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    text-align: left;
  }
}
.k-fx-end .k-fx-current,
.k-fx-end .k-fx-next {
  -webkit-transition: all 350ms ease-out;
  transition: all 350ms ease-out;
}
.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}
.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}
.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}
.k-fx-zoom.k-fx-start .k-fx-next {
  -webkit-transform: scale(0) !important;
  -ms-transform: scale(0) !important;
  transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next,
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: scale(0) !important;
  -ms-transform: scale(0) !important;
  transform: scale(0) !important;
}
.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .km-content,
.k-fx-slide.k-fx-end .k-fx-current .km-footer,
.k-fx-slide.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-content,
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-next .km-header {
  -webkit-transition: all 350ms ease-out;
  transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .km-footer,
.k-fx-slide.k-fx-start .k-fx-next .km-header {
  will-change: opacity;
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-next .km-header {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header {
  will-change: opacity;
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header {
  opacity: 1;
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
  -ms-transform: translatex(0);
  transform: translatex(0);
}
.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next,
.k-fx.k-fx-overlay.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  -webkit-transform: translatey(100%);
  -ms-transform: translatey(100%);
  transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  -webkit-transform: translatey(-100%);
  -ms-transform: translatey(-100%);
  transform: translatey(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current,
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -ms-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  -webkit-transform: translatey(100%);
  -ms-transform: translatey(100%);
  transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  -webkit-transform: translatey(-100%);
  -ms-transform: translatey(-100%);
  transform: translatey(-100%);
}
.k-virtual-wrap {
  position: relative;
}
.k-virtual-wrap .k-list.k-virtual-list {
  height: auto;
}
.k-virtual-content {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
}
.k-virtual-list > .k-virtual-content {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.k-virtual-option-label {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.k-virtual-wrap > .k-virtual-header {
  text-align: right;
}
.k-popup .k-item.k-first {
  position: relative;
}
.k-virtual-content > .k-virtual-list > .k-virtual-item {
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
}
.k-popup .k-list .k-item > .k-group,
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  text-transform: uppercase;
  font-size: 0.857em;
}
.k-popup .k-list .k-item > .k-group {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.5em;
  line-height: 1.8;
}
.k-popup .k-state-hover > .k-group {
  right: -1px;
}
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first,
.k-virtual-item.k-first {
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 0;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  text-align: right;
}
@font-face {
  font-family: KendoUIGlyphs;
  src: url(fonts/glyphs/KendoUIGlyphs.eot?wi2r8m);
  src: url(fonts/glyphs/KendoUIGlyphs.eot?wi2r8m?#iefix) format('embedded-opentype'), url(fonts/glyphs/KendoUIGlyphs.ttf?wi2r8m) format('truetype'), url(fonts/glyphs/KendoUIGlyphs.woff?wi2r8m) format('woff'), url(fonts/glyphs/KendoUIGlyphs.svg?wi2r8m#KendoUIGlyphs) format('svg');
  font-weight: 400;
  font-style: normal;
}
.k-i-arrow-n:before {
  content: "\e600";
}
.k-i-arrow-e:before {
  content: "\e601";
}
.k-i-arrow-s:before {
  content: "\e602";
}
.k-i-arrow-w:before {
  content: "\e603";
}
.k-i-seek-n:before {
  content: "\e604";
}
.k-i-seek-e:before {
  content: "\e605";
}
.k-i-seek-s:before {
  content: "\e606";
}
.k-i-seek-w:before {
  content: "\e607";
}
.k-i-sarrow-n:before {
  content: "\e608";
}
.k-i-sarrow-e:before {
  content: "\e609";
}
.k-i-sarrow-s:before {
  content: "\e60a";
}
.k-i-sarrow-w:before {
  content: "\e60b";
}
.k-i-expand-n:before {
  content: "\e60c";
}
.k-i-expand-e:before {
  content: "\e60d";
}
.k-i-expand-s:before {
  content: "\e60e";
}
.k-i-expand-w:before {
  content: "\e60f";
}
.k-i-collapse-ne:before {
  content: "\e610";
}
.k-i-collapse-se:before {
  content: "\e611";
}
.k-i-collapse-sw:before {
  content: "\e612";
}
.k-i-collapse-nw:before {
  content: "\e613";
}
.k-i-resize-ne:before {
  content: "\e614";
}
.k-i-resize-se:before {
  content: "\e615";
}
.k-i-resize-sw:before {
  content: "\e616";
}
.k-i-resize-nw:before {
  content: "\e617";
}
.k-i-arrowhead-n:before {
  content: "\e618";
}
.k-i-arrowhead-e:before {
  content: "\e619";
}
.k-i-arrowhead-s:before {
  content: "\e61a";
}
.k-i-arrowhead-w:before {
  content: "\e61b";
}
.k-i-pencil:before {
  content: "\e61c";
}
.k-i-x:before {
  content: "\e61d";
}
.k-i-checkmark:before {
  content: "\e61e";
}
.k-i-deny:before {
  content: "\e61f";
}
.k-i-trash:before {
  content: "\e620";
}
.k-i-plus:before {
  content: "\e621";
}
.k-i-splus:before {
  content: "\e622";
}
.k-i-minus:before {
  content: "\e623";
}
.k-i-sminus:before {
  content: "\e624";
}
.k-i-filter:before {
  content: "\e625";
}
.k-i-filter-clear:before {
  content: "\e626";
}
.k-i-refresh:before {
  content: "\e627";
}
.k-i-refresh-clear:before {
  content: "\e628";
}
.k-i-restore:before {
  content: "\e629";
}
.k-i-maximize:before {
  content: "\e62a";
}
.k-i-minimize:before {
  content: "\e62b";
}
.k-i-pin:before {
  content: "\e62c";
}
.k-i-unpin:before {
  content: "\e62d";
}
.k-i-calendar:before {
  content: "\e62e";
}
.k-i-clock:before {
  content: "\e62f";
}
.k-i-search:before {
  content: "\e630";
}
.k-i-zoom-in:before {
  content: "\e631";
}
.k-i-zoom-out:before {
  content: "\e632";
}
.k-i-print:before {
  content: "\e633";
}
.k-i-folder-add:before {
  content: "\e634";
}
.k-i-folder-up:before {
  content: "\e635";
}
.k-i-folder-open:before {
  content: "\e634";
}
.k-i-insert-image:before {
  content: "\e636";
}
.k-i-image:before {
  content: "\e637";
}
.k-i-insert-file:before {
  content: "\e638";
}
.k-i-file:before {
  content: "\e639";
}
.k-i-files:before {
  content: "\e63a";
}
.k-i-pdf:before {
  content: "\e63b";
}
.k-i-pdfa:before {
  content: "\e68d";
}
.k-i-xls:before {
  content: "\e63c";
}
.k-i-xlsa:before {
  content: "\e63d";
}
.k-i-lock:before {
  content: "\e63e";
}
.k-i-unlock:before {
  content: "\e63f";
}
.k-i-rows:before {
  content: "\e640";
}
.k-i-columns:before {
  content: "\e641";
}
.k-i-hamburger:before {
  content: "\e642";
}
.k-i-vbars:before {
  content: "\e643";
}
.k-i-hbars:before {
  content: "\e644";
}
.k-i-move:before {
  content: "\e645";
}
.k-i-group:before {
  content: "\e646";
}
.k-i-ungroup:before {
  content: "\e647";
}
.k-i-dimension:before {
  content: "\e648";
}
.k-i-connector:before {
  content: "\e649";
}
.k-i-kpi:before {
  content: "\e64a";
}
.k-i-undo:before {
  content: "\e64b";
}
.k-i-redo:before {
  content: "\e64c";
}
.k-i-undo-large:before {
  content: "\e64d";
}
.k-i-redo-large:before {
  content: "\e64e";
}
.k-i-rotate-ccw:before {
  content: "\e64f";
}
.k-i-rotate-cw:before {
  content: "\e650";
}
.k-i-cut:before {
  content: "\e651";
}
.k-i-copy:before {
  content: "\e652";
}
.k-i-paste:before {
  content: "\e653";
}
.k-i-bold:before {
  content: "\e654";
}
.k-i-italic:before {
  content: "\e655";
}
.k-i-underline:before {
  content: "\e656";
}
.k-i-strike-through:before {
  content: "\e657";
}
.k-i-text:before {
  content: "\e658";
}
.k-i-font-size:before {
  content: "\e68e";
}
.k-i-font-family:before {
  content: "\e68f";
}
.k-i-fx:before {
  content: "\e659";
}
.k-i-subscript:before {
  content: "\e65a";
}
.k-i-superscript:before {
  content: "\e65b";
}
.k-i-background:before {
  content: "\e65c";
}
.k-i-sum:before {
  content: "\e65d";
}
.k-i-increase-decimal:before {
  content: "\e65e";
}
.k-i-decrease-decimal:before {
  content: "\e65f";
}
.k-i-justify-left:before {
  content: "\e660";
}
.k-i-justify-center:before {
  content: "\e661";
}
.k-i-justify-right:before {
  content: "\e662";
}
.k-i-justify-full:before {
  content: "\e663";
}
.k-i-justify-clear:before {
  content: "\e664";
}
.k-i-align-top:before {
  content: "\e665";
}
.k-i-align-middle:before {
  content: "\e666";
}
.k-i-align-bottom:before {
  content: "\e667";
}
.k-i-indent:before {
  content: "\e668";
}
.k-i-outdent:before {
  content: "\e669";
}
.k-i-insert-n:before {
  content: "\e66a";
}
.k-i-insert-m:before {
  content: "\e66b";
}
.k-i-insert-s:before {
  content: "\e66c";
}
.k-i-insert-unordered-list:before {
  content: "\e66d";
}
.k-i-insert-ordered-list:before {
  content: "\e66e";
}
.k-i-sort-asc:before {
  content: "\e66f";
}
.k-i-sort-desc:before {
  content: "\e670";
}
.k-i-unsort:before {
  content: "\e671";
}
.k-i-hyperlink:before {
  content: "\e672";
}
.k-i-hyperlink-remove:before {
  content: "\e673";
}
.k-i-clearformat:before {
  content: "\e674";
}
.k-i-html:before {
  content: "\e675";
}
.k-i-exception:before {
  content: "\e676";
}
.k-i-custom:before {
  content: "\e677";
}
.k-i-cog:before {
  content: "\e678";
}
.k-i-create-table:before {
  content: "\e679";
}
.k-i-add-column-left:before {
  content: "\e67a";
}
.k-i-add-column-right:before {
  content: "\e67b";
}
.k-i-delete-column:before {
  content: "\e67c";
}
.k-i-add-row-above:before {
  content: "\e67d";
}
.k-i-add-row-below:before {
  content: "\e67e";
}
.k-i-delete-row:before {
  content: "\e67f";
}
.k-i-merge-cells:before {
  content: "\e680";
}
.k-i-normal-layout:before {
  content: "\e681";
}
.k-i-page-layout:before {
  content: "\e682";
}
.k-i-all-borders:before {
  content: "\e683";
}
.k-i-inside-borders:before {
  content: "\e684";
}
.k-i-inside-horizontal-borders:before {
  content: "\e685";
}
.k-i-inside-vertical-borders:before {
  content: "\e686";
}
.k-i-outside-borders:before {
  content: "\e687";
}
.k-i-top-border:before {
  content: "\e688";
}
.k-i-right-border:before {
  content: "\e689";
}
.k-i-bottom-border:before {
  content: "\e68a";
}
.k-i-left-border:before {
  content: "\e68b";
}
.k-i-no-borders:before {
  content: "\e68c";
}
.k-i-merge-horizontally:before {
  content: "\e690";
}
.k-i-merge-vertically:before {
  content: "\e691";
}
.k-i-text-wrap:before {
  content: "\e692";
}
.k-i-dollar:before {
  content: "\e693";
}
.k-i-percent:before {
  content: "\e694";
}
.k-i-freeze-col:before {
  content: "\e695";
}
.k-i-freeze-row:before {
  content: "\e696";
}
.k-i-freeze-panes:before {
  content: "\e697";
}
.k-i-format-number:before {
  content: "\e698";
}
.k-i-reset-color:before {
  content: "\e900";
}
.k-i-file-horizontal:before {
  content: "\e901";
}
.k-i-folder:before {
  content: "\e902";
}
.k-i-folder-open:before {
  content: "\e903";
}
.k-i-table-align-top-left:before {
  content: "\e904";
}
.k-i-table-align-top-center:before {
  content: "\e905";
}
.k-i-table-align-top-right:before {
  content: "\e906";
}
.k-i-table-align-middle-left:before {
  content: "\e907";
}
.k-i-table-align-middle-center:before {
  content: "\e908";
}
.k-i-table-align-middle-right:before {
  content: "\e909";
}
.k-i-table-align-bottom-left:before {
  content: "\e90a";
}
.k-i-table-align-bottom-center:before {
  content: "\e90b";
}
.k-i-table-align-bottom-right:before {
  content: "\e90c";
}
.k-i-table-align-remove:before {
  content: "\e90d";
}
.k-i-fullscreen-enter:before {
  content: "\e90e";
}
.k-i-fullscreen-exit:before {
  content: "\e90f";
}
.k-i-volume-low:before {
  content: "\e910";
}
.k-i-volume-high:before {
  content: "\e911";
}
.k-i-volume-mute:before {
  content: "\e912";
}
.k-i-play:before {
  content: "\e913";
}
.k-i-pause:before {
  content: "\e914";
}
.k-i-stop:before {
  content: "\e915";
}
.k-i-HD:before {
  content: "\e916";
}
.k-i-subtitles:before {
  content: "\e917";
}
.k-i-playlist:before {
  content: "\e918";
}
.k-spreadsheet {
  width: 800px;
  height: 600px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  cursor: default;
}
.k-spreadsheet .k-spreadsheet-pane {
  padding-right: 1px;
  position: absolute;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.k-spreadsheet .k-spreadsheet-pane.k-top {
  border-top-width: 0;
}
.k-spreadsheet .k-spreadsheet-pane.k-left {
  border-left-width: 0;
}
.k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell {
  position: absolute;
  white-space: pre;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  padding: 1px;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.k-spreadsheet .k-spreadsheet-haxis,
.k-spreadsheet .k-spreadsheet-vaxis {
  position: absolute;
  border-style: solid;
}
.k-spreadsheet .k-spreadsheet-vaxis {
  top: 0;
  border-width: 0 0 0 1px;
}
.k-spreadsheet .k-spreadsheet-haxis {
  left: 0;
  border-width: 1px 0 0;
}
.k-spreadsheet .k-filter-range,
.k-spreadsheet .k-spreadsheet-selection {
  border-width: 1px;
  border-style: solid;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.k-spreadsheet .k-spreadsheet-active-cell {
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.k-spreadsheet .k-spreadsheet-action-bar {
  border-width: 0 0 1px;
  border-style: solid;
  position: relative;
  font-size: 12px;
  font-family: Arial, Verdana, sans-serif;
  display: table;
  width: 100%;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar,
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor {
  display: table-cell;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor {
  position: relative;
  vertical-align: top;
  border-width: 0 1px 0 0;
  border-style: solid;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox {
  width: 9.5em;
  border-radius: 0;
  border-color: transparent;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap {
  border-radius: 0;
  border-color: transparent;
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap .k-input {
  border-radius: 0;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap .k-select {
  border-radius: 0;
  margin: -1px -1px -1px 0;
  padding: 1px 1px 1px 0;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar {
  width: 100%;
  padding-left: 31px;
  height: 1.65em;
  vertical-align: middle;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar::before {
  background-image: none;
  font-family: KendoUIGlyphs;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  font-size: 1.3em;
  line-height: 1;
  opacity: 1;
  text-indent: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e659";
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-width: 0 1px 0 0;
  border-style: solid;
  width: 24px;
  margin-left: -24px;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-input {
  overflow: hidden;
  height: 100%;
  line-height: 1.65em;
  padding: 0.177em 0;
  text-indent: 0.33em;
  border: 0;
  margin: 0;
}
.k-spreadsheet .k-spreadsheet-formula-input {
  outline: 0;
  border-width: 0;
  height: 26px;
  line-height: 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: pre;
  padding: 3px 0.5em 0;
}
.k-spreadsheet .k-spreadsheet-cell-editor {
  position: absolute;
  display: none;
  padding: 0 3px;
  line-height: 20px;
  z-index: 2000;
  overflow: hidden;
}
.k-spreadsheet > .k-spreadsheet-view {
  position: relative;
  font-size: 12px;
  font-family: Arial, Verdana, sans-serif;
}
.k-spreadsheet .k-tabstrip-wrapper {
  position: relative;
  line-height: 1.7em;
}
.k-spreadsheet .k-tabstrip-wrapper .k-tabstrip-items {
  padding: 0.3em 0 0;
}
.k-spreadsheet .k-spreadsheet-quick-access-toolbar {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 0.3em 0.3em 0;
}
.k-spreadsheet .k-spreadsheet-quick-access-toolbar .k-button {
  padding: 0.3em;
  line-height: 1.4em;
}
.k-spreadsheet .k-filter-wrapper,
.k-spreadsheet .k-merged-cells-wrapper,
.k-spreadsheet .k-selection-wrapper,
.k-spreadsheet .k-spreadsheet-row-header {
  position: relative;
}
.k-spreadsheet .k-spreadsheet-column-header {
  position: absolute;
}
.k-spreadsheet .k-spreadsheet-sheets-bar {
  border-width: 1px 0 0;
}
.k-spreadsheet .k-vertical-align-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.k-spreadsheet .k-vertical-align-bottom {
  position: relative;
  top: 100%;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.k-spreadsheet .k-dirty {
  position: absolute;
  top: 0;
  right: 0;
  margin: -2px 0 0 -7px;
  border-width: 4px;
  border-color: red red transparent transparent;
}
.k-spreadsheet .k-single-selection::after {
  content: " ";
  height: 6px;
  width: 6px;
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  margin-bottom: -5px;
  margin-right: -5px;
  border-width: 1px;
  border-style: solid;
  z-index: 100;
  cursor: crosshair;
}
.k-spreadsheet .k-spreadsheet-edit-container .k-single-selection::after {
  display: none;
}
.k-spreadsheet .k-auto-fill,
.k-spreadsheet .k-auto-fill-punch {
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.k-spreadsheet .k-auto-fill {
  border-width: 1px;
  border-style: solid;
  cursor: crosshair;
}
.k-spreadsheet .k-auto-fill-wrapper {
  position: relative;
}
.k-spreadsheet-names-popup .k-item {
  position: relative;
}
.k-spreadsheet-names-popup .k-icon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.7;
  cursor: pointer;
}
.k-spreadsheet-names-popup .k-icon:hover {
  opacity: 1;
}
.k-horizontal-resize {
  cursor: col-resize;
}
.k-vertical-resize {
  cursor: row-resize;
}
.k-merged-cells-wrapper,
.k-selection-wrapper,
.k-spreadsheet-active-cell,
.k-spreadsheet-data {
  cursor: cell;
}
.k-horizontal-resize .k-merged-cells-wrapper,
.k-horizontal-resize .k-selection-wrapper,
.k-horizontal-resize .k-spreadsheet-active-cell,
.k-horizontal-resize .k-spreadsheet-data {
  cursor: col-resize;
}
.k-vertical-resize .k-merged-cells-wrapper,
.k-vertical-resize .k-selection-wrapper,
.k-vertical-resize .k-spreadsheet-active-cell,
.k-vertical-resize .k-spreadsheet-data {
  cursor: row-resize;
}
.k-font-icon,
.k-font-icon.k-icon,
.k-font-icon.k-tool-icon {
  font-family: KendoUIGlyphs;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  font-size: 1.3em;
  line-height: 1;
  opacity: 1;
  text-indent: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: none;
  font-size: 16px;
}
.k-spreadsheet .k-colorpicker .k-tool-icon {
  overflow: visible;
}
.k-button.k-custom-color,
.k-button.k-reset-color {
  display: block;
  width: 100%;
  border-radius: 0;
  border: none;
  text-align: left;
  line-height: 2em;
}
.k-spreadsheet-colorpicker .k-colorpalette {
  border: 1px solid #dbdbdb;
  border-left: none;
  border-right: none;
  padding: 0.4em;
}
.k-spreadsheet-filter {
  position: absolute;
  cursor: pointer;
}
.k-spreadsheet-sample {
  float: right;
}
.k-spreadsheet-clipboard,
.k-spreadsheet-clipboard-paste {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 0;
  width: 1px;
  height: 1px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
textarea.k-spreadsheet-clipboard {
  position: fixed;
}
.k-spreadsheet-top-corner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  border-width: 0 1px 1px 0;
  border-style: solid;
}
.k-spreadsheet-top-corner:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  border-width: 6px;
  border-style: solid;
}
.k-spreadsheet-scroller {
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 1;
}
.k-spreadsheet-fixed-container {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.k-spreadsheet-view-size {
  position: relative;
}
.k-spreadsheet-column-header,
.k-spreadsheet-row-header {
  text-align: center;
}
.k-spreadsheet-column-header .k-spreadsheet-cell,
.k-spreadsheet-row-header .k-spreadsheet-cell {
  border-style: solid;
  border-width: 0 1px 1px 0;
}
div.k-spreadsheet-window {
  padding: 1.167em 0.75em 0.75em;
}
.k-spreadsheet-window .k-edit-form-container {
  width: 100%;
}
.k-spreadsheet-window .k-root-tabs {
  border-width: 0;
  margin: -1em -1em 0;
  padding: 0.5em 1em 0;
}
.k-spreadsheet-window .k-root-tabs .k-loading {
  display: none;
}
.k-spreadsheet-window .k-list-wrapper {
  padding: 0.15em 0;
  border-width: 1px;
  border-style: solid;
  height: 190px;
}
.k-spreadsheet-window .k-list .k-item {
  border-radius: 0;
  padding: 0.5em 0.6em 0.4em 0.6em;
  cursor: pointer;
  line-height: 1.1em;
}
.k-spreadsheet-window .k-format-filter {
  width: 100%;
}
.k-spreadsheet-window .k-format-filter + .k-group-header + .k-list-wrapper {
  margin-top: 1em;
}
.k-spreadsheet-window .k-action-buttons {
  padding: 1em;
}
.k-spreadsheet-window .k-edit-label {
  width: 27%;
  margin: 0 0 0 0.75em;
}
.k-spreadsheet-window .k-edit-field {
  width: 67%;
  margin: 0 0.917em 0 0;
}
.k-spreadsheet-window .k-edit-field > .k-radio-label {
  margin-right: 1.25em;
  vertical-align: baseline;
}
.k-spreadsheet-window .k-edit-field > .k-radio-label:after,
.k-spreadsheet-window .k-edit-field > .k-radio-label:before {
  margin-top: -1px;
}
.k-spreadsheet-window .k-edit-field,
.k-spreadsheet-window .k-edit-label {
  padding: 0;
  line-height: 3em;
}
.k-spreadsheet-window .k-edit-field .k-dropdown,
.k-spreadsheet-window .k-edit-field .k-textbox {
  width: 100%;
}
.k-spreadsheet-window .export-config {
  border-width: 1px 0 0;
  border-style: solid;
  padding: 1em;
  margin: 0 -1em;
}
.k-spreadsheet-window .export-config .k-edit-field {
  width: 40%;
  float: left;
  margin-left: 30px;
}
.k-spreadsheet-window .k-edit-field > input[type=radio] {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
}
.k-spreadsheet-window .k-edit-field > .k-orientation-label {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-width: 1px;
  border-style: solid;
}
.k-spreadsheet-window .k-edit-field > .k-orientation-label:before {
  font-family: KendoUIGlyphs;
  font-size: 16px;
}
.k-spreadsheet-window .k-orientation-portrait-label:before {
  content: "\e639";
}
.k-spreadsheet-window .k-orientation-landscape-label:before {
  content: "\e901";
}
.k-spreadsheet-window .k-page-orientation {
  position: absolute;
  right: 2em;
  top: 25%;
  width: 64px;
  height: 92px;
  border-width: 1px;
  border-style: solid;
}
.k-spreadsheet-window .k-page-orientation:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 3px;
  border-style: solid;
  margin-top: -1px;
  margin-right: -1px;
}
.k-spreadsheet-window .k-page-landscape {
  top: 29%;
  width: 92px;
  height: 64px;
}
.k-spreadsheet-window .k-margins-horizontal {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 62px;
  border-width: 1px 0;
  border-style: solid;
}
.k-spreadsheet-window .k-margins-vertical {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 34px;
  height: 100%;
  border-width: 0 1px;
  border-style: solid;
}
.k-spreadsheet-window .k-page-landscape .k-margins-horizontal {
  height: 34px;
}
.k-spreadsheet-window .k-page-landscape .k-margins-vertical {
  width: 62px;
}
.k-spreadsheet-window .export-config:after,
.k-spreadsheet-window > div:after {
  content: " ";
  display: block;
  clear: both;
  height: 0;
}
.k-spreadsheet-format-cells .k-spreadsheet-preview {
  font-weight: 700;
  text-align: center;
  font-size: 1.16em;
  line-height: 3.64em;
  margin: 0 -0.863em 0.72em;
  border-style: solid;
  border-width: 1px 0;
}
.k-spreadsheet-border-palette {
  width: 153px;
}
.k-spreadsheet-border-palette .k-spreadsheet-border-type-palette .k-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 20%;
}
.k-spreadsheet-border-palette .k-colorpalette {
  vertical-align: bottom;
}
.k-spreadsheet-popup .k-separator {
  width: 1px;
  height: 1.8em;
  vertical-align: middle;
  display: inline-block;
}
.k-spreadsheet-popup .k-colorpalette {
  vertical-align: top;
}
.k-spreadsheet-popup .k-button.k-button-icon {
  padding: 0.6em;
  border-width: 0;
  border-radius: 0;
}
.k-spreadsheet-popup .k-button.k-button-icontext {
  display: block;
  text-align: left;
  text-transform: initial;
  padding: 0.3em 1.5em 0.3em 0.9em;
  border-width: 0;
  border-radius: 0;
}
.k-spreadsheet-filter-menu {
  width: 280px;
}
.k-spreadsheet-filter-menu > .k-menu {
  border-width: 0;
}
.k-spreadsheet-filter-menu > .k-menu .k-link {
  padding-left: 26px;
}
.k-spreadsheet-filter-menu > .k-menu .k-icon.k-font-icon {
  margin-left: -26px;
  width: 26px;
}
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
  height: 200px;
  overflow-y: scroll;
  overflow-x: auto;
  border-width: 1px;
  border-style: solid;
}
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper .k-treeview {
  overflow: visible;
  padding: 6px 7px;
}
.k-spreadsheet-filter-menu .k-details {
  border-top-width: 1px;
  border-top-style: solid;
  padding: 4px 0;
}
.k-spreadsheet-filter-menu .k-details-summary {
  cursor: pointer;
  line-height: 26px;
}
.k-spreadsheet-filter-menu .k-details-summary .k-icon {
  margin: 0 5px;
}
.k-spreadsheet-filter-menu .k-details-content {
  padding: 0 8px 0 26px;
}
.k-spreadsheet-filter-menu .k-details-content > .k-textbox,
.k-spreadsheet-filter-menu .k-details-content > .k-widget {
  width: 100%;
  margin-bottom: 3px;
}
.k-spreadsheet-filter-menu .k-details-content .k-space-right {
  background-image: none;
}
.k-spreadsheet-filter-menu .k-details-content .k-filter-and {
  width: 75px;
  margin: 8px 0;
}
.k-spreadsheet-filter-menu .k-action-buttons {
  border-top-width: 0;
  margin: 8px;
  padding: 0;
  position: static;
}
.k-resize-handle,
.k-resize-hint {
  position: absolute;
}
.k-resize-hint-handle {
  width: 100%;
  height: 20px;
}
.k-resize-hint-marker {
  width: 2px;
  height: 100%;
  margin: 0 auto;
}
.k-resize-hint-vertical .k-resize-hint-handle {
  height: 100%;
  width: 20px;
  float: left;
}
.k-resize-hint-vertical .k-resize-hint-marker {
  height: 2px;
  width: 100%;
}
.k-button.k-spreadsheet-sheets-bar-add,
.k-spreadsheet-quick-access-toolbar .k-button {
  border-color: transparent;
  background-color: transparent;
  background-image: none;
}
.k-spreadsheet-tabstrip {
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}
.k-spreadsheet-tabstrip > .k-content {
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  margin: 0;
  padding: 0;
}
.k-spreadsheet-tabstrip .k-loading {
  display: none;
}
.k-spreadsheet-toolbar.k-toolbar {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}
.k-spreadsheet-toolbar.k-toolbar .k-overflow-anchor + * {
  margin-left: 0.3em;
}
.k-spreadsheet-toolbar.k-toolbar > .k-button-group {
  border-width: 0;
}
.k-spreadsheet-toolbar .k-button,
.k-spreadsheet-toolbar > .k-button-group,
.k-spreadsheet-toolbar > .k-widget,
.k-spreadsheet-toolbar > .k-widget .k-state-default:not(.k-state-hover):not(.k-state-active) {
  margin: 0;
  border-color: transparent;
  background-color: transparent;
  background-image: none;
}
.k-spreadsheet-toolbar > .k-widget[data-property=fontSize] {
  width: 75px;
}
.k-spreadsheet-toolbar > .k-widget[data-property=format] {
  width: 100px;
}
.k-spreadsheet-toolbar > .k-widget[data-property=fontFamily] {
  width: 130px;
}
.k-spreadsheet-toolbar > .k-combobox .k-state-default:not(.k-state-hover):not(.k-state-active) .k-input {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-spreadsheet-toolbar > .k-widget .k-state-default:not(.k-state-hover):not(.k-state-active) .k-select {
  border-color: transparent;
}
.k-spreadsheet-toolbar .k-separator {
  margin: 0 3px;
  width: 0;
  overflow: hidden;
  height: 1.8em;
  vertical-align: middle;
  display: inline-block;
}
.k-spreadsheet-formula-input > .k-syntax-bool.k-syntax-at-point,
.k-spreadsheet-formula-input > .k-syntax-func.k-syntax-at-point,
.k-spreadsheet-formula-input > .k-syntax-num.k-syntax-at-point,
.k-spreadsheet-formula-input > .k-syntax-ref.k-syntax-at-point,
.k-spreadsheet-formula-input > .k-syntax-str.k-syntax-at-point {
  text-decoration: underline;
}
.k-spreadsheet-formula-input > .k-series-a,
.k-spreadsheet-formula-input > .k-series-b,
.k-spreadsheet-formula-input > .k-series-c,
.k-spreadsheet-formula-input > .k-series-d {
  background-color: transparent;
}
.k-spreadsheet-selection-highlight {
  border-width: 1px;
  border-style: solid;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.k-spreadsheet-formula-list {
  min-width: 100px;
}
.k-spreadsheet-formula-list .k-item {
  padding: 0 0.3em;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-spreadsheet-sheets-bar {
  position: relative;
}
.k-button.k-spreadsheet-sheets-bar-add {
  position: absolute;
  bottom: 0.2em;
  left: 0.4em;
  z-index: 1;
}
.k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove {
  padding: 0;
  margin: 0.5em 0.5em 0.5em -0.5em;
}
.k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove .k-button-icon {
  padding: 2px;
  line-height: normal;
}
.k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove .k-icon {
  margin: 0;
}
.k-spreadsheet-sheets-items-hint {
  margin-top: 0.1em;
  background: 0 0!important;
  border-width: 0 !important;
}
.k-spreadsheet-editor-button {
  position: absolute;
  width: 20px;
  z-index: 10;
  margin-left: 2px;
  padding: 0 !important;
}
.k-spreadsheet-editor-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hint-wrapper {
  margin-top: 1em;
}
.hint-wrapper:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-left: -0.917em;
  padding-right: 0.917em;
  padding-left: 0.917em;
}
.k-edit-form-container > div:after {
  display: block;
  content: "";
  clear: both;
}
.k-dialog {
  min-width: 90px;
  min-height: 3em;
  max-width: 100%;
  max-height: 100%;
}
.k-dialog.k-window {
  position: fixed;
  padding-top: 0;
}
.k-dialog .k-window-titlebar {
  position: static;
  margin: 0;
  padding: 0.6em 0.8em 1em 0.8em;
  width: auto;
}
.k-dialog .k-window-titlebar .k-dialog-title {
  margin: 0;
  padding: 0;
  width: auto;
  max-width: 98%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2em;
  line-height: 1.2em;
}
.k-dialog .k-content {
  margin: 0;
  padding: 1.8em 1em 1.8em 1em;
}
.k-dialog.k-dialog-titleless .k-content {
  padding: 2.4em;
}
.k-dialog .k-dialog-buttongroup {
  margin: 0;
  padding: 0;
}
.k-dialog a.k-dialog-action.k-dialog-close {
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 10000;
}
.k-dialog a.k-dialog-action.k-dialog-close .k-font-icon {
  background-image: none;
  font-family: KendoUIGlyphs;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  font-size: 1.3em;
  line-height: 1;
  opacity: 1;
  text-indent: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.k-dialog.k-dialog-titleless a.k-dialog-action.k-dialog-close {
  right: 0.5em;
  top: 1em;
}
.k-dialog.k-alert .k-window-titlebar,
.k-dialog.k-confirm .k-window-titlebar,
.k-dialog.k-prompt .k-window-titlebar {
  background: 0 0;
  border-bottom: none;
}
.k-dialog.k-alert .k-content,
.k-dialog.k-confirm .k-content {
  padding: 2.4em;
}
.k-dialog.k-prompt .k-content {
  padding: 1.2em 1.2em 0.6em 1.2em;
}
.k-dialog .k-prompt-container {
  width: auto;
  padding: 0.6em 1.2em 1.8em 1.2em;
}
.k-dialog .k-prompt-container .k-textbox {
  width: 100%;
}
@font-face {
  font-family: "DejaVu Sans";
  src: url(fonts/DejaVu/DejaVuSans.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: 700;
  src: url(fonts/DejaVu/DejaVuSans-Bold.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url(fonts/DejaVu/DejaVuSans-Oblique.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: 700;
  font-style: italic;
  src: url(fonts/DejaVu/DejaVuSans-BoldOblique.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  src: url(fonts/DejaVu/DejaVuSerif.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-weight: 700;
  src: url(fonts/DejaVu/DejaVuSerif-Bold.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-style: italic;
  src: url(fonts/DejaVu/DejaVuSerif-Italic.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-weight: 700;
  font-style: italic;
  src: url(fonts/DejaVu/DejaVuSerif-BoldItalic.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  src: url(fonts/DejaVu/DejaVuSansMono.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-weight: 700;
  src: url(fonts/DejaVu/DejaVuSansMono-Bold.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-style: italic;
  src: url(fonts/DejaVu/DejaVuSansMono-Oblique.ttf?v=1.1) format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-weight: 700;
  font-style: italic;
  src: url(fonts/DejaVu/DejaVuSansMono-BoldOblique.ttf?v=1.1) format("truetype");
}
.kendo-pdf-hide-pseudo-elements:after,
.kendo-pdf-hide-pseudo-elements:before {
  display: none !important;
}
/*# sourceMappingURL=kendo.common.min.css.map */
