.conversion_actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.conversion_formula_tooltip {
  position: absolute;
  margin: -23px 0 0 70px;
  font-size: 16px;
}
