ul,
ol,
dl {
  margin-bottom: 0;
}
.ant-select,
.ant-select-dropdown li {
  font: 12px/1.5 'Oxygen', Arial, sans-serif;
}
.ant-tooltip-inner {
  background-color: #ffffff;
  overflow: hidden;
  font: 12px/1.5 'Oxygen', Arial, sans-serif;
  color: #54595E;
  text-align: left;
  min-height: 15px;
}
.ant-menu-horizontal {
  line-height: 25px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0px 0px;
  padding: 0;
}
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #54595E;
}
.ant-popover {
  z-index: 99999;
}
