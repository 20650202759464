input.k-textbox,
.k-ff input.k-textbox {
  height: 30px;
}
.k-dropdown .k-input,
.k-combobox .k-input {
  height: 28px;
}
.k-numerictextbox .k-numeric-wrap {
  height: 30px;
}
.k-multiselect-wrap .k-input {
  height: 28px;
  margin: 0;
}
.k-animation-container,
.k-animation-container *,
.k-animation-container :after,
.k-block .k-header,
.k-list-container,
.k-widget,
.k-widget *,
.k-widget :before {
  box-sizing: border-box;
}
.k-window-titlebar .k-window-action {
  width: 20px;
  height: 20px;
}
.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input,
.k-selectbox .k-input,
.k-textbox > input {
  height: 28px;
}
.k-editor-toolbar {
  display: flex;
  flex-wrap: wrap;
}
.k-editor-dialog {
  min-width: 400px;
}
.k-window.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-state-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
