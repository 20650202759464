.event-state-home-view__titlebar {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  margin-top: -40px;
  padding: 5px 0;
  text-align: left;
  border-top: 1px solid #fff;
  border-radius: 3px 3px 0 0;
  background-color: #fbfbfb;
  border-bottom: 1px solid #eeeeee;
}
.event-state-home-view__title {
  float: left;
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #3B3E42;
  line-height: 30px;
  text-indent: 15px;
  text-shadow: 0 1px 0 #fff;
}
.event-state-home-view__chart-container {
  height: 166px;
}
.event-state-home-view__footer {
  float: left;
  width: 100%;
  position: absolute;
  height: 40px;
  line-height: 40px;
  bottom: 0;
  border-radius: 0 0 3px 3px;
  text-align: center;
  background-color: #f5f5f5;
  font-size: 24px;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
}
.event-state-home-view__footer.active {
  cursor: pointer;
}
.event-state-home-view .event-state-chart__bars {
  height: 110px;
}
.event-state-home-view .event-state-chart__bar {
  height: 80px;
}
.event-state-home-view .event-state-chart__value {
  position: absolute;
  bottom: 50px;
}
.event-state-home-view .event-state-chart__legend {
  position: absolute;
  top: 80px;
}
.event-state-home-view .event-state-chart__progress-container {
  bottom: 45px;
}
