.form-entry {
  display: flex;
}
.form-entry_width_default {
  max-width: 400px;
}
.form-entry_width_fit {
  width: 100%;
}
.form-entry_width_half {
  width: 50%;
}
.form-entry_disabled {
  opacity: 0.5;
}
.form-entry__label {
  height: 30px;
  line-height: 30px;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  display: flex;
  position: relative;
  align-items: center;
}
.form-entry_validation_failed.form-entry_has-validation .form-entry__field::after,
.form-entry_required .form-entry__field::after {
  content: '*';
  width: 5px;
  height: 5px;
  position: absolute;
  left: 4px;
  display: block;
  color: red;
}
.form-entry__label-text {
  text-indent: 6px;
  flex: 1 0 auto;
}
.form-entry__label-text_smaller {
  font-size: 11px;
}
.form-entry__label-text_very_small {
  font-size: 9px;
}
.form-entry__field {
  display: flex;
  flex: 1;
  position: relative;
}
.form-entry__field > * {
  flex: 1 1 auto;
  margin-right: 10px;
}
.form-entry__field > *.ant-select {
  width: 1px;
}
.form-entry__field > *:last-child {
  margin-right: 0;
}
.form-entry_vertical {
  flex-direction: column;
}
.form-entry_vertical .form-entry__label {
  border-radius: 4px 4px 0 0;
  border-bottom: 0;
}
.form-entry_vertical .form-entry__field .c-textarea {
  resize: vertical;
  border-radius: 0 0 4px 4px;
}
.form-entry_vertical .form-entry__field .c-textbox {
  border-radius: 0 0 4px 4px;
}
.form-entry_horizontal {
  flex-direction: row;
}
.form-entry_horizontal .form-entry__label {
  width: 120px;
  border-radius: 4px 0 0 4px;
  margin-right: 7px;
  flex-shrink: 0;
}
.is-addable > .form-entry__field::after {
  right: 52px;
}
.is-select > .form-entry__field::after {
  right: 26px;
}
#authentication-input .required-field {
  right: 50px !important;
}
