.k-tabstrip-wrapper {
  height: 100%;
  width: 100%;
}
.k-tabstrip-wrapper .k-tabstrip {
  border: 0;
  height: 100%;
}
.k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items {
  background-color: #fbfbfb;
  height: 40px;
  padding: 4px 0 0 10px;
}
.k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items .k-link {
  min-width: 60px;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  text-align: center;
  font-weight: bold;
}
.k-tabstrip-wrapper .k-tabstrip .k-content.k-state-active {
  border-width: 1px 0 0;
  margin: 0;
  border-color: #d8d8d8;
  padding: 0;
}
.k-tabstrip-wrapper .c-tab-strip {
  display: flex;
  flex-direction: column;
}
.k-tabstrip-wrapper .c-tab-strip .k-content.k-state-active {
  height: 0;
  flex: 1 0 auto;
  display: flex !important;
  flex-direction: column;
  background-color: #dee8ec;
}
.k-tabstrip-wrapper .c-tab-strip_scrollable .k-content.k-state-active {
  overflow: auto;
}
