.sb-widget-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.sb-widget-form .widget-form__form-container {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 5px;
  width: 70%;
}
.sb-widget-form .widget-form__size-form {
  margin-bottom: 10px;
}
.sb-widget-form .widget-form__form-actions {
  width: 100%;
  height: 56px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-top: 13px;
  background-color: white;
  box-sizing: border-box;
}
.sb-widget-form .widget-form__form-actions button {
  margin-right: 10px;
}
