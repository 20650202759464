.cw_shared_disks_title {
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}
.cw_shared_disks_enabled {
  padding: 20px;
}
.cw_enable_monitoring {
  display: flex;
  font-size: 13px;
}
div.disk-view__window[role="dialog"] {
  width: 400px;
  overflow: hidden;
}
#cw_shared_disk_menu {
  margin-top: 2px;
}
#cw_shared_disk_menu .cw_actions_trigger {
  margin: unset;
}
#cw_shared_disk_filterpanel {
  padding-right: unset;
  margin-top: -3px;
}
.cw_shared_system_disk div.cw_shared_disk_list.k-grid.k-widget {
  margin-top: 10px;
  border: 1px solid #d8d8d8;
}
#cw_shared_disk_default_container {
  margin: 0 20px;
}
