.cw_service_details_module .k-splitter,
.cw_sladetails_wrapper .k-splitter,
.cw_content_wrapper .k-splitter,
.cw_reportdetails_wrapper .k-splitter,
.script-wrapper .k-splitter,
.cw_kpidetails_wrapper .k-splitter,
.cw_automation_details .k-splitter,
.application_container .k-splitter,
.cw_agentdetails_wrapper .k-splitter,
.admin_accountdetails_cont .k-splitter,
.ruleset-wrapper .k-splitter,
.cost-profile-details .k-splitter {
  overflow-x: visible;
}
.cw_service_details_module .k-splitbar-horizontal .k-collapse-prev,
.cw_sladetails_wrapper .k-splitbar-horizontal .k-collapse-prev,
.cw_content_wrapper .k-splitbar-horizontal .k-collapse-prev,
.cw_reportdetails_wrapper .k-splitbar-horizontal .k-collapse-prev,
.script-wrapper .k-splitbar-horizontal .k-collapse-prev,
.cw_kpidetails_wrapper .k-splitbar-horizontal .k-collapse-prev,
.cw_automation_details .k-splitbar-horizontal .k-collapse-prev,
.application_container .k-splitbar-horizontal .k-collapse-prev,
.cw_agentdetails_wrapper .k-splitbar-horizontal .k-collapse-prev,
.admin_accountdetails_cont .k-splitbar-horizontal .k-collapse-prev,
.ruleset-wrapper .k-splitbar-horizontal .k-collapse-prev,
.cost-profile-details .k-splitbar-horizontal .k-collapse-prev,
.cw_service_details_module .k-splitbar-horizontal .k-expand-prev,
.cw_sladetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_content_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_reportdetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.script-wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_kpidetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_automation_details .k-splitbar-horizontal .k-expand-prev,
.application_container .k-splitbar-horizontal .k-expand-prev,
.cw_agentdetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.admin_accountdetails_cont .k-splitbar-horizontal .k-expand-prev,
.ruleset-wrapper .k-splitbar-horizontal .k-expand-prev,
.cost-profile-details .k-splitbar-horizontal .k-expand-prev {
  width: 24px;
  height: 24px;
  z-index: 10002;
  border: 1px solid #aaa;
  border-radius: 50%;
  padding-top: 1px;
  margin-top: 11px;
  background: white;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  top: 4%;
  left: -10px;
  font-size: 20px;
  position: relative;
}
.cw_service_details_module .placeholder,
.cw_sladetails_wrapper .placeholder,
.cw_content_wrapper .placeholder,
.cw_reportdetails_wrapper .placeholder,
.script-wrapper .placeholder,
.cw_kpidetails_wrapper .placeholder,
.cw_automation_details .placeholder,
.application_container .placeholder,
.cw_agentdetails_wrapper .placeholder,
.admin_accountdetails_cont .placeholder,
.ruleset-wrapper .placeholder,
.cost-profile-details .placeholder {
  position: absolute;
  height: 100%;
  width: 20px;
  background: white;
  margin-top: -40px;
  z-index: 100;
}
.cw_service_details_module .placeholder .label,
.cw_sladetails_wrapper .placeholder .label,
.cw_content_wrapper .placeholder .label,
.cw_reportdetails_wrapper .placeholder .label,
.script-wrapper .placeholder .label,
.cw_kpidetails_wrapper .placeholder .label,
.cw_automation_details .placeholder .label,
.application_container .placeholder .label,
.cw_agentdetails_wrapper .placeholder .label,
.admin_accountdetails_cont .placeholder .label,
.ruleset-wrapper .placeholder .label,
.cost-profile-details .placeholder .label {
  transform: rotate(90deg) translate(105px, -2px);
  font-weight: bold;
}
